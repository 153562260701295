import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../components/toast.service";
import {HttpService} from "../core/http.service";
import {Observable, shareReplay} from "rxjs";
import {OrigenMateria} from "../../../model/master/origen-materia";

@Injectable({
  providedIn: 'root'
})
export class OrigenMateriaHttpService extends HttpService {

  protected BASE = "origen-materia";

  constructor(http: HttpClient,
              toastSvc: ToastService) {
    super(http, toastSvc);
  }

  public getOrigenMateria(): Observable<OrigenMateria[]> {
    return this.get(this.BASE)
        .pipe(shareReplay());
  }

}