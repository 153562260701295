import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";

export const OperadoresInstalacionTableConfig: TablaColumna[] =  [

    {
        header: 'OPERADORES.instalaciones.numOrdenCentro',
        field: "numCentro",
        type: "number"
    },
    {
        header: 'OPERADORES.actividad',
        field: "descripcionActividad",
        type: "string"
    },
    {
        header: 'general.municipio',
        field: "municipio",
        type: "string"
    }
]

export const OperadoresInstalacionEditAccionesConfig: TablaAccion[] = [
    {
        action: "edit",
        icon: "edit",
        toolTip: "Editar"
    },
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure"
    },
    {
        action: "delete",
        icon: "delete",
        toolTip: "Eliminar"
    }
]

export const OperadoresInstalacionViewAccionesConfig: TablaAccion[] = [
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure"
    }
]