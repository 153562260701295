import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HttpService} from "./core/http.service";
import {ToastService} from "../components/toast.service";
import {Observable, shareReplay, tap} from "rxjs";
import {Operador} from "../../model/operadores/operador";
import {Pageable} from "../../model/core/pageable";
import {Solicitud} from "../../model/solicitud/solicitud";
import {TranslateService} from "@ngx-translate/core";
import {Respuesta} from "../../model/core/respuesta";
import {ActividadPrincipal} from "../../model/operadores/actividadPrincipal";
import {DecretoOperador} from "../../model/operadores/decretoOperador";

export enum TipoAutorizacionEnum {
  ETIQUETATGE_DISTRIBUITS = "DISTRIBUITS",
  ETIQUETATGE_ELABORATS = "ELABORATS",
  ETIQUETATGE_HORTOFRUTICOLES = "HORTOFRUTICOLES",
  ETIQUETATGE_VINS = "VINS",
  ETIQUETATGE_IMPORTATS = "IMPORTATS",
}

@Injectable({
  providedIn: 'root'
})
export class OperadoresHttpService extends HttpService {

  private BASE = `operadores`
  private BASE_ID = (id: string) => `${this.BASE}/${id}`
  private SOLICITUDES = (id: string) => `${this.BASE}/${id}/solicitudes`
  private SOLICITUD = (idOperador: string, idSolicitud: string) =>
      `${this.BASE}/${idOperador}/solicitudes/${idSolicitud}`;
  private ACTIVIDADES_PRINCIPALES = `${this.BASE}/actividades-principales`;
  private DECRETO  = (id: string) => `${this.BASE}/${id}/decreto`;

  constructor(http: HttpClient,
              toastSvc: ToastService,
              private translateSvc: TranslateService) {
    super(http, toastSvc);
  }

  public listar(page: number = 1, filtros: object = {}, sort: string = "idOperador", order = "ASC") : Observable<Pageable<Operador>> {
    let httpParams = this.objectToHttpParams(filtros);
    httpParams = httpParams.set("page", page - 1);
    httpParams = httpParams.set("sort", sort);
    httpParams = httpParams.set("order", order);
    return this.get(this.BASE, httpParams);
  }


  public getOperador(idOperador: string): Observable<Operador> {
    return this.get(this.BASE_ID(idOperador));
  }

  getSolicitudes(idOperador: string, page: number, filtros: object = {}): Observable<Pageable<Solicitud>> {
    let httpParams = this.objectToHttpParams(filtros);
    if (page) {
      httpParams = httpParams.set("page", page - 1);
    }
    return this.get(this.SOLICITUDES(idOperador), httpParams)
        .pipe(tap(page => page.content = page.content.map(item => Object.assign(new Solicitud(this.translateSvc), item))));
  }

  public getSolicitud(idOperador: string, idSolicitud: string): Observable<Solicitud> {
    return this.get(this.SOLICITUD(idOperador, idSolicitud));
  }

  modificarOperador(idOperador, operador: any): Observable<Respuesta>{
    return this.put(this.BASE_ID(idOperador), operador)
        .pipe(
            tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("successes.operadorModificatOK")))
        );
  }

  crearOperador(operador: Operador): Observable<Respuesta> {
    return this.post(this.BASE, operador)
        .pipe(
            tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("successes.operadorCreatOK")))
        );
  }

  public getActividadesPrincipales(): Observable<ActividadPrincipal[]> {
    return this.get(this.ACTIVIDADES_PRINCIPALES)
        .pipe(shareReplay());
  }

  public getDecretoOperador(idOperador: string): Observable<DecretoOperador[]> {
    return this.get(this.DECRETO(idOperador));
  }

  public modificarDecretoOperador(idOperador: string, decretoOperador: DecretoOperador[]): Observable<Respuesta> {
    return this.put(this.DECRETO(idOperador), decretoOperador)
        .pipe(
            tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("successes.decretoOperadorModificatOK")))
        );
  }

}
