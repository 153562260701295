import {ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {
  OperadoresAltaAlmacenModalComponent
} from "../../modals/operadores-alta-almacen-modal/operadores-alta-almacen-modal.component";
import {TranslateService} from "@ngx-translate/core";
import {ModalService} from "../../../../core/services/components/modal.service";
import {
    OperadoresAlmacenViewAccionesConfig,
    OperadoresAltaAlmacenEditAccionesConfig,
    OperadoresAltaAlmacenTableConfig
} from "../../pages/operadores-listado-almacen/operadores-almacen-table.config";
import {Pageable} from "../../../../core/model/core/pageable";
import {AltaAlmacen} from "../../../../core/model/operadores/altaAlmacen";
import {lastValueFrom} from "rxjs";
import {AltaInscritoHttpService} from "../../../../core/services/http/operadores/alta-inscrito-http.service";
import {
  DisclaimerEliminarGenericoComponent
} from "../../modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";
import {AltaInscrito} from "../../../../core/model/operadores/altaInscrito";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-operadores-alta-almacen',
  templateUrl: './operadores-alta-almacen.component.html',
  styleUrls: ['./operadores-alta-almacen.component.scss']
})
export class OperadoresAltaAlmacenComponent implements OnInit {

  @Input() idSolicitudInscrito: string;
  @Input() altaInscrito: AltaInscrito;
  @Input() noTieneAlmacen: FormControl;
  @Input() hasAltaAlmacen: boolean;
  @Output() saveAlmacenAltaOp = new EventEmitter<AltaAlmacen>();
  @Output() altaAlmacenUpdated = new EventEmitter<boolean>();
  @Output() noTieneAlmacenChanged = new EventEmitter<void>();

  protected altaInscritoSvc: AltaInscritoHttpService;

  protected tableConfig = OperadoresAltaAlmacenTableConfig;
  protected tableAccionesEdit = OperadoresAltaAlmacenEditAccionesConfig
  protected tableAccionesView = OperadoresAlmacenViewAccionesConfig;

  protected data: Pageable<AltaAlmacen>;
  protected page = 1;
  protected filtros: object;
  protected operadorAction = "edit";

  constructor(protected injector: Injector,
              private translateSvc: TranslateService,
              protected modalSvc: ModalService,
              protected cdr: ChangeDetectorRef) {
    this.altaInscritoSvc = injector.get(AltaInscritoHttpService);
  }

  async ngOnInit(): Promise<void> {
    this.data = await lastValueFrom(this.altaInscritoSvc.getAltaAlmacenList(this.idSolicitudInscrito, this.page, this.filtros));
    this.altaAlmacenUpdated.emit(this.data.content.length > 0);
  }

  protected onAction($event: any) {
    if($event.action == "edit"){
      this.updateAltaAlmacen($event.item);
    }
    else if($event.action == "delete"){
      this.deleteAltaAlmacen($event.item);
    }
  }

  onNoTieneAlmacenCheck(): void {
       this.noTieneAlmacenChanged.emit();
  }

  createAltaAlmacen() {
    this.modalSvc.openDialog(OperadoresAltaAlmacenModalComponent, {idSolicitudInscrito: this.idSolicitudInscrito, altaInscrito: this.altaInscrito})
        .afterClosed().subscribe(newAlmacen => {
      if (newAlmacen) {
        this.altaInscritoSvc.createAltaAlmacen(newAlmacen)
            .subscribe({
              next: () => {
                this.ngOnInit();
              },
              error: (err) => {
                console.error("createAltaAlmacen failed", err);
              }
            });
      }
    });
  }

  updateAltaAlmacen(altaAlmacen: AltaAlmacen) {
    this.modalSvc.openDialog(OperadoresAltaAlmacenModalComponent, {idSolicitudInscrito: this.idSolicitudInscrito, altaAlmacen: altaAlmacen, altaInscrito: this.altaInscrito})
        .afterClosed().subscribe(updatedAlmacen => {
      if (updatedAlmacen) {
        this.altaInscritoSvc.updateAltaAlmacen(updatedAlmacen.idAltaAlmacen, updatedAlmacen)
            .subscribe({
              next: () => {
                this.ngOnInit();
              },
              error: (err) => {
                console.error("updateAltaAlmacen failed", err);
              }
            });
      }
    });
  }

  private deleteAltaAlmacen(altaAlmacen: AltaAlmacen) {
    this.page = 1;
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'OPERADORES.almacenes.disclaimerEliminar'}, {maxWidth: '30vw'})
        .afterClosed().subscribe(accepted => {
      if (accepted) {
        this.altaInscritoSvc.deleteAltaAlmacen(altaAlmacen.idAltaAlmacen)
            .subscribe({
              next: () => {
                this.ngOnInit();
              },
              error: (err) => {
                console.error("deleteAltaAlmacen failed", err);
              }
            });
      }
    });
  }

}
