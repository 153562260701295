import {Injectable} from '@angular/core';
import {HttpService} from "./core/http.service";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../components/toast.service";
import {Producto} from "../../model/autorizacion/producto";
import {Observable, tap} from "rxjs";
import {OrientacionProducto} from "../../model/granja/orientacionProducto";
import {Granja} from "../../model/granja/granja";
import {Pageable} from "../../model/core/pageable";
import {Raza} from "../../model/animal/raza";
import {TipoGanado} from "../../model/animal/tipoGanado";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class GranjaService extends HttpService {

  private BASE = "granjas";
  private GRANJAS_PAGE = (idOperador: string) => `${this.BASE}/${idOperador}/page`
  private GRANJAS_LIST = (idOperador: string) => `${this.BASE}/${idOperador}/list`
  private GRANJA = (idOperador: string, numGranja: string) =>
      `${this.BASE}/${idOperador}/${numGranja}`;
  private GRANJA_CREATE = (idOperador: string) =>
      `${this.BASE}/${idOperador}/create`;

  private ORIENTACIONES_PROD = `${this.BASE}/orientacionesProd`
  private ESPECIES_GANADO = `${this.BASE}/especiesGanado`
  private ESPECIES_GANADO_BY_ORIENTACION = (idOrientacionProd: string) => `${this.BASE}/especiesGanado/${idOrientacionProd}`
  private RAZAS = (idEspecie: string) => `${this.BASE}/razas/${idEspecie}`
  private TIPOS_GANADO =  `${this.BASE}/tiposGanado`

  constructor(http: HttpClient,
              toastSvc: ToastService,
              private translateSvc: TranslateService) {
    super(http, toastSvc);
  }

  public getGranjasPage(idOperador: string, page: number | null = null, filtros: object = {}): Observable<Pageable<Granja>> {
    let httpParams = this.objectToHttpParams(filtros);
    if (page) {
      httpParams = httpParams.set("page", page - 1);
    }
    return this.get(this.GRANJAS_PAGE(idOperador), httpParams)
        .pipe(tap(page => page.content = page.content.map(item => Object.assign(new Granja(), item))));
  }

  public getGranjasList(idOperador: string): Observable<Granja[]> {
    return this.get(this.GRANJAS_LIST(idOperador));
  }

  public getGranja(idOperador: string, numGranja: string): Observable<Granja> {

    return this.get(this.GRANJA(idOperador, numGranja));
  }

  public createGranja(idOperador: string, granja: Granja): Observable<Granja> {
    return this.post(this.GRANJA_CREATE(idOperador), granja)
        .pipe(
            tap(_=> this.toastSvc.showSuccess(this.translateSvc.instant("successes.createGranjaOk")))
        )
  }
  public updateGranja(idOperador: string, numGranja: string, granja: Granja): Observable<Granja> {
    return this.put(this.GRANJA(idOperador, numGranja), granja);
  }

  public eliminarGranja(idOperador: string, numGranja: string) {
    return this.delete(this.GRANJA(idOperador, numGranja));
  }

  public getOrientacionesProducto(): Observable<OrientacionProducto[]> {

    return this.get(this.ORIENTACIONES_PROD);
  }

  public getEspeciesGanado(): Observable<Producto[]> {

    return this.get(this.ESPECIES_GANADO);
  }

  public getEspeciesGanadoByOrientacionProd(idOrientacionProd: string): Observable<Producto[]> {

    return this.get(this.ESPECIES_GANADO_BY_ORIENTACION(idOrientacionProd));
  }



  public getRazas(idEspecie: string): Observable<Raza[]> {

    return this.get(this.RAZAS(idEspecie));
  }

  public getTiposGanado(): Observable<TipoGanado[]> {

    return this.get(this.TIPOS_GANADO);
  }
}
