import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './pages/login/login.component';
import {AppRoutingModule} from 'src/app/app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {ChangePasswordRecoverComponent} from './pages/change-password-recover/change-password-recover.component';

@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, ChangePasswordRecoverComponent],
    imports: [
        CommonModule,
        AppRoutingModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
  exports: [LoginComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class LoginModule { }
