import {Component, Injector, OnInit} from '@angular/core';
import {ModalService} from "../../../../core/services/components/modal.service";
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {Pageable} from "../../../../core/model/core/pageable";
import {ActivatedRoute, Router} from "@angular/router";
import {lastValueFrom} from "rxjs";
import {Page} from "../../../../core/model/core/pages/page";
import {Granja} from "../../../../core/model/granja/granja";
import {
  OperadoresGranjaEditAccionesConfig,
  OperadoresGranjaTableConfig,
  OperadoresGranjaViewAccionesConfig
} from "./operadores-granja.table-config";
import {GranjaService} from "../../../../core/services/http/granja.service";
import {
  DisclaimerEliminarGenericoComponent
} from "../../modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";

@Component({
  selector: 'app-operadores-listado-granja',
  templateUrl: './operadores-listado-granja.component.html',
  styleUrls: ['./operadores-listado-granja.component.scss']
})
export class OperadoresListadoGranjaComponent extends Page  implements OnInit {
  protected modalSvc: ModalService;
  protected operadorSvc: OperadoresHttpService;
  protected granjaSvc: GranjaService;

  protected tableConfig = OperadoresGranjaTableConfig;
  protected tableAccionesEdit = OperadoresGranjaEditAccionesConfig;
  protected tableAccionesView = OperadoresGranjaViewAccionesConfig;

  protected data: Pageable<Granja>;
  protected page = 1;
  protected idOperador: string;
  protected action;
  protected operadorAction;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              injector: Injector) {
    super();

    this.modalSvc = injector.get(ModalService)
    this.operadorSvc = injector.get(OperadoresHttpService);
    this.granjaSvc = injector.get(GranjaService);

    this.data = new Pageable<Granja>();
    this.page = 1;
    this.idOperador = this.activatedRoute.snapshot.params['idOperador'];
    this.action = this.activatedRoute.snapshot.params['action'];
  }

  async ngOnInit() {
    this.data = await lastValueFrom(this.granjaSvc.getGranjasPage(this.idOperador, this.page));
    this.activatedRoute.params.subscribe(params => {
      this.operadorAction = params['action'];
    });
  }

  public async onPageChange(page: number) {
    this.page = page;
    this.data = await lastValueFrom(this.granjaSvc.getGranjasPage(this.idOperador, page));
  }

  public onAction($event: any) {
    switch($event.action){
      case "view":
        this.router.navigate(['granja', $event.item.numGranja, 'view'], {relativeTo: this.activatedRoute})
        break;
      case "edit":
        this.router.navigate(['granja', $event.item.numGranja, 'edit'], {relativeTo: this.activatedRoute});
        break;
      case "delete":
        this.deleteGranja($event.item);
        break;
    }
  }

  public createGranja(){
    this.router.navigate(['granja', "create"], {relativeTo: this.activatedRoute});
  }

  private deleteGranja(granja: Granja){
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'OPERADORES.granjas.disclaimerEliminar'}, {maxWidth: '30vw'})
      .afterClosed().subscribe(accepted => {
      if (accepted) {
        this.granjaSvc.eliminarGranja(this.idOperador, granja.numGranja).subscribe(value => this.reloadCurrentRoute());
      }
    })
  }

  onBack(){
    this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
