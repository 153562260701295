<app-etiquetas-view-base
        *ngIf="etiquetasSvc.hasRequiredParamaters"
        [title]="'ETIQUETAS.titleImportats' | translate"
        [subTitle]="'ETIQUETAS.subTitleTramite' | translate:{
        'solicitud': etiquetasSvc.idSolicitud
    }"
>
    <form [formGroup]="formGroup" class="col-12 overflow-hidden">
        <div class="cards-container row">
            <div class="col-12">
                <mat-card class="card shadow">
                    <mat-card-title>
                        {{'ETIQUETAS.productosSolicitudTitulo' | translate}}
                    </mat-card-title>

                    <mat-card-content>
                        <div *ngIf="!formIsValid">
                            <div class="col-8 ms-auto d-flex justify-content-end">
                                <div class="col-3">
                                    <mat-icon class="red icon-margin mat-icon-middle">edit</mat-icon>
                                    {{'ETIQUETAS.leyendaProducto' | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex">
                            <div class="col-5 mr-auto">
                                <app-input-text
                                        formControlName="busqueda"
                                        [label]="'general.buscarPorNombreComercial' | translate"
                                        [showLabel]="false"
                                >
                                </app-input-text>
                            </div>
                        </div>

                        <div class="col-2 ms-auto d-flex justify-content-end">
                            <span class="badge rounded-pill bg-dark">{{'general.total' | translate}}: {{productos.length}}</span>
                        </div>

                        <div class="py-2">
                            <app-generic-table
                                    [data]="productosVisibles"
                                    [textNoData]="'ETIQUETAS.noDataAddProductos' | translate"
                                    [columnsConfig]="tableConfig"
                                    [actionsConfig]="tableAcciones"
                                    (onAction)="onActionTable($event)"
                            >
                            </app-generic-table>
                        </div>

                        <div *ngIf="!tramiteEsmena" class="col-12 mt-1 d-flex justify-content-end">
                            <button mat-raised-button color="primary" class="px-2 mx-1 fileUpload" (click)="onAddProducto()">
                                {{'ETIQUETAS.addOrUpdateProduct' | translate}}
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" style="margin-right: 10px;"
                    (click)="onGuardar()">
                {{'ETIQUETAS.guardarBorrador' | translate}}
            </button>
            <button mat-raised-button color="primary" class="px-2" [disabled]="!formIsValid"
                    (click)="onConfirmar()"
            >
                {{'ETIQUETAS.continuarTramitacion' | translate}}
            </button>
        </div>
    </form>




</app-etiquetas-view-base>
