import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";

export const OperadoresGranjaTableConfig: TablaColumna[] =  [
    {
        header: 'OPERADORES.granjas.numGranja',
        field: "numGranja",
        type: "string"
    },
    {
        header: 'general.nombre',
        field: "nombreGranja",
        type: "number"
    },
    {
        header: 'OPERADORES.granjas.orientacionProd',
        field: "orientacionProd",
        type: "string"
    },
    {
        header: 'OPERADORES.poligiono',
        field: "poligono",
        type: "number"
    },
    {
        header: 'OPERADORES.parcela',
        field: "parcela",
        type: "number"
    },
    {
        header: 'general.municipio',
        field: "municipio",
        type: "number"
    },
    {
        header: 'OPERADORES.granjas.superficieCubierta',
        field: "superficieCubierta",
        type: "number"
    },
    {
        header: 'OPERADORES.granjas.superficiePatio',
        field: "superficiePatio",
        type: "number"
    }
]

export const OperadoresGranjaEditAccionesConfig: TablaAccion[] = [
    {
        icon: 'edit',
        action: 'edit',
        toolTip: 'Editar granja'
    },
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure dades"
    },
    {
        icon: 'delete',
        action: 'delete',
        toolTip: 'Eliminar granja'
    }
]

export const OperadoresGranjaViewAccionesConfig: TablaAccion[] = [
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure dades"
    }
]