import {BaseObj} from "../core/baseObj";
import {Comarca} from "./comarca";
import {Provincia} from "./provincia";

export class Municipio extends BaseObj {

    codigoPostal: string = "";
    codigoIne: string = "";
    codigoLocal: string = "";
    descripcion: string = "";
    comarca: Comarca;
    provincia: Provincia;
    inspector: string;
}