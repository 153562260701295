import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SolicitudProductoCompuesto} from "../../../../core/model/etiquetas/solicitud-producto-compuesto";
import {
    EditProductoCompuestoBaseModalComponent
} from "../edit-producto-compuesto-base-modal/edit-producto-compuesto-base-modal.component";
import {FormControl} from "@angular/forms";
import {ModalEdicion} from "../../pages/etiquetas-base.component";


@Component({
    templateUrl: './edit-producto-elaborado-modal.component.html'
})
export class EditProductoElaboradoModal extends EditProductoCompuestoBaseModalComponent implements OnInit, ModalEdicion {

    constructor(@Inject(MAT_DIALOG_DATA) data: { producto: SolicitudProductoCompuesto},
                dialogRef: MatDialogRef<EditProductoElaboradoModal>,
                injector: Injector) {
        super(data, dialogRef, injector);

        this.formGroup.addControl("usaNitratos", new FormControl(false));
    }
    enableReadOnly() {
        this.readOnly = true;
        this.formGroup.disable({emitEvent: false});
        this.formGroup.get("idSolicitudProducto")?.enable({ emitEvent: false});
        this.formGroup.get("idProducto")?.enable({emitEvent: false});
    }

    enableReadOnlyEstado3() {
        this.enableReadOnly();
        this.formGroup.get("idCategoriaEcologica")?.enable({ emitEvent: false});
    }

    protected onSubmit(): void {
        this.formGroup.get("requiereEtiqueta")?.setValue(true);
        this.dialogRef.close(this.formGroup.value);
    }
}
