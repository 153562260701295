import {BaseObj} from "../core/baseObj";
import {ProdAlmacenados} from "./prodAlmacenados";
import {Municipio} from "../localizacion/municipio";

export class AlmacenPk {

    idInscrito!: number;
    numOrdenAlmacen!: number;
    numAlmacen!: string;

}

export class Almacen extends BaseObj {

    idInscrito!: number;
    numOrdenAlmacen!: number;
    numAlmacen!: number;
    nombreAlmacen: string = "";
    municipio!: Municipio;
    poligono: string = "";
    parcela: string = "";
    recinto: string = "";
    direccion: string = "";
    superficie: string = "";
    cualificacionAlmacen: string = "";
    usuario: string = "";
    compartidoCon: string = "";

    compartido: boolean;
    localizadoEnPoblacion: boolean;

    maquinaria: boolean;
    fertilizantes: boolean;
    semillas: boolean;
    pajaForraje: boolean;
    contProductos: boolean;

    prodAlmacenados?: ProdAlmacenados;

    constructor(json?: object) {
        super(json);
    }

}



