<mat-card class="card shadow mt-2" *ngIf=!viewResultadoImportDarp>

    <div class="col-12 p-2 mt-2 d-flex justify-content-end">
        <button *ngIf="action=='edit'" mat-raised-button color="basic" class="px-2" type="button"
                (click)="importDARP()"
        >
            {{'OPERADORES.parcelas.importarDatosDARP' | translate}}
        </button>
    </div>

    <div class="col-12 p-2 mt-2 d-flex justify-content-end">
        <button *ngIf="action=='edit'" mat-raised-button color="basic" class="px-2" type="button"
                (click)="createParcela()"
        >
            {{'OPERADORES.parcelas.anadirParcela' | translate}}
        </button>
    </div>
    <br/>

    <app-generic-table *ngIf="action == 'view'"
        [data]="data.content"
        [columnsConfig]="tableConfig"
        [actionsConfig]="tableAccionesView"
        [paginated]="true"
        [collectionSize]="data.totalElements"
        [totalPages]="data.totalPages"
        (onPageChange)="onPageChange($event)"
        [page]="page"
        (onAction)="onAction($event)"
    >
    </app-generic-table>

    <app-generic-table *ngIf="action != 'view'"
            [data]="data.content"
            [columnsConfig]="tableConfig"
            [actionsConfig]="tableAccionesEdit"
            [paginated]="true"
            [collectionSize]="data.totalElements"
            [totalPages]="data.totalPages"
            (onPageChange)="onPageChange($event)"
            [page]="page"
            (onAction)="onAction($event)"
    >
    </app-generic-table>
    <br/>
</mat-card>

<mat-card class="card shadow mt-2" *ngIf=viewResultadoImportDarp>
    <mat-card-content>

        <div class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button *ngIf="action=='edit'" mat-raised-button color="basic" class="px-2" type="button"
                    (click)="reloadCurrentRoute()"
            >
                {{'OPERADORES.parcelas.verImportarDatosDARP' | translate}}
            </button>
        </div>

        <form [formGroup]="formGroup">

            <h2>{{'OPERADORES.parcelas.darpResultadoProceso' | translate}}</h2>
            <div class="row">
                <div class="col-md-6 col-xl-3">
                    <app-input-text
                            [label]="'OPERADORES.parcelas.darpIdentificacion' | translate"
                            formControlName="ident"
                            [isDisabled] = true
                    >
                    </app-input-text>
                </div>
                <div class="col-md-6 col-xl-3">
                    <app-input-text
                            [label]="'OPERADORES.parcelas.darpFechaProceso' | translate"
                            formControlName="dataProces"
                            [isDisabled] = true
                    >
                    </app-input-text>
                </div>
                <div class="col-md-6 col-xl-3">
                    <app-input-text
                            [label]="'OPERADORES.parcelas.darpNombreArchivo' | translate"
                            formControlName="nomFitxer"
                            [isDisabled] = true
                    >
                    </app-input-text>
                </div>
                <div class="col-md-6 col-xl-3">
                    <app-input-text
                            [label]="'OPERADORES.parcelas.darpNumProceso' | translate"
                            formControlName="numProces"
                            [isDisabled] = true
                    >
                    </app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-xl-3">
                    <app-input-text
                            [label]="'OPERADORES.parcelas.darpRegistrosProcesados' | translate"
                            formControlName="registresProcessats"
                            [isDisabled] = true
                    >
                    </app-input-text>
                </div>
                <div class="col-md-6 col-xl-3">
                    <app-input-text
                            [label]="'OPERADORES.parcelas.darpRegistrosSubidos' | translate"
                            formControlName="registresCarregats"
                            [isDisabled] = true
                    >
                    </app-input-text>
                </div>
                <div class="col-md-6 col-xl-3">
                    <app-input-text
                            [label]="'OPERADORES.parcelas.darpRegistrosRechazados' | translate"
                            formControlName="registresRebutjats"
                            [isDisabled] = true
                    >
                    </app-input-text>
                </div>
            </div>
        </form>

        <br/>
        <hr>

        <h2>{{'OPERADORES.parcelas.darpIncidencias' | translate}}</h2>
        <app-generic-table *ngIf="action != 'view'"
                           [data]="logDun"
                           [columnsConfig]="tableConfigImport"
                           [actionsConfig]="tableAccionesImport"
                           [paginated]="true"
                           [collectionSize]="logDun.length"
                           (onPageChange)="onPageChange($event)"
                           [page]="page"
        >
        </app-generic-table>
    </mat-card-content>
</mat-card>

<button mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
    <mat-icon>arrow_back</mat-icon>
    {{"general.volver" | translate}}
</button>

