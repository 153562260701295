import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-drop-file-zone',
  templateUrl: './drop-file-zone.component.html',
  styleUrls: ['./drop-file-zone.component.scss']
})
export class DropFileZoneComponent {

  @Input() multiple = false;
  @Input() accept: string = "";
  @Output() onFilesDrop: EventEmitter<File[]> = new EventEmitter<File[]>();

  files: File[] = [];
  public static readonly ALLOWED_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];

  constructor() {}

  fileBrowseHandler(input: HTMLInputElement) {
    const files: FileList | null = input.files;
    if (files) {
      this.processAndEmitFiles(Array.from(files));
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files: FileList | undefined = event.dataTransfer?.files;
    if (files) {
      this.processAndEmitFiles(Array.from(files));
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  clearFiles() {
    this.files = [];
  }

  private processAndEmitFiles(files: File[]) {
    this.files = files.filter(file => DropFileZoneComponent.isFileTypeAllowed(file.type));
    if (this.files.length > 0) {
      this.onFilesDrop.emit(this.files);
    }
  }

  public static isFileTypeAllowed(fileType: string): boolean {
    return DropFileZoneComponent.ALLOWED_TYPES.includes(fileType);
  }
}


