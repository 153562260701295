import {Injectable} from '@angular/core';
import {HttpService} from "./http/core/http.service";

@Injectable({
  providedIn: 'root'
})
export class NavigationService extends HttpService {

  public currentTabIndex = 0;
}
