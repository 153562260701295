import {Component, Injector, OnInit} from '@angular/core';
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {lastValueFrom} from "rxjs";
import {
  OperadoresInstalacionEditAccionesConfig,
  OperadoresInstalacionTableConfig,
  OperadoresInstalacionViewAccionesConfig
} from "./operadores-instalacion-table.config";
import {Pageable} from "../../../../core/model/core/pageable";
import {ActivatedRoute, Router} from "@angular/router";
import {Page} from "../../../../core/model/core/pages/page";
import {Instalacion} from "../../../../core/model/instalacion/instalacion";
import {InstalacionHttpService} from "../../../../core/services/http/operadores/instalacion-http.service";
import {NavigationService} from "../../../../core/services/navigation.service";
import {ModalService} from "../../../../core/services/components/modal.service";
import {
  DisclaimerEliminarGenericoComponent
} from "../../modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";

@Component({
  selector: 'app-operadores-listado-instalacion',
  templateUrl: './operadores-listado-instalacion.component.html',
  styleUrls: ['./operadores-listado-instalacion.component.scss']
})
export class OperadoresListadoInstalacionComponent extends Page implements OnInit {

  protected modalSvc: ModalService;
  protected operadorSvc: OperadoresHttpService;
  protected instalacionSvc: InstalacionHttpService;

  protected tableConfig = OperadoresInstalacionTableConfig;
  protected tableAccionesEdit = OperadoresInstalacionEditAccionesConfig;
  protected tableAccionesView = OperadoresInstalacionViewAccionesConfig;

  protected data: Pageable<Instalacion>;
  protected page = 1;
  protected idOperador: string;
  protected operadorAction;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private navService: NavigationService,
              injector: Injector) {
    super();

    this.modalSvc = injector.get(ModalService);
    this.operadorSvc = injector.get(OperadoresHttpService);
    this.instalacionSvc = injector.get(InstalacionHttpService);

    this.data = new Pageable<Instalacion>();
    this.page = 1;
    this.idOperador = this.activatedRoute.snapshot.params['idOperador'];

  }

  async ngOnInit() {
    this.data = await lastValueFrom(this.instalacionSvc.getInstalaciones(this.idOperador, this.page));
    this.activatedRoute.params.subscribe(params => {
      this.operadorAction = params['action']; });
  }

  public async onPageChange(page: number) {
    this.page = page;
    this.data = await lastValueFrom(this.instalacionSvc.getInstalaciones(this.idOperador, page));
  }

  protected onAction($event: any){
    if ($event.action == "view") {
      this.router.navigate(['instalacio', $event.item.numOrdenInstalacion, "view"], {relativeTo: this.activatedRoute})
    }
    else if($event.action == "edit"){
      this.router.navigate(['instalacio', $event.item.numOrdenInstalacion, "edit"], {relativeTo: this.activatedRoute})
    }
    else if($event.action == "delete"){
      this.deleteInstalacion($event.item);
    }
  }

  public createInstalacion(){
    this.router.navigate(['instalacio', "create"], {relativeTo: this.activatedRoute})
  }

  private deleteInstalacion(item){
    this.page = 1;
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'OPERADORES.instalaciones.disclaimerEliminar'}, {maxWidth: '30vw'})
        .afterClosed().subscribe(accepted => {
      if(accepted){
        this.instalacionSvc.deleteInstalacion(this.idOperador, item)
            .subscribe(_ => this.onPageChange(this.page));
      }
    });
  }

  onBack(){
    this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
  }
}
