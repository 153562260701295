import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-disclaimer-eliminar-etiqueta',
  templateUrl: './disclaimer-eliminar-generico.component.html',
  styleUrls: ['./disclaimer-eliminar-generico.component.scss']
})
export class DisclaimerEliminarGenericoComponent {

  protected textoDisclaimer: string;

  constructor(protected dialogRef: MatDialogRef<DisclaimerEliminarGenericoComponent>,
              @Inject(MAT_DIALOG_DATA) data: {textoDisclaimer: string}) {
    this.textoDisclaimer = data.textoDisclaimer;
  }

}
