import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss']
})
export class HeaderTitleComponent implements OnInit {

  @Input() icon!: string;
  @Input() title!: string;
  @Input() subtitle: string | undefined;

  constructor() {
    this.subtitle = "";
  }

  ngOnInit(): void {
  }

}
