import {Injectable} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, tap} from 'rxjs/operators';
import {ToastService} from "../../components/toast.service";

@Injectable({
  providedIn: 'root'
})
export abstract class HttpService {

  protected baseUrl = environment.backendUrl;

  protected constructor(protected httpClient: HttpClient, protected toastSvc: ToastService) { }

  protected get(endpoint: string, params?: HttpParams): Observable<any> {
    return this.httpClient.get(this.baseUrl+endpoint, { params });
  }

  protected delete(endpoint:string, params = {}): Observable<{}> {
    return this.httpClient.delete(this.baseUrl+endpoint)
    .pipe(
      catchError(this.handleError)  
    );
  }

  protected post(endpoint:string, params: Object = {}, queryParams: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.post(this.baseUrl+endpoint, params, {
      params: queryParams
    })
    .pipe(
      catchError(this.handleError)  
    );
  }

  protected postBlob(endpoint:string, params: Object): Observable<{}> {
    return this.httpClient.post(this.baseUrl+endpoint, params, {responseType: 'blob' as 'json'})
    .pipe(
      catchError(this.handleError)  
    );
  }

  protected postText(endpoint:string, params: Object): Observable<{}> {
    return this.httpClient.post(this.baseUrl+endpoint, params, {responseType: 'text'})
    .pipe(
      catchError(this.handleError)  
    );
  }

  protected put(endpoint:string, params: Object, queryParams: HttpParams | undefined = undefined): Observable<any> {
    return this.httpClient.put(this.baseUrl+endpoint, params, {params: queryParams} )
    .pipe(
      catchError(this.handleError)  
    );
  }

  protected patch(endpoint:string, params: Object, queryParams: HttpParams | undefined = undefined): Observable<any> {
    return this.httpClient.patch(this.baseUrl+endpoint, params, {params: queryParams} )
        .pipe(
            catchError(this.handleError)
        );
  }

  protected downloadFile(endpoint: string, defaultName = "") {
    this.httpClient.get(this.baseUrl + endpoint,
        { observe: 'response', responseType: "blob"})
        .subscribe((res: HttpResponse<Blob>) => {
          if (res.body) {
            let url = window.URL.createObjectURL(res.body);
            let anchor = document.createElement("a");
            let match = res.headers.get('content-disposition')?.match(/filename="(.+)"/);

            if (match && match.length > 1)
              anchor.download = match[1];
            else if (defaultName != "") {
              anchor.download = defaultName;
            }

            anchor.href = url;
            anchor.click();
          }
        });
  }

  protected handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
    } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    if (error.status != 409) console.log(errorMessage);
    
    return throwError(() => {
      return {
        code: error.status,
        message: errorMessage
      }
    });
  }

  protected toastHttpDecorator(obs: Observable<any>, doingText: string, succesText: string, errorText?: string) {

    this.toastSvc.show(doingText);

    return obs
        .pipe(
            tap((event: any) => {
              if(event || event.codigoRespuesta === 200){
                this.toastSvc.showSuccess(succesText);
              }
              else{
                this.toastSvc.showError(event.mensaje);
              }
            }),
            catchError(err => {
              if (errorText && err.code != 409) {
                this.toastSvc.showError(errorText)
              }
              throw err;
            })
        );
  }

  protected objectToHttpParams(json: object) : HttpParams {
    let map = new Map(Object.entries(json));
    let httpParams = new HttpParams();

    map.forEach((value, key, map) => {
      if (value) {
        httpParams = httpParams.set(key, value);
      }
    })

    return httpParams;
  }


}
