import {Injectable} from "@angular/core";
import {HttpService} from "./core/http.service";
import {Observable, tap} from "rxjs";
import {Pageable} from "../../model/core/pageable";
import {Autorizacion} from "../../model/autorizacion/autorizacion";
import {TipoAutorizacionEnum} from "./operadores-http.service";
import {Etiqueta} from "../../model/etiquetas/etiqueta";
import {AutorizacionAdjunto} from "../../model/autorizacion/autorizacion-adjunto";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../components/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {AttachFile} from "../../model/autorizacion/AttachFile";

@Injectable({
    providedIn: 'root'
})
export class AutorizacionesHttpService extends HttpService {
    private BASE = (idOperador: string) => `autorizaciones/${idOperador}`;
    private BASE_ID = (idOperador: string, idAutorizacion: number) =>
        `${this.BASE(idOperador)}/${idAutorizacion}`;

    private ETIQUETA = (idOperador: string, idAutorizacion: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/etiqueta`;
    private ETIQUETA_DOWN = (idOperador: string, idAutorizacion: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/etiqueta/download`;

    private AUTO_DOWN = (idOperador: string, idAutorizacion: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/download`;

    private GET_NAME_AUTO_DOWN = (idOperador: string, idAutorizacion: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/getFileName`;
    private NO_AUTO_DOWN = (idOperador: string, idAutorizacion: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/downloadNoAutorizacion`;

    private ADJUNTOS = (idOperador: string, idAutorizacion: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/adjuntos`;
    private ADJUNTOS_MULTIPLES = (idOperador: string, idAutorizacion: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/adjuntos/multiples`;
    private ADJUNTOS_ID = (idOperador: string, idAutorizacion: number, idAutorizacionAdjunto: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/adjuntos/${idAutorizacionAdjunto}`;
    private ADJUNTOS_DOWN = (idOperador: string, idAutorizacion: number, idAutorizacionAdjunto: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/adjuntos/${idAutorizacionAdjunto}/download`;

    protected INGREDIENTES_ID = (idOperador: string, idAutorizacion: number, idIngrediente: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/ingredientes/${idIngrediente}`;
    protected INGREDIENTES = (idOperador: string, idAutorizacion: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/ingredientes`;

    private BAJA = (idOperador: string) =>
        `${this.BASE(idOperador)}/baja`;
    protected DAR_BAJA = (idOperador: string, idAutorizacion: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/baja`;
    protected DAR_ALTA = (idOperador: string, idAutorizacion: number) =>
        `${this.BASE_ID(idOperador, idAutorizacion)}/alta`;


    private SIN_ACTUALIZADOS = (idOperador: string) => `autorizaciones/actualizados/${idOperador}`;

    private ELABORATS_VINS_ALTA = (idOperador: string) => `autorizaciones/${idOperador}/elaborats-vins/alta`;

    private ELABORATS_VINS_BAJA = (idOperador: string) => `autorizaciones/${idOperador}/elaborats-vins/baja`;

    constructor(http: HttpClient,
                toastSvc: ToastService,
                private translateSvc: TranslateService) {
        super(http, toastSvc);
    }

    public getAutorizaciones(idOperador: string, tipoAutorizacion: TipoAutorizacionEnum, page: number | null = null, filtros: object = {}): Observable<Pageable<Autorizacion>> {
        let httpParams = this.objectToHttpParams(filtros);
        if (page) {
            httpParams = httpParams.set("page", page - 1);
        }

        httpParams = httpParams.set("tipoAutorizacion", tipoAutorizacion);
        return this.get(this.BASE(idOperador), httpParams)
            .pipe(tap(page => page.content = page.content.map(item => Object.assign(new Autorizacion(), item))));
    }

    public getAutorizacionesElaboratsVinsAlta(idOperador: string, tipoAutorizacion: TipoAutorizacionEnum, page: number | null = null, filtros: object = {}): Observable<Pageable<Autorizacion>> {
        let httpParams = this.objectToHttpParams(filtros);
        if (page) {
            httpParams = httpParams.set("page", page - 1);
        }

        httpParams = httpParams.set("tipoAutorizacion", tipoAutorizacion);
        return this.get(this.ELABORATS_VINS_ALTA(idOperador), httpParams)
            .pipe(tap(page => page.content = page.content.map(item => Object.assign(new Autorizacion(), item))));
    }

    public getAutorizacionesElaboratsVinsBaja(idOperador: string, tipoAutorizacion: TipoAutorizacionEnum, page: number | null = null, filtros: object = {}): Observable<Pageable<Autorizacion>> {
        let httpParams = this.objectToHttpParams(filtros);
        if (page) {
            httpParams = httpParams.set("page", page - 1);
        }

        httpParams = httpParams.set("tipoAutorizacion", tipoAutorizacion);
        return this.get(this.ELABORATS_VINS_BAJA(idOperador), httpParams)
            .pipe(tap(page => page.content = page.content.map(item => Object.assign(new Autorizacion(), item))));
    }

    public getAutorizacionesBaja(idOperador: string, tipoAutorizacion: TipoAutorizacionEnum, page: number | null = null, filtros: object = {}): Observable<Pageable<Autorizacion>> {
        let httpParams = this.objectToHttpParams(filtros);
        if (page) {
            httpParams = httpParams.set("page", page - 1);
        }

        httpParams = httpParams.set("tipoAutorizacion", tipoAutorizacion);
        return this.get(this.BAJA(idOperador), httpParams)
            .pipe(tap(page => page.content = page.content.map(item => Object.assign(new Autorizacion(), item))));
    }

    public getAutorizacion(idOperador: string, idAutorizacion: number): Observable<Autorizacion> {
        return this.get(this.BASE_ID(idOperador, idAutorizacion));
    }

    public crearAutorizacion(idOperador: string, autorizacion: Autorizacion) {
        return this.post(this.BASE(idOperador), autorizacion);
    }
    public modificarAutorizacion(idOperador: string, autorizacion: Autorizacion){
        return this.put(this.BASE(idOperador), autorizacion)
            .pipe(tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("OPERADORES.autorizacionModificadaOK"))));
    }

    public bajaAutorizacion(idOperador: string, idAutorizacion: number){
        return this.put(this.DAR_BAJA(idOperador, idAutorizacion), {});
    }

    public altaAutorizacion(idOperador: string, idAutorizacion: number, usuarioLogeado: string){
        return this.put(this.DAR_ALTA(idOperador, idAutorizacion), { usuarioLogeado: usuarioLogeado });
    }

    public eliminarAutorizacion(idOperador: string, autorizacion: Autorizacion){
        return this.delete(this.BASE_ID(idOperador,autorizacion.numOrdenAutorizacion));
    }

    public async downloadAutorizacion(idOperador: string, autorizacion: Autorizacion, filename: string) : Promise<void> {
        return this.downloadFile(this.AUTO_DOWN(idOperador, autorizacion.numOrdenAutorizacion), filename);
    }

    public getFileName(idOperador: string, autorizacion: Autorizacion) {
        return this.get(this.GET_NAME_AUTO_DOWN(idOperador,autorizacion.numOrdenAutorizacion));
    }


    //ETIQUETAS
    public getEtiquetaAutorizacion(idOperador: string, idAutorizacion: number): Observable<Etiqueta> {
        return this.get(this.ETIQUETA(idOperador, idAutorizacion));
    }

    public downloadEtiquetaAutorizacion(idOperador: string, idAutorizacion: number) : void {
        return this.downloadFile(this.ETIQUETA_DOWN(idOperador,idAutorizacion));
    }

    public crearEtiqueta(idOperador: string, idAutorizacion: number, file: File) {
        let formData = new FormData();
        formData.append("file", file);

        return this.post(this.ETIQUETA(idOperador, idAutorizacion), formData);
    }

    public eliminarEtiqueta(idOperador: string, idAutorizacion: number) {
        return this.delete(this.ETIQUETA(idOperador, idAutorizacion));
    }

    //ADJUNTOS
    public getAdjuntosAutorizacion(idOperador: string, idAutorizacion: number): Observable<AutorizacionAdjunto[]> {
        return this.get(this.ADJUNTOS(idOperador, idAutorizacion));
    }

    public downloadAdjuntoAutorizacion(idOperador: string, idAutorizacion: number, idAutorizacionAdjunto: number) : void {
        return this.downloadFile(this.ADJUNTOS_DOWN(idOperador, idAutorizacion, idAutorizacionAdjunto));
    }

    public crearAdjunto(idOperador: string, idAutorizacion: number, file: File) {
        let formData = new FormData();
        formData.append("file", file);

        return this.post(this.ADJUNTOS(idOperador, idAutorizacion), formData);
    }

    public crearAdjuntos(idOperador: string, idAutorizacion: number, attachFiles: AttachFile[]) {
        let formData = new FormData();
        for (const attachFile of attachFiles) {
            formData.append("files", attachFile.file);
        }
        return this.post(this.ADJUNTOS_MULTIPLES(idOperador, idAutorizacion), formData);
    }

    public eliminarAdjunto(idOperador: string, idAutorizacion: number, idAutorizacionAdjunto: number) {
        return this.delete(this.ADJUNTOS_ID(idOperador, idAutorizacion, idAutorizacionAdjunto))
    }


    //INGREDIENTES
    public crearIngrediente(idOperador: string, numOrdenAutorizacion: number, nuevoIngrediente: any) {
        return this.post(this.INGREDIENTES(idOperador, numOrdenAutorizacion), nuevoIngrediente);
    }

    public modificarIngrediente(idOperador: string, numOrdenAutorizacion: number, numOrden, ingredienteMod: any) {
        return this.put(this.INGREDIENTES_ID(idOperador, numOrdenAutorizacion, numOrden), ingredienteMod);
    }

    public eliminarIngrediente(idOperador: string, numOrdenAutorizacion: number, numOrden) {
        return this.delete(this.INGREDIENTES_ID(idOperador, numOrdenAutorizacion, numOrden));
    }

    public getAutorizacionesActualizacion(idOperador: string, tipoAutorizacion: TipoAutorizacionEnum, idSolicitud: string, page: number | null = null, filtros: object = {}): Observable<Pageable<Autorizacion>> {
        let httpParams = this.objectToHttpParams(filtros);
        if (page) {
            httpParams = httpParams.set("page", page - 1);
        }

        httpParams = httpParams.set("tipoAutorizacion", tipoAutorizacion);
        httpParams = httpParams.set("idSolicitud", idSolicitud);
        return this.get(this.SIN_ACTUALIZADOS(idOperador), httpParams)
            .pipe(tap(page => page.content = page.content.map(item => Object.assign(new Autorizacion(), item))));
    }
}
