<br/>
<mat-card class="card shadow">
    <mat-card-title>
        {{'OPERADORES.datosAlmacenes' | translate}}
    </mat-card-title>
    <mat-card-content>
        <div class="py-2">
            <app-generic-table
                    [data]="data.content"
                    [columnsConfig]="tableConfig"
                    [actionsConfig]="operadorAction === 'edit' ? tableAccionesEdit : tableAccionesView"
                    [headerRows]="2"
                    [paginated]="true"
                    [collectionSize]="data.totalElements"
                    [totalPages]="data.totalPages"
                    [page]="page"
                    (onAction)="onAction($event)"
            >
            </app-generic-table>
        </div>
        <div class="col-12 mt-1 d-flex justify-content-end">
            <div class="col-md-2 col-sm-6 d-flex justify-content-end">
                <app-input-checkbox
                        [formControl]="noTieneAlmacen"
                        [label]="'No disposo de magatzems' | translate"
                        (valueChanged)="onNoTieneAlmacenCheck()"
                        [disabled]="hasAltaAlmacen">
                </app-input-checkbox>
            </div>
            <button mat-raised-button color="basic" class="px-2" style="margin-left: 10px;"
                    (click)="createAltaAlmacen()"
                    [disabled]="noTieneAlmacen.value">
                {{'OPERADORES.almacenes.anadirAlmacen' | translate}}
            </button>
        </div>
    </mat-card-content>
</mat-card>
