import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AnimalCenso} from "../../../../core/model/animal/animalCenso";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AnimalHttpService} from "../../../../core/services/http/animal-http.service";
import {Animal} from "../../../../core/model/animal/animal";
import {EMPTY, lastValueFrom, Observable} from "rxjs";
import {Cualificacion} from "../../../../core/model/animal/cualificacion";
import {ClasificacionUbm} from "../../../../core/model/animal/clasificacionUbm";
import {AuthService} from "../../../../core/services/security/auth.service";

@Component({
  selector: 'app-edit-operador-animal-censo-modal',
  templateUrl: './edit-operador-animal-censo-modal.component.html',
  styleUrls: ['./edit-operador-animal-censo-modal.component.scss']
})
export class EditOperadorAnimalCensoModalComponent implements OnInit {

  protected idOperador: string;
  protected usuario;

  protected animal: Animal;
  protected animalCenso: AnimalCenso;
  protected animalCensoFormGroup: FormGroup;

  protected cualificaciones: Observable<Cualificacion[]> = EMPTY;
  protected clasificacionesUbm: Observable<ClasificacionUbm[]> = EMPTY;

  constructor(@Inject(MAT_DIALOG_DATA) data: {idOperador: string, animal: Animal, animalCenso?: AnimalCenso},
              protected dialogRef: MatDialogRef<EditOperadorAnimalCensoModalComponent>,
              private animalSvc: AnimalHttpService,
              private authSvc: AuthService) {

    this.idOperador = data.idOperador;
    this.animal = data.animal;
    this.animalCenso = data.animalCenso ? data.animalCenso : new AnimalCenso();

    this.animalCensoFormGroup = new FormGroup({
      "idInscrito": new FormControl('', [Validators.required, Validators.pattern("[0-9]+")]),
      "numGranja": new FormControl('', [Validators.required, Validators.maxLength(5)]),
      "numOrden": new FormControl('', [Validators.required, Validators.pattern("[0-9]+")]),
      "censo": new FormControl(null, [Validators.required, Validators.pattern("[0-9]+")]),
      "idCualificacion": new FormControl(null, [Validators.required, Validators.maxLength(10)]),
      "codigoUbm": new FormControl(null, [Validators.required, Validators.maxLength(10)]),
      "fechaCualificacion": new FormControl(null, Validators.required),
      "valorUbm": new FormControl(''),
      "usuario": new FormControl('', Validators.required)
      })
  }

  async ngOnInit() {

    this.authSvc.fullName.subscribe(value => {this.usuario = value;});

    if (this.animalCenso.numOrden) {

      this.animalCenso = await lastValueFrom(this.animalSvc.getAnimalCenso(
              this.idOperador, this.animal.numGranja,
              this.animal.idEspecie, this.animal.codigoRaza,
              this.animal.codigoTipoGanado, this.animalCenso.numOrden));

      this.animalCensoFormGroup.patchValue({
        ...this.animal,
        ...this.animalCenso,
        "usuario": this.usuario
      })
    } else {
      this.animalCensoFormGroup.patchValue({
        ...this.animal,
        ...this.animalCenso,
        "numOrden": this.animal.contadorAnimales + 1,
        "usuario": this.usuario
      })
    }

    this.cualificaciones = this.animalSvc.getCualificaciones();
    this.clasificacionesUbm = this.animalSvc.getClasificacionesUbm(this.animal.idEspecie);

    this.handleCualiEcologica();
    this.handleClasificacionUbm();
  }


  private handleCualiEcologica() {
    this.animalCensoFormGroup.get("idCualificacion")?.valueChanges
        .subscribe(idCualificacion => {
          this.animalCenso.idCualificacion = idCualificacion;
        })
  }

  private handleClasificacionUbm() {
    this.animalCensoFormGroup.get("codigoUbm")?.valueChanges
        .subscribe(codigoUbm => {
          this.animalCenso.codigoUbm = codigoUbm;
        })
  }

  onSubmit() {

    this.dialogRef.close(this.animalCensoFormGroup.value);
  }
}
