import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './modules/login/pages/login/login.component';
import {DashboardComponent} from "./modules/dashboard/pages/dashboard/dashboard.component";
import {LoggedGuard} from "./core/guards/authentication/logged.guard";
import {NotLoggedGuard} from "./core/guards/authentication/not-logged.guard";
import {RolWebGuard} from "./core/guards/roles/rol-web.guard";
import {ForgotPasswordComponent} from "./modules/login/pages/forgot-password/forgot-password.component";
import {
  ChangePasswordRecoverComponent
} from "./modules/login/pages/change-password-recover/change-password-recover.component";


export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent,
    canActivate: [NotLoggedGuard]
  }, {
    path: 'restablir-contrasenya',
    pathMatch: 'full',
    component: ForgotPasswordComponent,
    canActivate: [NotLoggedGuard]
  }, {
    path: 'canviar-contrasenya/:token',
    pathMatch: 'full',
    component: ChangePasswordRecoverComponent,
    canActivate: [NotLoggedGuard]
  }, {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [LoggedGuard, RolWebGuard]
  },
  {
    path: 'operadors',
    loadChildren: () => import('./modules/operadores/operadores.module').then(m => m.OperadoresModule)
  },
  {
    path: 'etiquetas',
    loadChildren: () => import('./modules/etiquetas/etiquetas.module').then(m => m.EtiquetasModule)
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppRoutingModule { }
