import {Component, Injector, OnInit} from '@angular/core';
import {lastValueFrom, Observable} from "rxjs";
import {
  OperadoresInstalacionRegistroEditAccionesConfig,
  OperadoresInstalacionRegistroTableConfig,
  OperadoresInstalacionRegistroViewAccionesConfig
} from "./operadores-instalacion-registro-table.config";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Pageable} from "../../../../core/model/core/pageable";
import {Page} from "../../../../core/model/core/pages/page";
import {ModalService} from "../../../../core/services/components/modal.service";
import {Instalacion} from "../../../../core/model/instalacion/instalacion";
import {Registro} from "../../../../core/model/instalacion/registro";
import {InstalacionHttpService} from "../../../../core/services/http/operadores/instalacion-http.service";
import {
  EditOperadorInstalacionRegistroModalComponent
} from "../../modals/edit-operador-instalacion-registro-modal/edit-operador-instalacion-registro-modal.component";
import {Actividad} from "../../../../core/model/instalacion/actividad";
import {TranslateService} from "@ngx-translate/core";
import {ToastService} from "../../../../core/services/components/toast.service";
import {Municipio} from "../../../../core/model/localizacion/municipio";
import {AuthService} from "../../../../core/services/security/auth.service";
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {
  DisclaimerEliminarGenericoComponent
} from "../../modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";

@Component({
  selector: 'app-operadores-detalle-instalacion',
  templateUrl: './operadores-detalle-instalacion.component.html',
  styleUrls: ['./operadores-detalle-instalacion.component.scss']
})
export class OperadoresDetalleInstalacionComponent extends Page implements OnInit {

  protected instalacionSvc: InstalacionHttpService;
  protected modalSvc: ModalService;
  protected authSvc: AuthService;

  protected tableConfig = OperadoresInstalacionRegistroTableConfig;
  protected tableAccionesEdit = OperadoresInstalacionRegistroEditAccionesConfig;
  protected tableAccionesView = OperadoresInstalacionRegistroViewAccionesConfig;

  protected idOperador: string;
  protected numOrdenInstalacion: number;
  protected instalacion: Instalacion;
  protected formGroup: FormGroup;
  protected municipio: Municipio = new Municipio();
  protected actividades: Observable<Actividad[]>;
  protected data: Pageable<Registro>;
  protected page = 1;
  protected usuario;

  protected action;
  protected readonly = false;
  protected operadorAction;
  protected municipioValido = false;

  constructor(private operadorSvc: OperadoresHttpService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastSvc: ToastService,
              private translateSvc: TranslateService,
              modalSvc: ModalService,
              injector: Injector) {
    super();

    this.instalacionSvc = injector.get(InstalacionHttpService);
    this.modalSvc = injector.get(ModalService);
    this.authSvc = injector.get(AuthService);

    this.idOperador = activatedRoute.snapshot.params['idOperador'];
    this.numOrdenInstalacion = activatedRoute.snapshot.params['numOrdenInstalacion'];
    this.instalacion = new Instalacion()
    this.data = new Pageable<Registro>();
    this.page = 1;

    this.formGroup = new FormGroup({
      "numCentro": new FormControl('', [Validators.required, Validators.pattern("[0-9]{0,2}$")]), //notNullDB
      "codigoActividad": new FormControl(null, Validators.required), //notNullDB
      "direccion": new FormControl('', [Validators.required, Validators.maxLength(60)]), //notNullDB
      "mixta": new FormControl(null), //notNullDB boolean
      "convencional": new FormControl(null), //notNullDB boolean
      "observaciones": new FormControl(''), //nullAcceptedDB
      "contadorRegistros": new FormControl(''),
      "municipio": new FormControl(null)
    });

  }

  async ngOnInit() {

    this.actividades = this.instalacionSvc.getActividades();
    this.authSvc.fullName.subscribe(value => {this.usuario = value;});

    if(this.numOrdenInstalacion){
      this.instalacionSvc.getInstalacion(this.idOperador, this.numOrdenInstalacion)
          .subscribe({
            next: value => {
              this.instalacion = value;
              this.formGroup.patchValue(this.instalacion)
            },
            error: (e) => this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
          });
      this.data = await lastValueFrom(this.instalacionSvc.getRegistros(this.idOperador, this.numOrdenInstalacion, this.page));
    }

    this.activatedRoute.params.subscribe(params => {
      this.operadorAction = params['action']; });


    this.activatedRoute.params.subscribe(params => {
      this.action = params['instalacionAction'];

      if (this.action === 'view'){
        this.formGroup.disable();
      }
      else if (this.action == 'edit' && this.action == 'create') {
        this.formGroup.enable();
      }

    });

  }

  protected validateForm() {
    return this.municipioValido && this.formGroup.valid;
  }

  public onChangeMixta(event: Event, buttonId: string){
    const isMixta = (buttonId=='mixta');

    this.formGroup.get('mixta')?.setValue(isMixta);
    this.instalacion.mixta = isMixta;
  }

  public onChangeConvencional(event: Event, buttonId: string){
    const isConvencional = (buttonId=='convencional');

    this.formGroup.get('convencional')?.setValue(isConvencional);
    this.instalacion.convencional = isConvencional;
  }

  public async onPageChange(page: number) {
    this.page = page;
    this.data = await lastValueFrom(this.instalacionSvc.getRegistros(this.idOperador, this.numOrdenInstalacion, page));
  }

  onGuardarForm() {
    if(this.numOrdenInstalacion!=null) {
      let updatedInstalacion = this.formGroup.value;
      updatedInstalacion.usuario = this.usuario;
      this.instalacionSvc.updateInstalacion(this.idOperador, this.numOrdenInstalacion, updatedInstalacion).subscribe(value => {
        this.instalacion = value;
        this.formGroup.patchValue({
          ...this.instalacion
        })
      });
    }
    else if(this.numOrdenInstalacion==null) {
      let newInstalacion = this.formGroup.value;
      newInstalacion.municipio = this.municipio;
      newInstalacion.usuario = this.usuario;
      this.instalacionSvc.createInstalacion(this.idOperador, newInstalacion).subscribe(_ => {
        this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
      });
    }
  }

  protected onActionTable($event: any) {
    if ($event.action == "edit") {
      this.updateRegistro($event.item);
    }
    else if($event.action == "delete"){
      this.deleteRegistro($event.item);
    }
  }

  public createRegistro(){
    this.modalSvc.openDialog(EditOperadorInstalacionRegistroModalComponent, {idOperador: this.idOperador, numOrdenInstalacion: this.numOrdenInstalacion})
        .afterClosed().subscribe(registro => {
      if(registro){
        registro.numOrdenInstalacion = this.numOrdenInstalacion
        registro.usuario = this.usuario;
        this.instalacionSvc.createRegistro(this.idOperador, this.numOrdenInstalacion, registro)
            .subscribe(_ => this.onPageChange(this.page));
      }
    });

  }

  private updateRegistro(registro) {
    this.modalSvc.openDialog(EditOperadorInstalacionRegistroModalComponent, {idOperador: this.idOperador, registro})
        .afterClosed().subscribe(registro => {
      if(registro) {
        registro.usuario = this.usuario;
        this.instalacionSvc.updateRegistro(this.idOperador, registro.numOrdenInstalacion, registro.numOrdenRegistro, registro)
            .subscribe(_ => this.onPageChange(this.page));
      }
    })
  }

  private deleteRegistro(item){
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'OPERADORES.instalaciones.disclaimerEliminarRegistro'}, {maxWidth: '30vw'})
        .afterClosed().subscribe(accepted => {
      if(accepted){
        this.instalacionSvc.deleteRegistro(this.idOperador, item)
            .subscribe(_ => this.onPageChange(this.page));
      }
    });
  }

  onBack(){
    this.router.navigate(["../../../"], {relativeTo: this.activatedRoute})
  }

  onBackCreate(){
    this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
  }

}