import {BaseObj} from "../core/baseObj";
import {QualificacionIngrediente} from "../master/qualificacion-ingrediente";
import {OrigenMateria} from "../master/origen-materia";

export class SolicitudIngrediente extends BaseObj {
    idSolicitudIngrediente!: number;
    idProducto!: number;
    porcentaje!: number;
    nombre!: string;
    agrario?: boolean;
    origenMateria?: OrigenMateria;
    qualificacionIngrediente?: QualificacionIngrediente;
    isValido?: boolean;

    constructor(json?: any) {
        super(json);

    }

	esValido(): boolean{
        let origenCanBeNull = !this.agrario || this.qualificacionIngrediente?.idQualificacionIngrediente == 1;
        let origenOK = [(origenCanBeNull && this.origenMateria?.idOrigenMateria == null) || (!origenCanBeNull && this.origenMateria?.idOrigenMateria != null)];

		return this.porcentaje > 0 && this.nombre?.length > 0 && this.agrario != null
			&& origenOK && this.qualificacionIngrediente?.idQualificacionIngrediente != null;
	}
}
