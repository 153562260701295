import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'inputNumberFormat'
})
export class InputNumberFormatPipe implements PipeTransform {

  private SEPERATOR = ".";
  private ENTERO = 0;
  private DECIMAL = 1;

  transform(value: string | number, decimals: string | number): string {

    if (typeof decimals == "string")
      decimals = parseInt(decimals);

    //Sin valor
    if (value == null || value == "" || value == "Infinity") {
      return this.padDecimalsLeft("0", decimals);
    }

    if (typeof value == "number")
      value = value.toString();

    //Si no limitamos decimales se retorna el valor tal cual
    if (isNaN(decimals) || decimals == undefined)
      return value;

    if (value == undefined) {
      return "";
    }

    //Obtenemos parteEntera y parteDecimal
    let parteEntera = value.split(this.SEPERATOR)[this.ENTERO];
    let parteDecimal = value.split(this.SEPERATOR)[this.DECIMAL];

    //Si definimos que no queremos decimales devolvemos la parte entera
    if(decimals == 0) {
      return parseInt(parteEntera) == 0 ? "0" : parteEntera;
    }

    //Si no hay partedecimal devolvemos un padding de 0
    //Si no rellenamos con la cantidad de 0 que haga falta para cumplir los decimales
    if (!parteDecimal)
      return this.padDecimalsLeft(parteEntera, decimals);
    else if (parteDecimal.length >= decimals)
      parteDecimal = parteDecimal.substring(0, decimals)
    else
      parteDecimal = parteDecimal + "0".repeat(decimals - parteDecimal.length);

    //Retornamos la parteEntera + parteDecimal
    return parteEntera + "." + parteDecimal;
  }

  private padDecimalsLeft(value: string, qty: number): string {

    if(qty == 0)
      return value;

    return value + this.SEPERATOR + "0".repeat(qty);
  }

}
