// Productos table
import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";

export const DuplicarColumns: TablaColumna[] = [{
	header: "OPERADORES.autorizaciones.numOrdenAutorizacion",
	field: "numOrdenAutorizacion",
	type: "number"
},{
	header: "ETIQUETAS.nombreComercial",
	field: "nombreProducto",
	type: "string"
},{
	header: "general.marca",
	field: "marca",
	type: "string"
}, {
	header: "general.envase",
	field: "tipoEnvase",
	type: "string"
}, {
	header: "general.capacidad",
	field: "capacidad",
	type: "string"
}, {
	header: 'general.fecha',
	field: "fechaAutorizacion",
	type: "date"
}]

export enum DuplicarActionsEnum {
	Add,
	Remove
}

export const DuplicarActionsAdd : TablaAccion[] = [
	{
		icon: 'add',
		action: DuplicarActionsEnum.Add,
		toolTip: "general.add"
	}
]

export const DuplicarActionsRemove : TablaAccion[] = [
	{
		icon: 'remove',
		action: DuplicarActionsEnum.Remove,
		toolTip: "general.eliminar"
	}
]
