import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EtiquetasRoutingModule} from './etiquetas-routing.module';
import {
    EtiquetasElaboradoPeticionComponent
} from './pages/etiquetas-elaborado-peticion/etiquetas-elaborado-peticion.component';
import {EtiquetasHortofruticolaPeticionComponent} from './pages/etiquetas-hortofruticola-peticion/etiquetas-hortofruticola-peticion.component';
import {EtiquetasDistribuidosPeticionComponent} from './pages/etiquetas-distribuidos-peticion/etiquetas-distribuidos-peticion.component';
import {
    EtiquetasVinosderivadosPeticionComponent
} from './pages/etiquetas-vinosderivados-peticion/etiquetas-vinosderivados-peticion.component';
import {
    EtiquetasPreviewOperadorComponent
} from './components/etiquetas-preview-operador/etiquetas-preview-operador.component';
import {EtiquetasViewBaseComponent} from './components/etiquetas-view-base/etiquetas-view-base.component';
import {SharedModule} from "../../shared/shared.module";
import {MatCardModule} from "@angular/material/card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {
    EditProductoElaboradoModal
} from './modals/edit-producto-elaborado-modal/edit-producto-elaborado-modal.component';
import {MatDialogModule} from "@angular/material/dialog";
import {
    EditPreIngredientesModalComponent
} from './modals/edit-pre-ingredientes-modal/edit-pre-ingredientes-modal.component';
import {EditEtiquetaModalComponent} from './modals/edit-etiqueta-modal/edit-etiqueta-modal.component';
import {EtiquetasAttachmentsComponent} from './components/etiquetas-attachments/etiquetas-attachments.component';
import {
    EtiquetaAddActualizarModalComponent
} from './modals/etiqueta-add-actualizar-modal/etiqueta-add-actualizar-modal.component';
import {
    EtiquetasDisclaimerConfirmComponent
} from './modals/etiquetas-disclaimer-confirm/etiquetas-disclaimer-confirm.component';
import {EditProductoVinoModalComponent} from './modals/edit-producto-vino-modal/edit-producto-vino-modal.component';
import {
    EtiquetasExternalEntryPointComponent
} from './pages/etiquetas-external-entry-point/etiquetas-external-entry-point.component';
import {
    EtiquetasElaboradoGestionComponent
} from './pages/etiquetas-elaborado-gestion/etiquetas-elaborado-gestion.component';

import { EditProductoHortofructiculaModalComponent } from './modals/edit-producto-hortofructicula-modal/edit-producto-hortofructicula-modal.component';
import { EditProductoDistribuidosModalComponent } from './modals/edit-producto-distribuidos-modal/edit-producto-distribuidos-modal.component';
import { EtiquetasHortofruticolaGestionComponent } from './pages/etiquetas-hortofruticola-gestion/etiquetas-hortofruticola-gestion.component';
import { EtiquetasDistribuidosGestionComponent } from './pages/etiquetas-distribuidos-gestion/etiquetas-distribuidos-gestion.component';
import {DuplicarProductoModalComponent} from "./modals/duplicar-producto-modal/duplicar-producto-modal.component";
import {
    EtiquetasVinosderivadosGestionComponent
} from "./pages/etiquetas-vinosderivados-gestion/etiquetas-vinosderivados-gestion.component";
import { EditAdjuntosModalComponent } from './modals/edit-adjuntos-modal/edit-adjuntos-modal.component';
import {
    DisclaimerConfirmValidacionComponent
} from "./modals/disclaimer-confirm-validacion/disclaimer-confirm-validacion.component";
import { EtiquetasImportadosPeticionComponent } from './pages/etiquetas-importados-peticion/etiquetas-importados-peticion.component';
import { EtiquetasImportadosGestionComponent } from './pages/etiquetas-importados-gestion/etiquetas-importados-gestion.component';
import { EditProductoImportadosModalComponent } from './modals/edit-producto-importados-modal/edit-producto-importados-modal.component';
import {
    EtiquetasDistribuidosConfirmacionComponent
} from "./pages/etiquetas-distribuidos-confirmacion/etiquetas-distribuidos-confirmacion.component";
import {
    EtiquetasElaboradoConfirmacionComponent
} from "./pages/etiquetas-elaborado-confirmacion/etiquetas-elaborado-confirmacion.component";
import {
    EtiquetasHortofruticolaConfirmacionComponent
} from "./pages/etiquetas-hortofruticola-confirmacion/etiquetas-hortofruticola-confirmacion.component";
import {
    EtiquetasImportadosConfirmacionComponent
} from "./pages/etiquetas-importados-confirmacion/etiquetas-importados-confirmacion.component";
import {
    EtiquetasVinosderivadosConfirmacionComponent
} from "./pages/etiquetas-vinosderivados-confirmacion/etiquetas-vinosderivados-confirmacion.component";
import {EtiquetaAddNewModalComponent} from "./modals/etiqueta-add-new-modal/etiqueta-add-new-modal.component";
import {ActualizarProductoModalComponent} from "./modals/actualizar-producto-modal/actualizar-producto-modal.component";
import { EditRechazoModalComponent } from './modals/edit-rechazo-modal/edit-rechazo-modal.component';
import { EtiquetasElaboradoVisualizacionComponent } from './pages/etiquetas-elaborado-visualizacion/etiquetas-elaborado-visualizacion.component';
import { EtiquetasDistribuidosVisualizacionComponent } from './pages/etiquetas-distribuidos-visualizacion/etiquetas-distribuidos-visualizacion.component';
import { EtiquetasImportadosVisualizacionComponent } from './pages/etiquetas-importados-visualizacion/etiquetas-importados-visualizacion.component';
import { EtiquetasHortofruticolaVisualizacionComponent } from './pages/etiquetas-hortofruticola-visualizacion/etiquetas-hortofruticola-visualizacion.component';
import { EtiquetasVinosderivadosVisualizacionComponent } from './pages/etiquetas-vinosderivados-visualizacion/etiquetas-vinosderivados-visualizacion.component';
import {
    EtiquetasImportadosRevisionComponent
} from "./pages/etiquetas-importados-revision/etiquetas-importados-revision.component";
import {
    EtiquetasDistribuidosRevisionComponent
} from "./pages/etiquetas-distribuidos-revision/etiquetas-distribuidos-revision.component";
import {
    EtiquetasHortofruticolaRevisionComponent
} from "./pages/etiquetas-hortofruticola-revision/etiquetas-hortofruticola-revision.component";
import {
    EtiquetasElaboradoRevisionComponent
} from "./pages/etiquetas-elaborado-revision/etiquetas-elaborado-revision.component";
import {
    EtiquetasVinosderivadosRevisionComponent
} from "./pages/etiquetas-vinosderivados-revision/etiquetas-vinosderivados-revision.component";
import { AlbaranModalComponent } from './modals/albaran-modal/albaran-modal.component';
import { EtiquetaAddModalComponent } from './modals/etiqueta-add-modal/etiqueta-add-modal.component';
import { AddHortofruticolasModalComponent } from './modals/add-hortofruticolas-modal/add-hortofruticolas-modal.component';
import {MatRadioModule} from "@angular/material/radio";
import {
    EtiquetaCrearProductosPlantillaModalComponent
} from "./modals/etiqueta-crear-productos-plantilla-modal/etiqueta-crear-productos-plantilla-modal.component";
import {
    EtiquetaCrearProductosModalComponent
} from "./modals/etiqueta-crear-productos-modal/etiqueta-crear-productos-modal.component";

@NgModule({
    declarations: [
        EtiquetasElaboradoPeticionComponent,
        EtiquetasHortofruticolaPeticionComponent,
        EtiquetasDistribuidosPeticionComponent,
        EtiquetasVinosderivadosPeticionComponent,
        EtiquetasPreviewOperadorComponent,
        EtiquetasViewBaseComponent,
        EditProductoElaboradoModal,
        EditPreIngredientesModalComponent,
        EditEtiquetaModalComponent,
        EtiquetasAttachmentsComponent,
        EtiquetaAddActualizarModalComponent,
        EtiquetaAddNewModalComponent,
        DuplicarProductoModalComponent,
        ActualizarProductoModalComponent,
        EtiquetasDisclaimerConfirmComponent,
        DisclaimerConfirmValidacionComponent,
        EditProductoVinoModalComponent,
        EtiquetasExternalEntryPointComponent,
        EtiquetasElaboradoGestionComponent,
        EditProductoHortofructiculaModalComponent,
        EditProductoDistribuidosModalComponent,
        EtiquetasExternalEntryPointComponent,
        EtiquetasHortofruticolaGestionComponent,
        EtiquetasDistribuidosGestionComponent,
        EtiquetasVinosderivadosGestionComponent,
        EditAdjuntosModalComponent,
        EtiquetasImportadosPeticionComponent,
        EtiquetasImportadosGestionComponent,
        EditProductoImportadosModalComponent,
        EtiquetasDistribuidosConfirmacionComponent,
        EtiquetasElaboradoConfirmacionComponent,
        EtiquetasHortofruticolaConfirmacionComponent,
        EtiquetasImportadosConfirmacionComponent,
        EtiquetasVinosderivadosConfirmacionComponent,
        EditRechazoModalComponent,
        EtiquetasImportadosRevisionComponent,
        EtiquetasDistribuidosRevisionComponent,
        EtiquetasHortofruticolaRevisionComponent,
        EtiquetasElaboradoRevisionComponent,
        EtiquetasVinosderivadosRevisionComponent,
        EditRechazoModalComponent,
        EtiquetasElaboradoVisualizacionComponent,
        EtiquetasDistribuidosVisualizacionComponent,
        EtiquetasImportadosVisualizacionComponent,
        EtiquetasHortofruticolaVisualizacionComponent,
        EtiquetasVinosderivadosVisualizacionComponent,
        AlbaranModalComponent,
        EtiquetaAddModalComponent,
        AddHortofruticolasModalComponent,
        EtiquetaCrearProductosModalComponent,
        EtiquetaCrearProductosPlantillaModalComponent
    ],
    exports: [
        EtiquetasAttachmentsComponent
    ],
    imports: [
        CommonModule,
        EtiquetasRoutingModule,
        SharedModule,
        MatCardModule,
        ReactiveFormsModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        FormsModule,
        MatRadioModule
    ]
})
export class EtiquetasModule { }
