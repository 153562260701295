import {BaseObj} from "../core/baseObj";
import {Ingrediente} from "./ingrediente";

export class AutorizacionPk {
    idInscrito!: number;
    numOrdenAutorizacion!: number;
}

export class Autorizacion extends BaseObj {

    idInscrito!: number;
    numOrdenAutorizacion!: number;
    nombreProducto: string = "";
    idProducto: string = "";
    nombreComercial: string = "";
    categoriaEcologica: string = "";
    tipoAutorizacion: string = "";

    marca:  string = "";
    tipoEnvase: string = "";
    idEnvase: string = "";
    capacidad: string = "";
    fechaAutorizacion: Date = new Date();
    fechaCambioEstado: Date = new Date();
    activo: boolean = false;
    impreso: boolean = false;
    usuario:  string = "";

    especificacion: string = "";
    aditivos: string = "";
    auxiliaresTecnologicos: string = "";
    observaciones: string = "";
    elaboracion: boolean = false;
    tipoOperacion: string = "";
    esElaborado: boolean = false;

    usaNitratos: boolean = false;
    tecnicaTransformacion: string = "";
    idUbicacionElaboracion: number;
    idUbicacionEnvase: number;
    idUbicacionEtiqueta: number;

    textoElaboracion: string = "";
    textoEnvase: string = "";
    textoEtiquetaje: string = "";
    tipoNormativa: string = "";

    ingredientes: Ingrediente[] = [];

    constructor(json?: object) {
        super(json);
    }

    equals(obj: Autorizacion) {
        return this.idInscrito == obj.idInscrito && this.numOrdenAutorizacion == obj.numOrdenAutorizacion;
    }
}
