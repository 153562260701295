import {OrientacionProducto} from "./orientacionProducto";
import {Producto} from "../autorizacion/producto";
import {Municipio} from "../localizacion/municipio";

export class Granja {

  idInscrito!: number;
  numGranja!: string;
  numOrden!: number;

  nombreGranja!: string;
  marcaOficial?: string;
  libroExplotacion?: string;
  codigoRega?: string;

  poligono?: string;
  parcela?: string;
  direccion?: string;
  municipio!: Municipio;

  superficieCubierta?: string;
  superficiePatio?: string;
  capacidadMaxCubierta?: number;
  capacidadMaxPatio?: number;
  capacidadDarp?: number;

  idEspecieGanado?: number;
  especieGanado?: string;
  idOrientacionProd?: string;
  orientacionProd?: string;
  alternanciaAvicola?: boolean;

  observaciones?: string;
  usuario: string

}