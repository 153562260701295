import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EtiquetasActionAdd} from "../../enum/EtiquetasActionAdd";
import {
  EtiquetaCrearProductosModalComponent
} from "../etiqueta-crear-productos-modal/etiqueta-crear-productos-modal.component";

@Component({
  selector: 'app-etiqueta-add-modal',
  templateUrl: './etiqueta-add-modal.component.html',
  styleUrls: ['./etiqueta-add-modal.component.scss']
})
export class EtiquetaAddModalComponent implements OnInit {
  tipoSolicitud: string;
  protected actions = EtiquetasActionAdd;

  constructor(
      protected dialogRef: MatDialogRef<EtiquetaAddModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialog: MatDialog
  ) {
    this.tipoSolicitud = data.tipoSolicitud;
  }

  ngOnInit(): void {
  }

  protected readonly EtiquetasActionAdd = EtiquetasActionAdd;

  openCrearProductosModal(): void {
    this.dialogRef.close();
    this.dialog.open(EtiquetaCrearProductosModalComponent);
  }

}
