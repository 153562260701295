<app-page-template
        [title]="'OPERADORES.almacenes.titleDetall' | translate:
        {'almacen': almacen?.numOrdenAlmacen ? almacen.numOrdenAlmacen : 'Alta'}"
>

    <form [formGroup]="formGroup">
        <mat-card class="card shadow">
            <mat-card-title>
                {{'OPERADORES.almacenes.titleCardDetall' | translate}}
            </mat-card-title>
            <mat-card-content>

                <div class="row">
                    <div class="col-md-6 col-xl-2">
                        <app-input-text
                                [label]="'OPERADORES.almacenes.numAlmacen' | translate"
                                formControlName="numOrdenAlmacen"
                                [isDisabled] = "true"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-md-6 col-xl-2">
                        <app-input-text
                                [label]="'OPERADORES.almacenes.nombreAlmacen' | translate"
                                formControlName="nombreAlmacen"
                        >
                        </app-input-text>
                    </div>
                </div>

                <div class="row">
                    <app-operadores-localizacion
                            [municipioInput]="creating ? municipio : almacen?.municipio"
                            [readonly]="!editing"
                            [enableProvincia]="true"
                            [campoObligatorio]="true"
                            (formValid)="municipioValido = $event">
                    </app-operadores-localizacion>
                </div>

                <div class="row">
                    <div class="col-md-6 col-xl-3">
                        <br/>
                        <p><strong>{{'OPERADORES.almacenes.localizadoEnPoblacion' | translate}}</strong></p>
                        <mat-radio-group>
                            <app-input-radio
                                    [label]="'OPERADORES.almacenes.enCampo' | translate"
                                    [isDisabled]="action == 'view'"
                                    [checked]="almacen.localizadoEnPoblacion == false"
                                    (change)="onChangeEnPoblacion($event, 'localizadoEnCampo')"
                                    [name]="'localizadoEnPoblacion'"
                                    [id]="'localizadoEnCampo'">
                            </app-input-radio>
                            <app-input-radio
                                    [label]="'OPERADORES.almacenes.enPoblacion' | translate"
                                    [isDisabled]="action == 'view'"
                                    [checked]="almacen.localizadoEnPoblacion == true"
                                    (change)="onChangeEnPoblacion($event, 'localizadoEnPoblacion')"
                                    [name]="'localizadoEnPoblacion'"
                                    [id]="'localizadoEnPoblacion'">
                            </app-input-radio>
                        </mat-radio-group>
                    </div>
                </div>

                <div class="row">
                    <div *ngIf="almacen.localizadoEnPoblacion == false || almacen.localizadoEnPoblacion == null"  class="col-md-6 col-xl-2">
                        <app-input-text
                                [label]="'OPERADORES.parcelas.poligono' | translate"
                                formControlName="poligono"
                                [isDisabled] = "almacen.localizadoEnPoblacion == null || action == 'view'"
                        >
                        </app-input-text>
                    </div>
                    <div *ngIf="almacen.localizadoEnPoblacion == false || almacen.localizadoEnPoblacion == null"  class="col-md-6 col-xl-2">
                        <app-input-text
                                [label]="'OPERADORES.parcela' | translate"
                                formControlName="parcela"
                                [isDisabled] = "almacen.localizadoEnPoblacion == null || action == 'view'"
                        >
                        </app-input-text>
                    </div>
                    <div *ngIf="almacen.localizadoEnPoblacion == false || almacen.localizadoEnPoblacion == null" class="col-md-6 col-xl-2">
                        <app-input-text
                                [label]="'OPERADORES.almacenes.recinto' | translate"
                                formControlName="recinto"
                                [isDisabled] = "almacen.localizadoEnPoblacion == null || action == 'view'"
                        >
                        </app-input-text>
                    </div>
                    <div *ngIf="almacen.localizadoEnPoblacion == true || almacen.localizadoEnPoblacion == null"  class="col-md-6 col-xl-4">
                        <app-input-text
                                [label]="'general.direccion' | translate"
                                formControlName="direccion"
                                [isDisabled] = "almacen.localizadoEnPoblacion == null || action == 'view'"
                        >
                        </app-input-text>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-xl-2">
                        <app-input-text
                                [label]="'OPERADORES.almacenes.superficie' | translate"
                                formControlName="superficie"
                                [campoObligatorio]=true
                        >
                        </app-input-text>
                    </div>
                    <div class="col-md-6 col-xl-2">
                        <app-input-select
                                formControlName="cualificacionAlmacen"
                                [label]="'OPERADORES.almacenes.usoAlmacen' | translate"
                                [options]="cualificacionesAlmacen | async | arrayToSelectItem:'codigo':'descripcion'"
                                [campoObligatorio]=true
                        >
                        </app-input-select>
                    </div>
                    <div class="col-md-6 col-xl-2">
                        <app-input-select
                                formControlName="camaraFrigorifica"
                                [label]="'OPERADORES.almacenes.tipologia' | translate"
                                [options]="camaraFrigorificaOptions | arrayToSelectItem:'id':'label'"
                                [campoObligatorio]=true
                        >
                        </app-input-select>
                    </div>
                    <div class="col-md-6 col-xl-2">
                        <app-input-select
                                formControlName="externo"
                                [label]="'OPERADORES.almacenes.caracteristicas' | translate"
                                [options]="externoOptions | arrayToSelectItem:'id':'label'"
                                [campoObligatorio]=true
                        >
                        </app-input-select>
                    </div>
                    <div class="col-md-6 col-xl-2" *ngIf="formGroup.get('camaraFrigorifica')?.value === '1' || formGroup.get('camaraFrigorifica')?.value === true">
                        <app-input-text
                                [label]="'OPERADORES.almacenes.registroSanitario' | translate"
                                formControlName="registroSanitario"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-md-6 col-xl-2">

                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-xl-2">
                        <br/>
                        <p><strong>{{'OPERADORES.compartido' | translate}}</strong></p>
                        <mat-radio-group>
                            <app-input-radio
                                    [label]="'No' | translate"
                                    [isDisabled]="action == 'view'"
                                    [checked]="!almacen.compartido"
                                    (change)="onChangeCompartido($event, 'noEsCompartido')"
                                    [name]="'compartido'"
                                    [id]="'noEsCompartido'">
                            </app-input-radio>
                            <app-input-radio
                                    [label]="'Sí' | translate"
                                    [isDisabled]="action == 'view'"
                                    [checked]="almacen.compartido"
                                    (change)="onChangeCompartido($event, 'esCompartido')"
                                    [name]="'compartido'"
                                    [id]="'esCompartido'">
                            </app-input-radio>
                        </mat-radio-group>
                    </div>
                </div>

                <div class="row">
                    <br/>
                    <div *ngIf="almacen.compartido == true" class="col-md-6 col-xl-4">
                        <p><strong>{{'OPERADORES.almacenes.codigoOperadorCompartido' | translate}}</strong></p>
                        <app-input-text
                                [label]="'OPERADORES.almacenes.ejemploCodigoOperadorCompartido' | translate"
                                [showLabel]="false"
                                formControlName="compartidoCon"
                        >
                        </app-input-text>

                    </div>
                </div>
            </mat-card-content>

        </mat-card>
        <mat-card class="card shadow">
            <mat-card-title>
                {{'OPERADORES.prodAlmacenados' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <app-input-checkbox
                            [label]="'OPERADORES.maquinaria' | translate"
                            formControlName="maquinaria"
                    >
                    </app-input-checkbox>
                </div>
                <div class="row">
                    <app-input-checkbox
                            [label]="'OPERADORES.fertilizantes' | translate"
                            formControlName="fertilizantes"
                    >
                    </app-input-checkbox>
                </div>
                <div class="row">
                    <app-input-checkbox
                            [label]="'OPERADORES.semillas' | translate"
                            formControlName="semillas"
                    >
                    </app-input-checkbox>
                </div>
                <div class="row">
                    <app-input-checkbox
                            [label]="'OPERADORES.contProductos' | translate"
                            formControlName="contProductos"
                    >
                    </app-input-checkbox>
                </div>
                <div class="row" *ngIf="produccioRamadera">
                    <app-input-checkbox
                            [label]="'OPERADORES.pajaForraje' | translate"
                            formControlName="pajaForraje"
                    >
                    </app-input-checkbox>
                </div>
                <div class="row" *ngIf="produccioRamadera">
                    <app-input-checkbox
                            [label]="'OPERADORES.veterinarios' | translate"
                            formControlName="veterinarios"
                    >
                    </app-input-checkbox>
                </div>
            </mat-card-content>
        </mat-card>

        <div *ngIf="editing && !altaOperador" class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" [disabled]="!this.validateForm()"
                    (click)="onGuardarForm()"
            >
                {{'OPERADORES.almacenes.guardarDatosAlmacen' | translate}}
            </button>
        </div>
        <div *ngIf="altaOperador" class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2 mx-2"
                    (click)="closeModalAltaOp.emit()"
            >
                {{'general.cancelar' | translate}}
            </button>
            <button mat-raised-button color="basic" class="px-2" [disabled]="!this.validateForm()"
                    (click)="onGuardarFormAltaOp()"
            >
                {{'OPERADORES.almacenes.guardarDatosAlmacen' | translate}}
            </button>
        </div>

    </form>


    <button *ngIf="!creating && !altaOperador" mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
        <mat-icon>arrow_back</mat-icon>
        {{"general.volver" | translate}}
    </button>

    <button *ngIf="creating && !altaOperador" mat-icon-button (click)="onBackCreate()" style="float: right; margin-right: 40px;">
        <mat-icon>arrow_back</mat-icon>
        {{"general.volver" | translate}}
    </button>


</app-page-template>
