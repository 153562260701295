import {Component, Injector, OnInit} from '@angular/core';
import {OperadoresHttpService, TipoAutorizacionEnum} from "../../../../core/services/http/operadores-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {Page} from "../../../../core/model/core/pages/page";
import {Ubicacion} from "../../../../core/model/master/ubicacion";
import {UbicacionesHttpService} from "../../../../core/services/http/solicitud/ubicaciones.http.service";
import {Observable, switchMap} from "rxjs";
import {Ubicaciones, UbicacionesEtiquetaje} from "../../../etiquetas/services/etiquetas-compuesto.service";
import {
  IngredientesTableAction,
  IngredientesTableConfig
} from "../../../etiquetas/etiquetas.config";
import {Etiqueta} from "../../../../core/model/etiquetas/etiqueta";
import {ProductoService} from "../../../../core/services/http/producto.service";
import {CategoriaEcologica} from "../../../../core/model/master/categoria-ecologica";
import {EnvasesHttpService} from "../../../../core/services/http/solicitud/envases.http.service";
import {
  CategoriasEcologicasHttpService
} from "../../../../core/services/http/solicitud/categorias-ecologicas.http.service";
import {Envase} from "../../../../core/model/master/envase";
import {Normativa} from "../../../../core/model/master/normativa";
import {ModalService} from "../../../../core/services/components/modal.service";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {NoAutorizacion} from "../../../../core/model/autorizacion/noAutorizacion";
import {NoAutorizacionesHttpService} from "../../../../core/services/http/no-autorizaciones.http.service";
import {NoAutorizacionAdjunto} from "../../../../core/model/autorizacion/no-autorizacion-adjunto";

class AttachFile {
  noAutorizacion!: NoAutorizacion;
  file!: File;
  public get isUploaded() {
    return this.noAutorizacion != null;
  }
}

@Component({
  selector: 'app-operadores-detalle-no-autorizacion',
  templateUrl: './operadores-detalle-no-autorizacion.component.html',
  styleUrls: ['./operadores-detalle-no-autorizacion.component.scss']
})
export class OperadoresDetalleNoAutorizacionComponent extends Page implements OnInit {

  //Services
  private operadorSvc: OperadoresHttpService;
  private ubicacionesSvc: UbicacionesHttpService
  protected productoSvc: ProductoService;
  protected envasesSvc: EnvasesHttpService;
  protected categoriaEcologicaSvc: CategoriasEcologicasHttpService;
  protected modalSvc: ModalService;
  protected noAutorizacionesSvc: NoAutorizacionesHttpService;

  //Data
  private idOperador: string;
  private idSolicitudProducto: number;
  public ingredientesConfigTable = IngredientesTableConfig;
  public noAutorizacion: NoAutorizacion;
  protected ubicacionesElaboracion: Observable<Ubicacion[]>;
  protected ubicacionesEnvasado: Observable<Ubicacion[]>;
  protected ubicacionesEtiquetaje: Observable<Ubicacion[]>;
  public tipoAutorizacion = TipoAutorizacionEnum;
  protected etiqueta: Etiqueta;
  protected adjuntos: NoAutorizacionAdjunto[] = [];
  protected categorias: Observable<CategoriaEcologica[]>;
  protected envases: Observable<Envase[]>;
  normativas: Normativa[] = [];
  tableAccionesEdit = IngredientesTableAction;
  tableAccionesView: Array<TablaAccion> = [];

  //FormGroup and auxiliars
  public formGroup: FormGroup;
  protected idUbicacionElaboracion: number;
  protected idUbicacionEnvase: number;
  protected idUbicacionEtiqueta: number;
  protected ubicacionEnum = Ubicaciones;
  protected ubicacionEtiquetajeEnum = UbicacionesEtiquetaje;
  protected action: string;
  protected porcentajeOK: boolean = false;
  protected porcentajeTotal: number = 0;
  protected title;
  protected titleParams;
  protected literalesUbicaciones = {
    1: 'ETIQUETAS.instalacionesDireccionMunicipio',
    2: 'ETIQUETAS.instalacionesSubcontratada'
  }
  private readonly NormativaEnum = {
    'UE': { idNormativa: 1, descripcion: 'UE' },
    'NOP': { idNormativa: 2, descripcion: 'NOP' }
  };

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private injector: Injector) {
    super();

    this.idOperador = activatedRoute.snapshot.params['idOperador'];
    this.idSolicitudProducto = activatedRoute.snapshot.params['idSolicitudProducto'];

    this.operadorSvc = injector.get(OperadoresHttpService);
    this.ubicacionesSvc = injector.get(UbicacionesHttpService);
    this.productoSvc = injector.get(ProductoService);
    this.envasesSvc = injector.get(EnvasesHttpService);
    this.categoriaEcologicaSvc = injector.get(CategoriasEcologicasHttpService);
    this.noAutorizacionesSvc = injector.get(NoAutorizacionesHttpService);
    this.modalSvc = injector.get(ModalService);

    this.noAutorizacion = new NoAutorizacion();
    this.formGroup = new FormGroup({
      "idSolicitudProducto": new FormControl(''),
      "tipoAutorizacion": new FormControl(''),
      "idProducto": new FormControl('', Validators.required),
      "nombreProducto": new FormControl('', Validators.required),
      "nombreComercial": new FormControl('', [Validators.required, Validators.maxLength(90)]),
      "idCategoriaEcologica": new FormControl(null, Validators.required),
      "marca": new FormControl('', [Validators.required, Validators.maxLength(90)]),
      "idEnvase": new FormControl(null, Validators.required),
      "capacidad": new FormControl('', Validators.maxLength(30)),
      "fecha": new FormControl('', Validators.required),
      "auxiliaresTecnologicos": new FormControl('', Validators.maxLength(1800)),
      "usaNitratos": new FormControl(''),
      "tecnicaTransformacion": new FormControl('', [Validators.required, Validators.maxLength(90)]),
      "idUbicacionElaboracion": new FormControl('', Validators.required),
      "idUbicacionEnvase": new FormControl('', Validators.required),
      "idUbicacionEtiqueta": new FormControl('', Validators.required),
      "textoElaboracion": new FormControl('', Validators.maxLength(90)),
      "textoEnvase": new FormControl('', Validators.maxLength(90)),
      "textoEtiquetaje": new FormControl('', Validators.maxLength(90)),
      "tipoNormativa": new FormControl('', Validators.required),
      "descripcionTipoRechazo": new FormControl(''),
      "motivoRechazo": new FormControl('')
    });

  }

  ngOnInit() {

    this.loadNoAutorizacion();
    this.loadEtiqueta();
    this.loadAdjuntos();

    this.ubicacionesElaboracion = this.ubicacionesSvc.getUbicacionesElaboracion();
    this.ubicacionesEnvasado = this.ubicacionesSvc.getUbicacionesEnvasado();
    this.ubicacionesEtiquetaje = this.ubicacionesSvc.getUbicacionesEtiquetaje();
    this.categorias = this.categoriaEcologicaSvc.getCategorias();
    this.envases = this.envasesSvc.getEnvases();
    this.normativas = Object.values(this.NormativaEnum);

    this.formGroup.valueChanges.subscribe(values => this.handleUbicacionesChanges(values));
    this.handleCurrentAction();
    this.handleFormControls();

    this.formGroup.get('nombreComercial')?.valueChanges
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((value: string) => {
          this.formGroup.get('nombreComercial')?.setValue(value.toUpperCase(), { emitEvent: false });
        });

  }

  private handleFormControls(){
    switch (this.noAutorizacion.tipoAutorizacion) {
      case TipoAutorizacionEnum.ETIQUETATGE_ELABORATS:
        this.formGroup.get('tipoNormativa')?.disable();
        break;
      case TipoAutorizacionEnum.ETIQUETATGE_IMPORTATS:
      case TipoAutorizacionEnum.ETIQUETATGE_DISTRIBUITS:
      case TipoAutorizacionEnum.ETIQUETATGE_HORTOFRUTICOLES:
        this.formGroup.get('auxiliaresTecnologicos')?.disable();
        this.formGroup.get('usaNitratos')?.disable();
        this.formGroup.get('tecnicaTransformacion')?.disable();
        this.formGroup.get('idUbicacionElaboracion')?.disable();
        this.formGroup.get('idUbicacionEnvase')?.disable();
        this.formGroup.get('idUbicacionEtiqueta')?.disable();
        this.formGroup.get('textoElaboracion')?.disable();
        this.formGroup.get('textoEnvase')?.disable();
        this.formGroup.get('textoEtiquetaje')?.disable();
        this.formGroup.get('tipoNormativa')?.disable();
        break;
      case TipoAutorizacionEnum.ETIQUETATGE_VINS:
        break;
    }
  }

  private loadNoAutorizacion() {
    let obs = this.noAutorizacionesSvc.getNoAutorizacion(this.idOperador, this.idSolicitudProducto);
    obs.pipe(
        switchMap(_ => {
          return obs
        })
    ).subscribe(value => {
      this.noAutorizacion = value;
      this.noAutorizacion.idSolicitudProducto = value.idSolicitudProducto;
      this.formGroup.patchValue({
        ...this.noAutorizacion,
        "idProducto": {id: this.noAutorizacion.idProducto, valor: this.noAutorizacion.nombreProducto}
      });

      this.handleCurrentAction();
      this.handleFormControls();
      this.handlePorcentajeIngredientes();
      this.handleUbicacionesChanges(this.noAutorizacion);
    })
  }

  onBack(){
    let queryParams = {};
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['tipoActual']) {
        queryParams = { tipoActual: params['tipoActual'] };
      }
    });

    this.router.navigate(["../../../"], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams
    });
  }

  private handlePorcentajeIngredientes() {
    this.porcentajeTotal = 0;
    for(const ingrediente of this.noAutorizacion.ingredientes){
      this.porcentajeTotal += ingrediente.porcentaje;
    }
    this.porcentajeOK = this.porcentajeTotal === 100;
  }

  private handleCurrentAction() {
    this.activatedRoute.params.subscribe(params => {
      this.action = params['noAutorizacionAction'];
      if(this.action == 'view') {
        this.formGroup.disable();
        this.title='OPERADORES.noAutorizaciones.titleDetall'
        this.titleParams={'idSolicitudProducto': this.idSolicitudProducto};
      }
    })
  }

  private handleUbicacionesChanges(values: any) {
    this.idUbicacionElaboracion = values.idUbicacionElaboracion;
    this.idUbicacionEnvase = values.idUbicacionEnvase;
    this.idUbicacionEtiqueta = values.idUbicacionEtiqueta;

    this.normalizeTextosUbicaciones(this.formGroup.get('textoElaboracion'), values.idUbicacionElaboracion);
    this.normalizeTextosUbicaciones(this.formGroup.get('textoEnvase'), values.idUbicacionEnvase);
    this.normalizeTextosUbicacionesEtiquetaje(this.formGroup.get('textoEtiquetaje'), values.idUbicacionEtiqueta);
  }

  private normalizeTextosUbicaciones(formControl: AbstractControl | null, ubicacion: Ubicaciones) {
    if(formControl && ubicacion == Ubicaciones.ELABORADO){
      formControl.setValue('', {emitEvent: false});
    }
  }

  private normalizeTextosUbicacionesEtiquetaje(formControl: AbstractControl | null, ubicacion: UbicacionesEtiquetaje) {
    if(formControl && ubicacion == (UbicacionesEtiquetaje.ETIQUETADO || ubicacion == UbicacionesEtiquetaje.SIN_ETIQUETAR)){
      formControl.setValue('', {emitEvent: false});
    }
  }

  private loadEtiqueta() {
    this.noAutorizacionesSvc.getEtiquetaNoAutorizacion(this.idOperador, this.idSolicitudProducto)
        .subscribe(value => {
          this.etiqueta = value;
        })
  }

  onDownloadEtiqueta() {
    this.noAutorizacionesSvc.downloadEtiquetaNoAutorizacion(this.idOperador, this.idSolicitudProducto);
  }

  private loadAdjuntos() {
    this.noAutorizacionesSvc.getAdjuntosNoAutorizacion(this.idOperador, this.idSolicitudProducto)
        .subscribe(values => {
          this.adjuntos = values;
        })
  }

  onDownloadAdjunto(idNoAutorizacionAdjunto: number) {
    this.noAutorizacionesSvc.downloadAdjuntoNoAutorizacion(this.idOperador, this.idSolicitudProducto, idNoAutorizacionAdjunto);
  }

}
