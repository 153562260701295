<div *ngIf="almacen">

    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div *ngIf="almacen.numOrdenAlmacen; else titleNuevo">
            <span *ngIf="readOnly; else titleEdit">Ver</span>
            <ng-template #titleEdit>{{'OPERADORES.almacenes.titleEdit' | translate}}</ng-template>
            {{almacen.numOrdenAlmacen}}
        </div>
        <ng-template #titleNuevo>
            {{'ETIQUETAS.addProducto' | translate}}
        </ng-template>

        <div class="clickable" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <hr/>

    <form [formGroup]="formGroup" class="col-12" (submit)="dialogRef.close(this.formGroup.value)">

        <div class="row">
            <div class="col-md-3 col-sm-12">
                <app-input-text
                        formControlName="numOrdenAlmacen"
                        [label]="'OPERADORES.almacenes.numOrdenAlmacen' | translate"
                        [campoObligatorio]="true"
                        [isDisabled] = true
                >
                </app-input-text>
            </div>
            <div class="col-md-3 col-sm-12">
                <app-input-text
                        formControlName="nombreAlmacen"
                        [label]="'OPERADORES.almacenes.nombreAlmacen' | translate"
                        [campoObligatorio]="true"
                >
                </app-input-text>
            </div>
            <div class="col-md-3 col-sm-12">
                <app-input-text
                        formControlName="parcela"
                        [label]="'OPERADORES.parcela' | translate"
                >
                </app-input-text>
            </div>
            <div class="col-md-3 col-sm-12">
                <app-input-text
                        formControlName="poligono"
                        [label]="'OPERADORES.parcelas.poligono' | translate"
                >
                </app-input-text>
            </div>
        </div>

        <div class="row">

            <div class="col-md-3 col-sm-12">
                <app-input-select
                        [label]="'general.municipio' | translate"
                        formControlName="municipio"
                        [options]="municipios | async | arrayToSelectItem:'idEnvase':'descripcion'">
                </app-input-select>
            </div>

            <div class="col-md-3 col-sm-12">
                <app-input-text
                        formControlName="direccion"
                        [label]="'general.direccion' | translate"
                >
                </app-input-text>
            </div>
            <div class="col-md-3 col-sm-12">
                <app-input-text
                        formControlName="codigoPostal"
                        [label]="'general.codigoPostal' | translate"
                        [campoObligatorio]="true"
                >
                </app-input-text>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 col-sm-12">
                <app-input-text
                        formControlName="superficie"
                        [label]="'general.superficie' | translate"
                        [campoObligatorio]="true"
                >
                </app-input-text>
            </div>
            <div class="col-md-3 col-sm-12">
                <app-input-text
                        formControlName="cualificacion"
                        [label]="'OPERADORES.parcelas.cualificacion' | translate"
                        [campoObligatorio]="true"
                >
                </app-input-text>
            </div>
        </div>

        <hr/>

        <div class="row">
            <div class="col-md-6 col-xl-3">
                <app-input-checkbox
                        formControlName="compartido"
                        [label]="'OPERADORES.compartido' | translate"
                        [campoObligatorio]="true"
                >
                </app-input-checkbox>
            </div>
        </div>

        <hr/>

        <div class="row">
            <app-input-checkbox
                    formControlName="prodAlmacenados.maquinaria"
                    [label]="'OPERADORES.maquinaria' | translate"
            >
            </app-input-checkbox>
        </div>
        <div class="row">
            <app-input-checkbox
                    formControlName="prodAlmacenados.fertilizantes"
                    [label]="'OPERADORES.fertilizantes' | translate"
            >
            </app-input-checkbox>
        </div>
        <div class="row">
            <app-input-checkbox
                    formControlName="prodAlmacenados.semillas"
                    [label]="'OPERADORES.semillas' | translate"
            >
            </app-input-checkbox>
        </div>
        <div class="row">
            <app-input-checkbox
                    formControlName="prodAlmacenados.ferraje"
                    [label]="'OPERADORES.ferraje' | translate"
            >
            </app-input-checkbox>
        </div>
        <div class="row">
            <app-input-checkbox
                    formControlName="prodAlmacenados.contProductos"
                    [label]="'OPERADORES.contProductos' | translate"
            >
            </app-input-checkbox>
        </div>

        <div class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button type="submit" mat-raised-button color="primary" class="px-2"
                    [disabled]="formGroup.invalid">
                {{'general.guardar' | translate}}
            </button>
        </div>

    </form>

</div>
