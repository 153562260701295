<ng-template #rt let-r="result" let-t="term">
    {{r.valor}}
</ng-template>

<div class="mt-2 p-0">
    <div class="d-flex align-content-start">
        <label *ngIf="label && !campoObligatorio" [for]="id" class="font-weight-bold">{{label}}</label>
        <label *ngIf="label && campoObligatorio" [for]="id" class="font-weight-bold">{{label}} *</label>
        <mat-icon *ngIf="helpText" placement="right" [ngbTooltip]="helpText" style="cursor: default;transform: scale(0.75)" class="pl-1">info</mat-icon>
    </div>
    <input type="text" class="form-control col-12 m-0"
           [ngClass]="{'is-invalid': ngControl?.invalid && !ngControl.pristine}"
           [placeholder]="label"
           [(ngModel)]="value"
           (change)="change($event)"
           [disabled]="isDisabled"
           [ngbTypeahead]="typeaheadObs"
           [resultTemplate]="rt"
           [inputFormatter]="formatter"
           [focusFirst]="true"/>
    <small *ngIf="small" class="text-muted w-100 pt-1">{{ small }}</small>
    <div *ngIf="ngControl?.invalid && !ngControl.pristine" class="invalid-feedback">
        <span *ngIf="ngControl.errors && ngControl.errors['required']">
            {{'formErrors.obligatorio' | translate}}
        </span>
    </div>
</div>
