import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";

export const OperadoresParcelaTableConfig: TablaColumna[] =  [
    {
        header: 'general.municipio',
        field: "municipio",
        type: "string"
    },
    {
        header: 'OPERADORES.parcelas.sigpac',
        field: "sigpac",
        type: "string"
    },
    {
        header: 'OPERADORES.parcelas.superficie',
        field: "superficie",
        type: "number"
    },
    {
        header: 'OPERADORES.parcelas.cultivo',
        field: "cultivo",
        type: "string"
    },
    {
        header: 'OPERADORES.parcelas.variedad',
        field: "variedad",
        type: "string"
    },
    {
        header: 'OPERADORES.parcelas.cualificacion',
        field: "cualificacion",
        type: "string"
    },
    {
        header: 'OPERADORES.parcelas.fechaCualificacion',
        field: "fechaCualificacion",
        type: "date"
    },
    {
        header: 'OPERADORES.parcelas.rvc',
        field: "rvc",
        type: "string"
    }
]

export const OperadoresParcelaImportTableConfig: TablaColumna[] =  [
    {
        header: 'OPERADORES.parcelas.darpFilaArchivo',
        field: "numFila",
        type: "string"
    },
    {
        header: 'general.provincia',
        field: "provincia",
        type: "string"
    },
    {
        header: 'general.municipio',
        field: "municipi",
        type: "string"
    },
    {
        header: 'general.producto',
        field: "producto",
        type: "string"
    },
    {
        header: 'OPERADORES.parcelas.poligono',
        field: "poligon",
        type: "string"
    },
    {
        header: 'OPERADORES.parcela',
        field: "parcela",
        type: "string"
    },
    {
        header: 'OPERADORES.parcelas.recinto',
        field: "recinto",
        type: "string"
    },
    {
        header: 'general.descripcion',
        field: "descripcio",
        type: "string"
    }
]

export const OperadoresParcelaViewAccionesConfig: TablaAccion[] = [
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure dades"
    }
]

export const OperadoresParcelaEditAccionesConfig: TablaAccion[] = [
    {
        action: "edit",
        icon: "edit",
        toolTip: "Editar parcel·la"
    },
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure dades"
    },
    {
        action: "delete",
        icon: "delete",
        toolTip: "Eliminar parcel·la"
    }
]

export const OperadoresParcelaImportAccionesConfig: TablaAccion[] = [

]