import {BasePage} from "./basePage";

export class Page extends BasePage {

    constructor() {
        super()
        this._isApantallamiento = false;
        this._isLogin = false;
    }

}