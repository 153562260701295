import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {OperadoresHttpService, TipoAutorizacionEnum} from "../../../../core/services/http/operadores-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Autorizacion} from "../../../../core/model/autorizacion/autorizacion";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {Page} from "../../../../core/model/core/pages/page";
import {Ubicacion} from "../../../../core/model/master/ubicacion";
import {UbicacionesHttpService} from "../../../../core/services/http/solicitud/ubicaciones.http.service";
import {Observable, switchMap} from "rxjs";
import {Ubicaciones, UbicacionesEtiquetaje} from "../../../etiquetas/services/etiquetas-compuesto.service";
import {
  AccionesIngredientes,
  IngredientesTableAction,
  IngredientesTableConfig
} from "../../../etiquetas/etiquetas.config";
import {Etiqueta} from "../../../../core/model/etiquetas/etiqueta";
import {AutorizacionAdjunto} from "../../../../core/model/autorizacion/autorizacion-adjunto";
import {ProductoService} from "../../../../core/services/http/producto.service";
import {CategoriaEcologica} from "../../../../core/model/master/categoria-ecologica";
import {EnvasesHttpService} from "../../../../core/services/http/solicitud/envases.http.service";
import {
  CategoriasEcologicasHttpService
} from "../../../../core/services/http/solicitud/categorias-ecologicas.http.service";
import {Envase} from "../../../../core/model/master/envase";
import {Normativa} from "../../../../core/model/master/normativa";
import {ModalService} from "../../../../core/services/components/modal.service";
import {
  DisclaimerEliminarGenericoComponent
} from "../../modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";
import {
  EditIngredienteAutorizacionModalComponent
} from "../../modals/edit-ingrediente-autorizacion-modal/edit-ingrediente-autorizacion-modal.component";
import {AutorizacionesHttpService} from "../../../../core/services/http/autorizaciones.http.service";
import {catchError, debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {ToastService} from "../../../../core/services/components/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {AlbaranModalComponent} from "../../../etiquetas/modals/albaran-modal/albaran-modal.component";
import {Producto} from "../../../../core/model/autorizacion/producto";
import {AttachFile} from "../../../../core/model/autorizacion/AttachFile";
import {DropFileZoneComponent} from "../../../../shared/components/drop-file-zone/drop-file-zone.component";


@Component({
  selector: 'app-operadores-detalle-autorizacion',
  templateUrl: './operadores-detalle-autorizacion.component.html',
  styleUrls: ['./operadores-detalle-autorizacion.component.scss']
})
export class OperadoresDetalleAutorizacionComponent extends Page implements OnInit {

  @ViewChild(DropFileZoneComponent) dropFileZone!: DropFileZoneComponent;

  //Services
  private operadorSvc: OperadoresHttpService;
  private ubicacionesSvc: UbicacionesHttpService
  protected productoSvc: ProductoService;
  protected envasesSvc: EnvasesHttpService;
  protected categoriaEcologicaSvc: CategoriasEcologicasHttpService;
  protected modalSvc: ModalService;
  protected autorizacionesSvc: AutorizacionesHttpService;

  //Data
  private idOperador: string;
  private idAutorizacion: number;
  public ingredientesConfigTable = IngredientesTableConfig;
  public autorizacion: Autorizacion;
  protected ubicacionesElaboracion: Observable<Ubicacion[]>;
  protected ubicacionesEnvasado: Observable<Ubicacion[]>;
  protected ubicacionesEtiquetaje: Observable<Ubicacion[]>;
  public tipoAutorizacion = TipoAutorizacionEnum;
  protected etiqueta: Etiqueta;
  protected adjuntos: AutorizacionAdjunto[] = [];
  protected categorias: Observable<CategoriaEcologica[]>;
  protected envases: Observable<Envase[]>;
  normativas: Normativa[] = [];
  tableAccionesEdit = IngredientesTableAction;
  tableAccionesView: Array<TablaAccion> = [];
  protected esPinsoFiltroPopUpAlbaran: Boolean;
  protected idOrientacionProducto: Observable<Producto>;

  //FormGroup and auxiliars
  public formGroup: FormGroup;
  protected idUbicacionElaboracion: number;
  protected idUbicacionEnvase: number;
  protected idUbicacionEtiqueta: number;
  protected ubicacionEnum = Ubicaciones;
  protected ubicacionEtiquetajeEnum = UbicacionesEtiquetaje;
  protected action: string;
  protected porcentajeOK: boolean = false;
  protected porcentajeTotal: number = 0;
  protected title;
  protected titleParams;
  protected literalesUbicaciones = {
    1: 'ETIQUETAS.instalacionesDireccionMunicipio',
    2: 'ETIQUETAS.instalacionesSubcontratada'
  }
  private readonly NormativaEnum = {
    'UE': { idNormativa: 1, descripcion: 'UE' },
    'NOP': { idNormativa: 2, descripcion: 'NOP' }
  };

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private injector: Injector,
              protected toastSvc: ToastService,
              protected translateSvc: TranslateService) {
    super();
    this.idOperador = activatedRoute.snapshot.params['idOperador'];
    this.idAutorizacion = activatedRoute.snapshot.params['idAutorizacion'];

    this.operadorSvc = injector.get(OperadoresHttpService);
    this.ubicacionesSvc = injector.get(UbicacionesHttpService);
    this.productoSvc = injector.get(ProductoService);
    this.envasesSvc = injector.get(EnvasesHttpService);
    this.categoriaEcologicaSvc = injector.get(CategoriasEcologicasHttpService);
    this.autorizacionesSvc = injector.get(AutorizacionesHttpService);
    this.modalSvc = injector.get(ModalService);

    this.autorizacion = new Autorizacion();
    this.formGroup = new FormGroup({
      "numOrdenAutorizacion": new FormControl(''),
      "tipoAutorizacion": new FormControl(''),
      "idProducto": new FormControl('', Validators.required),
      "nombreComercial": new FormControl('', [Validators.required, Validators.maxLength(90)]),
      "idCategoriaEcologica": new FormControl(null, Validators.required),
      "marca": new FormControl('', [Validators.required, Validators.maxLength(90)]),
      "idEnvase": new FormControl(null, Validators.required),
      "capacidad": new FormControl('', [Validators.required, Validators.maxLength(30)]),
      "fechaAutorizacion": new FormControl('', Validators.required),
      "fechaCambioEstado": new FormControl('', Validators.required),
      "auxiliaresTecnologicos": new FormControl('', Validators.maxLength(1800)),
      "usaNitratos": new FormControl(''),
      "tecnicaTransformacion": new FormControl('', [Validators.required, Validators.maxLength(90)]),
      "idUbicacionElaboracion": new FormControl('', Validators.required),
      "idUbicacionEnvase": new FormControl('', Validators.required),
      "idUbicacionEtiqueta": new FormControl('', Validators.required),
      "textoElaboracion": new FormControl('', Validators.maxLength(90)),
      "textoEnvase": new FormControl('', Validators.maxLength(90)),
      "textoEtiquetaje": new FormControl('', Validators.maxLength(90)),
      "tipoNormativa": new FormControl('', Validators.required),
      "usuario": new FormControl(''),
    });

    this.formGroup.get('fechaAutorizacion')?.valueChanges.subscribe((fechaAutorizacion: Date) => {
      this.formGroup.get('fechaCambioEstado')?.setValue(fechaAutorizacion);
    });

  }

  ngOnInit() {
    if(this.idAutorizacion){
      this.loadAutorizacion();
      this.loadEtiqueta();
      this.loadAdjuntos();
    }
    else {
      this.autorizacion = new Autorizacion();
      this.autorizacion.tipoAutorizacion = this.tipoAutorizacion.ETIQUETATGE_IMPORTATS;
    }

    this.categorias = this.categoriaEcologicaSvc.getCategorias();
    this.ubicacionesElaboracion = this.ubicacionesSvc.getUbicacionesElaboracion();
    this.ubicacionesEnvasado = this.ubicacionesSvc.getUbicacionesEnvasado();
    this.ubicacionesEtiquetaje = this.ubicacionesSvc.getUbicacionesEtiquetaje();
    this.envases = this.envasesSvc.getEnvases();
    this.normativas = Object.values(this.NormativaEnum);

    this.formGroup.valueChanges.subscribe(values => this.handleUbicacionesChanges(values));
    this.handleCurrentAction();
    this.handleFormControls();

    this.formGroup.get('nombreComercial')?.valueChanges
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((value: string) => {
          this.formGroup.get('nombreComercial')?.setValue(value.toUpperCase(), { emitEvent: false });
        });

  }

  private handleFormControls(){
    switch (this.autorizacion.tipoAutorizacion) {
      case TipoAutorizacionEnum.ETIQUETATGE_ELABORATS:
        this.formGroup.get('tipoNormativa')?.disable();
        break;
      case TipoAutorizacionEnum.ETIQUETATGE_IMPORTATS:
      case TipoAutorizacionEnum.ETIQUETATGE_DISTRIBUITS:
      case TipoAutorizacionEnum.ETIQUETATGE_HORTOFRUTICOLES:
        this.formGroup.get('auxiliaresTecnologicos')?.disable();
        this.formGroup.get('usaNitratos')?.disable();
        this.formGroup.get('tecnicaTransformacion')?.disable();
        this.formGroup.get('idUbicacionElaboracion')?.disable();
        this.formGroup.get('idUbicacionEnvase')?.disable();
        this.formGroup.get('idUbicacionEtiqueta')?.disable();
        this.formGroup.get('textoElaboracion')?.disable();
        this.formGroup.get('textoEnvase')?.disable();
        this.formGroup.get('textoEtiquetaje')?.disable();
        this.formGroup.get('tipoNormativa')?.disable();
        break;
      case TipoAutorizacionEnum.ETIQUETATGE_VINS:
        break;
    }
  }

  private loadAutorizacion() {
    let obs = this.autorizacionesSvc.getAutorizacion(this.idOperador, this.idAutorizacion);
    obs.pipe(
        switchMap(_ => {
          return obs
        })
    ).subscribe(value => {
      this.autorizacion = value;
      this.autorizacion.numOrdenAutorizacion = value.numOrdenAutorizacion;
      this.formGroup.patchValue({
        ...this.autorizacion,
        "idProducto": {id: this.autorizacion.idProducto, valor: this.autorizacion.nombreProducto}
      });

      this.handleCurrentAction();
      this.handleFormControls();
      this.handlePorcentajeIngredientes();
      this.handleUbicacionesChanges(this.autorizacion);
      this.handleCategorias(this.autorizacion);
      this.handleUbicacionesEtiquetaje(this.autorizacion);
    })
  }

  onBack(){
    let queryParams = {};
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['tipoActual']) {
        queryParams = { tipoActual: params['tipoActual'] };
      }
    });

    if (this.action == 'create') {
      this.router.navigate(["../../"], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams
      });
    }
    else {
      this.router.navigate(["../../../"], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams
      });
    }
  }

  onGuardarForm() {
    if(this.autorizacion.numOrdenAutorizacion != null){
      const updatedAutorizacion = {...this.autorizacion, ...this.formGroup.value};
      this.autorizacionesSvc.modificarAutorizacion(this.idOperador, updatedAutorizacion)
          .subscribe(value => {
            this.autorizacion = value;
            this.formGroup.patchValue({
              ...this.autorizacion,
              "idProducto": {id: this.autorizacion.idProducto, valor: this.autorizacion.nombreProducto}
            })
          });
    }
    else{
      this.autorizacion = this.formGroup.value;
      this.autorizacion.tipoAutorizacion = this.tipoAutorizacion.ETIQUETATGE_IMPORTATS;
      this.autorizacionesSvc.crearAutorizacion(this.idOperador, this.autorizacion)
          .subscribe(nuevaAutorizacion => {
            this.router.navigate(["../", nuevaAutorizacion.numOrdenAutorizacion, 'edit'],
                {relativeTo: this.activatedRoute})
          });
    }
  }

  private handlePorcentajeIngredientes() {
    this.porcentajeTotal = 0;
    for(const ingrediente of this.autorizacion.ingredientes){
      this.porcentajeTotal += ingrediente.porcentaje;
    }

    this.porcentajeOK = this.porcentajeTotal === 100;
  }

  private handleCurrentAction() {
    this.activatedRoute.params.subscribe(params => {
      this.action = params['autorizacionAction'];

      if(this.action == 'edit'){
        this.formGroup.enable();
        this.formGroup.get('usuario')?.disable();
        this.title='OPERADORES.autorizaciones.titleDetall'
        this.titleParams={'autorizacion': this.idAutorizacion,
          'estat': this.autorizacion.activo ? 'Alta' : 'Baixa'};
      }
      else if(this.action == 'view') {
        this.formGroup.disable();
        this.title='OPERADORES.autorizaciones.titleDetall'
        this.titleParams={'autorizacion': this.idAutorizacion,
          'estat': this.autorizacion.activo ? 'Alta' : 'Baixa'};
      }
      else if(this.action == 'create'){
        this.formGroup.enable();
        this.formGroup.get('usaNitratos')?.disable();
        this.title='OPERADORES.autorizaciones.titleAlta'
        this.titleParams={'tipoAutorizacion':TipoAutorizacionEnum.ETIQUETATGE_IMPORTATS}
      }
    })
  }

  private handleCategorias(values: any) {
    if (values.tipoAutorizacion == TipoAutorizacionEnum.ETIQUETATGE_HORTOFRUTICOLES){
      this.categorias = this.categorias.pipe(
          map(categorias => categorias.slice(1, 3))
      );
    } else {
      this.categorias = this.categoriaEcologicaSvc.getCategorias();
    }
  }

  private handleUbicacionesEtiquetaje(values: any) {
    if (values.tipoAutorizacion == TipoAutorizacionEnum.ETIQUETATGE_VINS || values.tipoAutorizacion == TipoAutorizacionEnum.ETIQUETATGE_ELABORATS ){
      this.ubicacionesEtiquetaje = this.ubicacionesEtiquetaje.pipe(
          map(ubicacionesEtiquetaje => [ubicacionesEtiquetaje[0], ubicacionesEtiquetaje[1], ubicacionesEtiquetaje[3]])
      )
    } else {
      this.ubicacionesEtiquetaje = this.ubicacionesSvc.getUbicacionesEtiquetaje();
    }
  }

  private handleUbicacionesChanges(values: any) {
    this.idUbicacionElaboracion = values.idUbicacionElaboracion;
    this.idUbicacionEnvase = values.idUbicacionEnvase;
    this.idUbicacionEtiqueta = values.idUbicacionEtiqueta;

    this.normalizeTextosUbicaciones(this.formGroup.get('textoElaboracion'), values.idUbicacionElaboracion);
    this.normalizeTextosUbicaciones(this.formGroup.get('textoEnvase'), values.idUbicacionEnvase);
    this.normalizeTextosUbicacionesEtiquetaje(this.formGroup.get('textoEtiquetaje'), values.idUbicacionEtiqueta);
  }

  private normalizeTextosUbicaciones(formControl: AbstractControl | null, ubicacion: Ubicaciones) {
    if(formControl && ubicacion == Ubicaciones.ELABORADO){
      formControl.setValue('', {emitEvent: false});
    }
  }

  private normalizeTextosUbicacionesEtiquetaje(formControl: AbstractControl | null, ubicacion: UbicacionesEtiquetaje) {
    if(formControl && ubicacion == (UbicacionesEtiquetaje.ETIQUETADO || ubicacion == UbicacionesEtiquetaje.SIN_ETIQUETAR)){
      formControl.setValue('', {emitEvent: false});
    }
  }

  onDownloadEtiqueta() {
    this.autorizacionesSvc.downloadEtiquetaAutorizacion(this.idOperador,this.idAutorizacion);
  }

  onDownloadAdjunto(idAutorizacionAdjunto: number) {
    this.autorizacionesSvc.downloadAdjuntoAutorizacion(this.idOperador,this.idAutorizacion, idAutorizacionAdjunto);
  }

  protected onDeleteAdjunto(idAutorizacionAdjunto: number) {
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'OPERADORES.disclaimerEliminarAdjunto'}, {maxWidth: '30vw'})
        .afterClosed().subscribe(accepted => {
      if(accepted){
        this.autorizacionesSvc.eliminarAdjunto(this.idOperador, this.idAutorizacion, idAutorizacionAdjunto)
            .subscribe(_ => this.loadAdjuntos());
      }
    });
  }

  protected onDeleteEtiqueta() {
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'OPERADORES.disclaimerEliminarEtiqueta'}, {maxWidth: '30vw'})
        .afterClosed().subscribe(accepted => {
      if(accepted){
        this.autorizacionesSvc.eliminarEtiqueta(this.idOperador, this.idAutorizacion)
            .subscribe(_ => this.loadEtiqueta());
      }
    });
  }

  private loadEtiqueta() {
    this.autorizacionesSvc.getEtiquetaAutorizacion(this.idOperador, this.idAutorizacion)
        .subscribe(value => {
          this.etiqueta = value;
        })
  }

  private loadAdjuntos() {
    this.autorizacionesSvc.getAdjuntosAutorizacion(this.idOperador, this.idAutorizacion)
        .subscribe(values => {
          this.adjuntos = values;
        })
  }

  onFileDropEtiqueta(file: File[]) {
    if (this.action == 'edit') {
      let attachFile = new AttachFile();
      attachFile.file = file[0];

      this.autorizacionesSvc.crearEtiqueta(this.idOperador, this.idAutorizacion, attachFile.file)
          .pipe(
              catchError(err => {
                this.toastSvc.showError(this.translateSvc.instant("ETIQUETAS.errorFileSize"));
                this.dropFileZone.clearFiles();
                throw this.translateSvc.instant("ETIQUETAS.errorFileSize");
              })
          )
          .subscribe(newEtiqueta => this.etiqueta = newEtiqueta);
    }
  }

  onFileDropAdjunto(files: File[]) {
    if (this.action == 'edit') {
      const attachFiles: AttachFile[] = [];

      files.forEach(file => {
        if (file.size <= 25 * 1024 * 1024) {
          const attachFile = new AttachFile();
          attachFile.file = file;
          attachFiles.push(attachFile);
        } else {
          this.toastSvc.showError(this.translateSvc.instant("ETIQUETAS.errorFileSize"));
        }
      });

      if (attachFiles.length > 0) {
        this.autorizacionesSvc.crearAdjuntos(this.idOperador, this.idAutorizacion, attachFiles)
            .pipe(
                catchError(err => {
                  this.toastSvc.showError(this.translateSvc.instant("ETIQUETAS.errorFileSize"));
                  this.loadEtiqueta();
                  throw this.translateSvc.instant("ETIQUETAS.errorFileSize");
                })
            )
            .subscribe(newAdjuntos => this.adjuntos = newAdjuntos);
      }
    }
  }

  onActionTableIngredientes($event: any) {
    switch ($event.action) {
      case AccionesIngredientes.EDIT:
        this.editIngrediente($event);
        break;
      case AccionesIngredientes.REMOVE:
        this.removeIngrediente($event);
        break;
    }
  }

  private editIngrediente($event: any){
    this.modalSvc.openDialog(EditIngredienteAutorizacionModalComponent,{autorizacion: this.autorizacion, ingrediente: $event.item, porcentajeRestante: $event.item.porcentaje})
        .afterClosed().subscribe(ingredienteMod => {
      if(ingredienteMod){
        this.autorizacionesSvc.modificarIngrediente(this.idOperador, this.autorizacion.numOrdenAutorizacion, ingredienteMod.numOrden, ingredienteMod)
            .subscribe(_ => this.loadAutorizacion())
      }
    })
  }

  private removeIngrediente($event: any) {
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'OPERADORES.disclaimerEliminarIngrediente'}, {maxWidth: '30vw'})
        .afterClosed().subscribe(accepted => {
      if(accepted){
        this.autorizacionesSvc.eliminarIngrediente(this.idOperador, this.autorizacion.numOrdenAutorizacion, $event.item.numOrden)
            .subscribe(_ => this.loadAutorizacion());
      }
    })
  }

  onAddIngrediente() {
    let porcentaje = this.autorizacion.ingredientes.reduce((acc, current) => acc - current.porcentaje, 100);

    this.modalSvc.openDialog(EditIngredienteAutorizacionModalComponent,{autorizacion: this.autorizacion, porcentaje: porcentaje})
        .afterClosed().subscribe(nuevoIngrediente => {
      if(nuevoIngrediente){
        this.autorizacionesSvc.crearIngrediente(this.idOperador, this.autorizacion.numOrdenAutorizacion, nuevoIngrediente)
            .subscribe(_ => this.loadAutorizacion());
      }
    })
  }

  handleFiltroPopUpAlbaran() {
    this.resetidUbicacionEtiqueta();

    this.productoSvc.getProducto(this.formGroup.get('idProducto')?.value).subscribe(producto => {
      let orientacionProducto = producto.orientacionProducto.idOrientacionProd;
      if (orientacionProducto === '900000') {
        this.esPinsoFiltroPopUpAlbaran = true;
      } else {
        this.esPinsoFiltroPopUpAlbaran = false;
      }
    });
  }

  resetidUbicacionEtiqueta(){
    if (this.formGroup.get('idUbicacionEtiqueta')?.value == 4 && this.esPinsoFiltroPopUpAlbaran != null) {
      this.formGroup.get('idUbicacionEtiqueta')?.setValue(null);
    }
  }

  handlePopUpAlbaran(){
    if(this.idUbicacionEtiqueta == UbicacionesEtiquetaje.SIN_ETIQUETAR){
      return this.modalSvc.openDialog(AlbaranModalComponent, {esPinsoFiltroPopUpAlbaran: this.esPinsoFiltroPopUpAlbaran}, {width: "900px"});
    } else {
      return null;
    }
  }

}
