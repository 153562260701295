<app-page-template
        [title]="title | translate: titleParams"
>
    <form [formGroup]="animalFormGroup">
        <mat-card class="card shadow">
            <mat-card-title>
                {{'OPERADORES.animales.titleCardDetall' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <div class="col-sm-6 col-md-3 col-lg-2">
                        <app-input-select
                                formControlName="numGranja"
                                [label]="'OPERADORES.granjas.numGranja' | translate"
                                [options]="granjas | async | arrayToSelectItem:'numGranja':'numGranja'"
                                [isDisabled]="action == 'edit'"
                                [campoObligatorio]="action != 'view'"
                        >
                        </app-input-select>
                    </div>
                    <div class="col-md-9 col-lg-10 col-xl-4">
                        <app-input-text
                                formControlName="nombreGranja"
                                [label]="'OPERADORES.granjas.nombreGranja' | translate"
                                [isDisabled]="true"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-2">
                        <app-input-text
                                [value]="granja?.marcaOficial"
                                [label]="'OPERADORES.animales.marcaOficial' | translate"
                                [isDisabled]="true"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-2">
                        <app-input-text
                                [value]="granja?.libroExplotacion"
                                [label]="'OPERADORES.animales.libroExplotacion' | translate"
                                [isDisabled]="true"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-2">
                        <app-input-text
                                [value]="granja?.codigoRega"
                                [label]="'OPERADORES.animales.codigoRega' | translate"
                                [isDisabled]="true"
                        >
                        </app-input-text>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-6 col-xxl-4">
                        <app-input-select
                                formControlName="idEspecie"
                                [label]="'OPERADORES.granjas.especieGanado' | translate"
                                [options]="especies | async | arrayToSelectItem:'idProducto':'nombre'"
                                [campoObligatorio]="action != 'view'"
                        >
                        </app-input-select>
                    </div>
                    <div class="col-lg-6 col-xxl-4">

                        <app-input-select
                                formControlName="codigoRaza"
                                [label]="'OPERADORES.animales.raza' | translate"
                                [options]="razas | async | arrayToSelectItem:'codigoRaza':'descripcion'"
                                [campoObligatorio]="action != 'view'"
                        >
                        </app-input-select>
                    </div>
                    <div class="col-lg-6 col-xxl-4">
                        <app-input-select
                                formControlName="codigoTipoGanado"
                                [label]="'OPERADORES.animales.tipoGanado' | translate"
                                [options]="tiposGanado | async | arrayToSelectItem:'codigoTipoGanado':'descripcion'"
                                [campoObligatorio]="action != 'view'"
                        >
                        </app-input-select>
                    </div>
                    <div class="col-sm-4 col-md-3 col-lg-2">
                        <p class="font-weight-bold mt-2 mb-0">{{'OPERADORES.animales.indicadorUbm' | translate}}</p>
                        <div class="row d-flex flex-row">
                            <div class="col">
                                <app-input-radio
                                        formControlName="indicadorUbm"
                                        [label]="'general.si' | translate"
                                        [value]="'si'"
                                        [id]="'si'"
                                        [isDisabled]="action == 'view' || action == 'edit'">

                                </app-input-radio>
                            </div>
                            <div class="col">
                                <app-input-radio
                                        formControlName="indicadorUbm"
                                        [label]="'general.no' | translate"
                                        [value]="'no'"
                                        [id]="'no'"
                                        [isDisabled]="action == 'view' || action == 'edit'">
                                </app-input-radio>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <app-input-textarea
                            formControlName="observaciones"
                            [label]="'general.observaciones' | translate"
                            [isDisabled]="action == 'view'"
                    >
                    </app-input-textarea>
                </div>

            </mat-card-content>

        </mat-card>
        <mat-card class="card shadow" *ngIf="animal.indicadorUbm=='si'">
            <mat-card-title>
                {{'OPERADORES.animales.datosCenso' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="col-12 p-2 mt-2 d-flex justify-content-end">
                    <button *ngIf="action=='edit'" mat-raised-button color="basic" class="px-2" type="button"
                            (click)="createAnimalCenso()"
                    >
                        {{'OPERADORES.animales.anadirAnimalCenso' | translate}}
                    </button>
                </div>
                <div class="row m-auto" *ngIf="action != 'view'">
                    <app-generic-table
                            [data]="animal?.animalesCenso"
                            [columnsConfig]="animalesCensoTableConfig"
                            [actionsConfig]="animalesCensoEditActionConfig"
                            (onAction)="onActionTable($event)"
                    >
                    </app-generic-table>
                </div>
                <div class="row m-auto" *ngIf="action == 'view'">
                    <app-generic-table
                            [data]="animal?.animalesCenso"
                            [columnsConfig]="animalesCensoTableConfig"
                            (onAction)="onActionTable($event)"
                    >
                    </app-generic-table>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-xl-1">
                        <app-input-text
                                formControlName="totalUbm"
                                [label]="'OPERADORES.animales.totalUbm' | translate"
                                [isDisabled]="true"
                        >
                        </app-input-text>
                    </div>
                </div>

            </mat-card-content>

        </mat-card>
        <div *ngIf="action != 'view'" class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" [disabled]="!animalFormGroup.valid"
                    (click)="onGuardarForm()"
            >
                {{'OPERADORES.modificarOperador.guardarDatosAnimal' | translate}}
            </button>
        </div>
    </form>


    <button mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
        <mat-icon>arrow_back</mat-icon>
        {{"general.volver" | translate}}
    </button>

</app-page-template>

