import {BasePage} from "./basePage";

export class Apantallamiento extends BasePage {

    constructor() {
        super()
        this._isApantallamiento = true;
        this._isLogin = false;
    }

}