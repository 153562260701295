import {Component, Inject, Injector, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToastService} from "../../../../core/services/components/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {map} from "rxjs/operators";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {TipoAutorizacionEnum} from "../../../../core/services/http/operadores-http.service";
import {Observable,of, throwError} from "rxjs";
import {Etiqueta} from "../../../../core/model/etiquetas/etiqueta";
import {EditEtiquetaCompromisosTexts} from "../edit-etiqueta-modal/edit-etiqueta-compromisos-texts";
import {CategoriaEcologica, CategoriaEcologicaEnum} from "../../../../core/model/master/categoria-ecologica";
import {SolicitudProductoHttpService} from "../../../../core/services/http/solicitud/solicitud-producto.http.service";
import {
  CategoriasEcologicasHttpService
} from "../../../../core/services/http/solicitud/categorias-ecologicas.http.service";
import {CodigoTramite, EtiquetasService} from "../../services/etiquetas.service";
import {SolicitudHttpService} from "../../../../core/services/http/solicitud/solicitud.http.service";
import {DropFileZoneComponent} from "../../../../shared/components/drop-file-zone/drop-file-zone.component";

export class RequiereEtiqueta {
  idRequiereEtiqueta!: number;
  descripcion!: string;
}

interface Data {
  producto: SolicitudProducto,
  tipoAutorizacion: TipoAutorizacionEnum,
  onLoad: () => Observable<Etiqueta>,
  onSave: (file: File) => Observable<Etiqueta>,
  onRemove: () => Observable<any>,
  onDownload: () => any
}

@Component({
  selector: 'app-add-hortofruticolas-modal',
  templateUrl: './add-hortofruticolas-modal.component.html',
  styleUrls: ['./add-hortofruticolas-modal.component.scss']
})
export class AddHortofruticolasModalComponent implements OnInit {

  @ViewChild(DropFileZoneComponent) dropFileZone!: DropFileZoneComponent;

  // Values
  protected etiqueta: Etiqueta | null;
  protected file: File | null;
  protected fileEtiqueta: File | null;
  protected tramiteEsmena: boolean;
  protected tipoAutorizacion: TipoAutorizacionEnum;
  protected idCategoriaEcologica;
  protected categoriaEcologicaEnum;
  protected categorias!: Observable<CategoriaEcologica[]>;
  protected solicitudRequiereEtiqueta: Boolean | null;
  protected requiereEtiquetaOpciones: RequiereEtiqueta[] = [];
  protected compromisos = EditEtiquetaCompromisosTexts;

  // Configuration
  protected formGroup: FormGroup;
  protected formGroupDisclaims: FormGroup;
  protected formIsValid: boolean = false;

  // Services
  protected solicitudProductoSvc: SolicitudProductoHttpService;
  protected categoriaEcologicaSvc: CategoriasEcologicasHttpService;
  protected etiquetasSvc: EtiquetasService;
  protected solicitudSvc: SolicitudHttpService;

  private readonly RequiereEtiquetaEnum = {
    'No': { idRequiereEtiqueta: 0, descripcion: 'No' },
    'Sí': { idRequiereEtiqueta: 1, descripcion: 'Sí' }
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: Data,
              public dialogRef: MatDialogRef<AddHortofruticolasModalComponent>,
              protected toastSvc: ToastService,
              protected translateSvc: TranslateService,
              injector: Injector) {

    this.solicitudProductoSvc = injector.get(SolicitudProductoHttpService);
    this.categoriaEcologicaSvc = injector.get(CategoriasEcologicasHttpService);
    this.etiquetasSvc = injector.get(EtiquetasService);

    this.requiereEtiquetaOpciones = Object.values(this.RequiereEtiquetaEnum);

    this.etiqueta = null;
    this.file = null;
    this.tipoAutorizacion = TipoAutorizacionEnum.ETIQUETATGE_HORTOFRUTICOLES;

    this.solicitudRequiereEtiqueta = null;

    this.formGroup = new FormGroup({
      "idCategoriaEcologica": new FormControl(null, Validators.required),
      "requiereEtiqueta": new FormControl(null, Validators.required)
    });

    this.formGroupDisclaims = new FormGroup({
      "disclaims": new FormArray([])
    });
  }

  ngOnInit(): void {
    this.categorias = this.categoriaEcologicaSvc.getCategorias()
        .pipe(map(categorias => categorias.slice(1, 3)));

    this.loadDisclaims();
  }

  handleIdCategoriaEcologica(){
    this.idCategoriaEcologica = this.formGroup.get("idCategoriaEcologica")?.value;
    if (this.idCategoriaEcologica == 2) {
      this.categoriaEcologicaEnum = CategoriaEcologicaEnum.AE_95;
    } else if (this.idCategoriaEcologica == 3) {
      this.categoriaEcologicaEnum = CategoriaEcologicaEnum.CONVERSION;
    }
    this.loadDisclaims();
  }

  handleRequiereEtiqueta(){
    let requiereEtiqueta = this.formGroup.get("requiereEtiqueta")?.value;
    if (requiereEtiqueta == 0) {
      this.solicitudRequiereEtiqueta = false;
    } else if (requiereEtiqueta == 1) {
      this.solicitudRequiereEtiqueta = true;
    }
  }

  onFileDropEtiqueta($event: File[]) {
    const maxSizeInBytes = 25 * 1024 * 1024; // 25MB
    const fileDropZone = $event[0];

    if (fileDropZone.size <= maxSizeInBytes) {
      this.fileEtiqueta = fileDropZone;
      return of(null);
    } else {
      const errorMessage = this.translateSvc.instant("ETIQUETAS.errorFileSize");
      this.toastSvc.showError(errorMessage);
      this.fileEtiqueta = null;
      this.dropFileZone.clearFiles();
      return throwError(errorMessage);
    }
  }

  onDelete() {
    this.data.onRemove().subscribe(_ => {
      this.etiqueta = null;
    })
  }

  onDownload() {
    this.data.onDownload();
  }

  onCancel() {
    this.dialogRef.close();
  }

  private loadDisclaims() {
    let texts = this.compromisos.get(this.categoriaEcologicaEnum);
    this.disclaims().clear();
    texts?.forEach(text => {
      this.disclaims().push(new FormGroup({
        "text": new FormControl(text),
        "checked": new FormControl(false, [Validators.requiredTrue])
      }));
    });
  }

  disclaims() : FormArray{
    return this.formGroupDisclaims.get("disclaims") as FormArray;
  }

  onLoadExcel(element : any) {
    const file = element.files[0];

    this.solicitudProductoSvc.cargarProductosExcelHortofruticolas(this.etiquetasSvc.idSolicitud, file, this.fileEtiqueta, CodigoTramite.ETIQUETATGE_HORTOFRUTICOLES, this.idCategoriaEcologica)
        .subscribe(_ => this.etiquetasSvc.cargarProductos());
    this.dialogRef.close();
  }

}
