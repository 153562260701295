import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, firstValueFrom, Observable } from 'rxjs';
import { ToastService } from '../../../core/services/components/toast.service';
import { TranslateService } from '@ngx-translate/core';
import {
    AuthService,
    JWTDataExternalAltaInscrito
} from '../../../core/services/security/auth.service';
import { ECityClicHttpService } from '../../../core/services/http/solicitud/e-city-clic.http.service';
import {AltaInscrito} from "../../../core/model/operadores/altaInscrito";
import {AltaInscritoHttpService} from "../../../core/services/http/operadores/alta-inscrito-http.service";


@Injectable({
    providedIn: 'root'
})
export class OperadoresService {

    // Basic values
    protected _idSolicitudInscrito: string;
    protected _isInitialized = false;
    private _altaInscrito: AltaInscrito;

    // Services
    private activatedRoute: ActivatedRoute;
    private router: Router;
    protected toastSvc: ToastService;
    protected translateSvc: TranslateService;
    protected altaInscritoSvc: AltaInscritoHttpService;
    protected semicSvc: ECityClicHttpService;
    protected authSvc: AuthService;

    constructor(protected injector: Injector) {
        this.activatedRoute = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.toastSvc = injector.get(ToastService);
        this.translateSvc = injector.get(TranslateService);
        this.altaInscritoSvc = injector.get(AltaInscritoHttpService);
        this.semicSvc = injector.get(ECityClicHttpService);
        this.authSvc = injector.get(AuthService);
    }

    public initTramite(jwtData: JWTDataExternalAltaInscrito) {
        this._isInitialized = true;
        this._idSolicitudInscrito = jwtData.idSolicitudInscrito;

        if (this.hasRequiredParamaters) {
            this.cargarDatosVisualizacion();
        } else {
            this.handleErrorLoadingData();
        }
    }

    public async cargarDatosVisualizacion() {
        try {
            this._altaInscrito = await firstValueFrom(this.altaInscritoSvc.getAltaInscrito(this._idSolicitudInscrito));
            this.redirectToTramitePage(this._altaInscrito);
        } catch (e) {
            this.handleErrorLoadingData();
        }
    }

    public enviarAltaInscrito() {
        return this.semicSvc.enviarAltaInscrito(this._idSolicitudInscrito);
    }

    public enviarConfirmacion() {
        return this.semicSvc.confirmarSolicitud(this._idSolicitudInscrito);
    }

    public enviarFinalizacion() {
        return this.semicSvc.finalizarSolicitud(this._idSolicitudInscrito);
    }

    public get hasRequiredParamaters(): boolean {
        return this._idSolicitudInscrito != null;
    }

    // Getters
    get altaInscrito(): AltaInscrito {
        return this._altaInscrito;
    }

    public get idSolicitudInscrito(): string {
        return this._idSolicitudInscrito;
    }

    public get initialized(): boolean {
        return this._isInitialized;
    }

    public handleErrorLoadingData(): Observable<any> {
        this.toastSvc.show(this.translateSvc.instant('OPERADORES.faltaInformacionTramite'));
        this.authSvc.logout();
        return EMPTY;
    }

    private redirectToTramitePage(altaInscrito: AltaInscrito) {
        this.router.navigate(['operadors', 'solicitud-inscrito']);
    }
}
