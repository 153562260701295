<div>
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div>
            {{textoDisclaimer | translate}}
        </div>

        <div class="clickable" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <div class="d-flex justify-content-center mt-2">
        <button mat-raised-button color="white" class="m-2" (click)="dialogRef.close(false)">
            {{'general.cancelar' | translate}}
        </button>

        <button mat-raised-button color="primary" class="m-2" (click)="dialogRef.close(true)">
            {{'general.aceptar' | translate}}
        </button>

    </div>
</div>