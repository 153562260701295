import { Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-disclaimer-confirm-validacion',
  templateUrl: './disclaimer-confirm-validacion.component.html',
  styleUrls: ['./disclaimer-confirm-validacion.component.scss']
})
export class DisclaimerConfirmValidacionComponent {

  constructor(protected dialogRef: MatDialogRef<DisclaimerConfirmValidacionComponent>) { }
  
}
