import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
})
export class InputCheckboxComponent implements OnInit, ControlValueAccessor {

  @Output() valueChanged = new EventEmitter();

  @Input() id;
  @Input() label: string = '';
  @Input() value: boolean = false;
  @Input() campoObligatorio: boolean = false;
  @Input() isDisabled: boolean;

  onChange = (_:any) => { }
  onTouch = () => { }

  public ngControl: NgControl;

  constructor(private inj: Injector) {}

  ngOnInit(): void {
    try {
      this.ngControl = this.inj.get(NgControl)
      this.ngControl.valueAccessor = this;
    } catch (e) {}
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  change($event) {
    this.onChange($event.checked);
    this.valueChanged.emit($event.checked);
  }
}
