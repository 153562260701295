import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {EtiquetasBaseComponent, ModalEdicion} from "../etiquetas-base.component";
import {ModalService} from "../../../../core/services/components/modal.service";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {MatDialogRef} from "@angular/material/dialog";
import {
  AccionesProductos, AccionesProductosDistribuidosReview,
  ProductoTablePreviewConfig
} from "../../etiquetas.config";
import {firstValueFrom} from "rxjs";
import {
  EditProductoDistribuidosModalComponent
} from "../../modals/edit-producto-distribuidos-modal/edit-producto-distribuidos-modal.component";
import {TramiteGuard} from "../../guards/tramite.guard";
import {TramiteDistribuidosGuard} from "../../guards/tramite-distribuidos.guard";
import {EditAdjuntosModalComponent} from "../../modals/edit-adjuntos-modal/edit-adjuntos-modal.component";
import {
  DisclaimerConfirmValidacionComponent
} from "../../modals/disclaimer-confirm-validacion/disclaimer-confirm-validacion.component";
import {
  DisclaimerEliminarGenericoComponent
} from "../../../operadores/modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";

@Component({
  selector: 'app-etiquetas-distribuidos-peticion-finalizacion',
  templateUrl: './etiquetas-distribuidos-revision.component.html',
  styleUrls: ['./etiquetas-distribuidos-revision.component.scss'],
  providers: [
    {provide: TramiteGuard, useClass: TramiteDistribuidosGuard}
  ]
})
export class EtiquetasDistribuidosRevisionComponent extends EtiquetasBaseComponent implements OnInit {
  tableConfig = ProductoTablePreviewConfig;
  tableAcciones = AccionesProductosDistribuidosReview

  constructor(injector: Injector,
              modalSvc: ModalService,
              tramiteGuard: TramiteGuard,
              cdr: ChangeDetectorRef) {
    super(injector, modalSvc, tramiteGuard, cdr);
  }

  override onHandleConfirmar() {
    this.etiquetasSvc.enviarConfirmacion()
        .subscribe(_ => {
          window.self.close();
        })
  }

  override onActionTable($event: any) {
    if($event.action == AccionesProductos.VIEW_ADJUNTOS) {
      this.modalSvc.openDialog(EditAdjuntosModalComponent, {producto: $event.item, readOnly: true})
    }
    else {
      super.onActionTable($event);
    }
  }

  override onEditarProducto(producto: SolicitudProducto): MatDialogRef<ModalEdicion> {
    let modal = this.modalSvc.openDialog(EditProductoDistribuidosModalComponent, {producto});
    modal.componentInstance.enableReadOnly();
    return modal;
  }

  override async onHandleEditarProducto(idSolicitudProducto: number, producto: SolicitudProducto) {
    if (producto) {
      await firstValueFrom(this.solicitudProductoSvc.patchProducto(idSolicitudProducto, producto));
      await this.etiquetasSvc.cargarProductos();
    }
  }

  override validateForm(productos: SolicitudProducto[]): boolean {
    return productos.every(item => item.aceptado === true || (item.aceptado === false && item.idTipoRechazo != null && item.motivoRechazo != null)) && productos.length > 0;
  }

  override onConfirmar(): void {
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: "ETIQUETAS.disclaimerEnviarACC"}, {maxWidth: '30vw'})
        .afterClosed()
        .subscribe(confirm => {
          if (confirm) this.onHandleConfirmar();
        });
  }

  onGuardar() {
    this.toastSvc.showSuccess("Sol·licitud guardada correctament");
  }
}
