import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {EtiquetasActionAdd} from "../../enum/EtiquetasActionAdd";

@Component({
  selector: 'app-etiqueta-add-modal',
  templateUrl: './etiqueta-crear-productos-modal.component.html',
  styleUrls: ['./etiqueta-crear-productos-modal.component.scss']
})
export class EtiquetaCrearProductosModalComponent implements OnInit {

  protected actions = EtiquetasActionAdd;

  constructor(protected dialogRef: MatDialogRef<EtiquetaCrearProductosModalComponent>) { }

  ngOnInit(): void {
  }

  protected readonly EtiquetasActionAdd = EtiquetasActionAdd;

}
