<div>
    <div *ngIf="file == null" class="py-2">
        <app-drop-file-zone
                (onFilesDrop)="onFileDrop($event)"
                [accept]="'.jpg,.jpeg,.png,.pdf'"
        >
        </app-drop-file-zone>
    </div>
    <div *ngIf="file != null" class="row">
        <div class="col-10">
            <app-input-text
                    [value]="this.file.name"
                    [isDisabled]="true"
            >
            </app-input-text>
        </div>
        <div class="col-2 d-flex align-self-center clickable">
            <mat-icon (click)="onDownload()">preview</mat-icon>
            <mat-icon (click)="onDelete()">delete_forever</mat-icon>
        </div>
    </div>
</div>
