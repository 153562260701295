import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {SolicitudProductoCompuesto} from "../../../core/model/etiquetas/solicitud-producto-compuesto";
import {Apantallamiento} from "../../../core/model/core/pages/apantallamiento";
import {EtiquetasService} from "../services/etiquetas.service";
import {SolicitudProductoHttpService} from "../../../core/services/http/solicitud/solicitud-producto.http.service";
import {ModalService} from "../../../core/services/components/modal.service";
import {FormControl, FormGroup} from "@angular/forms";
import {DuplicarProductoModalComponent} from "../modals/duplicar-producto-modal/duplicar-producto-modal.component";
import {EtiquetasActionAdd} from "../enum/EtiquetasActionAdd";
import {tap} from "rxjs/operators";
import {EtiquetaTramite} from "./etiquetas-tramite.interface";
import {SolicitudProducto} from "../../../core/model/etiquetas/solicitud-producto";
import {MatDialogRef} from "@angular/material/dialog";
import {AccionesProductos} from "../etiquetas.config";
import {forkJoin} from "rxjs";
import {JWTExternalMock} from "../../../testing/mock-objects/JWTExternalMock";
import {Autorizacion, AutorizacionPk} from "../../../core/model/autorizacion/autorizacion";
import {TramiteGuard} from "../guards/tramite.guard";
import {SolicitudHttpService} from "../../../core/services/http/solicitud/solicitud.http.service";
import {environment} from "../../../../environments/environment";
import {
  EtiquetasDisclaimerConfirmComponent
} from "../modals/etiquetas-disclaimer-confirm/etiquetas-disclaimer-confirm.component";
import {EditAdjuntosModalComponent} from "../modals/edit-adjuntos-modal/edit-adjuntos-modal.component";
import {ToastService} from "../../../core/services/components/toast.service";
import {
  ActualizarProductoModalComponent
} from "../modals/actualizar-producto-modal/actualizar-producto-modal.component";
import {EditRechazoModalComponent} from "../modals/edit-rechazo-modal/edit-rechazo-modal.component";
import {
  DisclaimerEliminarGenericoComponent
} from "../../operadores/modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";
import {EtiquetaAddModalComponent} from "../modals/etiqueta-add-modal/etiqueta-add-modal.component";
import {Solicitud, SolicitudEstado} from "../../../core/model/solicitud/solicitud";
import {
  EtiquetaCrearProductosModalComponent
} from "../modals/etiqueta-crear-productos-modal/etiqueta-crear-productos-modal.component";
import {
  EtiquetaCrearProductosPlantillaModalComponent
} from "../modals/etiqueta-crear-productos-plantilla-modal/etiqueta-crear-productos-plantilla-modal.component";

export interface ModalEdicion {
  enableReadOnly();
  enableReadOnlyEstado3Ingredientes?();
}

@Component({
  template: '<div></div>',
})
export abstract class EtiquetasBaseComponent extends Apantallamiento implements OnInit, EtiquetaTramite {

  // Services
  protected etiquetasSvc: EtiquetasService;
  protected solicitudProductoSvc: SolicitudProductoHttpService;
  protected solicitudSvc: SolicitudHttpService;
  protected toastSvc: ToastService;

  // Values
  protected productos: SolicitudProducto[] = [];
  protected productosVisibles: SolicitudProducto[] = [];
  protected tramiteEsmena: boolean;
  protected esVisualizacion: boolean;
  protected idSolicitud: string;
  protected estadoSolicitud: number;
  protected solicitud: Solicitud;

  // Configuration
  protected formGroup: FormGroup;
  protected formIsValid: boolean = false;
  protected formIsValidToEsmena: boolean = false;
  protected isButtonDisabled: boolean = false;


  constructor(protected injector: Injector,
              protected modalSvc: ModalService,
              protected tramiteGuard: TramiteGuard,
              protected cdr: ChangeDetectorRef) {

    super();
    this.etiquetasSvc = injector.get(EtiquetasService);
    this.solicitudProductoSvc = injector.get(SolicitudProductoHttpService);
    this.solicitudSvc = injector.get(SolicitudHttpService);
    this.toastSvc = injector.get(ToastService);

    this.formGroup = new FormGroup({
      'busqueda': new FormControl(''),
      "warnings": new FormControl(false)
    });

    if (environment.debugJwt) {
      this.etiquetasSvc.initTramite(JWTExternalMock);
    }
  }

  async ngOnInit(){

    if (!this.etiquetasSvc.initialized || !this.tramiteGuard.canActivate()) {
      this.etiquetasSvc.handleErrorLoadingData();
    }

    this.tramiteEsmena = this.etiquetasSvc.solicitud?.idSolicitudPadre != null;

    this.esVisualizacion = this.etiquetasSvc.esVisualizacion;

    this.etiquetasSvc.productos.subscribe(list => {
      this.productos = this.productosVisibles = list as SolicitudProductoCompuesto[];
      this.formIsValid = this.validateForm(this.productos);
      this.formIsValidToEsmena = this.someProductsRejected(this.productos);
    });

    this.formGroup.valueChanges.subscribe(value => {
      this.productosVisibles = this.productos.filter(item => item.nombreComercial.toLowerCase()
          .includes(value.busqueda.toLowerCase()));

      if (value.warnings) {
        this.productosVisibles = this.productos.filter(item => !item.nombreCorrecto);
      }
    });

    this.idSolicitud = this.etiquetasSvc.idSolicitud;
    this.solicitudSvc.getSolicitud(this.idSolicitud)
        .subscribe({
          next: value => {
            this.estadoSolicitud = value.estado;
          }
        });

    setTimeout(() => {
      if (this.estadoSolicitud == 1 && this.productos.length == 0 && !this.esVisualizacion) {
        this.onAddProducto();
      }
    }, 1000);

    this.solicitud = this.etiquetasSvc.solicitud;

    this.isButtonDisabled = this.solicitud.estado != SolicitudEstado.PEND_ESMENA;

  }

  onActionTable($event: any): void {
    switch ($event.action) {
      case AccionesProductos.EDIT_PRODUCTO:
        this.onEditarProducto($event.item).afterClosed()
            .subscribe(item => this.onHandleEditarProducto($event.item.idSolicitudProducto, item));
        break;
      case AccionesProductos.REMOVE:
        this.onRemove($event.item);
        break;
      case AccionesProductos.ACEPTAR:
        this.onChangeStateProducto([$event.item], true);
        break;
      case AccionesProductos.RECHAZAR:
        this.onChangeStateProducto([$event.item], false);
        break;
      case AccionesProductos.EDIT_RECHAZO:
        this.onEditarRechazo($event.item).afterClosed()
            .subscribe(item => this.onHandleEditarProducto($event.item.idSolicitudProducto, item));
        break;
      case AccionesProductos.EDIT_ADJUNTOS:
        this.onEditarAdjuntos($event.item);
        break;
    }
  }

  onAddProducto(): void {
    if (this.etiquetasSvc.esModificacion) {
      this.onDuplicarProducto();
    } else {
      this.modalSvc.openDialog(EtiquetaAddModalComponent, {tipoSolicitud: this.solicitud.codigoTramite}, {width: "750px"})
          .afterClosed().subscribe(action => this.onHandleAddProducto(action));
    }
  }

  onHandleAddProducto(action: EtiquetasActionAdd) {
    switch (action) {
      case EtiquetasActionAdd.NUEVO:
        this.onEditarProducto({"idSolicitud": this.etiquetasSvc.idSolicitud} as SolicitudProductoCompuesto)
            .afterClosed()
            .subscribe(item => this.onHandleEditarProducto(null, item));
        break;
      case EtiquetasActionAdd.CLONAR:
        this.onDuplicarProducto();
        break;
      case EtiquetasActionAdd.ACTUALIZAR:
        this.onActualizarProducto();
        break;
      case EtiquetasActionAdd.NUEVOS:
        this.modalSvc.openDialog(EtiquetaCrearProductosModalComponent, {}, {width: "750px"})
            .afterClosed().subscribe(action => this.onHandleAddProducto(action));
        break;
      case EtiquetasActionAdd.MASIVA:
        this.modalSvc.openDialog(EtiquetaCrearProductosPlantillaModalComponent, {tipoSolicitud: this.solicitud.codigoTramite}, {width: "750px"})
            .afterClosed().subscribe(action => this.onHandleAddProducto(action));
        break;
    }
  }

  onDuplicarProducto(): void {
    this.modalSvc.openDialog(DuplicarProductoModalComponent, {}, {maxWidth: "98vw", width: "1500px"})
        .afterClosed()
        .subscribe((productosSeleccionados: Autorizacion[]) => this.onHandleDuplicarProdutos(productosSeleccionados));
  }

  onActualizarProducto(): void {
    this.modalSvc.openDialog(ActualizarProductoModalComponent, {}, {maxWidth: "98vw", width: "1500px"})
        .afterClosed()
        .subscribe((productosSeleccionados: Autorizacion[]) => this.onHandleActualizarProdutos(productosSeleccionados));
  }

  onHandleDuplicarProdutos(productosSeleccionados: Autorizacion[]) {
    if (productosSeleccionados && productosSeleccionados.length > 0) {

      let keys: AutorizacionPk[] = productosSeleccionados.map(item => ({
        idInscrito: item.idInscrito,
        numOrdenAutorizacion: item.numOrdenAutorizacion
      }));

      this.solicitudSvc.duplicarProductos(this.etiquetasSvc.idSolicitud, keys)
          .subscribe(_ => this.etiquetasSvc.cargarProductos());
    }
  }

  onHandleActualizarProdutos(productosSeleccionados: Autorizacion[]) {
    if (productosSeleccionados && productosSeleccionados.length > 0) {

      let keys: AutorizacionPk[] = productosSeleccionados.map(item => ({
        idInscrito: item.idInscrito,
        numOrdenAutorizacion: item.numOrdenAutorizacion
      }));

      this.solicitudSvc.actualizarProductos(this.etiquetasSvc.idSolicitud, keys)
          .subscribe(_ => this.etiquetasSvc.cargarProductos());
    }
  }
  onRemove(item: SolicitudProductoCompuesto): void {
    this.solicitudProductoSvc.eliminar(item)
        .pipe(tap(_ => this.etiquetasSvc.cargarProductos()))
        .subscribe(_ => {
        });
  }

  onChangeStateProducto(item: SolicitudProducto[], state: boolean): void {
    let requests = item.map(item => this.solicitudProductoSvc.patchProducto(item.idSolicitudProducto, {aceptado: state}));

    forkJoin(requests).subscribe(_ => {
      item.forEach(item => item.aceptado = state)
      this.formIsValid = this.validateForm(this.productos);
      this.formIsValidToEsmena = this.someProductsRejected(this.productos);
    });
  }


  onEditarRechazo(producto: SolicitudProducto): MatDialogRef<ModalEdicion> {
    return this.modalSvc.openDialog(EditRechazoModalComponent, {producto});
  }

  onConfirmar(): void {
    this.modalSvc.openDialog(EtiquetasDisclaimerConfirmComponent, {}, {maxWidth: '70vw'})
        .afterClosed()
        .subscribe(confirm => {
          if (confirm) this.onHandleConfirmar();
        });
  }

  onDesistir(): void {
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'ETIQUETAS.disclaimerDesistir'}, {maxWidth: '30vw'})
        .afterClosed()
        .subscribe(confirm => {
          if (confirm) this.onHandleDesistir();
        });
  }

  onEsmena(): void {
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'ETIQUETAS.disclaimerEsmena'}, {maxWidth: '30vw'})
        .afterClosed()
        .subscribe(confirm => {
          if (confirm) this.onHandleEsmena();
        });
  }

  onRevision(): void {
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'ETIQUETAS.disclaimerRevision'}, {maxWidth: '30vw'})
        .afterClosed()
        .subscribe(confirm => {
          if (confirm) this.onHandleRevision();
        });
  }

  onHandleEsmena(): void {
    this.etiquetasSvc.enviarEsmena()
        .subscribe(_ => {
          window.self.close();
        })
  }

  onHandleRevision(): void {
    this.etiquetasSvc.enviarRevision()
        .subscribe(_ => {
          window.self.close();
        })
  }

  onHandleDesistir(): void {
    this.etiquetasSvc.enviarDesistimiento()
        .subscribe(_ => {
          this.isButtonDisabled = true;
          this.cdr.detectChanges();
          window.self.close();
        })
  }

  private onEditarAdjuntos(producto: SolicitudProducto) {
    this.modalSvc.openDialog(EditAdjuntosModalComponent, {producto: producto})
        .afterClosed()
        .subscribe(_ => {
          this.solicitudProductoSvc.modificarFlagAdjuntos(producto.idSolicitudProducto)
              .subscribe(_ => this.etiquetasSvc.cargarProductos());
        })
  }
  someProductsRejected(productos: SolicitudProducto[]): boolean {
    return productos.some(item => item.aceptado === false)
  }

  abstract onHandleConfirmar(): void;

  abstract onEditarProducto(producto: SolicitudProducto): MatDialogRef<ModalEdicion>;

  abstract onHandleEditarProducto(idSolicitudProducto: number | null, producto: SolicitudProducto): void;

  abstract validateForm(productos: SolicitudProducto[]): boolean;

  get hasUpdatedProduct(): boolean {
    return this.productos.some(producto => producto.esActualizacion);
  }

}
