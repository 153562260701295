import {Pipe, PipeTransform} from '@angular/core';
import {CondicionColor} from "../components/generic-table/model/condicion-color";
import {EvaluarCondicionPipe} from "./evaluar-condicion.pipe";

@Pipe({
  name: 'condicionesToColor',
  pure: false
})
export class CondicionesToColorPipe implements PipeTransform {

  private PREFIX = "condicion";
  private SEPARATOR = "-"

  private evaluarCondicionPipe = new EvaluarCondicionPipe();

  transform(condiciones: CondicionColor[] | CondicionColor | undefined, item:any): string {

    if (!condiciones) {
      return '';
    }

    if (condiciones) {
      if(!(condiciones instanceof Array)) {
        if(this.evaluarCondicionPipe.transform(condiciones.condicion, item)) {
          return this.PREFIX + this.SEPARATOR + condiciones.color;
        }
      }

      if (condiciones instanceof Array) {
        for (let condicion of condiciones) {
          if(this.evaluarCondicionPipe.transform(condicion.condicion, item)) {
            return this.PREFIX + this.SEPARATOR + condicion.color;
          }
        }
      }
    }

    return "";
  }

}
