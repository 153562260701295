import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {LocalizacionHttpService} from "../../../../core/services/http/localizacion.http.service";
import {BehaviorSubject, Observable} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Municipio} from "../../../../core/model/localizacion/municipio";
import {CodigoPostal} from "../../../../core/model/localizacion/codigoPostal";
import {Comarca} from "../../../../core/model/localizacion/comarca";
import {Provincia} from "../../../../core/model/localizacion/provincia";

@Component({
  selector: 'app-operadores-localizacion',
  templateUrl: './operadores-localizacion.component.html',
  styleUrls: ['./operadores-localizacion.component.scss']
})
export class OperadoresLocalizacionComponent implements OnInit, OnChanges {
  protected localizacionSvc: LocalizacionHttpService;

  protected provincias: Observable<Provincia[]>;
  protected comarcas: Observable<Comarca[]>;
  protected municipios: Observable<Municipio[]>;
  protected codigosPostales: Observable<CodigoPostal[]>;

  public formLocalizacion: FormGroup;
  private _municipioInput = new BehaviorSubject<Municipio>(new Municipio())

  @Input() readonly: boolean;
  @Input() enableProvincia = true;
  @Input() enableInspector = false;
  @Input() campoObligatorio = false;

  @Input() set municipioInput(value: Municipio) {
    this._municipioInput.next(value);
  }
  @Output() formValid = new EventEmitter<boolean>();
  @Output() municipioModified = new EventEmitter<Municipio>();
  @Output() blur = new EventEmitter<void>();
  private formCompleted = false;

  get municipioInput(){
    return this._municipioInput.getValue();
  }

  constructor(injector: Injector,
              private cdr: ChangeDetectorRef) {
    this.localizacionSvc = injector.get(LocalizacionHttpService);

    this.formLocalizacion = new FormGroup({
      "idProvincia": new FormControl(''),
      "idComarca": new FormControl(''),
      "municipio": new FormControl(''),
      "codigoPostal": new FormControl(''),
      "inspector": new FormControl('')
    })
  }


  async ngOnInit() {
    if(this.campoObligatorio) this.setValidators();

    this.provincias = this.localizacionSvc.getProvincias();
    this.comarcas = this.localizacionSvc.getComarcas();

    if(this.readonly) this.formLocalizacion.disable();

    this._municipioInput.subscribe(m => {
      this.handleMunicipioInputChanges(m);
    })

    if(this.municipioInput == null && !this.campoObligatorio) {
      this.formValid.emit(true);
      this.formCompleted = true;
    }

    this.handleComarcas();
    this.handleMunicipios();
    this.handleCodigosPostales();

    this.handleInfoCompleted();
  }

  private handleMunicipioInputChanges(m: Municipio) {
    if(m?.provincia != null){
      if(this.enableProvincia ){
        this.comarcas = this.localizacionSvc.getComarcasByProvincia(m?.provincia?.idProvincia);
      }
      this.municipios = this.localizacionSvc.getMunicipiosByComarcas(m?.comarca?.idComarca);
      this.codigosPostales = this.localizacionSvc.getCodigosPostalesByMunicipios(m?.descripcion);
      if(m.codigoPostal != null) this.formCompleted = true;
    }
    else if(!this.formCompleted){
      this.formLocalizacion.get('idComarca')?.disable();
      this.formLocalizacion.get('municipio')?.disable();
      this.formLocalizacion.get('codigoPostal')?.disable();
    }

    if(this._municipioInput != null && this.municipioInput?.descripcion != ""){
      this.formLocalizacion.get("idProvincia")?.setValue(this.municipioInput?.provincia.idProvincia);
      this.formLocalizacion.get("idComarca")?.setValue(this.municipioInput?.comarca.idComarca);
      this.formLocalizacion.get("municipio")?.setValue(this.municipioInput?.descripcion);
      this.formLocalizacion.get("codigoPostal")?.setValue(this.municipioInput?.codigoPostal);
      this.formLocalizacion.get("inspector")?.setValue(this.municipioInput?.inspector);
    }


    this.formLocalizacion.get("inspector")?.disable();
    this.formValid.emit(this.formLocalizacion.valid);

  }

  private handleComarcas() {
    if(this.enableProvincia) {
      this.formLocalizacion.get("idProvincia")?.valueChanges.subscribe((provincia) => {
        this.formLocalizacion.get("idComarca")?.reset();
        this.formLocalizacion.get("idComarca")?.disable();

        this.formLocalizacion.get("inspector")?.enable();
        this.formLocalizacion.get("inspector")?.reset();
        this.formLocalizacion.get("inspector")?.disable();
        this.formCompleted = false;

        if (provincia) {
          this.comarcas = this.localizacionSvc.getComarcasByProvincia(provincia);
          if(!this.readonly) this.formLocalizacion.get("idComarca")?.enable();
        }
      });
      this.formValid.emit(this.formCompleted);
    }
  }

  private handleMunicipios() {
    this.formLocalizacion.get("idComarca")?.valueChanges.subscribe((comarca) => {
      this.formLocalizacion.get("municipio")?.reset();
      this.formLocalizacion.get("municipio")?.disable();
      this.formCompleted = false;
      if (comarca) {
        this.municipios = this.localizacionSvc.getMunicipiosByComarcas(comarca);
        if(!this.readonly) this.formLocalizacion.get("municipio")?.enable();
      }
      this.formValid.emit(this.formCompleted);
    });
  }

  private handleCodigosPostales() {
    this.formLocalizacion.get("municipio")?.valueChanges.subscribe((municipio) => {
      this.formLocalizacion.get("codigoPostal")?.reset();
      this.formLocalizacion.get("codigoPostal")?.disable();
      this.formCompleted = false;
      if (municipio) {
        this.codigosPostales = this.localizacionSvc.getCodigosPostalesByMunicipios(municipio);
        if(!this.readonly) this.formLocalizacion.get("codigoPostal")?.enable();
      }
      this.formValid.emit(this.formCompleted);
    });
  }

  private handleInfoCompleted() {

    this.formLocalizacion.get("codigoPostal")?.valueChanges
        .subscribe(codigoPostal => {
          if(codigoPostal != null) {
            this.formCompleted = true;

            if(this.municipioInput == null){
              this.municipioInput = new Municipio();
            }

            this.provincias.subscribe(provincias => {
              const provincia = provincias.find(p => p.idProvincia == this.formLocalizacion.get("idProvincia")?.value);
              if(provincia) this.municipioInput.provincia = provincia;
            })

            this.comarcas.subscribe(comarcas => {
              const comarca = comarcas.find(c => c.idComarca == this.formLocalizacion.get("idComarca")?.value);
              if(comarca) this.municipioInput.comarca = comarca;
            })

            this.codigosPostales.subscribe(codigos => {
              const codigo = codigos.find(c => c.codigoPostal == this.formLocalizacion.get("codigoPostal")?.value);
              if(codigo) {
                this.municipioInput.codigoLocal = codigo.codigoLocal;
                this.municipioInput.codigoPostal = codigo.codigoPostal;
              }
            })

            this.municipioInput.descripcion = this.formLocalizacion.get("municipio")?.value;

            if(this.enableInspector) {
              this.localizacionSvc.getInspectorByMunicipio(this.municipioInput)
                  .subscribe(inspector => {
                    this.formLocalizacion.get('inspector')?.setValue(inspector.nombre);
                  })
            }
            this.formValid.emit(this.formCompleted);
            this.municipioModified.emit(this.municipioInput);
            this.cdr.detectChanges();
          }
        })
  }

  protected showCodigos(codigos: CodigoPostal): string {
    return codigos.codigoPostal + ' (' + codigos.codigoLocal + ')';
  }

  onReset() {
    this.formLocalizacion.reset();
    this.municipioInput = new Municipio();
    if(!this.campoObligatorio) this.formValid.emit(true);
  }

  private setValidators() {
    this.formLocalizacion.get('idProvincia')?.setValidators(Validators.required);
    this.formLocalizacion.get('idComarca')?.setValidators(Validators.required);
    this.formLocalizacion.get('municipio')?.setValidators(Validators.required);
    this.formLocalizacion.get('codigoPostal')?.setValidators(Validators.required);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.readonly){
      this.formLocalizacion.disable();
      this.handleMunicipioInputChanges(this.municipioInput)
    }
  }

  handleBlur() {
    this.blur.emit();
  }

}
