export abstract class BasePage {

    protected _isLogin: boolean = false;
    protected _isApantallamiento: boolean = false;

    constructor() {
        this._isApantallamiento = true;
        this._isLogin = true;
    }

    public get isLogin() {
        return this._isLogin;
    }

    protected set isLogin(value: boolean) {
        this._isLogin = value;
    }

    public get isApantallamiento() {
        return this._isApantallamiento;
    }

    protected set isApantallamiento(value: boolean) {
        this._isApantallamiento = value;
    }

}