<app-loader></app-loader>



<mat-drawer-container class="view-manager-container" [ngClass]="{isLogin: pageInfo.isLogin}">
    <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="pageInfo.hasNavBar">
        <app-navbar>
        </app-navbar>
    </mat-drawer>

    <div class="col-12 router-wrapper p-0" [ngClass]="{isLogin: pageInfo.isLogin}">

        <app-header [drawer]="drawer">
        </app-header>

        <div class="router-content" [ngClass]="{isLogin: pageInfo.isLogin}">
            <router-outlet (activate)="activate($event)">

            </router-outlet>
        </div>
    </div>

</mat-drawer-container>
