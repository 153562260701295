import {ChangeDetectorRef, Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {EtiquetasBaseComponent, ModalEdicion} from "../etiquetas-base.component";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {ModalService} from "../../../../core/services/components/modal.service";
import {AccionesProductosTable, ProductosTableConfig} from "../../etiquetas.config";
import {MatDialogRef} from "@angular/material/dialog";
import {TramiteGuard} from "../../guards/tramite.guard";
import {CodigoTramite} from "../../services/etiquetas.service";
import {TramiteDistribuidosGuard} from "../../guards/tramite-distribuidos.guard";
import {
  EditProductoDistribuidosModalComponent
} from "../../modals/edit-producto-distribuidos-modal/edit-producto-distribuidos-modal.component";
import {firstValueFrom} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {EtiquetasActionAdd} from "../../enum/EtiquetasActionAdd";

@Component({
  selector: 'app-etiquetas-distribuidos-peticion',
  templateUrl: './etiquetas-distribuidos-peticion.component.html',
  styleUrls: ['./etiquetas-distribuidos-peticion.component.scss'],
  providers: [
    {provide: TramiteGuard, useClass: TramiteDistribuidosGuard}
  ]
})
export class EtiquetasDistribuidosPeticionComponent extends EtiquetasBaseComponent implements OnInit {

  @ViewChild('fileUpload') fileUpload: ElementRef;
  protected tableConfig = ProductosTableConfig;
  protected tableAcciones = AccionesProductosTable;

  constructor(injector: Injector,
              modalSvc: ModalService,
              tramiteGuard: TramiteGuard,
              private translateSvc: TranslateService,
              cdr: ChangeDetectorRef) {
    super(injector, modalSvc, tramiteGuard, cdr);
  }

  override onEditarProducto(producto: SolicitudProducto): MatDialogRef<ModalEdicion>{
    return this.modalSvc.openDialog(EditProductoDistribuidosModalComponent, {producto});
  }

  override async onHandleEditarProducto(idSolicitudProducto: number | null, producto: SolicitudProducto) {
    if (!producto) return;
    if (idSolicitudProducto) {
      await firstValueFrom(this.solicitudProductoSvc.modificarProducto(idSolicitudProducto, producto));
    } else {
      await firstValueFrom(this.solicitudProductoSvc.crearProducto(producto));
    }

    await this.etiquetasSvc.cargarProductos();
  }

  onDownloadPlantilla() {
    this.solicitudProductoSvc.descargarPlantillaDistribuits();
  }

  onLoadExcel(target: any) {
    const file = target.files[0];
    this.solicitudProductoSvc.cargarProductosExcel(this.etiquetasSvc.idSolicitud, file, CodigoTramite.ETIQUETATGE_DISTRIBUITS)
        .subscribe(_ => this.etiquetasSvc.cargarProductos());
  }

  override onHandleAddProducto(action: EtiquetasActionAdd): void {
    switch (action) {
      case EtiquetasActionAdd.DESCARGAR_PLANTILLA:
        this.onDownloadPlantilla();
        break;
      case EtiquetasActionAdd.CARREGAR_PRODUCTES:
        if (this.fileUpload && this.fileUpload.nativeElement) {
          this.fileUpload.nativeElement.click();
        } else {
          console.error('fileUpload is not defined');
        }
        break;
      default:
        super.onHandleAddProducto(action);
        break;
    }
  }

  override onHandleConfirmar() {
    this.etiquetasSvc.enviarSolicitud()
        .subscribe(_ => {
          window.self.close();
        })
  }

  override validateForm(productos: SolicitudProducto[]): boolean {
    return  productos.every(item => item.informacionCompleta && (!this.tramiteEsmena || (this.tramiteEsmena && item.infoPadreMod && item.adjuntosPadreMod)))
        && productos.length > 0;
  }

  onGuardar() {
    this.toastSvc.showSuccess(this.translateSvc.instant("ETIQUETAS.guardarSolicitudOk"));
  }


}
