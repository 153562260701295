import {BaseObj} from "../core/baseObj";

export class ResultadoImportDarp extends BaseObj {

    ident: string;
    dataProces: Date;
    numProces: string = "";
    registresProcessats: number;
    registresCarregats: number;
    registresRebutjats: number;
    nomFitxer: string;
    llistaLog: LogDun[];

    constructor(json?: object) {
        super(json);
    }
}

export interface LogDun {
    anyProces: number;
    ident: string;
    sequenciaProces: number;
    numFila: number;
    nomColumna: string;
    nom: string;
    provincia: string;
    municipi: string;
    producto: string;
    poligon: string;
    parcela: string;
    recinto: string;
    descripcio: string;
}

