import {Component, OnInit} from '@angular/core';
import {Animal} from "../../../../core/model/animal/animal";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Granja} from "../../../../core/model/granja/granja";
import {EMPTY, Observable, tap} from "rxjs";
import {AnimalesCensoActionTable, AnimalesCensoConfigTable} from "./operadores-detalle-animal-censo.table-config";
import {TranslateService} from "@ngx-translate/core";
import {GranjaService} from "../../../../core/services/http/granja.service";
import {Producto} from "../../../../core/model/autorizacion/producto";
import {AnimalHttpService} from "../../../../core/services/http/animal-http.service";
import {AnimalCenso} from "../../../../core/model/animal/animalCenso";
import {ModalService} from "../../../../core/services/components/modal.service";
import {
  EditOperadorAnimalCensoModalComponent
} from "../../modals/edit-operador-animal-censo-modal/edit-operador-animal-censo-modal.component";
import {AuthService} from "../../../../core/services/security/auth.service";
import {Raza} from "../../../../core/model/animal/raza";
import {TipoGanado} from "../../../../core/model/animal/tipoGanado";
import {
  DisclaimerEliminarGenericoComponent
} from "../../modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";

@Component({
  selector: 'app-operadores-detalle-animal',
  templateUrl: './operadores-detalle-animal.component.html',
  styleUrls: ['./operadores-detalle-animal.component.scss']
})
export class OperadoresDetalleAnimalComponent implements OnInit {

  protected idOperador: string;
  protected numGranja: string;
  protected idEspecie: number;
  protected idRaza: string;
  protected idTipoGanado: string;

  public animal: Animal;
  public granja: Granja;

  protected usuario;

  public animalFormGroup: FormGroup;

  protected title;
  protected titleParams;
  protected action;

  public animalesCensoTableConfig = AnimalesCensoConfigTable;
  public animalesCensoEditActionConfig = AnimalesCensoActionTable;

  protected animales: Observable<Animal[]> = EMPTY;
  protected granjas: Observable<Granja[]> = EMPTY;
  protected especies: Observable<Producto[]> = EMPTY;
  protected razas: Observable<Raza[]> = EMPTY;
  protected tiposGanado: Observable<TipoGanado[]> = EMPTY;

  constructor(private granjaSvc: GranjaService,
              private animalSvc: AnimalHttpService,
              private modalSvc: ModalService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public translate: TranslateService,
              private authSvc: AuthService,
              protected translateSvc: TranslateService
) {

    this.idOperador = activatedRoute.snapshot.params['idOperador'];
    this.numGranja = activatedRoute.snapshot.params['numGranja'];
    this.idEspecie = activatedRoute.snapshot.params['idEspecie'];
    this.idRaza = activatedRoute.snapshot.params['idRaza'];
    this.idTipoGanado = activatedRoute.snapshot.params['idTipoGanado'];

    this.animal = new Animal();

    this.animalFormGroup = new FormGroup({
      "numGranja": new FormControl(null, [Validators.required, Validators.maxLength(5)]),
      "idEspecie": new FormControl(null, [Validators.required, Validators.pattern("[0-9]{6}")]),
      "codigoRaza": new FormControl(null, [Validators.required, Validators.pattern(".{5}")]),
      "codigoTipoGanado": new FormControl(null, [Validators.required, Validators.pattern(".{5}")]),
      "nombreGranja": new FormControl(null),
      "especie": new FormControl(null),
      "raza": new FormControl(null),
      "tipoGanado": new FormControl(null),
      "indicadorUbm": new FormControl('', Validators.required),
      "totalUbm": new FormControl(''),
      "fechaCualificacion": new FormControl(''),
      "observaciones": new FormControl(''),
      "usuario": new FormControl('', Validators.required),
      "contadorAnimales": new FormControl('')
    });

  }

  async ngOnInit(): Promise<void> {

    this.authSvc.fullName.subscribe(value => {
      this.usuario = value;
      this.animalFormGroup.patchValue({'usuario': value})
    });

    this.activatedRoute.params.subscribe(params => {
      this.action = params['animalAction'];

      if (this.action == 'edit') {
        this.animalFormGroup.enable();
        this.animalFormGroup.controls['idEspecie'].disable();
        this.animalFormGroup.controls['codigoRaza'].disable();
        this.animalFormGroup.controls['codigoTipoGanado'].disable();
      } else if(this.action == 'create'){
        this.animalFormGroup.enable();
        this.title ='OPERADORES.animales.titleAlta';
      } else {
        this.animalFormGroup.disable();
      }
    });

    if (this.action != "create"){
      this.animalSvc.getAnimal(this.idOperador, this.numGranja, this.idEspecie, this.idRaza, this.idTipoGanado)
          .subscribe(animal => this.onHandleAnimal(animal));
    }

    this.animales = this.animalSvc.getAnimalesList(this.idOperador)
    this.granjas = this.granjaSvc.getGranjasList(this.idOperador);
    this.especies = this.granjaSvc.getEspeciesGanado();
    this.tiposGanado = this.granjaSvc.getTiposGanado();

    this.handleRazas();
    this.handleGranja();
    this.handleSelectItems();

  }

  // CRUD Animals

  public async onHandleAnimal(animal: Animal) {

    this.animal = animal;

    this.title = 'OPERADORES.animales.titleDetall';
    this.titleParams = {
      'granja': '',
      'especie': this.animal.especie,
      'raza': this.animal.raza,
      'tipoGanado': this.animal.tipoGanado
    }
    this.animalFormGroup.patchValue({...this.animal, 'usuario': this.usuario});

    if (this.animal.numGranja != null && this.animal.numGranja != "" && this.animal.numGranja != ", "){
      this.granjaSvc.getGranja(this.idOperador, this.animal.numGranja).subscribe(granja =>  {
        this.granja = granja;
        this.animalFormGroup.patchValue({...this.granja, ...this.animal, 'usuario': this.usuario});
        this.titleParams = {
          'granja': granja.nombreGranja,
          'especie': this.animal.especie,
          'raza': this.animal.raza,
          'tipoGanado': this.animal.tipoGanado
        }
      })
    } else {
      let granja = new Granja();
      granja.numGranja = this.animal.numGranja
      this.granjas = this.granjas.pipe(
          tap(granjas => granjas.push(granja)))
    }

  }

  public handleRazas() {
    this.animalFormGroup.controls['idEspecie'].valueChanges.subscribe(idEspecie => {
      this.animalFormGroup.get('codigoRaza')?.reset();
      this.animalFormGroup.get("codigoRaza")?.disable();

      if (idEspecie) {
        this.razas = this.granjaSvc.getRazas(idEspecie);

        if (this.action == 'create') this.animalFormGroup.get("codigoRaza")?.enable();
      } else this.razas = EMPTY;
    })
  }

  public handleGranja() {
    this.animalFormGroup.controls['numGranja'].valueChanges.subscribe(
        numGranja => {

          if (numGranja!= null && numGranja != "" && numGranja != ", "){
            this.granjaSvc.getGranja(this.idOperador, numGranja)
                .subscribe(granja =>  {
                  this.granja = granja;
                })
            this.animalFormGroup.controls['idEspecie'].reset();
            this.animalFormGroup.controls['codigoRaza'].reset();
            this.animalFormGroup.controls['codigoTipoGanado'].reset();

          }
        }
    )
  }

  public handleSelectItems() {

    this.animalFormGroup.controls['idEspecie'].valueChanges.subscribe(idEspecie => {
      let numGranja = this.animalFormGroup.controls['numGranja'].value;
      let codigoTipoGanado = this.animalFormGroup.controls['codigoTipoGanado'].value;

      if (idEspecie && numGranja && codigoTipoGanado) {
        this.animales.subscribe(animales => {animales.forEach(a => {
          if (a.numGranja == numGranja && a.idEspecie == idEspecie && a.codigoTipoGanado == codigoTipoGanado) {
            this.razas = this.razas.pipe(tap(razas => razas.map(raza => {
              if (raza.codigoRaza == a.codigoRaza) raza.disabled = true;
            }) ))
          }
        })})
      }
    })

    this.animalFormGroup.controls['codigoRaza'].valueChanges.subscribe(codigoRaza => {
      let idEspecie = this.animalFormGroup.controls['idEspecie'].value;
      let numGranja = this.animalFormGroup.controls['numGranja'].value;

      if (idEspecie && numGranja && codigoRaza) {
        this.animales.subscribe(animales => {animales.forEach(a => {
          if (a.numGranja == numGranja && a.idEspecie == idEspecie && a.codigoRaza == codigoRaza) {
            this.tiposGanado = this.tiposGanado.pipe(tap(tiposGanado => tiposGanado.map(tipoGanado => {
              if (tipoGanado.codigoTipoGanado == a.codigoTipoGanado) tipoGanado.disabled = true;
            }) ))
          }
        })})
      }
    })
  }


  async onGuardarForm() {

    this.animal = this.animalFormGroup.value;

    if (this.action == 'edit'){

      this.animalSvc.updateAnimal(this.idOperador, this.numGranja, this.idEspecie, this.idRaza, this.idTipoGanado, this.animal)
          .subscribe(value => this.reloadCurrentRoute());

    } else if (this.action == 'create'){

      this.animalSvc.createAnimal(this.idOperador, this.animal).subscribe(animal=>{
        this.router.navigate(["../",
          this.animal.numGranja, this.animal.idEspecie, this.animal.codigoRaza, this.animal.codigoTipoGanado, 'edit'],
            {relativeTo: this.activatedRoute})
      });
    }
  }

  // CRUD Cens

  protected onActionTable($event: any) {

    switch($event.action){
      case "edit":
        this.editAnimalCenso($event.item)
        break;
      case "delete":
        this.deleteAnimalCenso($event.item);
        break;

    }
  }

  public createAnimalCenso() {

    this.modalSvc.openDialog(EditOperadorAnimalCensoModalComponent,
        {idOperador: this.idOperador, animal: this.animal}).afterClosed().subscribe(
            animalCenso => {
              if (animalCenso) this.onHandleCreateAnimalCenso(animalCenso);
            }
    )
  }

  private editAnimalCenso(animalCenso: AnimalCenso)  {

    return this.modalSvc.openDialog(EditOperadorAnimalCensoModalComponent,
        {idOperador: this.idOperador, animal: this.animal, animalCenso: animalCenso}).afterClosed().subscribe(
        item => {
          if (item) this.onHandleEditAnimalCenso(item)
        });
  }


  private deleteAnimalCenso(item){
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'OPERADORES.animales.animalCensoDisclaimerEliminar'}, {maxWidth: '30vw'})
        .afterClosed().subscribe(accepted => {
      if(accepted) this.onHandleDeleteAnimalCenso(item)

    });
  }

  public async onHandleCreateAnimalCenso(animalCenso: AnimalCenso) {
    this.animalSvc.createAnimalCenso(this.idOperador, this.animal.numGranja, this.animal.idEspecie,
        this.animal.codigoRaza, this.animal.codigoTipoGanado, animalCenso)
        .subscribe(
            value => this.reloadCurrentRoute())
  }

  public async onHandleEditAnimalCenso(animalCenso: AnimalCenso) {

    this.animalSvc.updateAnimalCenso(this.idOperador, this.animal.numGranja, this.animal.idEspecie,
        this.animal.codigoRaza, this.animal.codigoTipoGanado, animalCenso.numOrden, animalCenso)
        .subscribe(
            value => this.reloadCurrentRoute())
  }

  public async onHandleDeleteAnimalCenso(animalCenso: AnimalCenso) {

    this.animalSvc.deleteAnimalCenso(this.idOperador, animalCenso)
        .subscribe(
            value => this.reloadCurrentRoute());
  }

  //Routings
  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  onBack(){
    if (this.action == 'create'){
      this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
    } else {
      this.router.navigate(["../../../../../../"], {relativeTo: this.activatedRoute})
    }
  }

}
