import {BaseObj} from "../core/baseObj";
import {Ingrediente} from "./ingrediente";

export class NoAutorizacionPk {
    idInscrito!: number;
    idSolicitudProducto!: number;
}

export class NoAutorizacion extends BaseObj {

    idOperador: string = "";
    idInscrito!: number;
    idSolicitudProducto!: number;
    idProducto: string = "";
    nombreProducto: string = "";
    nombreComercial: string = "";
    marca: string = "";
    capacidad: string = "";
    observaciones: string = "";
    auxiliaresTecnologicos: string = "";
    tieneIngredientes: boolean = false;
    contadorIngredientes: number;
    impreso: boolean = false;
    usuario: string = "";
    fecha: Date = new Date();
    tipoAutorizacion: string = "";
    usaNitratos: boolean = false;
    tecnicaTransformacion: string = "";
    textoElaboracion: string = "";
    textoEnvase: string = "";
    textoEtiquetaje: string = "";
    normativa: string = "";
    motivoRechazo: string = "";

    ingredientes: Ingrediente[] = [];

    constructor(json?: object) {
        super(json);
    }

    equals(obj: NoAutorizacion) {
        return this.idInscrito == obj.idInscrito && this.idSolicitudProducto == obj.idSolicitudProducto;
    }

}
