import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {EtiquetasBaseComponent, ModalEdicion} from "../etiquetas-base.component";
import {ModalService} from "../../../../core/services/components/modal.service";
import {TramiteGuard} from "../../guards/tramite.guard";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {MatDialogRef} from "@angular/material/dialog";
import {
  AccionesProductos, AccionesProductosDistribuidosConfirmacion,
  ProductoTablePreviewConfig
} from "../../etiquetas.config";
import {firstValueFrom} from "rxjs";
import {TramiteImportadosGuard} from "../../guards/tramite-importados.guard";
import {
  EditProductoImportadosModalComponent
} from "../../modals/edit-producto-importados-modal/edit-producto-importados-modal.component";
import {
  DisclaimerConfirmValidacionComponent
} from "../../modals/disclaimer-confirm-validacion/disclaimer-confirm-validacion.component";
import {EditAdjuntosModalComponent} from "../../modals/edit-adjuntos-modal/edit-adjuntos-modal.component";
import {
  DisclaimerEliminarGenericoComponent
} from "../../../operadores/modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";


@Component({
  selector: 'app-etiquetas-importados-visualizacion',
  templateUrl: './etiquetas-importados-visualizacion.component.html',
  styleUrls: ['./etiquetas-importados-visualizacion.component.scss'],
  providers: [
    {provide: TramiteGuard, useClass: TramiteImportadosGuard}
  ]
})
export class EtiquetasImportadosVisualizacionComponent extends EtiquetasBaseComponent implements OnInit {


  tableConfig = ProductoTablePreviewConfig;
  tableAcciones = AccionesProductosDistribuidosConfirmacion;

  constructor(injector: Injector,
              modalSvc: ModalService,
              tramiteGuard: TramiteGuard,
              cdr: ChangeDetectorRef) {
    super(injector, modalSvc, tramiteGuard, cdr);
  }

  override onEditarProducto(producto: SolicitudProducto): MatDialogRef<ModalEdicion>{
    let modal = this.modalSvc.openDialog(EditProductoImportadosModalComponent, {producto});
    modal.componentInstance.enableReadOnly();
    return modal;
  }

  override onActionTable($event: any) {
    if($event.action == AccionesProductos.VIEW_ADJUNTOS) {
      this.modalSvc.openDialog(EditAdjuntosModalComponent, {producto: $event.item, readOnly: true})
    }
    else if($event.action == AccionesProductos.ACEPTAR) {
    }
    else if($event.action == AccionesProductos.RECHAZAR) {
    }
    else {
      super.onActionTable($event);
    }
  }

  override async onHandleEditarProducto(idSolicitudProducto: number, producto: SolicitudProducto) {
  }

  override validateForm(productos: SolicitudProducto[]): boolean {
    return true;
  }

  override onHandleConfirmar(): void {
  }

}

