<div *ngIf="data && data.length > 0">
    <div>
        <div *ngIf="title" class="font-weight-bold mt-1 mb-1">
            <h2>{{title}}</h2>
        </div>
        <h4 *ngIf="paginated"> Mostrant {{data.length}} de {{collectionSize}} registres</h4>
        <table class="table table-responsive" [ngClass]="tableType" aria-describedby="Expedients table">
            <thead class="thead-dark" *ngIf="headerVisible" [ngClass]="headerClass">
            <tr>
                <th *ngFor="let column of columnsConfig"  [attr.rowspan]="column.colspan>1 ? 1 : 2" [attr.colspan]="column.colspan || 1" style="vertical-align: middle" scope="col" (click)="onHeaderClick(column)">
                    {{column.header | translate}}
                    <mat-icon
                            *ngIf="column.sortable && column?.order === 'ASC'"
                    >
                        keyboard_arrow_down
                    </mat-icon>
                    <mat-icon
                            *ngIf="column.sortable && column?.order === 'DESC'"
                    >
                        keyboard_arrow_up
                    </mat-icon>
                </th>
                <th *ngIf="actionsConfig.length > 0" style="text-align: right; vertical-align: middle;"  [attr.rowspan]="2" scope="col">
                    {{'general.acciones' | translate}}
                </th>
            </tr>
            <ng-container *ngIf="headerRows>1">
                <ng-container *ngFor="let column of columnsConfig" >
                    <ng-container *ngIf="column.subheaders != null">
                        <tr>
                            <th *ngFor="let subCol of column.subheaders" scope="col" >
                                {{subCol.header | translate}}
                                <mat-icon
                                        *ngIf="column.sortable && column?.order === 'ASC'"
                                >
                                    keyboard_arrow_down
                                </mat-icon>
                                <mat-icon
                                        *ngIf="column.sortable && column?.order === 'DESC'"
                                >
                                    keyboard_arrow_up
                                </mat-icon>
                            </th>
                        </tr>
                    </ng-container>
                </ng-container>
            </ng-container>

            </thead>
            <tbody>
                <tr *ngFor="let item of data">
                    <ng-container *ngFor="let column of columnsConfig">
                        <ng-container *ngIf="column.subheaders != null">
                            <td *ngFor="let subCol of column.subheaders">
                                <div [ngSwitch]="subCol.type">
                                    <div *ngSwitchCase="'function'">
                                        {{ subCol.fn | functionExecute:item | translate }}
                                    </div>
                                    <div *ngSwitchCase="'semaphore'">
                                        <div *ngIf="subCol.redValue | evaluarCondicion:item">
                                            <mat-icon class="semaphore-red">brightness_1</mat-icon>
                                        </div>
                                        <div *ngIf="subCol.greenValue | evaluarCondicion:item">
                                            <mat-icon class="semaphore-green">brightness_1</mat-icon>
                                        </div>
                                        <div *ngIf="subCol.orangeValue | evaluarCondicion:item">
                                            <mat-icon class="semaphore-orange">brightness_1</mat-icon>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'icon'">
                                        <div *ngIf="item | ObjetoToValor:column.field + '.' + subCol.field; else cancelIcon">
                                            <mat-icon>check</mat-icon>
                                        </div>
                                        <ng-template #cancelIcon>
                                            <mat-icon>close</mat-icon>
                                        </ng-template>
                                    </div>
                                    <div *ngSwitchCase="'boolean'">
                                        <div *ngIf="item | ObjetoToValor:column.field + '.' + subCol.field; else cancelIcon">
                                            <mat-icon>check</mat-icon>
                                        </div>
                                        <ng-template #cancelIcon>
                                            <mat-icon>close</mat-icon>
                                        </ng-template>
                                    </div>
                                    <div *ngSwitchCase="'date'">
                                        {{ item | ObjetoToValor:column.field + '.' + subCol.field | date:'dd/MM/yyyy' }}
                                    </div>
                                    <div *ngSwitchCase="'money'">
                                        <div *ngIf="(item | ObjetoToValor:column.field + '.' + subCol.field) != null">
                                            {{ item | ObjetoToValor:column.field + "." + subCol.field | number:'1.2-2' }} €
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'percentage'">
                                        <div *ngIf="(item | ObjetoToValor:column.field + '.' + subCol.field) != null">
                                            {{ item | ObjetoToValor:column.field + "." + subCol.field | number:'1.3-3' }} %
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'checkbox'">
                                        <app-input-checkbox (valueChanged)="checkValueChange($event)">

                                        </app-input-checkbox>
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ item | ObjetoToValor:column.field + "." + subCol.field | emptyOutput }}
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container *ngIf="column.subheaders == null" >
                            <td>
                                <div [ngSwitch]="column.type">
                                    <div *ngSwitchCase="'function'">
                                        {{column.fn | functionExecute:item | translate}}
                                    </div>

                                    <div *ngSwitchCase="'semaphore'">
                                        <div *ngIf="column.redValue | evaluarCondicion:item">
                                            <mat-icon class="semaphore-red">brightness_1</mat-icon>
                                        </div>
                                        <div *ngIf="column.greenValue | evaluarCondicion:item">
                                            <mat-icon class="semaphore-green">brightness_1</mat-icon>
                                        </div>
                                        <div *ngIf="column.orangeValue | evaluarCondicion:item">
                                            <mat-icon class="semaphore-orange">brightness_1</mat-icon>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'icon'">
                                        <div *ngIf="item | ObjetoToValor:column.field; else cancelIcon">
                                            <mat-icon>check</mat-icon>
                                        </div>
                                        <ng-template #cancelIcon>
                                            <mat-icon>close</mat-icon>
                                        </ng-template>
                                    </div>

                                    <div *ngSwitchCase="'boolean'">
                                        <div *ngIf="item | ObjetoToValor:column.field; else cancelIcon">
                                            <mat-icon>check</mat-icon>
                                        </div>
                                        <ng-template #cancelIcon>
                                            <mat-icon>close</mat-icon>
                                        </ng-template>
                                    </div>

                                    <div *ngSwitchCase="'date'">
                                        {{item | ObjetoToValor:column.field | date:'dd/MM/yyyy'}}
                                    </div>

                                    <div *ngSwitchCase="'money'">
                                        <div *ngIf="(item | ObjetoToValor:column.field) != null">
                                            {{item | ObjetoToValor:column.field | number:'1.2-2'}} €
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'percentage'">
                                        <div *ngIf="(item | ObjetoToValor:column.field) != null">
                                            {{item | ObjetoToValor:column.field | number:'1.3-3'}} %
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'checkbox'">
                                        <app-input-checkbox>

                                        </app-input-checkbox>
                                    </div>

                                    <div *ngSwitchDefault>
                                        {{item | ObjetoToValor:column.field | emptyOutput}}
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                    </ng-container>

                    <td *ngIf="actionsConfig.length > 0">
                        <div class="d-flex justify-content-end">
                            <div class="action" [ngClass]="{'disabled': action.condicionDisable | evaluarCondicion:item}"
                                 *ngFor="let action of actionsConfig"
                                 (click)="onActionClick(action, item)">
                                <mat-icon matTooltip="{{action.toolTip | translate}}"
                                          [ngClass]="action.condicionColor | condicionesToColor:item"
                                          *ngIf="action.condicion == undefined || (action.condicion | evaluarCondicion:item)">
                                    {{action.icon}}
                                </mat-icon>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="paginated" class="p-2 d-flex justify-content-center paginacion">
        <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="collectionSize/totalPages" [maxSize]="8"
                        [rotate]="true" [boundaryLinks]="true" (pageChange)="pageChanged($event)">
        </ngb-pagination>
    </div>
</div>
<div *ngIf="!data || data.length == 0">
    <h4>{{this.textNoData | translate}}</h4>
    <span *ngIf="subtitleVisible">{{this.subTextNoData | translate}}</span>
</div>