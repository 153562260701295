<div class="col-12 login-page d-flex justify-content-center align-items-center align-middle">
    <div class="col-5 login-box d-flex justify-content-center align-items-center">
        <div class="login-box-content d-flex justify-content-center">
            <div class="col-9 d-flex justify-content-center align-items-center">
                <div class="login-form p-5">

                    <div class="d-flex justify-content-center logo mb-4">
                        <img src="../../../../../assets/images/logo.webp" alt="logo"/>
                    </div>

                    <div class="d-flex justify-content-center">
                        <h2>{{'recuperarPw.restablirContrasenya' | translate}}</h2>
                    </div>

                    <form [formGroup]="formGroup" (submit)="onSubmit()">
                        <div class="row form-group">
                            <input formControlName="email" class="form-control" type="text"
                                   [placeholder]="'general.email' | translate"/>
                        </div>

                        <div class="row form-group mt-3">
                            <button class="form-control btn btn-success" type="submit"
                                    [disabled]="formGroup.invalid">
                                {{'recuperarPw.enviarLinkRec' | translate}}
                            </button>
                        </div>
                    </form>

                    <div *ngIf="userNotFound" class="d-flex justify-content-center mt-3">
                        <h4 class="error">{{'recuperarPw.usuariNotFound' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
