import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EtiquetasCompuestoService} from '../../services/etiquetas-compuesto.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {catchError, map} from "rxjs/operators";
import {BehaviorSubject, EMPTY, lastValueFrom, Observable} from "rxjs";
import {QualificacionIngrediente} from "../../../../core/model/master/qualificacion-ingrediente";
import {SolicitudIngrediente} from "../../../../core/model/etiquetas/solicitud-ingrediente";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {OrigenMateria} from "../../../../core/model/master/origen-materia";
import {EtiquetasService} from "../../services/etiquetas.service";
import {SolicitudProductoCompuesto} from "../../../../core/model/etiquetas/solicitud-producto-compuesto";
import {
  AccionesIngredientes,
  IngredientesTableAction,
  IngredientesTableActionEstado3,
  IngredientesTableConfig
} from "../../etiquetas.config";
import {ModalEdicion} from "../../pages/etiquetas-base.component";
import {SolicitudProductoHttpService} from "../../../../core/services/http/solicitud/solicitud-producto.http.service";
import {
  QualificacionIngredienteHttpService
} from "../../../../core/services/http/solicitud/qualificacion-ingrediente.http.service";
import {OrigenMateriaHttpService} from "../../../../core/services/http/solicitud/origen-materia.http.service";
import {TableEvent} from "../../../../shared/components/generic-table/generic-table.component";
import {SolicitudHttpService} from "../../../../core/services/http/solicitud/solicitud.http.service";
import {read} from "@popperjs/core";

@Component({
  templateUrl: './edit-pre-ingredientes-modal.component.html',
  styleUrls: ['./edit-pre-ingredientes-modal.component.scss']
})
export class EditPreIngredientesModalComponent implements OnInit, ModalEdicion {

  // Services
  protected solicitudProductoSvc: SolicitudProductoHttpService;
  protected etiquetasSvc: EtiquetasService;
  protected etiquetasCompuestoSvc: EtiquetasCompuestoService;
  protected qualificacionIngredienteSvc: QualificacionIngredienteHttpService;
  protected origenMateriaSvc: OrigenMateriaHttpService;
  protected solicitudSvc: SolicitudHttpService;

  // Form values
  protected idSolicitudProducto: number;
  protected showAddForm: boolean = false;
  protected showAddButton: boolean = true;
  protected _porcentajeRestante: number = 100;
  protected _porcentajeDisponible: number = 100;
  protected selectedItem: SolicitudIngrediente | null = null;
  private noAgrario = false;
  private qualiConvencional = false;
  protected origenNoObligatorio = true;
  protected estado3Ingredientes: boolean = false;

  // Masters
  protected qualiIngredientes: Observable<QualificacionIngrediente[]> = EMPTY;
  protected origenes: Observable<OrigenMateria[]> = EMPTY;

  // Domain values
  protected producto: SolicitudProducto;
  protected ingredientes: BehaviorSubject<SolicitudIngrediente[]>;

  // Configuration
  public estadoSolicitud: number;
  protected readOnly: boolean = false;
  protected esVisualizacion: boolean;
  protected formGroup: FormGroup;
  protected defaultValues = {
    "nombre": '',
    "agrario": 'true',
    "idOrigenMateria": null,
    "idQualificacionIngrediente": null
  };

  protected tableIngredientesConfig = IngredientesTableConfig;
  protected tableIngredienteAcciones = IngredientesTableAction;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {producto: SolicitudProductoCompuesto},
              protected dialogRef: MatDialogRef<EditPreIngredientesModalComponent>,
              protected injector: Injector,
              protected fb: FormBuilder) {

    this.solicitudProductoSvc = injector.get(SolicitudProductoHttpService);
    this.etiquetasSvc = injector.get(EtiquetasService);
    this.etiquetasCompuestoSvc = injector.get(EtiquetasCompuestoService);
    this.qualificacionIngredienteSvc = injector.get(QualificacionIngredienteHttpService);
    this.origenMateriaSvc = injector.get(OrigenMateriaHttpService);
    this.solicitudSvc = injector.get(SolicitudHttpService);

    this.idSolicitudProducto = data.producto.idSolicitudProducto;
    this.producto = data.producto;
    this.ingredientes = new BehaviorSubject<SolicitudIngrediente[]>([]);

    this.formGroup = fb.group({
      "nombre": fb.control('', [Validators.required, Validators.maxLength(140)]),
      'porcentaje': fb.control("100", [Validators.required, Validators.min(0.001)]),
      "agrario": fb.control(null, [Validators.required]),
      "idOrigenMateria": fb.control(null ),
      "idQualificacionIngrediente": fb.control(null, [Validators.required])
    });

    this.formGroup.get('idQualificacionIngrediente')?.valueChanges
        .subscribe(id => {
          this.qualiConvencional = id == 1;
          this.handleOrigenMateria()
        });

    this.noAgrario = !this.formGroup.get('agrario')?.value;
    this.formGroup.get('agrario')?.valueChanges
        .subscribe(agrario => {
          this.noAgrario = agrario == 'false';
          this.handleOrigenMateria()
        });

  }

  async ngOnInit() {

    if (this.idSolicitudProducto) {
      await this.loadIngredientes();
      this.qualiIngredientes = this.qualificacionIngredienteSvc.getQualificaciones();
      this.qualiIngredientes = this.qualiIngredientes.pipe(
          map(qualiIngredientes => qualiIngredientes.slice(0,4))
      );
      this.origenes = this.origenMateriaSvc.getOrigenMateria();
      this.esVisualizacion = this.etiquetasSvc.esVisualizacion;

      this.ingredientes.subscribe(ingredientes => this.onHandleIngredientesChange(ingredientes));
    }
  }

  protected onHandleIngredientesChange(ingredientes: SolicitudIngrediente[]): void {
    this.porcentajeRestante = this.etiquetasCompuestoSvc.porcentajeRestante(ingredientes);

    this.porcentajeDisponible = this.porcentajeRestante;

    this.formGroup.patchValue({...this.defaultValues, "porcentaje": this.porcentajeRestante}, {emitEvent: false});

    this.formGroup.markAsPristine();

    if (this.porcentajeRestante <= 0) {
      this.showAddForm = false;
    }

    this.showAddButton = this.porcentajeRestante > 0;
  }

  protected async loadIngredientes() {
    this.ingredientes.next(await lastValueFrom(this.solicitudProductoSvc
        .getIngredientes(this.idSolicitudProducto)
        .pipe(catchError(_ => this.etiquetasSvc.handleErrorLoadingData()))));
  }

  protected onAdd() {
    this.showAddForm = true;
  }

  protected onCancel() {
    this.showAddForm = false;
    this.selectedItem = null;
    this.formGroup.reset({
      ...this.defaultValues,
      'porcentaje': this.porcentajeRestante
    });
  }

  onSubmit(_: SubmitEvent) {

    let obs;

    if (this.selectedItem != null) {
      obs = this.solicitudProductoSvc.modificarIngrediente(this.idSolicitudProducto,
          this.selectedItem.idSolicitudIngrediente, this.formGroup.value);
    } else {
      obs = this.solicitudProductoSvc.crearIngrediente(this.idSolicitudProducto, this.formGroup.value);
    }

    obs.subscribe(_ => this.loadIngredientes());
    this.showAddForm = false;
    this.selectedItem = null;
  }

  onActionTable($event: TableEvent<SolicitudIngrediente>) {
    if($event.action == AccionesIngredientes.REMOVE) {
      this.solicitudProductoSvc.eliminarIngrediente(this.idSolicitudProducto, $event.item.idSolicitudIngrediente).subscribe(_ => {
        this.ingredientes.next(this.ingredientes.value
            .filter(item => item.idSolicitudIngrediente != $event.item.idSolicitudIngrediente));
      });
    }
    else if ($event.action == AccionesIngredientes.EDIT) {
      this.showAddForm = false;

      setTimeout(() => {
        this.showAddForm = true;
        this.selectedItem = $event.item;
        this.porcentajeDisponible = $event.item.porcentaje + this.porcentajeRestante;

        this.formGroup.patchValue({
          ...$event.item,
          "idOrigenMateria": $event.item.origenMateria?.idOrigenMateria,
          "idQualificacionIngrediente": $event.item.qualificacionIngrediente?.idQualificacionIngrediente
        });

        this.noAgrario = !$event.item.agrario;
        this.qualiConvencional = $event.item.qualificacionIngrediente?.idQualificacionIngrediente == 1;
        this.handleOrigenMateria();
      }, 0);
    }
  }

  enableReadOnly() {
    this.readOnly = true;
    this.tableIngredienteAcciones = []
  }

  enableReadOnlyEstado3Ingredientes() {
    this.readOnly = true;
    this.tableIngredienteAcciones = IngredientesTableActionEstado3;
  }

  protected get porcentajeDisponible() {
    return this._porcentajeDisponible;
  }

  protected set porcentajeDisponible(value: number) {
    if (value) {
      this._porcentajeDisponible = parseFloat(value.toFixed(3));
    }
  }

  protected get porcentajeRestante() {
    return this._porcentajeRestante;
  }

  protected set porcentajeRestante(value: number) {
    if (value !== null && value !== undefined) {
      const fixedValue = Number(value.toFixed(3));
      if (!Number.isNaN(fixedValue)) {
        this._porcentajeRestante = fixedValue;
      }
    }
  }

  private handleOrigenMateria() {
    this.origenNoObligatorio = this.noAgrario || this.qualiConvencional;

    if(this.origenNoObligatorio) this.formGroup.get('idOrigenMateria')?.clearValidators();
    else this.formGroup.get('idOrigenMateria')?.setValidators(Validators.required);

    this.formGroup.get('idOrigenMateria')?.updateValueAndValidity();
  }
}
