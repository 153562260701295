import {Pipe, PipeTransform} from '@angular/core';
import {
  NgbDateCustomParserFormatter
} from "../components/generic-inputs/input-datepicker/configuration/ngb-date-custom-parser-formatter";
import {NgbDateNativeAdapter} from "@ng-bootstrap/ng-bootstrap";

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: string): string | null {
    if (value) {

      if (value.split('/').length == 3) {
        return value;
      }

      return new NgbDateCustomParserFormatter().format(
          new NgbDateNativeAdapter().fromModel(new Date(value))
      );
    }

    return null;
  }

}
