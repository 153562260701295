import {Component, OnInit} from '@angular/core';
import {EtiquetasService} from "../../services/etiquetas.service";
import {FormControl, FormGroup} from "@angular/forms";
import {Operador} from "../../../../core/model/operadores/operador";

@Component({
  selector: 'app-etiquetas-preview-operador',
  templateUrl: './etiquetas-preview-operador.component.html',
  styleUrls: ['./etiquetas-preview-operador.component.scss']
})
export class EtiquetasPreviewOperadorComponent implements OnInit {

  protected formGroup: FormGroup;
  protected operador: Operador;

  constructor(protected etiquetasSvc: EtiquetasService) {
    this.operador = new Operador();
    this.formGroup = new FormGroup({
      'numInscrito': new FormControl(''),
      'nombreCompleto': new FormControl('')
    });

    this.formGroup.disable();
  }

  async ngOnInit() {
    this.etiquetasSvc.operador.subscribe(value => this.formGroup.patchValue(value));
  }

}
