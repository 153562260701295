<form [formGroup]="formGroup">
    <mat-card class="card shadow">
        <mat-card-title>
            {{'ETIQUETAS.infoOperador' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col-3">
                    <app-input-text
                        formControlName="numInscrito"
                        [label]="'general.numInscrito' | translate"
                    >

                    </app-input-text>

                </div>
                <div class="col-4">
                    <app-input-text
                        formControlName="nombreCompleto"
                        [label]="'general.nombreCompleto' | translate"
                    >

                    </app-input-text>

                </div>
            </div>
        </mat-card-content>
    </mat-card>
</form>