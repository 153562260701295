<app-page-template
        [title]="'OPERADORES.instalaciones.titleDetall' | translate:
        {'instalacion': instalacion?.numOrdenInstalacion ? instalacion.numCentro : 'Alta'}"
>
    <form [formGroup]="formGroup">
        <mat-card class="card shadow">
            <mat-card-title>
                {{'OPERADORES.instalaciones.titleCardDetall' | translate}}
            </mat-card-title>
            <mat-card-content>

                <div class="row">
                    <div class="col-md-6 col-xl-3">
                        <app-input-text
                                [label]="'OPERADORES.instalaciones.numOrdenCentro' | translate"
                                formControlName="numCentro"
                                [campoObligatorio]=true
                        >
                        </app-input-text>
                    </div>
                    <div class="col-md-6 col-xl-6">
                        <app-input-select
                                formControlName="codigoActividad"
                                [label]="'OPERADORES.tipoActividad' | translate"
                                [options]="actividades | async | arrayToSelectItem:'codigoActividad':'descripcionActividad'"
                                [campoObligatorio]=true
                        >
                        </app-input-select>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <app-input-text
                                [label]="'general.direccion' | translate"
                                formControlName="direccion"
                                [campoObligatorio]=true
                        >
                        </app-input-text>
                    </div>
                </div>

                <div class="row">
                    <app-operadores-localizacion
                            [municipioInput]="action == 'create' ? municipio : instalacion?.municipio"
                            [readonly]="action === 'view'"
                            [enableProvincia]="true"
                            [campoObligatorio]="true"
                            (formValid)="municipioValido = $event">
                    </app-operadores-localizacion>
                </div>

                <div class="row">
                    <div class="col-md-6 col-xl-3">
                        <p><strong>{{'OPERADORES.instalaciones.cualiActividadesInstalacion' | translate}}</strong></p>
                        <mat-radio-group>
                            <app-input-radio
                                    [label]="'OPERADORES.instalaciones.cualiExcEcologica' | translate"
                                    [isDisabled]="action == 'view'"
                                    [checked]="!instalacion.mixta"
                                    (change)="onChangeMixta($event, 'eco')"
                                    [name]="'mixta'"
                                    [id]="'eco'">
                            </app-input-radio>
                            <app-input-radio
                                    [label]="'OPERADORES.instalaciones.cualiMixta' | translate"
                                    [isDisabled]="action == 'view'"
                                    [checked]="instalacion.mixta"
                                    (change)="onChangeMixta($event, 'mixta')"
                                    [name]="'mixta'"
                                    [id]="'mixta'">
                            </app-input-radio>
                        </mat-radio-group>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <p><strong>{{'OPERADORES.instalaciones.cualiDominante' | translate}}</strong></p>
                        <mat-radio-group>
                            <app-input-radio
                                    [label]="'OPERADORES.instalaciones.cualiEcologica' | translate"
                                    [isDisabled]="action == 'view'"
                                    [checked]="!instalacion.convencional"
                                    (change)="onChangeConvencional($event, 'ecologica')"
                                    [name]="'convencional'"
                                    [id]="'ecologica'">
                            </app-input-radio>
                            <app-input-radio
                                    [label]="'OPERADORES.instalaciones.cualiConvencional' | translate"
                                    [isDisabled]="action == 'view'"
                                    [checked]="instalacion.convencional"
                                    (change)="onChangeConvencional($event, 'convencional')"
                                    [name]="'convencional'"
                                    [id]="'convencional'">
                            </app-input-radio>
                        </mat-radio-group>
                    </div>
                </div>

                <br/>
                <br/>

                <div class="row">
                    <app-input-textarea
                            [label]="'general.observaciones' | translate"
                            [rows]="4"
                            formControlName="observaciones"
                    >
                    </app-input-textarea>
                </div>

            </mat-card-content>
        </mat-card>

        <div *ngIf="action == 'edit' || action == 'create'" class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" [disabled]="!this.validateForm()"
                    (click)="onGuardarForm()"
            >
                {{'OPERADORES.instalaciones.guardarDatosInstalacion' | translate}}
            </button>
        </div>

    </form>

    <mat-card *ngIf="operadorAction=='view' || action == 'view'" class="card shadow">
        <mat-card-title>
            {{'OPERADORES.instalaciones.titleCardDetallReg' | translate}}
        </mat-card-title>
        <app-generic-table
                [data]="data.content"
                [columnsConfig]="tableConfig"
                [actionsConfig]="tableAccionesView"
                [paginated]="true"
                [collectionSize]="data.totalElements"
                [totalPages]="data.totalPages"
                (onPageChange)="onPageChange($event)"
                [page]="page"
                (onAction)="onActionTable($event)"
        >
        </app-generic-table>
    </mat-card>

    <mat-card *ngIf="operadorAction=='edit' && action == 'edit'" class="card shadow">
        <mat-card-title>
            {{'OPERADORES.instalaciones.titleCardDetallReg' | translate}}
        </mat-card-title>

        <div class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" type="button"
                    (click)="createRegistro()"
            >
                {{'OPERADORES.instalaciones.anadirRegistro' | translate}}
            </button>
        </div>

        <br/>

        <app-generic-table
                [data]="data.content"
                [columnsConfig]="tableConfig"
                [actionsConfig]="tableAccionesEdit"
                [paginated]="true"
                [collectionSize]="data.totalElements"
                [totalPages]="data.totalPages"
                (onPageChange)="onPageChange($event)"
                [page]="page"
                (onAction)="onActionTable($event)"
        >
        </app-generic-table>
    </mat-card>

    <button *ngIf="action != 'create'; else creating " mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
        <mat-icon>arrow_back</mat-icon>
        {{"general.volver" | translate}}
    </button>

    <ng-template #creating>
        <button mat-icon-button (click)="onBackCreate()" style="float: right; margin-right: 40px;">
            <mat-icon>arrow_back</mat-icon>
            {{"general.volver" | translate}}
        </button>
    </ng-template>

</app-page-template>




