<app-page-template
        [title]="action === 'create' ? 'Alta d\'operador' : (operador.numInscrito + ' | ' + operador.empresa + ' | ' + operador.nombreCompleto)"
>
    <form class="col-12">

        <mat-tab-group [selectedIndex]="selectedIndex"
                        (selectedTabChange)="onTabChange($event)"
        >

            <mat-tab [label]="'OPERADORES.datosGenerales' | translate">
                <app-operadores-detalle-general

                >
                </app-operadores-detalle-general>
            </mat-tab>

            <mat-tab *ngIf="this.action !== 'create'"  [label]="'OPERADORES.productos.title' | translate">
                <mat-tab-group>
                    <mat-tab [label]="'OPERADORES.autorizaciones.title' | translate">
                        <app-operadores-listado-autorizacion
                        >
                        </app-operadores-listado-autorizacion>
                    </mat-tab>

                    <mat-tab [label]="'OPERADORES.autorizacionesBajas.title' | translate">
                        <app-operadores-listado-autorizacion-baja
                        >
                        </app-operadores-listado-autorizacion-baja>
                    </mat-tab>

                    <mat-tab [label]="'OPERADORES.noAutorizaciones.title' | translate">
                        <app-operadores-listado-no-autorizacion
                        >
                        </app-operadores-listado-no-autorizacion>
                    </mat-tab>

                    <mat-tab [label]="'OPERADORES.solicitudes.title' | translate">
                        <app-operadores-listado-solicitud
                        >
                        </app-operadores-listado-solicitud>
                    </mat-tab>
                </mat-tab-group>
            </mat-tab>

            <mat-tab *ngIf="this.action !== 'create'"  [label]="'OPERADORES.almacenes.title' | translate">
                <app-operadores-listado-almacen
                >
                </app-operadores-listado-almacen>
            </mat-tab>

            <mat-tab *ngIf="this.action !== 'create'"  [label]="'OPERADORES.parcelas.title' | translate">
                <app-operadores-listado-parcela
                >
                </app-operadores-listado-parcela>
            </mat-tab>

            <mat-tab *ngIf="this.action !== 'create'"  [label]="'OPERADORES.instalaciones.title' | translate">
                <app-operadores-listado-instalacion
                >
                </app-operadores-listado-instalacion>
            </mat-tab>

            <mat-tab *ngIf="this.action !== 'create'"  [label]="'OPERADORES.granjas.title' | translate">
                <app-operadores-listado-granja
                >
                </app-operadores-listado-granja>
            </mat-tab>

            <mat-tab *ngIf="this.action !== 'create'" [label]="'OPERADORES.animales.title' | translate">
                <app-operadores-listado-animal
                >
                </app-operadores-listado-animal>
            </mat-tab>

        </mat-tab-group>

    </form>

</app-page-template>
