import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ProductoService} from "../../../../core/services/http/producto.service";
import {CategoriaEcologica} from "../../../../core/model/master/categoria-ecologica";
import {Envase} from "../../../../core/model/master/envase";
import {Observable} from "rxjs";
import {
  SolicitudIngredienteHttpService
} from "../../../../core/services/http/solicitud/solicitud-ingrediente-http.service";
import {SolicitudProductoHttpService} from "../../../../core/services/http/solicitud/solicitud-producto.http.service";
import {EnvasesHttpService} from "../../../../core/services/http/solicitud/envases.http.service";
import {
  CategoriasEcologicasHttpService
} from "../../../../core/services/http/solicitud/categorias-ecologicas.http.service";
import {EtiquetasService} from "../../services/etiquetas.service";

@Component({
  template: '<div></div>'
})
export abstract class EditProductoBaseModalComponent implements OnInit {
  //Services
  protected productoSvc : ProductoService;
  protected solicitudProductoSvc: SolicitudProductoHttpService;
  protected solicitudIngredienteSvc: SolicitudIngredienteHttpService;
  protected envasesSvc: EnvasesHttpService;
  protected categoriaEcologicaSvc: CategoriasEcologicasHttpService;
  protected etiquetasSvc: EtiquetasService;

  //Data
  protected producto: SolicitudProducto;
  protected categorias!: Observable<CategoriaEcologica[]>;
  protected envases!: Observable<Envase[]>;

  //FormGroup and auxiliars
  protected readOnly: boolean = false;
  protected esVisualizacion: boolean;
  protected formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) data: {producto:SolicitudProducto},
              public dialogRef: MatDialogRef<any>,
              injector: Injector) {

    this.productoSvc = injector.get(ProductoService);
    this.solicitudProductoSvc = injector.get(SolicitudProductoHttpService);
    this.solicitudIngredienteSvc = injector.get(SolicitudIngredienteHttpService);
    this.envasesSvc = injector.get(EnvasesHttpService);
    this.categoriaEcologicaSvc = injector.get(CategoriasEcologicasHttpService);
    this.etiquetasSvc = injector.get(EtiquetasService);

    this.producto = data.producto;

    this.formGroup = new FormGroup({
      "idSolicitudProducto": new FormControl(this.producto.idSolicitudProducto),
      "idSolicitud": new FormControl(this.producto.idSolicitud),
      "nombreComercial": new FormControl('', [Validators.required, Validators.maxLength(90)]),
      "idProducto": new FormControl('', [Validators.required]),
      "marca": new FormControl('', [Validators.required, Validators.maxLength(90)]),
      "idEnvase": new FormControl(null, [Validators.required]),
      "capacidad": new FormControl('', [Validators.required, Validators.maxLength(30)])
    });

  }

  ngOnInit(): void {
    this.envases = this.envasesSvc.getEnvases();
    this.categorias = this.categoriaEcologicaSvc.getCategorias();
    this.esVisualizacion = this.etiquetasSvc.esVisualizacion;

    if(this.producto.idSolicitudProducto) {
      this.solicitudProductoSvc.getProducto(this.producto.idSolicitudProducto).subscribe(producto => {
        this.producto = producto;
        this.formGroup.patchValue({
          ...this.producto,
          "idProducto": {id: this.producto.idProducto, valor: this.producto.nombreProducto},
        })
        this.gestionarCategoria();
      })
    }
  }

  protected abstract onSubmit() : void;

  protected abstract gestionarCategoria() : void;
}
