import {Component, EventEmitter, Input, Output} from '@angular/core';

import {EvaluarCondicionPipe} from '../../pipes/evaluar-condicion.pipe';
import {TablaAccion} from './model/tabla-accion';
import {TablaColumna} from './model/tabla-columna';

export interface TableEvent<T> {
  action: any;
  item: T;
}

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent {

  @Input() columnsConfig!: Array<TablaColumna>;
  @Input() actionsConfig: Array<TablaAccion> = [];
  @Input() title?: string;
  @Input() data: Array<any> | null = [];
  @Input() headerVisible = true;
  @Input() tableType = "table-hover";
  @Input() headerClass = "";
  @Input() subtitleVisible = false;
  @Input() textNoData = 'general.table.titleNoData'
  @Input() subTextNoData = 'general.table.subTitleNoData'

  @Input() paginated = true;
  @Input() collectionSize = 0;
  @Input() totalPages = 0;
  @Input() page = 1;
  @Input() headerRows: number = 1;

  @Output() onSort = new EventEmitter<TablaColumna>();
  @Output() onAction = new EventEmitter<TableEvent<any>>();
  @Output() onPageChange = new EventEmitter<any>();
  @Output() onCheckValueChange = new EventEmitter<any>();

  private evaluarCondicion = new EvaluarCondicionPipe();

  constructor() {
    if (this.collectionSize == 0 || this.totalPages == 0) {
      this.paginated = false;
    }
  }

  pageChanged($event: any) {
    this.onPageChange.emit($event);
  }

  onActionClick(action: TablaAccion, item: object) {
    if (!this.evaluarCondicion.transform(action.condicionDisable, item)) {
      this.onAction.emit({
        action: action.action, item
      });
    }
  }

  onHeaderClick(column: TablaColumna) {
    if (column.sortable){
      column.order  = column.order == "DESC" ? "ASC" : "DESC";
      this.onSort.emit(column);
    }

  }

  checkValueChange($event: any) {
    this.onCheckValueChange.emit($event);
  }

}

