import {AfterContentChecked, Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-chip-item',
  templateUrl: './chip-item.component.html',
  styleUrls: ['./chip-item.component.scss']
})
export class ChipItemComponent implements AfterContentChecked {

  public activeSubj = new BehaviorSubject<boolean>(false);
  public active = false;

  @Input() value: number | string = 0;
  @Output() click = new EventEmitter<number | string>();

  ngAfterContentChecked(): void {
    this.active = this.activeSubj.value;
  }

  onClick() {
    this.click.emit(this.value);
  }
}
