import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ObjetoToValor'
})
export class ObjetoToValorPipe implements PipeTransform {

  transform(value: {}, field: string): any {

    let negative = field.indexOf("!") == 0;

    if (negative) {
      return !this.getValue(value, field.slice(1).split('.'))
    } else {
      return this.getValue(value, field.split('.'));
    }
  }

  getValue(object: any, fields: Array<string>) : string|boolean|null {
    const value = object[fields[0]];

    if(fields.length == 1 || value == undefined)
      return value;

    return this.getValue(value, fields.slice(1, fields.length));
  }

}
