import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from '@angular/material/tooltip';
import {LoaderComponent} from "./components/loader/loader.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {RouterModule} from "@angular/router";
import {MatTreeModule} from "@angular/material/tree";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ArrayToSelectItemPipe} from "./pipes/array-to-select-item.pipe";
import {DateFormatPipe} from "./pipes/date-format.pipe";
import {EvaluarCondicionPipe} from "./pipes/evaluar-condicion.pipe";
import {FunctionExecutePipe} from "./pipes/function-execute.pipe";
import {ObjetoToValorPipe} from "./pipes/objeto-to-valor.pipe";
import {ToSelectItemPipe} from "./pipes/to-select-item.pipe";
import {InputAutocompleteComponent} from "./components/generic-inputs/input-autocomplete/input-autocomplete.component";
import {InputCheckboxComponent} from "./components/generic-inputs/input-checkbox/input-checkbox.component";
import {InputDatePickerComponent} from "./components/generic-inputs/input-datepicker/input-date-picker.component";
import {InputNumberComponent} from "./components/generic-inputs/input-number/input-number.component";
import {InputPasswordComponent} from "./components/generic-inputs/input-password/input-password.component";
import {InputRadioComponent} from "./components/generic-inputs/input-radio/input-radio.component";
import {InputSelectComponent} from "./components/generic-inputs/input-select/input-select.component";
import {InputTextComponent} from "./components/generic-inputs/input-text/input-text.component";
import {InputTextareaComponent} from "./components/generic-inputs/input-textarea/input-textarea.component";
import {GenericTableComponent} from "./components/generic-table/generic-table.component";
import {HeaderTitleComponent} from "./components/header-title/header-title.component";
import {
    NgbDatepickerModule,
    NgbPaginationModule,
    NgbToastModule,
    NgbTooltipModule,
    NgbTypeaheadModule
} from "@ng-bootstrap/ng-bootstrap";
import {CondicionesToColorPipe} from "./pipes/array-condicion-to-color.pipe";
import {PageTemplateComponent} from './components/page-template/page-template.component';
import {MatCardModule} from "@angular/material/card";
import {ChipCardsComponent} from './components/chip-cards/chip-cards.component';
import {ChipItemComponent} from './components/chip-cards/components/chip-item/chip-item.component';
import {EmptyOutputPipe} from './pipes/empty-output.pipe';
import {DropFileZoneComponent} from './components/drop-file-zone/drop-file-zone.component';

@NgModule({
    declarations: [
        LoaderComponent,
        InputAutocompleteComponent,
        InputCheckboxComponent,
        InputDatePickerComponent,
        InputNumberComponent,
        InputPasswordComponent,
        InputRadioComponent,
        InputSelectComponent,
        InputTextComponent,
        InputTextareaComponent,
        GenericTableComponent,
        HeaderTitleComponent,
        ArrayToSelectItemPipe,
        DateFormatPipe,
        EvaluarCondicionPipe,
        FunctionExecutePipe,
        ObjetoToValorPipe,
        ToSelectItemPipe,
        CondicionesToColorPipe,
        PageTemplateComponent,
        ChipCardsComponent,
        ChipItemComponent,
        EmptyOutputPipe,
        DropFileZoneComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        ReactiveFormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        FormsModule,
        MatTreeModule,
        MatButtonModule,
        MatProgressBarModule,
        NgbToastModule,
        NgbPaginationModule,
        NgbTooltipModule,
        NgbDatepickerModule,
        NgbTypeaheadModule,
        MatCardModule
    ],
    exports: [
        LoaderComponent,
        InputAutocompleteComponent,
        InputCheckboxComponent,
        InputDatePickerComponent,
        InputNumberComponent,
        InputPasswordComponent,
        InputRadioComponent,
        InputSelectComponent,
        InputTextComponent,
        InputTextareaComponent,
        GenericTableComponent,
        HeaderTitleComponent,
        ArrayToSelectItemPipe,
        DateFormatPipe,
        EvaluarCondicionPipe,
        FunctionExecutePipe,
        ObjetoToValorPipe,
        ToSelectItemPipe,
        CondicionesToColorPipe,
        PageTemplateComponent,
        ChipCardsComponent,
        ChipItemComponent,
        DropFileZoneComponent
    ]
})
export class SharedModule { }
