import {Injectable} from "@angular/core";
import {NgbDateParserFormatter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

    format(date: NgbDateStruct | null): string {
        if(!date)
            return "";

        return date.day.toString().padStart(2, '0') + "/"
            + date.month.toString().padStart(2, '0') + "/"
            + date.year;
    }

    formatISO(date: NgbDateStruct | null): string | null {
      if (date) {
        return new Date(Date.UTC(date.year, date.month-1, date.day)).toISOString()
      }
      return null;
    }

    parse(value: string | null): NgbDateStruct | null {
        if (value) {
          const dateParts = value.trim().split('/');
          if(dateParts.length == 3)
              return {day: parseInt(dateParts[0]), month: parseInt(dateParts[1]), year: parseInt(dateParts[2])}
        }
        
        return null;
    }

    parseToISOString(value: string | null): string | null {
      if (value) {
        const dateParts = value.trim().split('/');
        if(dateParts.length == 3) {
          const date = new Date(Date.UTC(parseInt(dateParts[2]), parseInt(dateParts[1])-1, parseInt(dateParts[0])));
          return date.toISOString();
        }
            
      }
      
      return null;
  }

}
