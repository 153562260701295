<app-etiquetas-view-base
        *ngIf="etiquetasSvc.hasRequiredParamaters"
        [title]="'ETIQUETAS.titleDistribuits' | translate"
        [subTitle]="'ETIQUETAS.subTitleTramite' | translate:{
        'solicitud': etiquetasSvc.idSolicitud
    }"
>

    <form [formGroup]="formGroup" class="col-12 overflow-hidden">
        <div class="cards-container row">
            <div class="col-12">
                <mat-card class="card shadow">
                    <mat-card-title>
                        {{'ETIQUETAS.productosSolicitudTitulo' | translate}}
                    </mat-card-title>

                    <mat-card-content>
                        <div class="row d-flex">
                            <mat-card class="card shadow col-auto ms-auto d-flex justify-content-end icon-margin">
                                <mat-card-content style="display: flex; flex-direction: row;">
                                    <div class="col-auto d-flex align-items-center">
                                        <mat-icon class="margin-right mat-icon-middle">check</mat-icon>
                                        <span class="margin-right">{{'ETIQUETAS.leyendaConfirmacionProducto' | translate}}</span>
                                    </div>
                                    <div class="col-auto d-flex align-items-center">
                                        <mat-icon class="margin-right mat-icon-middle">block</mat-icon>
                                        <span class="margin-right">{{'ETIQUETAS.leyendaRechazoProducto' | translate}}</span>
                                    </div>
                                    <div class="col-auto d-flex align-items-center">
                                        <mat-icon class="margin-right mat-icon-middle">preview</mat-icon>
                                        <span class="margin-right">{{'ETIQUETAS.leyendaDatosProducto' | translate}}</span>
                                    </div>
                                    <div class="col-auto d-flex align-items-center">
                                        <mat-icon class="margin-right mat-icon-middle">file_present</mat-icon>
                                        <span class="margin-right">{{'ETIQUETAS.leyendaAdjuntosProducto' | translate}}</span>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="row d-flex">
                            <div class="col-5 d-flex mr-auto align-items-center">
                                <app-input-text
                                        formControlName="busqueda"
                                        [label]="'general.buscarPorNombreComercial' | translate"
                                        [showLabel]="false"
                                >
                                </app-input-text>

                                <div class="m-2">
                                    <app-input-checkbox
                                            label="Veure alertes"
                                            formControlName="warnings"
                                    >
                                    </app-input-checkbox>
                                </div>
                            </div>

                            <div class="col-7 d-flex align-self-center justify-content-end">

                                <button mat-raised-button color="primary" class="mx-1" (click)="onChangeStateProducto(productosVisibles, true)">
                                    {{'ETIQUETAS.aceptarTodos' | translate}}
                                </button>

                                <button mat-raised-button color="cancel" class="mx-1" (click)="onChangeStateProducto(productosVisibles, false)">
                                    {{'ETIQUETAS.cancelarTodos' | translate}}
                                </button>

                            </div>
                        </div>

                        <div class="py-2">
                            <app-generic-table
                                    [data]="productosVisibles"
                                    [columnsConfig]="tableConfig"
                                    [actionsConfig]="tableAcciones"
                                    (onAction)="onActionTable($event)"
                            >
                            </app-generic-table>
                        </div>

                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" style="margin-right: 10px;"
                    (click)="onGuardar()">
                {{'ETIQUETAS.desar' | translate}}
            </button>
            <button mat-raised-button color="primary" class="px-2" style="margin-right: 10px;" [disabled]="!formIsValidToEsmena"
                    (click)="onRevision()"
            >
                {{'ETIQUETAS.revisionSolicitud' | translate}}
            </button>
            <button mat-raised-button color="primary" class="px-2" [disabled]="!formIsValid"
                    (click)="onConfirmar()"
            >
                {{'ETIQUETAS.finalizarSolicitud' | translate}}
            </button>
        </div>

    </form>


</app-etiquetas-view-base>
