<div class="col-12 navbar d-flex align-content-start p-0">

    <div class="logo py-2 col-12  d-flex justify-content-center">
        <img src="../../../../assets/images/logo.webp" alt="logo"/>
    </div>

    <div class="col-12 d-flex justify-content-center">
        <span class="divisor col-10 my-2"></span>
    </div>

    <div class="navbar-items-container">

        <div *ngFor="let item of menuItems" class="col-12 p-0" [ngClass]="{
                'groupActive': grupoActivo === item.groupName
             }">

            <div *ngIf="item.active || item.active == undefined">

                <!-- Base item -->
                <ng-container  [ngSwitch]="item.type">
                    <div *ngSwitchDefault routerLinkActive="active" [routerLink]="item.type == 'link' || item.type == undefined ? item.url : null"
                         class="item col-12 d-flex align-items-center">

                        <mat-icon>{{item.icon}}</mat-icon>
                        <span>{{item.text | translate}}</span>
                    </div>

                    <div *ngSwitchCase="'group'" class="item col-12 d-flex align-items-center" (click)="onChangeUrl(item)">
                        <mat-icon>{{item.icon}}</mat-icon>
                        <span class="pl-1">{{item.text | translate}}</span>
                        <mat-icon *ngIf="item.type == 'group'">
                            {{grupoActivo === item.groupName ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}
                        </mat-icon>
                    </div>
                </ng-container>

                <!-- Subitems if have -->
                <div class="col-12 p-0 subitems-wrapper" [ngClass]="{'active-group': grupoActivo == item.groupName}">
                    <div *ngFor="let subItem of item.subItems">
                        <div *ngIf="subItem.active || subItem.active == undefined"
                            class="col-12 subitem d-flex align-items-center"
                             routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="subItem.url">
                            <mat-icon>{{subItem.icon}}</mat-icon>
                            <span class="pl-1">{{subItem.text | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 bottom-0 position-absolute">
        <div class="col-12 d-flex justify-content-center">
            <span class="divisor col-10 my-2"></span>
        </div>

        <div class="col-12 version d-flex justify-content-center mb-2">
            Versió 1.0.0
        </div>
    </div>
</div>
