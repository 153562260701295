import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {HeaderComponent} from './core/components/header/header.component';
import {NavbarComponent} from './core/components/navbar/navbar.component';
import {registerLocaleData} from "@angular/common";
import es from '@angular/common/locales/es';
import {LoadingInterceptor} from './core/interceptors/loading.interceptor';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from "@angular/material/icon";
import {LoginModule} from "./modules/login/login.module";
import {HttpLoaderFactory} from "./core/http-loader-factory-language";
import {MatSidenavModule} from "@angular/material/sidenav";
import {SharedModule} from "./shared/shared.module";
import {MatButtonModule} from "@angular/material/button";
import {OperadoresModule} from "./modules/operadores/operadores.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatDialogModule} from "@angular/material/dialog";
import {AuthInterceptor} from "./core/interceptors/auth.interceptor";

registerLocaleData(es);

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        NavbarComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatIconModule,
        LoginModule,
        MatSidenavModule,
        SharedModule,
        MatButtonModule,
        OperadoresModule,
        MatSnackBarModule,
        MatDialogModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
        {provide: LOCALE_ID, useValue: 'es-ES'}
    ],
    exports: [
        HeaderComponent
    ],
    bootstrap: [AppComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
