<form [formGroup]="formDocumentacion">
    <mat-tab-group [selectedIndex]="selectedIndex"
                   (selectedTabChange)="onTabChange($event)">
        <mat-tab *ngIf="checkFamiliaSelected('prodVegetal')">
            <ng-template mat-tab-label>
                {{'OPERADORES.docuVegetal' | translate}}
                <mat-icon class="example-tab-icon">{{validateSubForm('prodVegetal') ? 'check': 'close'}}</mat-icon>
            </ng-template>
            <ng-container *ngTemplateOutlet="docuVegetal"></ng-container>
        </mat-tab>

        <mat-tab *ngIf="checkFamiliaSelected('prodRamadera')">
            <ng-template mat-tab-label>
                {{'OPERADORES.docuRamadera' | translate}}
                <mat-icon class="example-tab-icon">{{validateSubForm('prodRamadera') ? 'check': 'close'}}</mat-icon>
            </ng-template>
            <ng-container *ngTemplateOutlet="docuRamadera"></ng-container>
        </mat-tab>
    </mat-tab-group>

    <div class="col-12 p-2 mt-2 d-flex justify-content-end">
        <button mat-raised-button color="basic" class="px-2 mx-1"
                (click)="onChangeViewAtras()"
        >
            {{'general.atras' | translate}}
        </button>
        <button mat-raised-button color="basic" class="px-2 mx-1"
                (click)="onChangeViewSiguiente()"
        >
            {{'general.siguiente' | translate}}
        </button>
    </div>

    <!-- VIEW DOCU VEGETAL --->
    <ng-template #docuVegetal>
        <mat-card class="mb-2 card shadow">
            <mat-card-title>
                {{'general.docuAnexa' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="mb-4">
                    <p> {{'OPERADORES.docTitularidadParcelas' | translate}}
                        {{formDocumentacion.get('prodVegetal.docTitularitat').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodVegetal.docTitularitat')?.value"
                            (fileDrop)="onFileDrop($event, 'prodVegetal.docTitularitat')"
                            (fileDeleted)="onFileDeleted('prodVegetal.docTitularitat')">
                    </operadores-attachments>
                </div>

                <div class="mb-4">
                    <p> {{'OPERADORES.docAnnex1' | translate}}
                        {{formDocumentacion.get('prodVegetal.docAnnex1').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodVegetal.docAnnex1')?.value"
                            (fileDrop)="onFileDrop($event, 'prodVegetal.docAnnex1')"
                            (fileDeleted)="onFileDeleted('prodVegetal.docAnnex1')">
                    </operadores-attachments>
                </div>

                <div class="mb-4">
                    <p> {{'OPERADORES.docAnnex2' | translate}}
                        {{formDocumentacion.get('prodVegetal.docAnnex2').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodVegetal.docAnnex2')?.value"
                            (fileDrop)="onFileDrop($event, 'prodVegetal.docAnnex2')"
                            (fileDeleted)="onFileDeleted('prodVegetal.docAnnex2')">
                    </operadores-attachments>
                </div>

                <div class="mb-4">
                    <p> {{'OPERADORES.docAnnex3' | translate}}
                        {{formDocumentacion.get('prodVegetal.docAnnex3').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodVegetal.docAnnex3')?.value"
                            (fileDrop)="onFileDrop($event, 'prodVegetal.docAnnex3')"
                            (fileDeleted)="onFileDeleted('prodVegetal.docAnnex3')">
                    </operadores-attachments>
                </div>

                <div class="mb-4">
                    <p> {{'OPERADORES.docAnnex4' | translate}}
                        {{formDocumentacion.get('prodVegetal.docAnnex4').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodVegetal.docAnnex4')?.value"
                            (fileDrop)="onFileDrop($event, 'prodVegetal.docAnnex4')"
                            (fileDeleted)="onFileDeleted('prodVegetal.docAnnex4')">
                    </operadores-attachments>
                </div>

                <div class="mb-4">
                    <p> {{'OPERADORES.docAnnex5' | translate}}
                        {{formDocumentacion.get('prodVegetal.docAnnex5').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodVegetal.docAnnex5')?.value"
                            (fileDrop)="onFileDrop($event, 'prodVegetal.docAnnex5')"
                            (fileDeleted)="onFileDeleted('prodVegetal.docAnnex5')">
                    </operadores-attachments>
                </div>

                <div class="mb-4">
                    <p> {{'OPERADORES.docAnnex6' | translate}}
                        {{formDocumentacion.get('prodVegetal.docAnnex6').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodVegetal.docAnnex6')?.value"
                            (fileDrop)="onFileDrop($event, 'prodVegetal.docAnnex6')"
                            (fileDeleted)="onFileDeleted('prodVegetal.docAnnex6')">
                    </operadores-attachments>
                </div>

                <div class="mb-4">
                    <p> {{'OPERADORES.docAnnex7' | translate}}
                        {{formDocumentacion.get('prodVegetal.docAnnex7').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodVegetal.docAnnex7')?.value"
                            (fileDrop)="onFileDrop($event, 'prodVegetal.docAnnex7')"
                            (fileDeleted)="onFileDeleted('prodVegetal.docAnnex7')">
                    </operadores-attachments>
                </div>

                <div class="mb-4">
                    <p>{{'OPERADORES.docAnnex8' | translate}}
                        {{formDocumentacion.get('prodVegetal.docAnnex8').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodVegetal.docAnnex8')?.value"
                            (fileDrop)="onFileDrop($event, 'prodVegetal.docAnnex8')"
                            (fileDeleted)="onFileDeleted('prodVegetal.docAnnex8')">
                    </operadores-attachments>
                </div>

                <div class="mb-4">
                    <p>{{'OPERADORES.docModelQuadCamp' | translate}}
                        {{formDocumentacion.get('prodVegetal.docModelQuadCamp').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodVegetal.docModelQuadCamp')?.value"
                            (fileDrop)="onFileDrop($event, 'prodVegetal.docModelQuadCamp')"
                            (fileDeleted)="onFileDeleted('prodVegetal.docModelQuadCamp')">
                    </operadores-attachments>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-template>

    <!-- VIEW DOCU RAMADERA --->
    <ng-template #docuRamadera>
        <mat-card class="mb-2 card shadow">
            <mat-card-title>
                {{'general.docuAnexa' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="mb-4">
                    <p> {{'OPERADORES.docPlanesAlojamiento' | translate}}
                        {{formDocumentacion.get('prodRamadera.docPlanesAlojamiento').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodRamadera.docPlanesAlojamiento')?.value"
                            (fileDrop)="onFileDrop($event, 'prodRamadera.docPlanesAlojamiento')"
                            (fileDeleted)="onFileDeleted('prodRamadera.docPlanesAlojamiento')">
                    </operadores-attachments>
                </div>

                <div class="mb-4">
                    <p> {{'OPERADORES.docHojaDatosExpl' | translate}}
                        {{formDocumentacion.get('prodRamadera.docHojaDatosExpl').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodRamadera.docHojaDatosExpl')?.value"
                            (fileDrop)="onFileDrop($event, 'prodRamadera.docHojaDatosExpl')"
                            (fileDeleted)="onFileDeleted('prodRamadera.docHojaDatosExpl')">
                    </operadores-attachments>
                </div>

                <div class="mb-4">
                    <p> {{'OPERADORES.docFichaTecnicaProdLimpieza' | translate}}
                        {{formDocumentacion.get('prodRamadera.docFichaTecnicaProdLimpieza').validator ? ' *' : ''}}</p>
                    <operadores-attachments
                            [file]="formDocumentacion.get('prodRamadera.docFichaTecnicaProdLimpieza')?.value"
                            (fileDrop)="onFileDrop($event, 'prodRamadera.docFichaTecnicaProdLimpieza')"
                            (fileDeleted)="onFileDeleted('prodRamadera.docFichaTecnicaProdLimpieza')">
                    </operadores-attachments>
                </div>

                <ng-container *ngIf="true; else docuRamApicola">
                    <div class="mb-4">
                        <p> {{'OPERADORES.docDescripciones' | translate}}
                            {{formDocumentacion.get('prodRamadera.docDescripciones').validator ? ' *' : ''}}</p>
                        <operadores-attachments
                                [file]="formDocumentacion.get('prodRamadera.docDescripciones')?.value"
                                (fileDrop)="onFileDrop($event, 'prodRamadera.docDescripciones')"
                                (fileDeleted)="onFileDeleted('prodRamadera.docDescripciones')">
                        </operadores-attachments>
                    </div>

                    <div class="mb-4">
                        <p> {{'OPERADORES.docDescFems' | translate}}
                            {{formDocumentacion.get('prodRamadera.docDescFems').validator ? ' *' : ''}}</p>
                        <operadores-attachments
                                [file]="formDocumentacion.get('prodRamadera.docDescFems')?.value"
                                (fileDrop)="onFileDrop($event,'prodRamadera.docDescFems')"
                                (fileDeleted)="onFileDeleted('prodRamadera.docDescFems')">
                        </operadores-attachments>
                    </div>

                    <div class="mb-4">
                        <p> {{'OPERADORES.docModelQuadernExplotacio' | translate}}
                            {{formDocumentacion.get('prodRamadera.docModelQuadernExplotacio').validator ? ' *' : ''}}</p>
                        <operadores-attachments
                                [file]="formDocumentacion.get('prodRamadera.docModelQuadernExplotacio')?.value"
                                (fileDrop)="onFileDrop($event, 'prodRamadera.docModelQuadernExplotacio')"
                                (fileDeleted)="onFileDeleted('prodRamadera.docModelQuadernExplotacio')">
                        </operadores-attachments>
                    </div>

                    <div class="mb-4">
                        <p> {{'OPERADORES.docMemHerbivors' | translate}}
                            {{formDocumentacion.get('prodRamadera.docMemHerbivors').validator ? ' *' : ''}}</p>
                        <operadores-attachments
                                [file]="formDocumentacion.get('prodRamadera.docMemHerbivors')?.value"
                                (fileDrop)="onFileDrop($event, 'prodRamadera.docMemHerbivors')"
                                (fileDeleted)="onFileDeleted('prodRamadera.docMemHerbivors')">
                        </operadores-attachments>
                    </div>

                    <div class="mb-4">
                        <p> {{'OPERADORES.docMemAvicola' | translate}}
                            {{formDocumentacion.get('prodRamadera.docMemAvicola').validator ? ' *' : ''}}</p>
                        <operadores-attachments
                                [file]="formDocumentacion.get('prodRamadera.docMemAvicola')?.value"
                                (fileDrop)="onFileDrop($event, 'prodRamadera.docMemAvicola')"
                                (fileDeleted)="onFileDeleted('prodRamadera.docMemAvicola')">
                        </operadores-attachments>
                    </div>

                    <div class="mb-4">
                        <p> {{'OPERADORES.docMemPorcina' | translate}}
                            {{formDocumentacion.get('prodRamadera.docMemPorcina').validator ? ' *' : ''}}</p>
                        <operadores-attachments
                                [file]="formDocumentacion.get('prodRamadera.docMemPorcina')?.value"
                                (fileDrop)="onFileDrop($event, 'prodRamadera.docMemPorcina')"
                                (fileDeleted)="onFileDeleted('prodRamadera.docMemPorcina')">
                        </operadores-attachments>
                    </div>

                    <div class="mb-4">
                        <p> {{'OPERADORES.docHelicicola' | translate}}
                            {{formDocumentacion.get('prodRamadera.docHelicicola').validator ? ' *' : ''}}</p>
                        <operadores-attachments
                                [file]="formDocumentacion.get('prodRamadera.docHelicicola')?.value"
                                (fileDrop)="onFileDrop($event,'prodRamadera.docHelicicola')"
                                (fileDeleted)="onFileDeleted('prodRamadera.docHelicicola')">
                        </operadores-attachments>
                    </div>
                </ng-container>

                <ng-template #docuRamApicola>
                    <div class="mb-4">
                        <p> {{'OPERADORES.docOrtofotomapa' | translate}} </p>
                        <operadores-attachments
                                (fileDrop)="onFileDrop($event, 'prodRamadera.docOrtofotomapa')">
                        </operadores-attachments>
                    </div>

                    <div class="mb-4">
                        <p> {{'OPERADORES.docPlanolUbiArnes' | translate}} </p>
                        <operadores-attachments
                                (fileDrop)="onFileDrop($event, 'prodRamadera.docPlanolUbiArnes')">
                        </operadores-attachments>
                    </div>

                    <div class="mb-4">
                        <p> {{'OPERADORES.docTitularitatArnes' | translate}} </p>
                        <operadores-attachments
                                (fileDrop)="onFileDrop($event, 'prodRamadera.docTitularitatArnes')">
                        </operadores-attachments>
                    </div>

                    <div class="mb-4">
                        <p> {{'OPERADORES.docProgramaRenovacioCera' | translate}} </p>
                        <operadores-attachments
                                (fileDrop)="onFileDrop($event, 'prodRamadera.docProgramaRenovacioCera')">
                        </operadores-attachments>
                    </div>

                    <div class="mb-4">
                        <p> {{'OPERADORES.docPlaConversió' | translate}} </p>
                        <operadores-attachments
                                (fileDrop)="onFileDrop($event, 'prodRamadera.docPlaConversió')">
                        </operadores-attachments>
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>
    </ng-template>
</form>
