import {HttpService} from "../core/http.service";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../components/toast.service";
import {Observable, tap} from "rxjs";
import {AltaInscrito, AltaInscritoEstado} from "../../../model/operadores/altaInscrito";
import {AltaAlmacen} from "../../../model/operadores/altaAlmacen";
import {Pageable} from "../../../model/core/pageable";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class AltaInscritoHttpService extends HttpService {

    private BASE = `solicitud-inscrito`;
    private BASE_ID = (idSolicitudInscrito: string) => `${this.BASE}/${idSolicitudInscrito}`;
    private ALTA_INSCRITO_ESTADOS = `${this.BASE}/estados`;
    private ALMACEN_LIST = (idSolicitudInscrito: string) => `${this.BASE}/almacen-list/${idSolicitudInscrito}`;
    private ALMACEN = (idAltaAlmacen: number) => `${this.BASE}/almacen/${idAltaAlmacen}`;
    private ALMACEN_CREATE = `${this.BASE}/almacen`;

    constructor(http: HttpClient,
                toastSvc: ToastService,
                private translateSvc: TranslateService) {
        super(http, toastSvc);
    }

    // ALTA INSCRITO

    public getAltaInscrito(idSolicitudInscrito: string): Observable<AltaInscrito> {
        return this.get(this.BASE_ID(idSolicitudInscrito));
    }

    public getAltaInscritoEstados(): Observable<AltaInscritoEstado[]> {
        return this.get(this.ALTA_INSCRITO_ESTADOS)
    }

    public updateAltaInscrito(idSolicitudInscrito: string, altaInscrito: AltaInscrito): Observable<AltaInscrito> {
        return this.put(this.BASE_ID(idSolicitudInscrito), altaInscrito);
    }

    public enviarAltaInscrito(): Observable<any> {
        return this.post(this.BASE);
    }

    // ALTA ALMACEN

    public getAltaAlmacenList(idSolicitudInscrito: string, page: number | null = null, filtros: object = {}): Observable<Pageable<AltaAlmacen>> {
        let httpParams = this.objectToHttpParams(filtros);
        if (page) {
            httpParams = httpParams.set("page", page - 1);
        }
        return this.get(this.ALMACEN_LIST(idSolicitudInscrito), httpParams)
            .pipe(tap(page => page.content = page.content.map(item => Object.assign(new AltaAlmacen(), item))));
    }

    public getAltaAlmacen(idAltaAlmacen: number): Observable<AltaAlmacen> {
        return this.get(this.ALMACEN(idAltaAlmacen));
    }

    public createAltaAlmacen(altaAlmacen: AltaAlmacen){
        return this.post(this.ALMACEN_CREATE, altaAlmacen)
            .pipe(
                tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("successes.createAlmacenOk")))
            );
    }

    public updateAltaAlmacen(idAltaAlmacen: number, altaAlmacen: AltaAlmacen): Observable<any> {
        return this.put(this.ALMACEN(idAltaAlmacen), altaAlmacen)
            .pipe(
                tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("successes.editAlmacenOk")))
            );
    }

    public deleteAltaAlmacen(idAltaAlmacen: number){
        return this.delete(this.ALMACEN(idAltaAlmacen))
            .pipe(
                tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("successes.deleteAlmacenOk")))
            );
    }

}
