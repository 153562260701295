import {BaseObj} from "../core/baseObj";

export class Registro extends BaseObj {

    idInscrito!: number;
    numOrdenInstalacion!: number;
    numOrdenRegistro!: number;
    codigoRegistro: string = "";
    descripcionRegistro: string = "";
    numRegistro: string = "";
    actividadAutorizada: string = "";
    fechaCaducidad: Date = new Date();
    usuario: string = "";

    constructor(json?: any) {
        super(json);
    }

}