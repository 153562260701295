<div *ngIf="producto">
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div *ngIf="producto.idSolicitudProducto; else titleNuevo">
            {{'general.edicion' | translate}} "{{producto.nombreComercial}}"
        </div>
        <ng-template #titleNuevo>
            {{'ETIQUETAS.addProducto' | translate}}
        </ng-template>

        <div class="clickable" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <hr/>
    <form [formGroup]="formGroup" class="col-12" (submit)="onSubmit()">
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <app-input-text
                        formControlName="nombreComercial"
                        [label]="'ETIQUETAS.nombreComercial' | translate"
                        [campoObligatorio]="true"
                >
                </app-input-text>
            </div>

            <div class="col-md-4 col-sm-6">
                <app-input-autocomplete
                        formControlName="idProducto"
                        [observable]="productoSvc.getProductosAutocomplete()"
                        [label]="'ETIQUETAS.producto' | translate"
                        [campoObligatorio]="true"
                        [isDisabled]="esVisualizacion"
                        [small]="'ETIQUETAS.productoHelp' | translate"
                >
                </app-input-autocomplete>
            </div>

            <div class="col-md-4 col-sm-6">
                <app-input-select
                        formControlName="idCategoriaEcologica"
                        [label]="'ETIQUETAS.categoriaEcologica' | translate"
                        [campoObligatorio]="true"
                        [options]="categorias | async | arrayToSelectItem:'idCategoriaEcologica':'descripcion'"
                >
                </app-input-select>
            </div>

            <div class="col-md-4 col-sm-6">
                <app-input-text
                        formControlName="marca"
                        [label]="'general.marca' | translate"
                        [campoObligatorio]="true"
                >
                </app-input-text>
            </div>

            <div class="col-md-4 col-sm-6">
                <app-input-select
                        formControlName="idEnvase"
                        [label]="'general.envase' | translate"
                        [campoObligatorio]="true"
                        [options]="envases | async | arrayToSelectItem: 'idEnvase': 'descripcion'"
                >
                </app-input-select>
            </div>

            <div class="col-md-4 col-sm-6">
                <app-input-text
                        formControlName="capacidad"
                        [label]="'general.capacidad' | translate"
                        [campoObligatorio]="true"
                        [small]="'general.capacidadHelp' | translate"
                >
                </app-input-text>
            </div>

            <div>
                <br/>
                <p>{{'general.camposObligatorios' | translate}}</p>
            </div>

            <div *ngIf="!esVisualizacion" class="col-12 p-2 mt-2 d-flex justify-content-end">
                <button type="submit" mat-raised-button color="primary" class="px-2"
                        [disabled]="formGroup.invalid">
                    {{'general.guardar' | translate}}
                </button>
            </div>
        </div>
    </form>
</div>
