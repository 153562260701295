<div>
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div>
            {{'general.albaranRequisitos' | translate}}
        </div>
    </h2>

    <hr/>

    <div [formGroup]="formGroup" class="mt-2 col-12">
        <div formArrayName="disclaims">
            <div *ngFor="let disclaim of disclaims().controls; let i=index">
                <div [formGroupName]="i" class="col-12">
                    <app-input-checkbox
                            formControlName="checked"
                            [label]="disclaim.get('text').value"
                    >
                    </app-input-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end">
        <button mat-raised-button color="white" class="mx-2" type="button" (click)="onCancel()">
            {{'general.cancelar' | translate}}
        </button>
        <button mat-raised-button color="primary" type="submit" (click)="onSubmit()"
                [disabled]="formGroup.invalid">
            D'acord
        </button>
    </div>
</div>


