<div class="col-12 mt-2 p-0">
    <div *ngIf="showLabel" class="d-flex align-content-start">
        <label *ngIf="label && !campoObligatorio" [for]="id" class="font-weight-bold">{{label}}</label>
        <label *ngIf="label && campoObligatorio" [for]="id" class="font-weight-bold">{{label}} *</label>
        <mat-icon *ngIf="helpText" placement="right" [ngbTooltip]="helpText" style="cursor: default;transform: scale(0.75)" class="pl-1">info</mat-icon>
    </div>
    <div class="">
        <input type="text" class="form-control col-12 m-0"
               [ngClass]="{'is-invalid': ngControl?.invalid && !ngControl.pristine && modificado}"
               [value]="value"
               [placeholder]="label"
               (keyup)="change($event)"
               [disabled]="isDisabled" autocomplete="off"
               [readonly]="readonly"
        />

        <small *ngIf="small" class="text-muted w-100 pt-1">{{ small }}</small>
        <div class="input-group-append">
            <span *ngIf="append" class="input-group-text">{{append}}</span>
            <button
                    (click)="copyText()"
                    *ngIf="copy"
                    class="btn btn-outline-secondary"
                    type="button" [ngbTooltip]="'general.copiado' | translate" triggers="click:blur">
                <mat-icon>file_copy</mat-icon>
            </button>
        </div>
        <div *ngIf="ngControl?.invalid && !ngControl.pristine" class="invalid-feedback">
            <span *ngIf="ngControl.errors['required']">
                {{'formErrors.obligatorio' | translate}}
            </span>
            <span *ngIf="ngControl.errors['checkNif']">
                {{'formErrors.nifFormat' | translate}}
            </span>
            <span *ngIf="ngControl.errors['maxlength']">
                {{'formErrors.longitudExcedida'
                | translate:{value: ngControl.errors['maxlength']['requiredLength']}
                }}
            </span>
            <span *ngIf="ngControl.errors['email']">
                {{'formErrors.emailIncorrecto' | translate}}
            </span>
            <span *ngIf="ngControl.errors['pattern']">
                {{'formErrors.formatoIncorrecto' | translate}}
            </span>
            <span *ngIf="ngControl.errors['CIFValid']">
                {{'formErrors.CIFFormat' | translate}}
            </span>
            <span *ngIf="ngControl.errors['emailListValid']">
                {{'formErrors.emailListIncorrecto' | translate}}
            </span>
            <span *ngIf="ngControl.errors['NIFValid']">
                {{'formErrors.nifFormat' | translate}}
            </span>
        </div>
    </div>
</div>
