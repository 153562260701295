import {Component, OnInit} from '@angular/core';
import {Granja} from "../../../../core/model/granja/granja";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {EMPTY, lastValueFrom, Observable} from "rxjs";
import {OrientacionProducto} from "../../../../core/model/granja/orientacionProducto";
import {GranjaService} from "../../../../core/services/http/granja.service";
import {Producto} from "../../../../core/model/autorizacion/producto";
import {ToastService} from "../../../../core/services/components/toast.service";
import {AuthService} from "../../../../core/services/security/auth.service";
import {Municipio} from "../../../../core/model/localizacion/municipio";

@Component({
  selector: 'app-operadores-detalle-granja',
  templateUrl: './operadores-detalle-granja.component.html',
  styleUrls: ['./operadores-detalle-granja.component.scss']
})
export class OperadoresDetalleGranjaComponent implements OnInit {

  protected idOperador: string;
  private numGranja: string;

  public granja: Granja;
  protected usuario;
  protected municipio: Municipio;

  protected title;
  protected titleParams;
  public action = 'view';

  protected municipioValido = false;
  protected numGranjaValido = true;
  protected textNumGranja = '';
  public formGroup: FormGroup;
  protected granjas: Observable<Granja[]> = EMPTY;
  protected orientacionesProductos: Observable<OrientacionProducto[]> = EMPTY;
  protected especiesGanado: Observable<Producto[]> = EMPTY;

  constructor(private operadorSvc: OperadoresHttpService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private granjaSvc: GranjaService,
              private toastSvc: ToastService,
              private authSvc: AuthService) {
 
    this.idOperador = activatedRoute.snapshot.params['idOperador'];
    this.numGranja = activatedRoute.snapshot.params['numGranja'];

    this.granja = new Granja();
    this.municipio = new Municipio();

    this.formGroup = new FormGroup({
      "numGranja": new FormControl('', [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9, A-Z]+$")]),
      "numOrden": new FormControl(null),
      "nombreGranja": new FormControl(null, Validators.maxLength(25)),
      "marcaOficial": new FormControl(null, Validators.maxLength(10)),
      "libroExplotacion": new FormControl(null, Validators.maxLength(10)),
      "codigoRega": new FormControl(null, Validators.maxLength(14)),
      "poligono": new FormControl('', [Validators.required, Validators.maxLength(20)]),
      "parcela": new FormControl('', [Validators.required, Validators.maxLength(35)]),
      "direccion": new FormControl('', [Validators.required, Validators.maxLength(60)]),
      "alternanciaAvicola": new FormControl(Validators.required),
      "superficieCubierta": new FormControl(null, Validators.pattern("^[0-9]{0,9}([.][0-9]{1})?$")),
      "capacidadMaxCubierta": new FormControl(null, Validators.pattern("[0-9]+?")),
      "superficiePatio": new FormControl(null, Validators.pattern("^[0-9]{0,9}([.][0-9]{0,1})?$")),
      "capacidadMaxPatio": new FormControl(null, Validators.pattern("[0-9]+?")),
      "capacidadDarp": new FormControl(null, Validators.pattern("[0-9]+?")),
      "observaciones": new FormControl(null),
      "idOrientacionProd": new FormControl('', [Validators.required, Validators.maxLength(6)]),
      "orientacionProd": new FormControl('', [Validators.required]),
      "idEspecieGanado": new FormControl('', Validators.required),
      "usuario": new FormControl('', Validators.required)
    });
  }

  async ngOnInit() {
    this.authSvc.fullName.subscribe(value => {
      this.usuario = value;
      this.formGroup.patchValue({'usuario': value})
    });


    this.activatedRoute.params.subscribe(params => {
      this.action = params['granjaAction'];

      if (this.action == 'edit') {
        this.formGroup.enable();
        this.formGroup.controls['numGranja'].disable();
      } else if(this.action == 'create'){
        this.formGroup.enable();
        this.title ='OPERADORES.granjas.titleAlta';
      } else {
        this.formGroup.disable();
      }
    });

    if (this.action != "create"){
      this.granja = await lastValueFrom(this.granjaSvc.getGranja(this.idOperador, this.numGranja));
      this.title = 'OPERADORES.granjas.titleDetall';
      this.titleParams = {'granja': this.granja?.nombreGranja ? this.granja.nombreGranja : this.granja.numGranja};
      this.formGroup.patchValue({
        ...this.granja,
        "usuario": this.usuario
      });
      this.municipio = this.granja.municipio;
    }

    this.granjas = this.granjaSvc.getGranjasList(this.idOperador);
    this.orientacionesProductos = this.granjaSvc.getOrientacionesProducto();
    this.especiesGanado = this.granjaSvc.getEspeciesGanadoByOrientacionProd(this.formGroup.controls['orientacionProd'].value)

    this.handleNumGranja();
    this.handleEspeciesGanado();
    this.handleInfoCompleted();

  }

  async onGuardarForm() {

    let orientacionProd = this.granja.orientacionProd;
    let especieGanado = this.granja.especieGanado;

    this.granja = this.formGroup.value;

    this.granja.municipio = this.municipio;
    this.granja.orientacionProd = orientacionProd;
    this.granja.especieGanado = especieGanado;

    if (this.action == 'edit'){
      this.granjaSvc.updateGranja(this.idOperador, this.numGranja, this.granja).subscribe(value => this.reloadCurrentRoute());

    } else if (this.action == 'create') {
      this.granjaSvc.createGranja(this.idOperador, this.granja).subscribe(value => {
        this.router.navigate(["../", this.granja.numGranja, 'edit'], {relativeTo: this.activatedRoute})
      });
    }

  }

  private handleNumGranja() {
    this.formGroup.get("numGranja")?.valueChanges.subscribe((numGranja) => {
      this.numGranjaValido = true;
      this.textNumGranja = '';
      if (numGranja) {
        this.granjas.subscribe(granjas =>{
          granjas.forEach(g => {
            if (this.action == 'create'){
              if (g.numGranja == numGranja) {
                this.numGranjaValido = false;
                this.textNumGranja = 'OPERADORES.granjas.numGranjaExists';
              } else if (!(/^([0-9, A-Z]+)$/.test(numGranja))){
                this.textNumGranja = 'OPERADORES.granjas.numGranjaFormat'
              }
            }

          });
        })
      }
    });
  }

  private handleEspeciesGanado() {
    this.formGroup.get("orientacionProd")?.valueChanges.subscribe((orientacionProducto) => {
      this.formGroup.get("idEspecieGanado")?.reset();
      this.formGroup.get("idEspecieGanado")?.disable();
      if (orientacionProducto) {
        this.especiesGanado = this.granjaSvc.getEspeciesGanadoByOrientacionProd(orientacionProducto);
        if(this.action != 'view') this.formGroup.get("idEspecieGanado")?.enable();

        this.orientacionesProductos.subscribe(ops =>{
          let orientacionProd = ops.find(op => op.descripcion == orientacionProducto);
          if (orientacionProd) {
            this.granja.idOrientacionProd = orientacionProd.idOrientacionProd;
            this.granja.orientacionProd = orientacionProd.descripcion;
            this.formGroup.patchValue({"idOrientacionProd": orientacionProd.idOrientacionProd})
          }
        })
      }
    });
  }

  private handleInfoCompleted() {
    this.formGroup.get("idEspecieGanado")?.valueChanges
        .subscribe(idEspecieGanado => {
          if(idEspecieGanado != null) {
            this.especiesGanado.subscribe(ganados => {
              const ganado = ganados.find(g => g.idProducto == this.formGroup.get("idEspecieGanado")?.value);
              if(ganado) {
                this.granja.idEspecieGanado = ganado.idProducto;
                this.granja.especieGanado = ganado.nombre;
              }
            })
          }
        })

  }

  protected showGanado(ganado: Producto): string {
    return ganado.nombre + ' (' + ganado.idProducto + ')';
  }
  protected validateForm() {
    return this.numGranjaValido && this.municipioValido && this.formGroup.valid;
  }

  //Routings
  onBack(){
    if(this.action == 'create')
      this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
    else
      this.router.navigate(["../../../"], {relativeTo: this.activatedRoute})
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
