<div>
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div>
            {{'ETIQUETAS.addProducto' | translate}}
        </div>

        <div class="clickable" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <div class="d-flex justify-content-center">
        <button mat-raised-button color="primary" class="m-2" (click)="dialogRef.close(actions.NUEVO)">
            {{'ETIQUETAS.crearNuevo' | translate}}
        </button>

        <button mat-raised-button color="primary" class="m-2" (click)="dialogRef.close(actions.ACTUALIZAR)">
            {{'ETIQUETAS.actualizarProducto' | translate}}
        </button>
    </div>
</div>
