<div *ngIf="producto">
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div>
            {{'general.editAdjuntos' | translate}} "{{producto.nombreComercial}}"
        </div>

        <div class="clickable" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <etiquetas-attachments
            [producto]="producto"
            [currentFiles]="adjuntos"
            [readOnly]="readOnly"
    >
    </etiquetas-attachments>

    <div *ngIf="!readOnly" class="col-12 p-2 mt-2 d-flex justify-content-end">
        <button mat-raised-button color="primary" class="px-2" (click)="dialogRef.close()">
            {{'general.guardar' | translate}}
        </button>
    </div>
</div>
