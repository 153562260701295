import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {EtiquetasBaseComponent, ModalEdicion} from "../etiquetas-base.component";
import {ModalService} from "../../../../core/services/components/modal.service";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {
  EditProductoHortofructiculaModalComponent
} from "../../modals/edit-producto-hortofructicula-modal/edit-producto-hortofructicula-modal.component";
import {
  AccionesProductos,
  AccionesProductosHortofruticolasTable,
  ProductosTableConfig
} from "../../etiquetas.config";
import {MatDialogRef} from "@angular/material/dialog";
import {firstValueFrom} from "rxjs";
import {TramiteGuard} from "../../guards/tramite.guard";
import {TramiteHortofruticolaGuard} from "../../guards/tramite-hortofruticola.guard";
import {TranslateService} from "@ngx-translate/core";
import {SolicitudProductoCompuesto} from "../../../../core/model/etiquetas/solicitud-producto-compuesto";
import {EditEtiquetaModalComponent} from "../../modals/edit-etiqueta-modal/edit-etiqueta-modal.component";
import {tap} from "rxjs/operators";
import {TipoAutorizacionEnum} from "../../../../core/services/http/operadores-http.service";
import {
  AddHortofruticolasModalComponent
} from "../../modals/add-hortofruticolas-modal/add-hortofruticolas-modal.component";
import {EtiquetasActionAdd} from "../../enum/EtiquetasActionAdd";

@Component({
  selector: 'app-etiquetas-hortofruticola-peticion',
  templateUrl: './etiquetas-hortofruticola-peticion.component.html',
  styleUrls: ['./etiquetas-hortofruticola-peticion.component.scss'],
  providers: [
    {provide: TramiteGuard, useClass: TramiteHortofruticolaGuard}
  ]
})
export class EtiquetasHortofruticolaPeticionComponent extends EtiquetasBaseComponent implements OnInit {

  protected tableConfig = ProductosTableConfig;
  protected tableAcciones = AccionesProductosHortofruticolasTable;

  protected file: File | null;

  constructor(injector: Injector,
              modalSvc: ModalService,
              tramiteGuard: TramiteGuard,
              private translateSvc: TranslateService,
              cdr: ChangeDetectorRef) {
    super(injector, modalSvc, tramiteGuard, cdr);

    this.etiquetasSvc.productos.subscribe(productos => {
      this.validateForm(productos)
    });

    this.file = null;
  }

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.solicitudSvc.getEtiqueta(this.etiquetasSvc.idSolicitud)
        .subscribe(item => {
          if(item != null) {
            this.etiquetasSvc.solicitud.etiqueta = item;
          }
        });

    this.closeModal()
  }

  closeModal() {

    const buttons = document.querySelectorAll('.col-7 button');
    buttons.forEach(button => {
      button.classList.add('blink-animation');
    });

    setTimeout(() => {
      buttons.forEach(button => {
        button.classList.remove('blink-animation');
      });
    }, 3000); // Adjust the delay as needed
  }


  override onActionTable($event: any): void {
    switch($event.action) {
      case AccionesProductos.EDIT_ETIQUETA:
        this.onEditarEtiqueta($event.item)
            .afterClosed().subscribe(_ => {
          this.solicitudProductoSvc.modificarFlagEtiqueta($event.item.idSolicitudProducto)
              .subscribe(_ => this.etiquetasSvc.cargarProductos());
          this.formIsValid = this.validateForm(this.productos);
        });
        break;
      default:
        super.onActionTable($event);
        break;
    }
  }

  override onHandleAddProducto(action: EtiquetasActionAdd): void {
    switch (action) {
      case EtiquetasActionAdd.DESCARGAR_PLANTILLA:
        this.onDownloadPlantilla();
        break;
      case EtiquetasActionAdd.CARREGAR_PRODUCTES:
        this.onAddHortofruticolasViaPlantilla();
        break;
      default:
        super.onHandleAddProducto(action);
        break;
    }
  }

  onEditarEtiqueta(producto: SolicitudProductoCompuesto): MatDialogRef<any> {
    return this.modalSvc.openDialog(EditEtiquetaModalComponent, {
      producto: producto,
      tipoAutorizacion: TipoAutorizacionEnum.ETIQUETATGE_HORTOFRUTICOLES,
      onLoad: () => this.solicitudProductoSvc.getEtiqueta(producto.idSolicitudProducto),
      onSave: (file: File) => this.solicitudProductoSvc.crearEtiqueta(producto.idSolicitudProducto, file)
          .pipe(tap(_ => producto.tieneEtiqueta = true)),
      onRemove: () => this.solicitudProductoSvc.eliminarEtiqueta(producto.idSolicitudProducto)
          .pipe(tap(_ => producto.tieneEtiqueta = false)),
      onDownload: () => this.solicitudProductoSvc.visualizarEtiqueta(producto.idSolicitudProducto)
    });
  }

  override onEditarProducto(producto: SolicitudProducto): MatDialogRef<ModalEdicion> {
    return this.modalSvc.openDialog(EditProductoHortofructiculaModalComponent, {producto});
  }

  override validateForm(productos: SolicitudProducto[]): boolean {
    return  productos.every(item => (
            (item.tieneEtiqueta || !item.requiereEtiqueta)
            && item.informacionCompleta
            && (!this.tramiteEsmena || (this.tramiteEsmena && item.infoPadreMod && (!item.requiereEtiqueta || item.etiquetaPadreMod) && item.adjuntosPadreMod))))
            && productos.length > 0;
  }

  override onHandleConfirmar() {
    this.etiquetasSvc.enviarSolicitud()
        .subscribe(_ => {
          window.self.close();
        })
  }

  onDownloadPlantilla(){
    this.solicitudProductoSvc.descargarPlantillaHortofructicola();
  }

  onAddHortofruticolasViaPlantilla(): MatDialogRef<ModalEdicion> {
    let modal = this.modalSvc.openDialog(AddHortofruticolasModalComponent);
    modal.afterClosed().subscribe(result => {
      this.formIsValid = this.validateForm(this.productos);
    });
    return modal;
  }

  override async onHandleEditarProducto(idSolicitudProducto: number | null, producto: SolicitudProducto) {
    if (!producto) return;
    if (idSolicitudProducto) {
      await firstValueFrom(this.solicitudProductoSvc.modificarProducto(idSolicitudProducto, producto));
    }
    else{
      await firstValueFrom(this.solicitudProductoSvc.crearProducto(producto));
    }
    await this.etiquetasSvc.cargarProductos();
  }

  onGuardar() {
    this.toastSvc.showSuccess(this.translateSvc.instant("ETIQUETAS.guardarSolicitudOk"));
  }

}
