<div class="mt-2 p-0">
    <mat-checkbox
            color="warn"
            [checked]="value"
            [disabled]="isDisabled"
            (change)="change($event)">
        <div [innerHTML]="label">
            <div *ngIf="label && !campoObligatorio">{{label}}</div>
            <div *ngIf="label && campoObligatorio">{{label}} *</div>
        </div>
    </mat-checkbox>
</div>
