import {Component, OnInit} from '@angular/core';
import {NavbarConfig} from "./configuration/NavbarConfig";
import {NavbarItem} from "./configuration/NavbarItem";
import {Location} from "@angular/common";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  menuItems = NavbarConfig;

  grupoActivo: string | null | undefined = null;

  constructor(public router: Location) {
    this.grupoActivo = this.router.path(false).split("/")[1];
  }

  ngOnInit(): void {

  }

  onChangeUrl(item: NavbarItem) {
    if(item.type == 'group') {

      if(this.grupoActivo == item.groupName)
        this.grupoActivo = null;
      else
        this.grupoActivo = item.groupName;

    }
  }
}
