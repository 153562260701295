import {Injectable} from '@angular/core';
import {BasePage} from "../../model/core/pages/basePage";
import {LoginPage} from "../../model/core/pages/login-page";

@Injectable({
  providedIn: 'root'
})
export class PageInfoService {

  private currentPage: BasePage;

  constructor() {
    this.currentPage = new LoginPage();
  }

  set page(page: BasePage) {
    this.currentPage = page;
  }

  get page() {
    return this.currentPage;
  }

  get isLogin() {
    return this.currentPage.isLogin;
  }

  get isApantallamiento() {
    return this.currentPage.isApantallamiento;
  }

  get hasNavBar() {
    return !this.isLogin && !this.isApantallamiento;
  }

}
