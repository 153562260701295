import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {SolicitudProductoCompuesto} from "../../../core/model/etiquetas/solicitud-producto-compuesto";
import {EtiquetasCompuestoService} from "../services/etiquetas-compuesto.service";
import {ModalService} from "../../../core/services/components/modal.service";
import {
  EditPreIngredientesModalComponent
} from "../modals/edit-pre-ingredientes-modal/edit-pre-ingredientes-modal.component";
import {EtiquetasBaseComponent, ModalEdicion} from "./etiquetas-base.component";
import {EditEtiquetaModalComponent} from "../modals/edit-etiqueta-modal/edit-etiqueta-modal.component";
import {tap} from "rxjs/operators";
import {AccionesProductos} from "../etiquetas.config";
import {MatDialogRef} from "@angular/material/dialog";
import {
  SolicitudProductoCompuestoHttpService
} from "../../../core/services/http/solicitud/solicitud-producto-compuesto.http.service";
import {SolicitudIngrediente} from "../../../core/model/etiquetas/solicitud-ingrediente";
import {TramiteGuard} from "../guards/tramite.guard";

@Component({
  template: '<div></div>',
})
export abstract class EtiquetasCompuestoBaseComponent extends EtiquetasBaseComponent implements OnInit {

  // Services
  protected etiquetasCompuestoSvc: EtiquetasCompuestoService;
  protected solicitudProductoCompuestoSvc: SolicitudProductoCompuestoHttpService;

  constructor(injector: Injector,
              modalSvc: ModalService,
              tramiteGuard: TramiteGuard,
              cdr: ChangeDetectorRef) {
    super(injector, modalSvc, tramiteGuard, cdr);
    this.etiquetasCompuestoSvc = injector.get(EtiquetasCompuestoService);
    this.solicitudProductoCompuestoSvc = injector.get(SolicitudProductoCompuestoHttpService);
  }

  override onActionTable($event: any): void {
    switch($event.action) {
      case AccionesProductos.EDIT_INGREDIENTE:
        this.onEditarIngredientes($event.item)
            .afterClosed().subscribe(result => this.onHandleEditarIngredientes($event.item, result));
        break;
      case AccionesProductos.EDIT_ETIQUETA:
        this.onEditarEtiqueta($event.item)
            .afterClosed().subscribe(_ => {
          this.solicitudProductoSvc.modificarFlagEtiqueta($event.item.idSolicitudProducto)
              .subscribe(_ => this.etiquetasSvc.cargarProductos());
          this.formIsValid = this.validateForm(this.productos);
        });
        break;
      default:
        super.onActionTable($event);
        break;
    }
  }

  onEditarEtiqueta(producto: SolicitudProductoCompuesto): MatDialogRef<any> {
    return this.modalSvc.openDialog(EditEtiquetaModalComponent, {
      producto: producto,
      tipoAutorizacion: null,
      onLoad: () => this.solicitudProductoSvc.getEtiqueta(producto.idSolicitudProducto),
      onSave: (file: File) => this.solicitudProductoSvc.crearEtiqueta(producto.idSolicitudProducto, file)
          .pipe(tap(_ => producto.tieneEtiqueta = true)),
      onRemove: () => this.solicitudProductoSvc.eliminarEtiqueta(producto.idSolicitudProducto)
          .pipe(tap(_ => producto.tieneEtiqueta = false)),
      onDownload: () => this.solicitudProductoSvc.visualizarEtiqueta(producto.idSolicitudProducto)
    });
  }

  onEditarIngredientes(producto: SolicitudProductoCompuesto): MatDialogRef<ModalEdicion> {
    return this.modalSvc.openDialog(EditPreIngredientesModalComponent, {producto});
  }

  onHandleEditarIngredientes(producto: SolicitudProductoCompuesto, ingredientes: SolicitudIngrediente[]) {
    producto.ingredientesCorrectos = this.etiquetasCompuestoSvc.esPorcentajeCompleto(ingredientes)
      && ingredientes.every(item => item.esValido());
    this.formIsValid = this.validateForm(this.productos);

    this.solicitudProductoCompuestoSvc.modificarFlagIngredientes(producto.idSolicitudProducto)
        .subscribe(_ => this.etiquetasSvc.cargarProductos());
  }

}
