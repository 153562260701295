<h2 mat-dialog-title class="d-flex justify-content-between">
    <div *ngIf="ingrediente; else nuevoIngrediente">
        {{'OPERADORES.modificarIngrediente' | translate}} "{{ingrediente.nombre}}"
    </div>
    <ng-template #nuevoIngrediente>
        {{'OPERADORES.crearIngrediente' | translate}}
    </ng-template>

    <div class="clickable" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </div>
</h2>

<form [formGroup]="formGroup" (submit)="onSubmit($event)" class="card shadow p-2 ">
    <div class="row">
        <div class="col-3">
            <app-input-text
                    formControlName="nombre"
                    [campoObligatorio]="true"
                    [label]="'general.nombre' | translate"
            >
            </app-input-text>
        </div>
        <div class="col-2">
            <app-input-number
                    formControlName="porcentaje"
                    [campoObligatorio]="true"
                    [label]="'general.porcentaje' | translate"
                    [max]="porcentajeDisponible"
                    min="0"
                    [numerosDecimales]="3"
            >
            </app-input-number>
        </div>
        <div class="col-3">
            <app-input-select
                    formControlName="idQualificacionIngrediente"
                    [campoObligatorio]="true"
                    [label]="'ETIQUETAS.qualificacionIngrediente' | translate"
                    [options]="qualiIngredientes | async| arrayToSelectItem:'idQualificacionIngrediente':'descripcion'"
            >
            </app-input-select>
        </div>
        <div class="col-2">
            <app-input-select
                    formControlName="agrario"
                    [label]="'general.tipo' | translate"
                    [campoObligatorio]="true"
                    [options]="[
                        {id: 'true', valor: 'general.agrario' | translate},
                        {id: 'false', valor: 'general.noAgrario' | translate}
                    ]"
            >
            </app-input-select>
        </div>

        <div class="col-2">
            <app-input-select
                    formControlName="idOrigenMateria"
                    [label]="('ETIQUETAS.origenMateria' | translate) + '?'"
                    [options]="origenes | async | arrayToSelectItem:'idOrigenMateria':'descripcion'"
            >
            </app-input-select>
        </div>
    </div>

    <div class="row mt-3">
        <div class="d-flex justify-content-end">
            <button mat-raised-button color="primary" type="submit" [disabled]="formGroup.invalid">
                {{'general.guardar' | translate}}
            </button>
        </div>
    </div>
</form>