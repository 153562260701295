import {Injectable} from '@angular/core';
import {HttpService} from "../core/http.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ToastService} from "../../components/toast.service";
import {Observable, shareReplay} from "rxjs";
import {SolicitudIngrediente} from "../../../model/etiquetas/solicitud-ingrediente";
import {QualificacionIngrediente} from "../../../model/master/qualificacion-ingrediente";
import {OrigenMateria} from "../../../model/master/origen-materia";

@Injectable({
  providedIn: 'root'
})
export class SolicitudIngredienteHttpService extends HttpService {

  private BASE = "solicitud-ingrediente";
  private BASE_ID = (idSolicitudIngrediente: number) => `${this.BASE}/${idSolicitudIngrediente}`;
  private QUALIFICACION = `${this.BASE}/qualificaciones`;
  private ORIGEN_MATERIA = `${this.BASE}/origen-materia`;

  constructor(http: HttpClient,
              toastSvc: ToastService) {
    super(http, toastSvc);
  }

  public getIngredientes(idSolicitudProducto: number): Observable<SolicitudIngrediente[]> {
    let params = new HttpParams();
    params = params.set("idSolicitudProducto", idSolicitudProducto);
    return this.get(this.BASE, params);
  }

  public crearIngrediente(ingrediente: SolicitudIngrediente): Observable<SolicitudIngrediente> {
    return this.post(this.BASE, ingrediente);
  }

  public modificarIngrediente(ingrediente: SolicitudIngrediente): Observable<SolicitudIngrediente> {
    return this.put(this.BASE, ingrediente);
  }

  public eliminar(idSolicitudIngrediente: number) {
    return this.delete(this.BASE_ID(idSolicitudIngrediente));
  }

  public getQualificaciones(): Observable<QualificacionIngrediente[]> {
    return this.get(this.QUALIFICACION)
        .pipe(shareReplay());
  }

  public getOrigenMateria(): Observable<OrigenMateria[]> {
    return this.get(this.ORIGEN_MATERIA)
        .pipe(shareReplay());
  }

}
