<div class="file-drop-zone" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
    <input #fileDropRef [multiple]="multiple" (change)="fileBrowseHandler($event.target)" type="file" id="formFile" [accept]="accept">
    <div *ngIf="multiple || (!multiple && files.length === 0)">
        <h3>{{ 'general.dropZone' | translate }}</h3>
    </div>
    <div *ngIf="!multiple && files.length > 0">
        <div *ngFor="let file of files">
            <h3>{{ file.name }} ({{ file.size }} bytes)</h3>
        </div>
    </div>
</div>



