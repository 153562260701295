import {Component, Injector, OnInit} from '@angular/core';
import {LoginPage} from "../../../../core/model/core/pages/login-page";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "../../../../core/services/http/login.service";
import {ToastService} from "../../../../core/services/components/toast.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends LoginPage implements OnInit {

  formGroup: FormGroup;

  loginSvc: LoginService;
  private toastSvc: ToastService;
  private translateSvc: TranslateService
  protected userNotFound = false;

  constructor(injector: Injector) {
    super();

    this.loginSvc = injector.get(LoginService);
    this.toastSvc = injector.get(ToastService);
    this.translateSvc = injector.get(TranslateService);

  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      'email': new FormControl('', [ Validators.required])
    });
  }

  onSubmit() {
    //Send to back and send mail with token
    this.loginSvc.enviarMailRestablecerPassword(this.formGroup.get('email')?.value)
        .subscribe(value => {

          if(value.code === 404){
            this.userNotFound = true;

          }
          else {
            this.toastSvc.showSuccess(this.translateSvc.instant("recuperarPw.mailEnviat"))
            this.userNotFound = false;
          }
        });
  }
}
