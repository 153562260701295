import {Injectable, Injector} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Operador} from "../../../core/model/operadores/operador";
import {OperadoresHttpService, TipoAutorizacionEnum} from "../../../core/services/http/operadores-http.service";
import {BehaviorSubject, EMPTY, firstValueFrom, Observable} from "rxjs";
import {ToastService} from "../../../core/services/components/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {SolicitudProducto} from "../../../core/model/etiquetas/solicitud-producto";
import {SolicitudHttpService} from "../../../core/services/http/solicitud/solicitud.http.service";
import {Solicitud, SolicitudEstado} from "../../../core/model/solicitud/solicitud";
import {AuthService, JWTDataExternal} from "../../../core/services/security/auth.service";
import {ECityClicHttpService} from "../../../core/services/http/solicitud/e-city-clic.http.service";
import {SolicitudAdjunto} from "../../../core/model/etiquetas/solicitud-adjunto";
import {StringUtils} from "../../../core/helper/string-distance";
import {RolEnum} from "../../../core/enum/rol.enum";

export enum CodigoTramite {
  ETIQUETATGE_DISTRIBUITS = "ETIQUETATGE_DISTRIBUITS",
  ETIQUETATGE_ELABORATS = "ETIQUETATGE_ELABORATS",
  ETIQUETATGE_HORTOFRUTICOLES = "ETIQUETATGE_HORTOFRUTICOLES",
  ETIQUETATGE_VINS = "ETIQUETATGE_VINS",
  ETIQUETATGE_IMPORTATS = "ETIQUETATGE_IMPORTATS"
}

export enum TipoTramite {
  ALTA = "ALTA",
  MODIFICACION = "MODIFICACION",
  BAJA = "BAJA"
}

@Injectable({
  providedIn: "root"
})
export class EtiquetasService {

  // Consts
  private LEVENSHTEIN_TH = 12;

  // Basic values
  protected _codigoOperador: string;
  protected _idSolicitud: string;
  protected _codigoTramite: string;
  protected _tipoTramite: string;
  protected _visualizar: boolean;
  protected _isValid: BehaviorSubject<boolean>;
  protected _isInitialized = false;

  // Data
  protected _operador: BehaviorSubject<Operador>;
  protected _productos: BehaviorSubject<SolicitudProducto[]>;
  protected _adjuntos: BehaviorSubject<SolicitudAdjunto[]>;
  private _solicitud: Solicitud;

  //Services
  private activatedRoute: ActivatedRoute;
  private router: Router;
  protected operadorSvc: OperadoresHttpService;
  protected toastSvc: ToastService;
  protected translateSvc: TranslateService;
  protected solicitudSvc: SolicitudHttpService;
  protected semicSvc: ECityClicHttpService;
  protected authSvc: AuthService;

  constructor(protected injector: Injector) {
    this.activatedRoute = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.operadorSvc = injector.get(OperadoresHttpService);
    this.toastSvc = injector.get(ToastService);
    this.translateSvc = injector.get(TranslateService);
    this.solicitudSvc = injector.get(SolicitudHttpService);
    this.semicSvc = injector.get(ECityClicHttpService);
    this.authSvc = injector.get(AuthService);

    this._operador = new BehaviorSubject<Operador>(new Operador());
    this._productos = new BehaviorSubject<SolicitudProducto[]>([]);
    this._adjuntos = new BehaviorSubject<SolicitudAdjunto[]>([]);
    this._isValid = new BehaviorSubject<boolean>(false);
  }

  public initTramite(jwtData: JWTDataExternal) {

    this._isInitialized = true;
    this._codigoOperador = jwtData.codigoOperador;
    this._idSolicitud = jwtData.idSolicitud;
    this._codigoTramite = jwtData.codigoTramite;
    this._tipoTramite = jwtData.tipoTramite;
    this._visualizar = jwtData.visualizar;

    if (this.hasRequiredParamaters) {
      this.cargarDatosVisualizacion();
    } else {
      this.handleErrorLoadingData();
    }
  }

  public async cargarDatosVisualizacion() {

      this.cargarOperador();

    try {
      this._solicitud = await firstValueFrom(this.solicitudSvc.getSolicitud(this._idSolicitud));
    } catch (e) {}

    this.cargarProductos().then();

    if (!this._solicitud || this._codigoTramite != this._solicitud.codigoTramite) {
      this.handleErrorLoadingData();
    } else {
      this.redirectToTramitePage(this._solicitud, this.codigoTramite);
    }
  }

  public enviarSolicitud() {
    return this.semicSvc.enviarSolicitud(this._idSolicitud);
  }

  public enviarConfirmacion() {
    return this.semicSvc.confirmarSolicitud(this._idSolicitud);
  }

  public enviarFinalizacion() {
    return this.semicSvc.finalizarSolicitud(this._idSolicitud);
  }

  public enviarEsmena() {
    return this.semicSvc.esmenaSolicitud(this._idSolicitud);
  }

  public enviarRevision() {
    return this.semicSvc.revisarSolicitud(this._idSolicitud);
  }

  public enviarDesistimiento() {
    return this.semicSvc.desistirSolicitud(this._idSolicitud);
  }

  private cargarOperador() {
    this.operadorSvc.getOperador(this._codigoOperador).subscribe({
      next: value => this._operador.next(value),
      error: _ => this.handleErrorLoadingData()
    });
  }

  public async cargarProductos() {
    try {
      let productos = await firstValueFrom(this.solicitudSvc.getProductos(this._idSolicitud));
      if (this.authSvc.hasRole(RolEnum.CCPAE_EXT) && ((this._solicitud.estado == SolicitudEstado.PEND_REVISIO_CC || this._solicitud.estado == SolicitudEstado.PEND_REVISIO_IE) || this._visualizar))  {
        productos = await firstValueFrom(this.solicitudSvc.getAllProductos(this._idSolicitud));
      }
      productos = this.calcularSemaforoNombre(productos);
      this._productos.next(productos);
    } catch (e) {
      this.handleErrorLoadingData();
    }
  }

  public async cargarProductosFinalizacion() {
    try {
      let productos = await firstValueFrom(this.solicitudSvc.getAllProductos(this._idSolicitud));
      productos = this.calcularSemaforoNombre(productos);
      this._productos.next(productos);
    } catch (e) {
      this.handleErrorLoadingData();
    }
  }

  public get hasRequiredParamaters() : boolean {

    let hasCommonRequirements = this._codigoTramite != null && this._idSolicitud != null;

    if (this.authSvc.hasRole(RolEnum.CCPAE_EXT)) {
      return hasCommonRequirements;
    } else if (this.authSvc.hasRole(RolEnum.OPERADOR_EXT)) {
      return (this._codigoOperador != null && hasCommonRequirements);
    }

    return false;
  }

  //Getters
  get solicitud(): Solicitud {
    return this._solicitud;
  }

  public get codigoOperador(): string {
    return this._codigoOperador;
  }

  public get operador(): Observable<Operador> {
    return this._operador.asObservable();
  }

  public get idSolicitud(): string {
    return this._idSolicitud;
  }

  public get productos(): Observable<SolicitudProducto[]> {
    return this._productos.asObservable();
  }

  public setProductos(productos: SolicitudProducto[]): void {
    this._productos.next(productos);
  }

  public get adjuntos(): Observable<SolicitudAdjunto[]> {
    return this._adjuntos.asObservable();
  }

  public get codigoTramite(): CodigoTramite {
    return this._codigoTramite as CodigoTramite;
  }

  public get esModificacion(): boolean {
    return this._tipoTramite == TipoTramite.MODIFICACION;
  }

  public get esVisualizacion(): boolean {
    return this._visualizar;
  }

  public get initialized(): boolean {
    return this._isInitialized;
  }

  // Navigation
  private redirectToTramitePage(solicitud: Solicitud, codigoTramite: string) {

    if (this.authSvc.hasRole(RolEnum.ADMIN))
      return;

    if (this.authSvc.hasRole(RolEnum.CCPAE_EXT) && this._visualizar) {
      this.navigateToVisualizacion(codigoTramite);
    } else if (this.authSvc.hasRole(RolEnum.OPERADOR_EXT) && solicitud.estado == SolicitudEstado.BORRADOR) {
      this.navigateToSolicitud(codigoTramite);
    } else if (this.authSvc.hasRole(RolEnum.CCPAE_EXT) && solicitud.estado == SolicitudEstado.PEND_GESTIO) {
      this.navigateToConfirmacion(codigoTramite);
    } else if (this.authSvc.hasRole(RolEnum.CCPAE_EXT) && solicitud.estado == SolicitudEstado.PEND_REVISIO_CC) {
      this.navigateToFinalizacion(codigoTramite);
    } else if (this.authSvc.hasRole(RolEnum.CCPAE_EXT) && solicitud.estado == SolicitudEstado.PEND_REVISIO_IE) {
      this.navigateToRevision(codigoTramite);
    }else {
      this.handleErrorLoadingData();
    }

  }

  private navigateToSolicitud(codigoTramite: string): void {

    switch (codigoTramite) {
      case CodigoTramite.ETIQUETATGE_ELABORATS:
        this.router.navigate(['etiquetas', 'elaborats']);
        break;
      case CodigoTramite.ETIQUETATGE_VINS:
        this.router.navigate(['etiquetas', 'vins-derivats']);
        break;
      case CodigoTramite.ETIQUETATGE_HORTOFRUTICOLES:
        this.router.navigate(['etiquetas', 'hortofruticoles']);
        break;
      case CodigoTramite.ETIQUETATGE_DISTRIBUITS:
        this.router.navigate(['etiquetas', 'distribuits'])
        break;
      case CodigoTramite.ETIQUETATGE_IMPORTATS:
        this.router.navigate(['etiquetas', 'importats'])
        break;
    }
  }

  private navigateToConfirmacion(codigoTramite: string): void {
    switch (codigoTramite) {
      case CodigoTramite.ETIQUETATGE_ELABORATS:
        this.router.navigate(['etiquetas', 'elaborats', 'confirmacio']);
        break;
      case CodigoTramite.ETIQUETATGE_VINS:
        this.router.navigate(['etiquetas', 'vins-derivats', 'confirmacio']);
        break;
      case CodigoTramite.ETIQUETATGE_HORTOFRUTICOLES:
        this.router.navigate(['etiquetas', 'hortofruticoles', 'confirmacio']);
        break;
      case CodigoTramite.ETIQUETATGE_DISTRIBUITS:
        this.router.navigate(['etiquetas', 'distribuits', 'confirmacio'])
        break;
      case CodigoTramite.ETIQUETATGE_IMPORTATS:
        this.router.navigate(['etiquetas', 'importats', 'confirmacio'])
        break;
    }
  }

  private navigateToFinalizacion(codigoTramite: string): void {
    switch (codigoTramite) {
      case CodigoTramite.ETIQUETATGE_ELABORATS:
        this.router.navigate(['etiquetas', 'elaborats', 'finalitzacio']);
        break;
      case CodigoTramite.ETIQUETATGE_VINS:
        this.router.navigate(['etiquetas', 'vins-derivats', 'finalitzacio']);
        break;
      case CodigoTramite.ETIQUETATGE_HORTOFRUTICOLES:
        this.router.navigate(['etiquetas', 'hortofruticoles', 'finalitzacio']);
        break;
      case CodigoTramite.ETIQUETATGE_DISTRIBUITS:
        this.router.navigate(['etiquetas', 'distribuits', 'finalitzacio'])
        break;
      case CodigoTramite.ETIQUETATGE_IMPORTATS:
        this.router.navigate(['etiquetas', 'importats', 'finalitzacio'])
        break;
    }
  }

  private navigateToRevision(codigoTramite: string): void {
    switch (codigoTramite) {
      case CodigoTramite.ETIQUETATGE_ELABORATS:
        this.router.navigate(['etiquetas', 'elaborats', 'revisio']);
        break;
      case CodigoTramite.ETIQUETATGE_VINS:
        this.router.navigate(['etiquetas', 'vins-derivats', 'revisio']);
        break;
      case CodigoTramite.ETIQUETATGE_HORTOFRUTICOLES:
        this.router.navigate(['etiquetas', 'hortofruticoles', 'revisio']);
        break;
      case CodigoTramite.ETIQUETATGE_DISTRIBUITS:
        this.router.navigate(['etiquetas', 'distribuits', 'revisio'])
        break;
      case CodigoTramite.ETIQUETATGE_IMPORTATS:
        this.router.navigate(['etiquetas', 'importats', 'revisio'])
        break;
    }
  }

  private navigateToVisualizacion(codigoTramite: string): void {
    switch (codigoTramite) {
      case CodigoTramite.ETIQUETATGE_ELABORATS:
        this.router.navigate(['etiquetas', 'elaborats', 'visualitzacio']);
        break;
      case CodigoTramite.ETIQUETATGE_VINS:
        this.router.navigate(['etiquetas', 'vins-derivats', 'visualitzacio']);
        break;
      case CodigoTramite.ETIQUETATGE_HORTOFRUTICOLES:
        this.router.navigate(['etiquetas', 'hortofruticoles', 'visualitzacio']);
        break;
      case CodigoTramite.ETIQUETATGE_DISTRIBUITS:
        this.router.navigate(['etiquetas', 'distribuits', 'visualitzacio'])
        break;
      case CodigoTramite.ETIQUETATGE_IMPORTATS:
        this.router.navigate(['etiquetas', 'importats', 'visualitzacio'])
        break;
    }
  }

  public handleErrorLoadingData(): Observable<any> {
    this.toastSvc.show(this.translateSvc.instant('ETIQUETAS.faltaInformacionTramite'));
    this.authSvc.logout();
    return EMPTY;
  }

  private calcularSemaforoNombre(productos: SolicitudProducto[]): SolicitudProducto[] {
    return productos.map(item => {
      const distance = StringUtils.LevenshteinSubminimal(item.nombreProducto.toLowerCase(), item.nombreComercial.toLowerCase());
      item.nombreCorrecto =  distance < this.LEVENSHTEIN_TH;
      return item;
    });
  }

  public convertCodigoTramiteToTipoAutorizacion(codigoTramite: CodigoTramite): TipoAutorizacionEnum {
    return TipoAutorizacionEnum[codigoTramite];
  }


}
