import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild
} from '@angular/core';
import {Autorizacion} from "../../../../core/model/autorizacion/autorizacion";
import {OperadoresHttpService, TipoAutorizacionEnum} from "../../../../core/services/http/operadores-http.service";
import {lastValueFrom, Observable} from "rxjs";
import {
  OperadoresAutorizacionAccionesEditConfig,
  OperadoresAutorizacionAccionesViewConfig,
  OperadoresAutorizacionTableConfig
} from "./operadores-autorizacion.table-config";
import {Pageable} from "../../../../core/model/core/pageable";
import {ActivatedRoute, Router} from "@angular/router";
import {Page} from "../../../../core/model/core/pages/page";
import {ModalService} from "../../../../core/services/components/modal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Envase} from "../../../../core/model/master/envase";
import {EnvasesHttpService} from "../../../../core/services/http/solicitud/envases.http.service";
import {NavigationService} from "../../../../core/services/navigation.service";
import {AutorizacionesHttpService} from "../../../../core/services/http/autorizaciones.http.service";
import {
  DisclaimerEliminarGenericoComponent
} from "../../modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";


@Component({
  selector: 'app-operadores-listado-autorizacion',
  templateUrl: './operadores-listado-autorizacion.component.html',
  styleUrls: ['./operadores-listado-autorizacion.component.scss'],
})
export class OperadoresListadoAutorizacionComponent extends Page implements OnInit {

  @ViewChild('formElement') formElement: ElementRef;

  protected modalSvc: ModalService;
  protected operadorSvc: OperadoresHttpService;
  protected envasesSvc: EnvasesHttpService;
  protected autorizacionesSvc: AutorizacionesHttpService;

  public views = TipoAutorizacionEnum;
  protected tipoActual = TipoAutorizacionEnum.ETIQUETATGE_IMPORTATS

  protected tableConfig = OperadoresAutorizacionTableConfig;
  protected tableAccionesView = OperadoresAutorizacionAccionesViewConfig;
  protected tableAccionesEdit = OperadoresAutorizacionAccionesEditConfig;

  protected data: Pageable<Autorizacion>;
  protected page = 1;
  protected idOperador: string;

  protected formGroup: FormGroup;
  protected envases: Observable<Envase[]>;
  protected filtros: object;
  protected operadorAction: any;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private navService: NavigationService,
              private cdr: ChangeDetectorRef,
              injector: Injector) {
    super();

    this.modalSvc = injector.get(ModalService)
    this.operadorSvc = injector.get(OperadoresHttpService);
    this.envasesSvc = injector.get(EnvasesHttpService);
    this.autorizacionesSvc = injector.get(AutorizacionesHttpService);

    this.data = new Pageable<Autorizacion>();
    this.page = 1;
    this.idOperador = this.activatedRoute.snapshot.params['idOperador'];

    this.formGroup = new FormGroup({
      'numOrdenAutorizacion': new FormControl(null, [Validators.pattern(/^[0-9]*$/)]),
      'nombreComercial': new FormControl(null),
      'marca': new FormControl(null),
      'fechaIni': new FormControl(null),
      'fechaFi': new FormControl(null)
    })
  }

  async ngOnInit() {

    this.handleActiveChipItem();
    this.envases = this.envasesSvc.getEnvases();

    if(this.tipoActual == TipoAutorizacionEnum.ETIQUETATGE_ELABORATS) {
      this.data = await lastValueFrom(this.autorizacionesSvc.getAutorizacionesElaboratsVinsAlta(this.idOperador, this.tipoActual, this.page, this.filtros));
    } else {
      this.data = await lastValueFrom(this.autorizacionesSvc.getAutorizaciones(this.idOperador, this.tipoActual, this.page, this.filtros));
    }

    this.activatedRoute.params.subscribe(params => {
      this.operadorAction = params['action'];
    })

  }

  public handleActiveChipItem() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['tipoActual']) {
        this.tipoActual = params['tipoActual'];
      }
    });
  }

  public async changeView(viewId: string) {
    this.page = 1;
    this.tipoActual = viewId as TipoAutorizacionEnum;

    if(this.tipoActual === TipoAutorizacionEnum.ETIQUETATGE_ELABORATS) {
      this.data = await lastValueFrom(this.autorizacionesSvc.getAutorizacionesElaboratsVinsAlta(this.idOperador, this.tipoActual, this.page));
    } else {
      this.data = await lastValueFrom(this.autorizacionesSvc.getAutorizaciones(this.idOperador, this.tipoActual, this.page));
    }
  }

  public async onPageChange(page: number) {
    this.page = page;
    if(this.tipoActual === TipoAutorizacionEnum.ETIQUETATGE_ELABORATS) {
      this.data = await lastValueFrom(this.autorizacionesSvc.getAutorizacionesElaboratsVinsAlta(this.idOperador, this.tipoActual, this.page, this.filtros));
    } else {
      this.data = await lastValueFrom(this.autorizacionesSvc.getAutorizaciones(this.idOperador, this.tipoActual, this.page, this.filtros));
    }
  }

  public onAction($event: any) {
    switch($event.action){
      case "download":
        this.downloadAutorizacion($event.item);
        break;
      case "view":
        this.viewAutorizacion($event.item);
        break;
      case "edit":
        this.editAutorizacion($event.item);
        break;
      case "baja":
        this.bajaAutorizacion($event.item);
        break;
    }
  }

  private viewAutorizacion(item) {
    this.router.navigate(['autoritzacio', item.numOrdenAutorizacion, 'view'], {
      relativeTo: this.activatedRoute,
      queryParams: {tipoActual: this.tipoActual}
    });
  }

  private editAutorizacion(item) {
    this.router.navigate(['autoritzacio', item.numOrdenAutorizacion, 'edit'], {
      relativeTo: this.activatedRoute,
      queryParams: {tipoActual: this.tipoActual}
    });
  }

  private bajaAutorizacion(item){
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'OPERADORES.autorizaciones.disclaimerBaja'}, {maxWidth: '30vw'})
        .afterClosed().subscribe(accepted => {
      if(accepted){
        this.autorizacionesSvc.bajaAutorizacion(this.idOperador, item.numOrdenAutorizacion)
            .subscribe(_ => this.onPageChange(this.page));
      }
    });
  }

  private async downloadAutorizacion(item) {
      await this.autorizacionesSvc.getFileName(this.idOperador, item).subscribe(res => {
        this.autorizacionesSvc.downloadAutorizacion(this.idOperador, item, res.filename).then(() => {
          setTimeout(() => {
            this.onPageChange(this.page)
          }, 3500);
        });
      })
  }

  public altaImportat() {
    this.router.navigate(['autoritzacio', 'create'],
        {relativeTo: this.activatedRoute})
  }
  
  //Filtres cerca

  protected onReset() {
    this.formGroup.reset();
    this.filtros = {};
    this.onSearch(this.filtros);
  }

  protected async onSearch(value) {
    this.page = 1;
    this.filtros = value;
    if(this.tipoActual === TipoAutorizacionEnum.ETIQUETATGE_ELABORATS) {
      this.data = await lastValueFrom(this.autorizacionesSvc.getAutorizacionesElaboratsVinsAlta(this.idOperador, this.tipoActual, this.page, this.filtros));
    } else {
      this.data = await lastValueFrom(this.autorizacionesSvc.getAutorizaciones(this.idOperador, this.tipoActual, this.page, this.filtros));
    }
  }

  ngAfterViewInit() {
    this.formElement.nativeElement.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        this.onSearch(this.formGroup.value);
        event.preventDefault();
      }
    });
  }

  onBack(){
    this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
  }
}
