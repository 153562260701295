import {Injectable} from "@angular/core";
import {HttpService} from "./core/http.service";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../components/toast.service";
import {Observable, shareReplay} from "rxjs";
import {Genero} from "../../model/operadores/genero";

@Injectable({
    providedIn: 'root'
})
export class GenerosService extends HttpService {

    protected BASE = "genero";

    constructor(http: HttpClient,
                toastSvc: ToastService) {
        super(http, toastSvc);
    }

    public getGeneros(): Observable<Genero[]> {
        return this.get(this.BASE)
            .pipe(shareReplay());
    }

}