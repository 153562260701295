import {Component, Injector, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['input-textarea.component.scss']
})
export class InputTextareaComponent implements OnInit, ControlValueAccessor {

  @Input() id : string = "noName";
  @Input() label = "";
  @Input() value = "";
  @Input() campoObligatorio: boolean = false;
  @Input() rows = 2;
  @Input() isDisabled: boolean = false;
  @Input() copy: boolean = false;

  modificado = false;
  onChange = (_:any) => { }
  onTouch = () => { }

  public ngControl!: NgControl;

  constructor(private inj: Injector,
              private clipboard: Clipboard) {
  }

  ngOnInit(): void {
    try {
      this.ngControl = this.inj.get(NgControl)
      this.ngControl.valueAccessor = this;
    } catch (e) {

    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  change($event: any) {
    this.modificado = true;
    this.value = $event.target.value.toUpperCase();
    this.onChange(this.value);
  }

  copyText() {
    this.clipboard.copy(this.value);
  }
}
