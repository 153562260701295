import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SolicitudProductoHttpService} from "../../../../core/services/http/solicitud/solicitud-producto.http.service";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {
  SolicitudProductoCompuestoHttpService
} from "../../../../core/services/http/solicitud/solicitud-producto-compuesto.http.service";
import {switchMap} from "rxjs";
import {Ubicaciones, UbicacionesEtiquetaje} from "../../../etiquetas/services/etiquetas-compuesto.service";
import {Ubicacion} from "../../../../core/model/master/ubicacion";
import {UbicacionesHttpService} from "../../../../core/services/http/solicitud/ubicaciones.http.service";
import {IngredientesTableConfig} from "../../../etiquetas/etiquetas.config";
import {Etiqueta} from "../../../../core/model/etiquetas/etiqueta";
import {TranslateService} from "@ngx-translate/core";
import {SolicitudHttpService} from "../../../../core/services/http/solicitud/solicitud.http.service";
import {Normativa} from "../../../../core/model/master/normativa";

@Component({
  selector: 'app-operadores-detalle-solicitud-producto',
  templateUrl: './operadores-detalle-solicitud-producto.component.html',
  styleUrls: ['./operadores-detalle-solicitud-producto.component.scss']
})
export class OperadoresDetalleSolicitudProductoComponent implements OnInit {

  private idOperador: string;
  private idSolicitudProducto: number;
  private idSolicitud: string;
  private idSolicitudProductoPadre: number;
  public estadoSolicitud: number;
  public producto: SolicitudProducto;
  public etiqueta: Etiqueta;
  public formGroup: FormGroup;
  protected idUbicacionElaboracion!: number;
  protected idUbicacionEnvase!: number;
  protected idUbicacionEtiqueta!: number;
  protected ubicacionEnum = Ubicaciones;
  protected ubicacionEtiquetajeEnum = UbicacionesEtiquetaje;
  protected ubicaciones: Ubicacion[];
  protected ubicacionesElaboracion: Ubicacion[];
  protected ubicacionesEnvasado: Ubicacion[];
  protected ubicacionesEtiquetaje: Ubicacion[];
  public ingredientesConfigTable = IngredientesTableConfig;
  public normativas: Normativa[] = [];

  protected literalesUbicaciones = {
    1: 'ETIQUETAS.instalaciones',
    2: 'ETIQUETAS.instalacionesSubcontratada'
  }

    private readonly NormativaEnum = {
        'UE': { idNormativa: 1, descripcion: 'UE' },
        'NOP': { idNormativa: 2, descripcion: 'NOP' }
    };

    constructor(private operadorSvc: OperadoresHttpService,
              private solicitudSvc: SolicitudHttpService,
              private solicitudProductoSvc: SolicitudProductoHttpService,
              private solicitudProductoCompuestoSvc: SolicitudProductoCompuestoHttpService,
              private ubicacionesSvc: UbicacionesHttpService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public translate: TranslateService) {
    this.idOperador = activatedRoute.snapshot.params['idOperador'];
    this.idSolicitud = activatedRoute.snapshot.params['idSolicitud'];
    this.idSolicitudProducto = activatedRoute.snapshot.params['idSolicitudProducto'];

    this.producto = new SolicitudProducto();

    this.formGroup = new FormGroup({
      "numOrdenAutorizacion": new FormControl(''),
      "nombreProducto": new FormControl(''),
      "nombreComercial": new FormControl(''),
      "categoriaEcologica": new FormControl(''),
      "marca": new FormControl(''),
      "tipoEnvase": new FormControl(''),
      "capacidad": new FormControl(''),
      "activo": new FormControl(''),
      "especificacion": new FormControl(''),
      "aditivos": new FormControl(''),
      "requiereEtiqueta": new FormControl(''),
      "usaNitratos": new FormControl(''),
      "auxiliaresTecnologicos": new FormControl(''),
      "observaciones": new FormControl(''),
      "elaboracion": new FormControl(''),
      "tecnicaTransformacion": new FormControl(''),
      "idUbicacionElaboracion": new FormControl(''),
      "idUbicacionEnvase": new FormControl(''),
      "idUbicacionEtiqueta": new FormControl(''),
      "textoElaboracion": new FormControl(''),
      "textoEnvase": new FormControl(''),
      "textoEtiquetaje": new FormControl(''),
      "etiqueta": new FormControl(new Etiqueta()),
      "tipoNormativa": new FormControl(''),
      "aceptado": new FormControl(''),
      "idSolicitudProductoPadre": new FormControl('')
    });

    this.formGroup.disable();
  }

 ngOnInit() {

    let obs = this.solicitudProductoSvc.getProducto(this.idSolicitudProducto)

    obs.pipe(
            switchMap(value=> {
              return value.esCompuesto ? this.solicitudProductoCompuestoSvc.getProducto(this.idSolicitudProducto) : obs
            })
    ).subscribe(value=>{
      this.producto=value;
      this.formGroup.patchValue(this.producto);
      this.idUbicacionEnvase= this.formGroup.controls['idUbicacionEnvase'].value;
      this.idUbicacionElaboracion= this.formGroup.controls['idUbicacionElaboracion'].value;
      this.idUbicacionEtiqueta= this.formGroup.controls['idUbicacionEtiqueta'].value;

      this.solicitudProductoSvc.getEtiqueta(this.idSolicitudProducto).subscribe(
              e =>{
                this.etiqueta = e;
              }
          )
    })

     this.ubicacionesSvc.getUbicacionesElaboracion().subscribe(value=> this.ubicacionesElaboracion=value)
     this.ubicacionesSvc.getUbicacionesEnvasado().subscribe(value=> this.ubicacionesEnvasado=value)
     this.ubicacionesSvc.getUbicacionesEtiquetaje().subscribe(value=> this.ubicacionesEtiquetaje=value)

     this.solicitudSvc.getSolicitud(this.idSolicitud)
         .subscribe({
             next: value => {
                 this.estadoSolicitud = value.estado;
             },
             error: (e) => this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
         });

     this.normativas = Object.values(this.NormativaEnum);
  }

  onDownloadEtiqueta() {

      this.solicitudProductoSvc.visualizarEtiqueta(this.idSolicitudProducto);

  }

  onDownloadAdjunto(idSolicitudAdjunto: number) {
    this.solicitudProductoSvc.downloadAdjunto(this.idSolicitudProducto, idSolicitudAdjunto);
  }

  // Botó de retorn
  onBack(){
    this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
  }

}
