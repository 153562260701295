import {Injectable} from '@angular/core';
import {TramiteGuard} from "./tramite.guard";
import {CodigoTramite, EtiquetasService} from "../services/etiquetas.service";

@Injectable({
  providedIn: 'root'
})
export class TramiteImportadosGuard implements TramiteGuard {

  constructor(private etiquetasSvc: EtiquetasService) {
  }

  canActivate() : boolean {
    return this.etiquetasSvc.codigoTramite == CodigoTramite.ETIQUETATGE_IMPORTATS;
  }
  
}
