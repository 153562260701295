import {Injectable} from '@angular/core';
import {HttpService} from "./core/http.service";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../components/toast.service";
import {map} from "rxjs/operators";
import {Producto} from "../../model/autorizacion/producto";
import {Observable} from "rxjs";
import {Pageable} from "../../model/core/pageable";

@Injectable({
  providedIn: 'root'
})
export class ProductoService extends HttpService {

  private BASE = "productos";
  private PRODUCTO = (id: string) => `${this.BASE}/${id}`;

  constructor(http: HttpClient,
              toastSvc: ToastService) {
    super(http, toastSvc);
  }

  public getProductos(page: number, filter: object): Observable<Pageable<Producto>> {
    let httpParams = this.objectToHttpParams(filter);
    httpParams.append("page", page);

    return this.get(this.BASE, httpParams);
  }

  public getProducto(idProducto: string): Observable<Producto> {
      return this.get(this.PRODUCTO(idProducto));
  }

  public getProductosAutocomplete() {
    return (nombre: string) => this.getProductos(0, {nombre})
        .pipe(map((page: Pageable<Producto>) => {
          return page.content.map((item: Producto) => ({
            "id": item.idProducto,
            "valor": item.nombre,
            "orientacionProducto": item.orientacionProducto.idOrientacionProd
          }))
        }));
  }

  public getProductosHortofruticolasAutocomplete() {
    return (nombre: string, orientacionProducto: string) => this.getProductos(0, {nombre, orientacionProducto : "540000"})
        .pipe(map((page: Pageable<Producto>) => {
          return page.content.map((item: Producto) => ({
            "id": item.idProducto,
            "valor": item.nombre,
            "orientacionProducto": item.orientacionProducto
          }))
        }));
  }

}
