import {BaseObj} from "../core/baseObj";
import {Municipio} from "../localizacion/municipio";

export class Instalacion extends BaseObj {

    idInscrito!: number;
    numOrdenInstalacion!: number;
    numCentro!: number;
    codigoActividad: string = "";
    descripcionActividad: string = "";
    municipio!: Municipio;
    direccion: string = "";
    observaciones: string = "";
    contadorRegistros: number;
    usuario: string = "";

    mixta: boolean;
    convencional: boolean;

    constructor(json?: any) {
        super(json);
    }

}