<form [formGroup]="formInscrit">
    <mat-card class="mb-2 card shadow">
        <mat-card-title>
            {{'OPERADORES.datosGenerales' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row d-flex align-content-start" >
                <div class="col-md-4 col-xl-2 order-1">
                    <app-input-text
                            [formControlName]="'numInscrito'"
                            [label]="'OPERADORES.numeroInscrito' | translate"
                    >
                    </app-input-text>
                </div>

                <div class="col-md-12 col-xl-6 order-2 order-md-4 order-xl-2">
                    <app-input-text
                            [formControlName]="'empresa'"
                            [label]="'general.empresa' | translate"
                    >

                    </app-input-text>
                </div>

                <div class="col-sm-6 col-md-4 col-xl-2 order-3 order-md-2 order-xl-3">
                    <app-input-text
                            [formControlName]="'cif'"
                            [label]="'general.cif' | translate"
                    >

                    </app-input-text>
                </div>

            </div>
            <div class="row mt-1">
                <div class="col-sm-3 col-lg-6 col-xl-2">
                    <app-input-text
                            [formControlName]="'nif'"
                            [label]="'general.nif' | translate"
                            [campoObligatorio]="true"
                    >
                    </app-input-text>
                </div>
                <div class="col-sm-2 col-lg-6 col-xl-2 order-xl-last" *ngIf="this.action !== 'create'">
                    <app-input-select
                            [formControlName]="'idGenero'"
                            [label]="'general.genero' | translate"
                            [options]="generos | async | arrayToSelectItem:'idGenero':'descripcion'"
                            [campoObligatorio]="true"
                    >

                    </app-input-select>
                </div>
                <div class="col-sm-7 col-lg-12 col-xl-3">
                    <app-input-text
                            [formControlName]="'nombre'"
                            [label]="'general.nombre' | translate"
                            [campoObligatorio]="true"
                    >

                    </app-input-text>
                </div>

                <div class="col-sm-6 col-lg-6 col-xl-3">
                    <app-input-text
                            [formControlName]="'primerApellido'"
                            [label]="'general.primerApellido' | translate"
                    >

                    </app-input-text>
                </div>
                <div class="col-sm-6 col-lg-6 col-xl-3">
                    <app-input-text
                            [formControlName]="'segundoApellido'"
                            [label]="'general.segundoApellido' | translate"
                    >

                    </app-input-text>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="mb-2 card shadow" *ngIf="this.action !== 'create'" >
        <mat-card-title>
            {{'general.direccionSocial' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row justify-content-start">
                <div class="col-12">
                    <app-input-text
                            [formControlName]="'direccionSocial'"
                            [label]="'general.direccion' | translate"
                    >
                    </app-input-text>
                </div>

            </div>

            <app-operadores-localizacion
                    [readonly]="!editing"
                    [municipioInput]="operador?.municipioSocial"
                    (formValid)="municipioSocialValid = $event"
            >
            </app-operadores-localizacion>
        </mat-card-content>
    </mat-card>

    <mat-card class="mb-2 card shadow" *ngIf="this.action !== 'create'">
        <mat-card-title>
            {{'general.direccionPostal' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row justify-content-start">
                <div class="col-12">
                    <app-input-text
                            [formControlName]="'direccionPostal'"
                            [label]="'general.direccion' | translate"
                    >
                    </app-input-text>
                </div>

            </div>

            <app-operadores-localizacion
                    [readonly]="!editing"
                    [municipioInput]="operador?.municipioPostal"
                    (formValid)="municipioPostalValid = $event"
            >
            </app-operadores-localizacion>
        </mat-card-content>
    </mat-card>

    <mat-card class="mb-2 card shadow">
        <mat-card-title>
            {{'OPERADORES.datosContacto' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col-sm-6 col-lg-3 col-xl-2">
                    <app-input-text
                            [formControlName]="'telefono1'"
                            [label]="'general.telefono1' | translate"
                    >

                    </app-input-text>
                </div>
                <div class="col-sm-6 col-lg-3 col-xl-2">
                    <app-input-text
                            [formControlName]="'telefono2'"
                            [label]="'general.telefono2' | translate"
                    >

                    </app-input-text>
                </div>
                <div class="col-sm-6 col-lg-3 col-xl-2">
                    <app-input-text
                            [formControlName]="'movil'"
                            [label]="'general.movil' | translate"
                    >

                    </app-input-text>
                </div>
                <div class="col-sm-6 col-lg-3 col-xl-2">
                    <app-input-text
                            [formControlName]="'fax'"
                            [label]="'general.fax' | translate"
                    >

                    </app-input-text>
                </div>
                <div class="col-sm-6 col-lg-3 col-xl-2">
                    <app-input-text
                            [formControlName]="'movilNot'"
                            [label]="'general.movilNot' | translate"
                    >
                    </app-input-text>
                </div>
                <div class="col-md-12 col-xl-6">
                    <app-input-text
                            [formControlName]="'web'"
                            [label]="'general.web' | translate"
                    >

                    </app-input-text>
                </div>
            </div>

            <div class="row">

                <div class="col-md-12 col-xl-6">
                    <app-input-text
                            [formControlName]="'email'"
                            [label]="'general.email' | translate"
                    >
                    </app-input-text>
                </div>
                <div class="col-md-12 col-xl-6">
                    <app-input-text
                            [formControlName]="'emailNot'"
                            [label]="'general.emailNot' | translate"
                    >

                    </app-input-text>
                </div>
            </div>
            <div class="row">

                <div class="col-md-12 col-lg-6">
                    <app-input-text
                            [formControlName]="'facebook'"
                            [label]="'general.facebook' | translate"
                    >

                    </app-input-text>
                </div>
                <div class="col-md-12 col-lg-6">
                    <app-input-text
                            [formControlName]="'twitter'"
                            [label]="'general.twitter' | translate"
                    >

                    </app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-input-checkbox
                            [formControlName]="'mostrarDatosDirectorio'"
                            [label]="'OPERADORES.permisoDatos' | translate"
                    >
                    </app-input-checkbox>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="mb-2 card shadow">
        <mat-card-title>
            {{'OPERADORES.datosProduccion' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col-md-4 col-sm-6">
                    <app-input-select
                            formControlName="actividadPrincipal"
                            [label]="'OPERADORES.actPrincipal' | translate"
                            [options]="actividadesPrincipales | async | arrayToSelectItem:'idActividadPrincipal':'descripcion'"
                    >
                    </app-input-select>
                </div>
                <br/>
            </div>

            <br/>
            <div class="row d-flex align-content-start" >
                <div class="col-sm-6 col-md-4 col-xl-2 order-4 order-md-3 order-xl-4">
                    <app-input-datepicker
                            [formControlName]="'fechaAlta'"
                            [label]="'OPERADORES.fechaAlta' | translate"
                    >
                    </app-input-datepicker>
                </div>
                <div class="col-sm-6 col-md-4 col-xl-2 order-4 order-md-3 order-xl-4">
                    <app-input-datepicker
                            [formControlName]="'fechaBaja'"
                            [label]="'OPERADORES.fechaBaja' | translate"
                    >
                    </app-input-datepicker>
                </div>
                <div class="col-sm-6 col-md-4 col-xl-2 order-4 order-md-3 order-xl-4">
                    <app-input-datepicker
                            [formControlName]="'fechaPrimeraCertificacion'"
                            [label]="'OPERADORES.fechaPrimeraCertificacion' | translate"
                    >
                    </app-input-datepicker>
                </div>
                <div class="col-sm-6 col-md-4 col-xl-2 order-4 order-md-3 order-xl-4">
                    <app-input-datepicker
                            [formControlName]="'fechaUltimaOperacion'"
                            [label]="'OPERADORES.fechaUltimaOperacion' | translate"
                    >
                    </app-input-datepicker>
                </div>
                <div class="col-sm-6 col-md-4 col-xl-2 order-4 order-md-3 order-xl-4">
                    <app-input-datepicker
                            [formControlName]="'fechaSuspension'"
                            [label]="'OPERADORES.fechaSuspension' | translate"
                    >
                    </app-input-datepicker>
                </div>
                <div class="col-sm-6 col-md-4 col-xl-2 order-4 order-md-3 order-xl-4">
                    <app-input-datepicker
                            [formControlName]="'fechaRetirada'"
                            [label]="'OPERADORES.fechaRetirada' | translate"
                    >
                    </app-input-datepicker>
                </div>
            </div>

            <br/>
            <div class="row">
                <app-input-checkbox
                        [label]="'OPERADORES.enviarRegoe' | translate"
                        formControlName="enviarRegoe"
                >
                </app-input-checkbox>
            </div>
            <div class="row">
                <app-input-checkbox
                        [label]="'OPERADORES.elaboradorPinsos' | translate"
                        formControlName="elaboradorPienso"
                >
                </app-input-checkbox>
            </div>
            <div class="row">
                <app-input-checkbox
                        [label]="'OPERADORES.facturacionElectronica' | translate"
                        formControlName="facturacionElectronica"
                >
                </app-input-checkbox>
            </div>
            <div class="row">
                <app-input-checkbox
                        [label]="'OPERADORES.maquila' | translate"
                        formControlName="maquilador"
                >
                </app-input-checkbox>
            </div>

            <br/>

            <div class="row">
                <div class="col-md-6 col-xl-3">
                    <p><strong>{{'OPERADORES.produccionMixta' | translate}}</strong></p>
                    <mat-radio-group>
                        <app-input-radio
                                [label]="'OPERADORES.instalaciones.cualiExcEcologica' | translate"
                                [isDisabled]="action == 'view'"
                                [checked]="!operador.produccionMixta"
                                (change)="onChangeProduccionMixta($event, 'eco')"
                                [name]="'produccionMixta'"
                                [id]="'eco'">
                        </app-input-radio>
                        <app-input-radio
                                [label]="'OPERADORES.instalaciones.cualiMixta' | translate"
                                [isDisabled]="action == 'view'"
                                [checked]="operador.produccionMixta"
                                (change)="onChangeProduccionMixta($event, 'produccionMixta')"
                                [name]="'produccionMixta'"
                                [id]="'produccionMixta'">
                        </app-input-radio>
                    </mat-radio-group>
                </div>
            </div>

            <br/>
            <div class="row">
                <app-input-textarea
                        [label]="'general.observaciones' | translate"
                        [rows]="4"
                        formControlName="observaciones"
                >
                </app-input-textarea>
            </div>
        </mat-card-content>
    </mat-card>

    <div *ngIf="editing" class="col-12 p-2 mt-2 d-flex justify-content-end">
        <button mat-raised-button color="basic" class="px-2" [disabled]="!checkDadesInscritValid()"
                (click)="onGuardarForm(formInscrit)"
        >
            {{'OPERADORES.modificarOperador.guardarDatosInscrito' | translate}}
        </button>
    </div>
</form>

<form [formGroup]="formDecreto">
    <mat-card class="mb-2 card shadow">
        <mat-card-title>
            {{'OPERADORES.datosDecreto' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col-md-2 col-sm-6">
                    <app-input-checkbox formControlName="produccioPrimaria" [label]="'Producció primària' | translate" style="font-weight: bold;" (valueChanged)="onSectionChange('produccioPrimaria')"></app-input-checkbox>
                    <div *ngIf="formDecreto.get('produccioPrimaria')?.value" [formGroup]="formDecreto.get('produccioPrimariaGroup')">
                        <br/>
                        <app-input-checkbox formControlName="produccioVegetal" [label]="'Producció vegetal' | translate" (valueChanged)="onFamilyChange('produccioVegetal')"></app-input-checkbox>
                        <div *ngIf="formDecreto.get('produccioPrimariaGroup.produccioVegetal')?.value" [formGroup]="formDecreto.get('produccioPrimariaGroup.produccioVegetalGroup')" class="px-4 text-muted">
                            <app-input-checkbox formControlName="llenyosos" [label]="'Llenyosos' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="extensius" [label]="'Extensius' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="horticoles" [label]="'Hortícoles' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="obtentorsMRV" [label]="'Obtentors MRV' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="pastures" [label]="'Pastures' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="forestals" [label]="'Forestals' | translate"></app-input-checkbox>
                        </div>
                        <br/>
                        <app-input-checkbox formControlName="produccioRamadera" [label]="'Producció ramadera' | translate" (valueChanged)="onFamilyChange('produccioRamadera')"></app-input-checkbox>
                        <div *ngIf="formDecreto.get('produccioPrimariaGroup.produccioRamadera')?.value" [formGroup]="formDecreto.get('produccioPrimariaGroup.produccioRamaderaGroup')" class=" px-4 text-muted">
                            <app-input-checkbox formControlName="herbivors" [label]="'Herbívors' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="porcs" [label]="'Porcs' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="avicola" [label]="'Avícola' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="apicola" [label]="'Apícola' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="helicicola" [label]="'Helicícola' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="insectes" [label]="'Insectes' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="conills" [label]="'Conills' | translate"></app-input-checkbox>
                        </div>
                        <br/>
                        <app-input-checkbox formControlName="recolleccioSilvestre" [label]="'Recol·lecció silvestre' | translate"></app-input-checkbox>
                        <br/>
                        <app-input-checkbox formControlName="produccioSal" [label]="'Producció de sal' | translate"></app-input-checkbox>
                        <br/>
                        <app-input-checkbox formControlName="aquicultura" [label]="'Aqüicultura' | translate" (valueChanged)="onFamilyChange('aquicultura')"></app-input-checkbox>
                        <div *ngIf="formDecreto.get('produccioPrimariaGroup.aquicultura')?.value" [formGroup]="formDecreto.get('produccioPrimariaGroup.aquiculturaGroup')" class="px-4 text-muted">
                            <app-input-checkbox formControlName="alguesFitoplancton" [label]="'Algues i fitoplàncton' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="moluscs" [label]="'Mol·luscs' | translate"></app-input-checkbox>
                            <app-input-checkbox formControlName="peixos" [label]="'Peixos' | translate"></app-input-checkbox>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-sm-6">
                    <app-input-checkbox formControlName="preparacio" [label]="'Preparació' | translate" style="font-weight: bold;"></app-input-checkbox>
                </div>
                <div class="col-md-2 col-sm-6">
                    <app-input-checkbox formControlName="importacioExportacio" [label]="'Importació i/o exportació' | translate" style="font-weight: bold;" (valueChanged)="onSectionChange('importacioExportacio')"></app-input-checkbox>
                    <div *ngIf="formDecreto.get('importacioExportacio')?.value" [formGroup]="formDecreto.get('importacioExportacioGroup')">
                        <br/>
                        <app-input-checkbox formControlName="importacio" [label]="'Importació' | translate"></app-input-checkbox>
                        <br/>
                        <app-input-checkbox formControlName="exportacio" [label]="'Exportació' | translate"></app-input-checkbox>
                    </div>
                </div>
                <div class="col-md-2 col-sm-6">
                    <app-input-checkbox formControlName="distribucioMajorista" [label]="'Distribució majorista' | translate" style="font-weight: bold;"></app-input-checkbox>
                </div>
                <div class="col-md-2 col-sm-6">
                    <app-input-checkbox formControlName="distribucioMinorista" [label]="'Distribució minorista' | translate" style="font-weight: bold;" (valueChanged)="onSectionChange('distribucioMinorista')"></app-input-checkbox>
                    <div *ngIf="formDecreto.get('distribucioMinorista')?.value" [formGroup]="formDecreto.get('distribucioMinoristaGroup')">
                        <br/>
                        <app-input-checkbox formControlName="certificatGran" [label]="'Certificat gran' | translate" (valueChanged)="onCertificatChange('certificatGran', $event)"></app-input-checkbox>
                        <br/>
                        <app-input-checkbox formControlName="certificatPetit" [label]="'Certificat petit' | translate" (valueChanged)="onCertificatChange('certificatPetit', $event)"></app-input-checkbox>
                        <br/>
                        <app-input-checkbox formControlName="noCertificatEximit" [label]="'No certificat, eximit' | translate" (valueChanged)="onCertificatChange('noCertificatEximit', $event)"></app-input-checkbox>
                    </div>
                </div>
                <div class="col-md-2 col-sm-6">
                    <app-input-checkbox formControlName="altresActivitats" [label]="'Altres activitats' | translate" style="font-weight: bold;"></app-input-checkbox>
                </div>
            </div>
            <br/>
            <div *ngIf="!formDecreto.valid && editing" class="text-danger">
                <p>* És necessari seleccionar almenys una de les seccions: <span style="font-weight: bold;">producció primària, preparació, importació i/o exportació, distribució majorista, distribució minorista, altres activitats.</span></p>
                <p>* Si has seleccionat la secció de <span style="font-weight: bold;">producció primària</span>, has de seleccionar almenys una de les seves famílies: <span style="font-weight: bold;">producció vegetal, producció ramadera, recol·lecció silvestre, producció de sal, aqüicultura.</span></p>
                <p>* Si has seleccionat la secció de <span style="font-weight: bold;">importació i/o exportació</span>, has de seleccionar almenys una de les seves famílies: <span style="font-weight: bold;">importació o exportació</span>.</p>
            </div>
        </mat-card-content>
    </mat-card>

    <div *ngIf="editing" class="col-12 p-2 mt-2 d-flex justify-content-end">
        <button mat-raised-button color="basic" class="px-2" [disabled]="!formDecreto.valid" (click)="onGuardarFormDecreto(formDecreto)">
            {{'OPERADORES.modificarOperador.guardarDatosDecreto' | translate}}
        </button>
    </div>
</form>

<button mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
    <mat-icon>arrow_back</mat-icon>
    {{"general.volver" | translate}}
</button>

