<div class="col-12 mt-2 p-0">
    <label *ngIf="label && !campoObligatorio" [for]="id" class="font-weight-bold pull-left">{{label}}</label>
    <label *ngIf="label && campoObligatorio" [for]="id" class="font-weight-bold pull-left">{{label}} *</label>
    <input type="password" class="form-control col-12 m-0" [ngClass]="{'is-invalid': ngControl?.invalid && modificado}"
           [value]="value"
           [placeholder]="label"
           (change)="change($event)"
           [disabled]="isDisabled" autocomplete="off"
           [readonly]="readonly"
    />
    <div *ngIf="ngControl?.invalid" class="invalid-feedback">
        <span *ngIf="ngControl.errors['required']">
            {{'formErrors.obligatorio' | translate}}
        </span>
        <span *ngIf="ngControl.errors['checkPasswords']">
            {{'formErrors.passwordEqual' | translate}}
        </span>
        <span *ngIf="ngControl.errors['pattern']">
            {{'formErrors.passwordPattern' | translate}}
        </span>
        <span *ngIf="ngControl.errors['maxlength']">
            {{'formErrors.longitudExcedida'
                | translate:{value: ngControl.errors['maxlength']['requiredLength']}
            }}
        </span>
    </div>
</div>
