<div>
    <div *ngIf="!readOnly" class="my-2">
        <div>
            <app-drop-file-zone
                (onFilesDrop)="onFileDrop($event)"
                [multiple]="true"
                [accept]="'.jpg,.jpeg,.png,.pdf'"
            >
            </app-drop-file-zone>
        </div>
    </div>

    <div *ngIf="files.length > 0" class="row">
        <div class="col-auto file-box" *ngFor="let file of files">
            <div *ngIf="file.isUploaded; else uploading">
                <div class="file-open d-flex align-items-center" data-toggle="tooltip" [title]="file.solicitudAdjunto.nombre"
                     (click)="onDownload(file.solicitudAdjunto.idSolicitudAdjunto)">
                    <mat-icon>file_present</mat-icon>
                    {{ (file.solicitudAdjunto.nombre | slice:0:24) +
                        (file.solicitudAdjunto.nombre.length > 24? '...' : '')}}
                </div>

                <div *ngIf="!readOnly" class="remove-file" (click)="onDelete(file.solicitudAdjunto.idSolicitudAdjunto)">
                    <mat-icon>close</mat-icon>
                </div>
            </div>

            <ng-template #uploading>
                <div class="d-flex align-items-center" data-toggle="tooltip" [title]="file.file.name">
                    <div class="loader-wrapper">
                        <div class="loader"></div>
                    </div>
                    {{ (file.file.name | slice:0:24) + (file.file.name.length > 24? '...' : '')}}
                </div>
            </ng-template>
        </div>
    </div>

</div>
