import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from '../../../core/services/components/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  show = false;
  private subscription: Subscription;
  constructor(private loaderService: LoaderService) {
    this.subscription = this.loaderService.loaderState
        .subscribe((state: LoaderState) => {
          this.show = state.show;
        });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

export interface LoaderState {
  show: boolean;
}
