<app-page-template
    [title]="title | translate: titleParams"
><form [formGroup]="formGroup">
    <mat-card class="card shadow">
        <mat-card-title>
            {{'OPERADORES.parcelas.titleCardDetall' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col">
                    <app-operadores-localizacion
                            [municipioInput]="municipio"
                            [readonly] = "parcelaAction == 'view'"
                            [campoObligatorio] = "parcelaAction != 'view'"
                            (formValid) = "municipioValido = $event"
                    >
                    </app-operadores-localizacion>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-3 col-xl-2">
                    <app-input-text
                            formControlName="poligono"
                            [label]="'OPERADORES.parcelas.poligono' | translate"
                            [campoObligatorio] = "parcelaAction != 'view'"
                    >
                    </app-input-text>
                </div>
                <div class="col-sm-6 col-md-3 col-xl-2">
                    <app-input-text
                            formControlName="parcela"
                            [label]="'OPERADORES.parcela' | translate"
                            [campoObligatorio] = "parcelaAction != 'view'"
                    >
                    </app-input-text>
                </div>
                <div class="col-sm-6 col-md-3 col-xl-2">
                    <app-input-text
                            formControlName="recinto"
                            [label]="'OPERADORES.parcelas.recinto' | translate"
                            [campoObligatorio] = "parcelaAction != 'view'"
                    >
                    </app-input-text>
                </div>
                <div class="col-sm-6 col-md-3 col-xl-2">
                    <app-input-text
                            formControlName="numPar"
                            [label]="'OPERADORES.parcelas.numPar' | translate"
                    >
                    </app-input-text>
                </div>
                <div class="col-sm-6 col-xl-2">
                    <app-input-text
                            formControlName="superficie"
                            [label]="'OPERADORES.parcelas.superficie' | translate"
                            [campoObligatorio] = "parcelaAction != 'view'"
                    >
                    </app-input-text>
                </div>
                <div class="col-sm-6 col-xl-2">
                    <app-input-text
                            formControlName="superficieNeta"
                            [label]="'OPERADORES.parcelas.superficieNeta' | translate"
                    >
                    </app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-xxl-4">
                    <app-input-select
                            formControlName="idCultivo"
                            [label]="'OPERADORES.parcelas.cultivo' | translate"
                            [campoObligatorio] = "parcelaAction != 'view'"
                            [options]="cultivos | async | arrayToSelectItem: 'idProducto': 'nombre'"
                    >
                    </app-input-select>
                </div>
                <div class="col-md-6 col-xxl-4">
                    <app-input-select
                            formControlName="idVariedad"
                            [label]="'OPERADORES.parcelas.variedad' | translate"
                            [options]="variedades | async | arrayToSelectItem: 'idVariedad': 'descripcion'"
                    >
                    </app-input-select>
                </div>
                <div class="col-md-6 col-xxl-2">
                    <app-input-text
                            formControlName="codigoVariedadSigCosecha"
                            [label]="'OPERADORES.parcelas.codigoVariedadSigCosecha' | translate"
                    >
                    </app-input-text>
                </div>
                <div class="col-md-6 col-xxl-2">
                    <app-input-text
                            formControlName="codigoProduccionSigCosecha"
                            [label]="'OPERADORES.parcelas.codigoProduccionSigCosecha' | translate"
                    >
                    </app-input-text>
                </div>
            </div>
                <div class="row mt-4 mb-4">
                    <div class="col-sm-6 col-md-4 col-xxl-3">
                        <app-input-datepicker
                                formControlName="fechaInicio"
                                [label]="'OPERADORES.parcelas.fechaInicio' | translate"
                                [campoObligatorio] = "parcelaAction != 'view'"
                        >
                        </app-input-datepicker>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xxl-3">
                        <app-input-datepicker
                                formControlName="fechaCualificacion"
                                [label]="'OPERADORES.parcelas.fechaCualificacion' | translate"
                                [campoObligatorio] = "parcelaAction != 'view'"
                        >
                        </app-input-datepicker>
                    </div>
                    <div class="col-md-4 col-xxl-2">
                        <app-input-select
                                formControlName="idCualificacion"
                                [label]="'OPERADORES.parcelas.cualificacion' | translate"
                                [campoObligatorio] = "parcelaAction != 'view'"
                                [options]="cualificaciones | async | arrayToSelectItem: 'idCualificacion': 'descripcion'"
                        >
                        </app-input-select>
                    </div>
                    <div class="col-lg-4">
                        <div class="row">
                            <app-input-checkbox
                                    formControlName="modificacionManual"
                                    [label]="'OPERADORES.parcelas.modificacionManual' | translate"
                            >
                            </app-input-checkbox>
                        </div>
                        <div class="row">
                            <app-input-checkbox
                                    formControlName="indicadorConversion"
                                    [label]="'OPERADORES.parcelas.indicadorConversion' | translate"
                            >
                            </app-input-checkbox>
                        </div>
                    </div>
                </div>

            <div class="row">
                <div class="col-sm-6 col-md-5 col-xl-3">
                    <app-input-text
                            formControlName="sigpac"
                            [label]="'OPERADORES.parcelas.sigpac' | translate"
                    >
                    </app-input-text>
                </div>
                <div class="col-sm-6 col-md-5 col-xl-3">
                    <app-input-text
                            formControlName="recSigpac"
                            [label]="'OPERADORES.parcelas.recSigpac' | translate"
                    >
                    </app-input-text>
                </div>
                <div class="col-sm-4 col-md-2 col-xl-2">
                    <app-input-text
                            formControlName="rvc"
                            [label]="'OPERADORES.parcelas.rvc' | translate"
                    >
                    </app-input-text>
                </div>
            </div>

            <div class="row">
                <app-input-textarea
                        formControlName="observaciones"
                        [label]="'general.observaciones' | translate"
                        [rows]="4"
                >
                </app-input-textarea>
            </div>

        </mat-card-content>

    </mat-card>
    <div *ngIf="parcelaAction != 'view'" class="col-12 p-2 mt-2 d-flex justify-content-end">
        <button mat-raised-button color="basic" class="px-2" [disabled]="!formGroup.valid"
                (click)="onGuardarForm()"
        >
            {{'OPERADORES.modificarOperador.guardarDatosParcela' | translate}}
        </button>
    </div>
    </form>

    <button mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
        <mat-icon>arrow_back</mat-icon>
        {{"general.volver" | translate}}
    </button>
</app-page-template>

