import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {EtiquetasActionAdd} from "../../enum/EtiquetasActionAdd";

@Component({
  templateUrl: './etiqueta-add-actualizar-modal.component.html',
  styleUrls: ['./etiqueta-add-actualizar-modal.component.scss']
})
export class EtiquetaAddActualizarModalComponent {

  protected actions = EtiquetasActionAdd;

  constructor(protected dialogRef: MatDialogRef<EtiquetaAddActualizarModalComponent>) { }

}
