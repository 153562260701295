import {Component, Inject, Injector, OnInit} from '@angular/core';
import {
  QualificacionIngredienteHttpService
} from "../../../../core/services/http/solicitud/qualificacion-ingrediente.http.service";
import {OrigenMateriaHttpService} from "../../../../core/services/http/solicitud/origen-materia.http.service";
import {EMPTY, Observable} from "rxjs";
import {QualificacionIngrediente} from "../../../../core/model/master/qualificacion-ingrediente";
import {OrigenMateria} from "../../../../core/model/master/origen-materia";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Autorizacion} from "../../../../core/model/autorizacion/autorizacion";
import {Ingrediente} from "../../../../core/model/autorizacion/ingrediente";
import {AuthService} from "../../../../core/services/security/auth.service";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-edit-ingrediente-autorizacion-modal',
  templateUrl: './edit-ingrediente-autorizacion-modal.component.html',
  styleUrls: ['./edit-ingrediente-autorizacion-modal.component.scss']
})
export class EditIngredienteAutorizacionModalComponent implements OnInit {

  //Services
  protected qualificacionIngredienteSvc: QualificacionIngredienteHttpService;
  protected origenMateriaSvc: OrigenMateriaHttpService;
  protected authSvc: AuthService;

  //Form values
  protected _porcentajeDisponible: number = 100;
  private noAgrario = false;
  private qualiConvencional = false;
  protected origenNoObligatorio = true;

  // Masters
  protected qualiIngredientes: Observable<QualificacionIngrediente[]> = EMPTY;
  protected origenes: Observable<OrigenMateria[]> = EMPTY;

  // Domain values
  protected autorizacion: Autorizacion;
  protected ingrediente: Ingrediente;

  // Configuration
  protected readOnly: boolean = false;
  protected formGroup: FormGroup;
  protected defaultValues = {
    "nombre": '',
    "agrario": 'true',
    "idOrigenMateria": null,
    "idQualificacionIngrediente": null
  };


  constructor(@Inject(MAT_DIALOG_DATA) public data: {autorizacion: Autorizacion, ingrediente: Ingrediente,
                porcentaje: number},
              protected dialogRef: MatDialogRef<EditIngredienteAutorizacionModalComponent>,
              protected injector: Injector,
              protected fb: FormBuilder) {
    this.qualificacionIngredienteSvc = injector.get(QualificacionIngredienteHttpService);
    this.origenMateriaSvc = injector.get(OrigenMateriaHttpService);
    this.authSvc = injector.get(AuthService);

    this.autorizacion = data.autorizacion;
    this.ingrediente = data.ingrediente;
    this.porcentajeDisponible = data.porcentaje;

    this.formGroup = fb.group({
      "nombre": fb.control('', [Validators.required]),
      'porcentaje': fb.control("100", [Validators.required, Validators.min(0.001)]),
      "agrario": fb.control(null, [Validators.required]),
      "idOrigenMateria": fb.control(null ),
      "idQualificacionIngrediente": fb.control(null, [Validators.required]),
      "numOrden": fb.control(''),
      "usuario": fb.control('')
    });


  }

  ngOnInit(): void {
    this.qualiIngredientes = this.qualificacionIngredienteSvc.getQualificaciones();
    this.qualiIngredientes = this.qualiIngredientes.pipe(
        map(qualiIngredientes => qualiIngredientes.slice(0,4))
    );
    this.origenes = this.origenMateriaSvc.getOrigenMateria();

    this.formGroup.patchValue({
      ...this.ingrediente,
      "idOrigenMateria": this.ingrediente?.origenMateria?.idOrigenMateria,
      "idQualificacionIngrediente": this.ingrediente?.qualificacionIngrediente?.idQualificacionIngrediente
    });

    this.formGroup.get('porcentaje')?.setValue(this.porcentajeDisponible)

    this.formGroup.get('idQualificacionIngrediente')?.valueChanges
        .subscribe(id => {
          this.qualiConvencional = id == 1;
          this.handleOrigenMateria()
        });

    this.noAgrario = !this.formGroup.get('agrario')?.value;
    this.formGroup.get('agrario')?.valueChanges
        .subscribe(agrario => {
          this.noAgrario = agrario == 'false';
          this.handleOrigenMateria()
        });

    this.authSvc.fullName.subscribe(value => this.formGroup.get('usuario')?.setValue(value));

  }

  onSubmit($event: SubmitEvent) {
    this.dialogRef.close(this.formGroup.value);
  }

  private handleOrigenMateria() {
    this.origenNoObligatorio = this.noAgrario || this.qualiConvencional;

    if(this.origenNoObligatorio) this.formGroup.get('idOrigenMateria')?.clearValidators();
    else this.formGroup.get('idOrigenMateria')?.setValidators(Validators.required);

    this.formGroup.get('idOrigenMateria')?.updateValueAndValidity();
  }

  protected get porcentajeDisponible() {
    return this._porcentajeDisponible;
  }

  protected set porcentajeDisponible(value: number) {
    if (value) {
      this._porcentajeDisponible = parseFloat(value.toFixed(3));
    }
  }
}
