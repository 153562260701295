import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalEdicion} from "../../pages/etiquetas-base.component";
import {TipoRechazo} from "../../../../core/model/etiquetas/tipoRechazo";
import {Observable} from "rxjs";
import {SolicitudProductoHttpService} from "../../../../core/services/http/solicitud/solicitud-producto.http.service";
import {EtiquetasService} from "../../services/etiquetas.service";

@Component({
  selector: 'app-edit-rechazo-modal',
  templateUrl: './edit-rechazo-modal.component.html',
  styleUrls: ['./edit-rechazo-modal.component.scss']
})
export class EditRechazoModalComponent implements OnInit, ModalEdicion {

  // Services
  protected solicitudProductoSvc: SolicitudProductoHttpService;
  protected etiquetasSvc: EtiquetasService;

  // Domain values
  protected producto: SolicitudProducto;

  // Configuration
  protected readOnly: boolean = false;
  protected esVisualizacion: boolean;
  protected formGroup: FormGroup;
  protected tiposRechazo: Observable<TipoRechazo[]>

  constructor(@Inject(MAT_DIALOG_DATA) public data: {producto: SolicitudProducto},
              protected dialogRef: MatDialogRef<EditRechazoModalComponent>,
              protected injector: Injector) {

    this.producto = data.producto;
    this.solicitudProductoSvc = injector.get(SolicitudProductoHttpService);
    this.etiquetasSvc = injector.get(EtiquetasService);

    this.formGroup = new FormGroup<any>({
      "idTipoRechazo": new FormControl(null, [Validators.required]),
      'motivoRechazo': new FormControl(null, [Validators.required, Validators.maxLength(1000)])
    });
  }

  async ngOnInit() {
    this.esVisualizacion = this.etiquetasSvc.esVisualizacion;
    this.tiposRechazo = this.solicitudProductoSvc.getTiposRechazo();
    this.formGroup.patchValue({
      ...this.producto,
    });
  }

  enableReadOnly() {
    this.readOnly = true;
  }

  onSubmit(item) {
    let updatedProduct = this.producto
    updatedProduct.idTipoRechazo = item.idTipoRechazo;
    updatedProduct.motivoRechazo = item.motivoRechazo;
    this.dialogRef.close(updatedProduct);
  }
}
