<mat-card class="mb-2 card shadow">
    <mat-card-title>
        {{'OPERADORES.almacenesInscribir' | translate}}
    </mat-card-title>
    <mat-card-content>
        <div class="py-2">
            <app-generic-table
                    [data]="magatzems"
                    [columnsConfig]="magTableConfig"
                    [actionsConfig]="tableAcciones"
                    [headerRows]="2"
                    (onAction)="onActionTableMag($event)"
            >
            </app-generic-table>
        </div>

        <div class="col-12 mt-1 d-flex justify-content-end">
            <button mat-mini-fab color="basic" (click)="onAddMagatzem()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </mat-card-content>
</mat-card>

<div class="col-12 p-2 mt-2 d-flex justify-content-end">
    <button mat-raised-button color="basic" class="px-2 mx-1"
            (click)="onChangeViewAtras()"
    >
        {{'general.atras' | translate}}
    </button>

    <button mat-raised-button color="basic" class="px-2 mx-1"
            (click)="onChangeViewSiguiente()"
    >
        {{'general.siguiente' | translate}}
    </button>
</div>