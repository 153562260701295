import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../components/toast.service";
import {Observable} from "rxjs";
import {SolicitudProductoCompuesto} from "../../../model/etiquetas/solicitud-producto-compuesto";
import {HttpService} from "../core/http.service";

@Injectable({
  providedIn: 'root'
})
export class SolicitudProductoCompuestoHttpService extends HttpService {

  protected BASE_MULTI = "solicitud-producto/compuesto";
  protected BASE_MULTI_ID = (id: number) => `${this.BASE_MULTI}/${id}`;
  protected SET_FLAG_INGREDIENTES = (id: number) => `${this.BASE_MULTI}/${id}/ingredientes`;

  constructor(http: HttpClient,
              toastSvc: ToastService) {
    super(http, toastSvc);
  }

  public getProducto(idSolicitudProducto: number): Observable<SolicitudProductoCompuesto> {
    return this.get(this.BASE_MULTI_ID(idSolicitudProducto));
  }

  public modificarProducto(idSolicitudProducto: number, producto: SolicitudProductoCompuesto): Observable<any> {
    return this.put(this.BASE_MULTI_ID(idSolicitudProducto), producto);
  }

  public crearProducto(producto: SolicitudProductoCompuesto) {
    return this.post(this.BASE_MULTI, producto);
  }

  public patchProducto(idSolicitudProducto: number, productoCompuesto: SolicitudProductoCompuesto) {
    return this.patch(this.BASE_MULTI_ID(idSolicitudProducto), productoCompuesto);
  }

  modificarFlagIngredientes(idSolicitudProducto: number) {
    return this.put(this.SET_FLAG_INGREDIENTES(idSolicitudProducto),{flag: true})
  }
}
