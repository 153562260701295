<mat-card class="card shadow mb-2 mt-2">
    <form [formGroup]="formGroup" (ngSubmit)="onSearch(formGroup.value)" #formElement>
        <div class="row">
            <div class="col-md-2 col-sm-6">
                <app-input-text
                        [label]="'OPERADORES.solicitudes.idSolicitud' | translate"
                        formControlName="idSolicitud"
                >
                </app-input-text>
            </div>

            <div class="col-md-2 col-sm-6">
                <app-input-text
                        [label]="'OPERADORES.productos.idProducto' | translate"
                        formControlName="idSolicitudProducto"
                >
                </app-input-text>
            </div>

            <div class="col-md-2 col-sm-6">
                <app-input-select
                        formControlName="estado"
                        [label]="'OPERADORES.solicitudes.estadoTitle' | translate"
                        [options]="estadosSolicitud | async | arrayToSelectItem:'id':'descripcion'"
                >
                </app-input-select>
            </div>

            <div class="col-md-2 col-sm-6">
                <app-input-select
                        formControlName="tipoTramite"
                        [label]="'OPERADORES.solicitudes.tipoTramiteTitle' | translate"
                        [options]="tiposTramites | async | arrayToSelectItem:'id':'descripcion'"
                >
                </app-input-select>
            </div>

            <div class="col-md-2 col-sm-6">
                <app-input-select
                        formControlName="codigoTramite"
                        [label]="'OPERADORES.solicitudes.codigoTramiteTitle' | translate"
                        [options]="codigosTramites | async | arrayToSelectItem:'id':'descripcion'"
                 >
                </app-input-select>
            </div>

            <div class="row">
                <div class="col-md-2 col-sm-6">
                    <app-input-datepicker
                            [label]="'general.fechaIni' | translate"
                            formControlName="fechaIni">
                    </app-input-datepicker>
                </div>

                <div class="col-md-2 col-sm-6">
                    <app-input-datepicker
                            [label]="'general.fechaFi' | translate"
                            formControlName="fechaFi">
                    </app-input-datepicker>
                </div>

            </div>



            <div class="col-12 p-2 mt-2 d-flex justify-content-end">
                <button mat-raised-button class="px-2 mx-2" (click)="onReset()">
                    {{'general.limpiar' | translate}}
                </button>
                <button mat-raised-button color="basic" class="px-2" (click)="onSearch(formGroup.value)">
                    {{'general.buscar' | translate}}
                </button>
            </div>
        </div>

    </form>
</mat-card>


<mat-card class="card shadow mt-2">
    <app-generic-table
            [data]="data.content"
            [columnsConfig]="tableConfig"
            [actionsConfig]="tableAcciones"
            [paginated]="true"
            [collectionSize]="data.totalElements"
            [totalPages]="data.totalPages"
            (onPageChange)="onPageChange($event)"
            [page]="page"
            (onAction)="onAction($event)"
    >
    </app-generic-table>
</mat-card>

<button mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
    <mat-icon>arrow_back</mat-icon>
    {{"general.volver" | translate}}
</button>
