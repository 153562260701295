import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-etiquetas-view-base',
  templateUrl: './etiquetas-view-base.component.html',
  styleUrls: ['./etiquetas-view-base.component.scss']
})
export class EtiquetasViewBaseComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string | undefined;

  constructor() {
    this.title = "Titulo de ejemplo";
  }

  ngOnInit(): void {
  }

}
