import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  EtiquetasElaboradoPeticionComponent
} from "./pages/etiquetas-elaborado-peticion/etiquetas-elaborado-peticion.component";
import {EtiquetasHortofruticolaPeticionComponent} from "./pages/etiquetas-hortofruticola-peticion/etiquetas-hortofruticola-peticion.component";
import {EtiquetasDistribuidosPeticionComponent} from "./pages/etiquetas-distribuidos-peticion/etiquetas-distribuidos-peticion.component";
import {
  EtiquetasVinosderivadosPeticionComponent
} from "./pages/etiquetas-vinosderivados-peticion/etiquetas-vinosderivados-peticion.component";
import {
  EtiquetasVinosderivadosGestionComponent
} from "./pages/etiquetas-vinosderivados-gestion/etiquetas-vinosderivados-gestion.component";
import {
  EtiquetasExternalEntryPointComponent
} from "./pages/etiquetas-external-entry-point/etiquetas-external-entry-point.component";
import {
  EtiquetasElaboradoGestionComponent
} from "./pages/etiquetas-elaborado-gestion/etiquetas-elaborado-gestion.component";
import {RolOperadorExternalGuard} from "../../core/guards/roles/rol-operador-external.guard";
import {RolCcpaeExternalGuard} from "../../core/guards/roles/rol-ccpae-external.guard";
import {
  EtiquetasDistribuidosGestionComponent
} from "./pages/etiquetas-distribuidos-gestion/etiquetas-distribuidos-gestion.component";
import {ExternalEtiquetasGuard} from "../../core/guards/external-etiquetas.guard";
import {LoggedGuard} from "../../core/guards/authentication/logged.guard";
import {
  EtiquetasHortofruticolaGestionComponent
} from "./pages/etiquetas-hortofruticola-gestion/etiquetas-hortofruticola-gestion.component";
import {
  EtiquetasImportadosPeticionComponent
} from "./pages/etiquetas-importados-peticion/etiquetas-importados-peticion.component";
import {
  EtiquetasImportadosGestionComponent
} from "./pages/etiquetas-importados-gestion/etiquetas-importados-gestion.component";
import {
  EtiquetasDistribuidosConfirmacionComponent
} from "./pages/etiquetas-distribuidos-confirmacion/etiquetas-distribuidos-confirmacion.component";
import {
  EtiquetasElaboradoConfirmacionComponent
} from "./pages/etiquetas-elaborado-confirmacion/etiquetas-elaborado-confirmacion.component";
import {
  EtiquetasHortofruticolaConfirmacionComponent
} from "./pages/etiquetas-hortofruticola-confirmacion/etiquetas-hortofruticola-confirmacion.component";
import {
  EtiquetasVinosderivadosConfirmacionComponent
} from "./pages/etiquetas-vinosderivados-confirmacion/etiquetas-vinosderivados-confirmacion.component";
import {
  EtiquetasImportadosConfirmacionComponent
} from "./pages/etiquetas-importados-confirmacion/etiquetas-importados-confirmacion.component";
import {
  EtiquetasElaboradoVisualizacionComponent
} from "./pages/etiquetas-elaborado-visualizacion/etiquetas-elaborado-visualizacion.component";
import {
  EtiquetasDistribuidosVisualizacionComponent
} from "./pages/etiquetas-distribuidos-visualizacion/etiquetas-distribuidos-visualizacion.component";
import {
  EtiquetasHortofruticolaVisualizacionComponent
} from "./pages/etiquetas-hortofruticola-visualizacion/etiquetas-hortofruticola-visualizacion.component";
import {
  EtiquetasImportadosVisualizacionComponent
} from "./pages/etiquetas-importados-visualizacion/etiquetas-importados-visualizacion.component";
import {
  EtiquetasVinosderivadosVisualizacionComponent
} from "./pages/etiquetas-vinosderivados-visualizacion/etiquetas-vinosderivados-visualizacion.component";
import {
  EtiquetasElaboradoRevisionComponent
} from "./pages/etiquetas-elaborado-revision/etiquetas-elaborado-revision.component";
import {
  EtiquetasHortofruticolaRevisionComponent
} from "./pages/etiquetas-hortofruticola-revision/etiquetas-hortofruticola-revision.component";
import {
  EtiquetasImportadosRevisionComponent
} from "./pages/etiquetas-importados-revision/etiquetas-importados-revision.component";
import {
  EtiquetasVinosderivadosRevisionComponent
} from "./pages/etiquetas-vinosderivados-revision/etiquetas-vinosderivados-revision.component";
import {
  EtiquetasDistribuidosRevisionComponent
} from "./pages/etiquetas-distribuidos-revision/etiquetas-distribuidos-revision.component";

const routes: Routes = [
  {
    path: 'external',
    component: EtiquetasExternalEntryPointComponent,
    canActivate: [ExternalEtiquetasGuard]
  },
  {
    path: 'elaborats',
    component: EtiquetasElaboradoPeticionComponent,
    canActivate: [LoggedGuard, RolOperadorExternalGuard]
  },
  {
    path: 'elaborats/confirmacio',
    component: EtiquetasElaboradoGestionComponent,
    canActivate: [LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'elaborats/revisio',
    component: EtiquetasElaboradoRevisionComponent,
    canActivate: [LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'elaborats/finalitzacio',
    component: EtiquetasElaboradoConfirmacionComponent,
    canActivate: [LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'elaborats/visualitzacio',
    component: EtiquetasElaboradoVisualizacionComponent,
    canActivate: [LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'hortofruticoles',
    component: EtiquetasHortofruticolaPeticionComponent,
    canActivate: [LoggedGuard, RolOperadorExternalGuard]
  },
  {
    path: 'hortofruticoles/confirmacio',
    component: EtiquetasHortofruticolaGestionComponent,
    canActivate: [LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'hortofruticoles/revisio',
    component: EtiquetasHortofruticolaRevisionComponent,
    canActivate: [LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'hortofruticoles/finalitzacio',
    component: EtiquetasHortofruticolaConfirmacionComponent,
    canActivate: [LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'hortofruticoles/visualitzacio',
    component: EtiquetasHortofruticolaVisualizacionComponent,
    canActivate: [LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'distribuits',
    component: EtiquetasDistribuidosPeticionComponent,
    canActivate: [LoggedGuard, RolOperadorExternalGuard]
  },
  {
    path: 'distribuits/confirmacio',
    component: EtiquetasDistribuidosGestionComponent,
    canActivate:[LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'distribuits/revisio',
    component: EtiquetasDistribuidosRevisionComponent,
    canActivate:[LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'distribuits/finalitzacio',
    component: EtiquetasDistribuidosConfirmacionComponent,
    canActivate:[LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'distribuits/visualitzacio',
    component: EtiquetasDistribuidosVisualizacionComponent,
    canActivate:[LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'vins-derivats',
    component: EtiquetasVinosderivadosPeticionComponent,
    canActivate: [LoggedGuard, RolOperadorExternalGuard]
  },
  {
    path: 'vins-derivats/confirmacio',
    component: EtiquetasVinosderivadosGestionComponent,
    canActivate: [LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'vins-derivats/revisio',
    component: EtiquetasVinosderivadosRevisionComponent,
    canActivate: [LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'vins-derivats/finalitzacio',
    component: EtiquetasVinosderivadosConfirmacionComponent,
    canActivate: [LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'vins-derivats/visualitzacio',
    component: EtiquetasVinosderivadosVisualizacionComponent,
    canActivate: [LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'importats',
    component: EtiquetasImportadosPeticionComponent,
    canActivate: [LoggedGuard, RolOperadorExternalGuard]
  },
  {
    path: 'importats/revisio',
    component: EtiquetasImportadosRevisionComponent,
    canActivate:[LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'importats/confirmacio',
    component: EtiquetasImportadosGestionComponent,
    canActivate:[LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'importats/finalitzacio',
    component: EtiquetasImportadosConfirmacionComponent,
    canActivate:[LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: 'importats/visualitzacio',
    component: EtiquetasImportadosVisualizacionComponent,
    canActivate:[LoggedGuard, RolCcpaeExternalGuard]
  },
  {
    path: "**",
    redirectTo: "/"
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EtiquetasRoutingModule { }
