<app-etiquetas-view-base
    *ngIf="etiquetasSvc.hasRequiredParamaters"
    [title]="'ETIQUETAS.titleElaborados' | translate"
    [subTitle]="'ETIQUETAS.subTitleTramite' | translate:{
        'solicitud': etiquetasSvc.idSolicitud
    }"
>

    <form [formGroup]="formGroup" class="col-12 overflow-hidden">
        <div class="cards-container row">
            <div class="col-12">
                <mat-card class="card shadow">
                    <mat-card-title>
                        {{'ETIQUETAS.productosSolicitudTitulo' | translate}}
                    </mat-card-title>

                    <mat-card-content>
                        <div *ngIf="!formIsValid" class="row d-flex">
                            <mat-card class="card shadow col-auto ms-auto d-flex justify-content-end icon-margin">
                                <mat-card-content style="display: flex; flex-direction: row;">
                                    <div>
                                        <mat-icon class="red margin-right mat-icon-middle">edit</mat-icon>
                                        <span class="margin-right">{{'ETIQUETAS.leyendaProducto' | translate}}</span>
                                    </div>
                                    <div>
                                        <mat-icon class="red margin-right mat-icon-middle">receipt</mat-icon>
                                        <span class="margin-right">{{'ETIQUETAS.leyendaIngrediente' | translate}}</span>
                                    </div>
                                    <div>
                                        <mat-icon class="red margin-right mat-icon-middle">label</mat-icon>
                                        <span class="margin-right">{{'ETIQUETAS.leyendaEtiqueta' | translate}}</span>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="col-3">
                                <app-input-text
                                    formControlName="busqueda"
                                    [label]="'general.buscarPorNombreComercial' | translate"
                                    [showLabel]="false"
                                >
                                </app-input-text>
                            </div>
                            <div class="col-2 ms-auto d-flex justify-content-end">
                                <span class="badge rounded-pill bg-dark">{{'general.total' | translate}}: {{productos.length}}</span>
                            </div>
                        </div>

                        <div class="py-2 mt-1">
                            <app-generic-table
                                    [data]="productosVisibles"
                                    [textNoData]="'ETIQUETAS.noDataAddProductos' | translate"
                                    [columnsConfig]="tableConfig"
                                    [actionsConfig]="tableAcciones"
                                    (onAction)="onActionTable($event)"
                            >
                            </app-generic-table>
                        </div>

                        <div *ngIf="!tramiteEsmena" class="col-12 mt-1 d-flex justify-content-end">
                            <button mat-raised-button color="primary" class="px-2 mx-1 fileUpload" (click)="onAddProducto()">
                                {{'ETIQUETAS.addOrUpdateProduct' | translate}}
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" style="margin-right: 10px;"
                    (click)="onGuardar()">
                {{'ETIQUETAS.guardarBorrador' | translate}}
            </button>
            <button mat-raised-button color="primary" class="px-2" [disabled]="!formIsValid"
                    (click)="onConfirmar()"
            >
                {{'ETIQUETAS.continuarTramitacion' | translate}}
            </button>
        </div>

    </form>


</app-etiquetas-view-base>

