import {HttpClient} from '@angular/common/http';
import {IModuleTranslationOptions, ModuleTranslateLoader} from "@larscom/ngx-translate-module-loader";

export function HttpLoaderFactory(http: HttpClient) {

    const baseTranslateUrl = '/assets/i18n/';

    const options: IModuleTranslationOptions = {
        modules: [
            // final url: ./assets/i18n/en.json
            { baseTranslateUrl },
            // final url: ./assets/i18n/feature1/en.json
            { baseTranslateUrl, moduleName: 'autorizacion' },
            // final url: ./assets/i18n/feature2/en.json
            { baseTranslateUrl, moduleName: 'operadores' },
            { baseTranslateUrl, moduleName: 'etiquetas' }
        ],
        version: 1
    };

    return new ModuleTranslateLoader(http, options);
}
