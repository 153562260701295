import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  templateUrl: './etiquetas-disclaimer-confirm.component.html',
  styleUrls: ['./etiquetas-disclaimer-confirm.component.scss']
})
export class EtiquetasDisclaimerConfirmComponent {

  protected checkboxAceptado: boolean = false;

  constructor(protected dialogRef: MatDialogRef<EtiquetasDisclaimerConfirmComponent>) { }

}
