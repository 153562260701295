<app-page-template
        [title]="'OPERADORES.solicitudes.titleDetall' | translate:
    {'solicitud': solicitud.idSolicitud}"
        >

    <mat-card class="card shadow">
        <mat-card-title>
            {{'OPERADORES.solicitudes.titleCardDetall' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col-md-3 col-xl-2">
                    <app-input-text
                            [label]="'OPERADORES.solicitudes.idSolicitud' | translate"
                            [value]="solicitud.idSolicitud"
                            [isDisabled]="true"
                    >
                    </app-input-text>
                </div>
                <div class="col-md-3 col-xl-2">
                    <app-input-text
                            [label]="'OPERADORES.solicitudes.tipoTramiteTitle' | translate"
                            [value]="solicitud.tipoTramite ? translate.instant('OPERADORES.solicitudes.tipoTramite.'+solicitud.tipoTramite) : '-'"
                            [isDisabled]="true"
                    >
                    </app-input-text>
                </div>
                <div class="col-md-3 col-xl-2">
                    <app-input-text
                            [label]="'OPERADORES.solicitudes.codigoTramiteTitle' | translate"
                            [value]="translate.instant('OPERADORES.solicitudes.codigoTramite.'+solicitud.codigoTramite)"
                            [isDisabled]="true"
                    >
                    </app-input-text>
                </div>
                <div class="col-md-3 col-xl-2">
                    <app-input-text
                            [label]="'OPERADORES.solicitudes.fecha' | translate"
                            [value]="solicitud.fechaCreacion?.toString() | dateFormat"
                            [isDisabled]="true"
                    >
                    </app-input-text>
                </div>
                <div class="col-md-3 col-xl-4">
                    <app-input-text
                            [label]="'OPERADORES.solicitudes.estadoTitle' | translate"
                            [value]="translate.instant('OPERADORES.solicitudes.estado')[solicitud.estado-1]"
                            [isDisabled]="true"
                    >
                    </app-input-text>
                </div>
            </div>

            <div class="row" *ngIf="solicitudEstado == SolicitudEstado.ELIMINAT">
                <div class="col-md-3 col-xl-2">
                    <app-input-text
                            [label]="'OPERADORES.solicitudes.fechaDelete' | translate"
                            [value]="solicitud.fechaDelete?.toString() | dateFormat"
                            [isDisabled]="true"
                    >
                    </app-input-text>
                </div>
                <div class="col-md-3 col-xl-2">
                    <app-input-text
                            [label]="'OPERADORES.solicitudes.usuarioDelete' | translate"
                            [value]="solicitud.usuarioDelete"
                            [isDisabled]="true"
                    >
                    </app-input-text>
                </div>
                <div class="col-md-3 col-xl-4">
                    <app-input-text
                            [label]="'OPERADORES.solicitudes.motivoDelete' | translate"
                            [value]="solicitud.motivoDelete"
                            [isDisabled]="true"
                    >
                    </app-input-text>
                </div>
            </div>

        </mat-card-content>

    </mat-card>

    <mat-card class="card shadow">
        <mat-card-title>
            {{'OPERADORES.productos.title' | translate}}
        </mat-card-title>
        <mat-card-content>
            <app-generic-table
                    [data]="productos?.content"
                    [paginated]="true"
                    [columnsConfig]="productosConfigTable"
                    [actionsConfig]="productosActionsConfig"
                    (onAction)="onAction($event)"
                    [collectionSize]="productos?.totalElements"
                    [totalPages]="productos?.totalPages"
                    (onPageChange)="onPageChange($event)"
                    [page]="page"
            >
            </app-generic-table>
        </mat-card-content>
    </mat-card>

    <button mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
        <mat-icon>arrow_back</mat-icon>
        {{"general.volver" | translate}}
    </button>

</app-page-template>

