<mat-card *ngIf="operadorAction=='edit'" class="card shadow mt-2">

    <div class="col-12 p-2 mt-2 d-flex justify-content-end">
        <button *ngIf="action=='edit'" mat-raised-button color="basic" class="px-2" type="button"
                (click)="createGranja()"
        >
            {{'OPERADORES.granjas.anadirGranja' | translate}}
        </button>
    </div>

    <app-generic-table
            [data]="data.content"
            [columnsConfig]="tableConfig"
            [actionsConfig]="tableAccionesEdit"
            [paginated]="true"
            [collectionSize]="data.totalElements"
            [totalPages]="data.totalPages"
            (onPageChange)="onPageChange($event)"
            [page]="page"
            (onAction)="onAction($event)"
    >

    </app-generic-table>
</mat-card>

<mat-card *ngIf="operadorAction=='view'" class="card shadow mt-2">

    <app-generic-table
            [data]="data.content"
            [columnsConfig]="tableConfig"
            [actionsConfig]="tableAccionesView"
            [paginated]="true"
            [collectionSize]="data.totalElements"
            [totalPages]="data.totalPages"
            (onPageChange)="onPageChange($event)"
            [page]="page"
            (onAction)="onAction($event)"
    >

    </app-generic-table>
</mat-card>

<button mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
    <mat-icon>arrow_back</mat-icon>
    {{"general.volver" | translate}}
</button>
