import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ComponentType} from "@angular/cdk/overlay";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private matDialog: MatDialog) { }

  public openDialog(component: ComponentType<any>, data?: object, options?: object): MatDialogRef<any> {
    return this.matDialog.open(component, {
      disableClose: true,
      width: '1200px',
      data,
      ...options
    });
  }
}
