import {AnimalCenso} from "./animalCenso";

export class Animal {

  idInscrito!: number;
  numGranja!: string;
  idEspecie!: number;
  codigoRaza!: string;
  codigoTipoGanado!: string;

  especie!: string;
  raza!: string;
  tipoGanado!: string;

  observaciones?: string;

  indicadorUbm?: string;
  animalesCenso?: AnimalCenso[];
  totalUbm?: number;

  contadorAnimales!: number;
  usuario!: string;

}