<app-operadores-detalle-almacen
        [altaOperador]="true"
        [idAltaInscrito]="idSolicitudInscrito"
        [numOrdenAlmacenAltaOp]="numOrdenAlmacen"
        [almacenAltaOp]="altaAlmacen"
        [altaInscrito]="altaInscrito"
        (saveAlmacenAltaOp)="onCreateAltaAlmacen($event)"
        (closeModalAltaOp)="onCloseModal()">

</app-operadores-detalle-almacen>
