import {Component, Injector, OnInit} from '@angular/core';
import {Almacen} from "../../../../core/model/almacen/almacen";
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {lastValueFrom} from "rxjs";
import {Pageable} from "../../../../core/model/core/pageable";
import {Page} from "../../../../core/model/core/pages/page";
import {ActivatedRoute, Router} from "@angular/router";
import {NavigationService} from "../../../../core/services/navigation.service";
import {
    OperadoresAlmacenEditAccionesConfig,
    OperadoresAlmacenTableConfig,
    OperadoresAlmacenViewAccionesConfig
} from "./operadores-almacen-table.config";
import {AlmacenHttpService} from "../../../../core/services/http/operadores/almacen-http-service";
import {ModalService} from "../../../../core/services/components/modal.service";
import {
    DisclaimerEliminarGenericoComponent
} from "../../modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";

@Component({
  selector: 'app-operadores-listado-almacen',
  templateUrl: './operadores-listado-almacen.component.html',
  styleUrls: ['./operadores-listado-almacen.component.scss']
})
export class OperadoresListadoAlmacenComponent extends Page implements OnInit {

    protected modalSvc: ModalService;
    protected operadorSvc: OperadoresHttpService;
    protected almacenSvc: AlmacenHttpService;

    protected tableConfig = OperadoresAlmacenTableConfig;
    protected tableAccionesEdit = OperadoresAlmacenEditAccionesConfig;
    protected tableAccionesView = OperadoresAlmacenViewAccionesConfig;

    protected data: Pageable<Almacen>;
    protected page = 1;
    protected idOperador: string;
    protected operadorAction;
    protected filtros: object;

    constructor(private router: Router,
             private activatedRoute: ActivatedRoute,
             private navService: NavigationService,
             injector: Injector) {
    super();

    this.modalSvc = injector.get(ModalService);
    this.operadorSvc = injector.get(OperadoresHttpService);
    this.almacenSvc = injector.get(AlmacenHttpService);

    this.data = new Pageable<Almacen>();
    this.page = 1;
    this.idOperador = this.activatedRoute.snapshot.params['idOperador'];

    }

    async ngOnInit() {
        this.data = await lastValueFrom(this.almacenSvc.getAlmacenes(this.idOperador, this.page, this.filtros));
        this.activatedRoute.params.subscribe(params => {
            this.operadorAction = params['action']; });
    }

    public async onPageChange(page: number){
        this.page = page;
        this.data = await lastValueFrom(this.almacenSvc.getAlmacenes(this.idOperador, page, this.filtros));
    }

    protected onAction($event: any) {
      if ($event.action == "view") {
          this.router.navigate(['magatzem', $event.item.numOrdenAlmacen, "view"], {relativeTo: this.activatedRoute})
      }
      else if($event.action == "edit"){
          this.router.navigate(['magatzem', $event.item.numOrdenAlmacen, "edit"], {relativeTo: this.activatedRoute})
      }
      else if($event.action == "delete"){
          this.deleteAlmacen($event.item);
      }
    }

    public createAlmacen(){
        this.router.navigate(['magatzem', "create"], {relativeTo: this.activatedRoute})
    }

    private deleteAlmacen(item){
        this.page = 1;
        this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'OPERADORES.almacenes.disclaimerEliminar'}, {maxWidth: '30vw'})
            .afterClosed().subscribe(accepted => {
            if(accepted){
                this.almacenSvc.deleteAlmacen(this.idOperador, item)
                    .subscribe(_ => this.onPageChange(this.page));
            }
        });
    }

    onBack(){
        this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
    }

}