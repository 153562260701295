import {BaseObj} from '../core/baseObj';
import {CategoriaEcologica} from "../master/categoria-ecologica";
import {SolicitudIngrediente} from "./solicitud-ingrediente";
import {Etiqueta} from "./etiqueta";
import {SolicitudAdjunto} from "./solicitud-adjunto";

export class SolicitudProducto extends BaseObj {
    idSolicitudProducto!: number;
    idSolicitud!: string;
    idSolicitudProductoPadre: number;
    nombreComercial!: string;
    idProducto!: string;
    nombreProducto!: string;
    marca?: string;
    capacidad?: string;
    idEnvase?: string;
    tipoEnvase?: string;
    aceptado?: boolean;
    categoriaEcologica!: CategoriaEcologica;
    ingredientes?: SolicitudIngrediente[] = [];
    requiereEtiqueta?: boolean;
    esElaborado?: boolean;
    esCompuesto?: boolean;
    esConIngredientes?: boolean;
    esDistribuido?: boolean;
    esActualizacion?: boolean;
    numAutorizacion?: number;
    tipoProducto?: string;
    etiqueta?: Etiqueta | null = null;
    idTipoRechazo?: number | null;
    motivoRechazo?: string | null;
    orientacionProducto?: string | null;
    tieneEtiqueta?: boolean;

    adjuntos?: SolicitudAdjunto[] = []

    //Auxiliar
    nombreCorrecto = false;
    informacionCompleta?: boolean;
    infoPadreMod: boolean;
    ingredientesPadreMod: boolean;
    etiquetaPadreMod: boolean;
    adjuntosPadreMod: boolean;
}
