import {Component, Inject, Injector} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Operador} from "../../../../core/model/operadores/operador";
import {Almacen} from "../../../../core/model/almacen/almacen";

@Component({
  templateUrl: './operador-add-magatzem-modal.component.html',
})
export class OperadorAddMagatzemModalComponent {

  operador: Operador;
  numOrdenAlmacen: number;
  almacen: Almacen;

  constructor(@Inject(MAT_DIALOG_DATA) data: {operador: Operador, numOrdenAlmacen: number, almacen: Almacen},
              public dialogRef: MatDialogRef<OperadorAddMagatzemModalComponent>,
              injector: Injector) {
    this.operador = data.operador;
    this.numOrdenAlmacen = data.numOrdenAlmacen;
    this.almacen = data.almacen;
  }

  onCreateAlmacen($event) {
    this.dialogRef.close($event);
  }

  onCloseModal() {
    this.dialogRef.close();
  }
}
