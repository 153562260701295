<div class="col-12 mt-2 p-0">
    <div class="form-check">
        <input class="form-check-input p-1" type="radio"
               [value]="value"
               [name]="name"
               (change)="change($event)"
               [disabled]="isDisabled" autocomplete="off"
               [checked]="checked"
               id="{{id}}">
        <label *ngIf="label && !campoObligatorio" [for]="id" >{{label}}</label>
        <label *ngIf="label && campoObligatorio" [for]="id" >{{label}} *</label>
    </div>
</div>
