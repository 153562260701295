export class AnimalCenso {

  idInscrito!: number;
  numGranja!: string;
  idEspecie!: number;
  codigoRaza!: string;
  codigoTipoGanado!: string;
  numOrden!: number;

  especie!: string;
  raza!: string;
  tipoGanado!: string;

  censo!: number;
  codigoUbm!: number;
  clasificacionUbm?: string;
  valorUbm?: number

  idCualificacion!: string;
  cualiEcologica!: string;
  fechaCualificacion!: Date;

  usuario!: string;

}