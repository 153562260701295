import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'functionExecute'
})
export class FunctionExecutePipe implements PipeTransform {

  transform(fn: (item: any) => any, item: any): any {
    return fn(item);
  }

}
