<app-page-template
    [title]="title"
    [subTitle]="subTitle"
>

    <div class="col-12">
        <app-etiquetas-preview-operador>
        </app-etiquetas-preview-operador>
    </div>

    <div>
        <ng-content>

        </ng-content>
    </div>

</app-page-template>

