import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Animal} from "../../../../core/model/animal/animal";
import {ModalService} from "../../../../core/services/components/modal.service";
import {
  OperadoresAnimalEditAccionesConfig,
  OperadoresAnimalTableConfig,
  OperadoresAnimalViewAccionesConfig
} from "./operadores-animal.table-config"
import {Pageable} from "../../../../core/model/core/pageable";
import {lastValueFrom} from "rxjs";
import {Page} from "../../../../core/model/core/pages/page";
import {AnimalHttpService} from "../../../../core/services/http/animal-http.service";
import {
  DisclaimerEliminarGenericoComponent
} from "../../modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";

@Component({
  selector: 'app-operadores-listado-animal',
  templateUrl: './operadores-listado-animal.component.html',
  styleUrls: ['./operadores-listado-animal.component.scss']
})
export class OperadoresListadoAnimalComponent extends Page implements OnInit {

  protected idOperador: string;
  protected action;

  protected data: Pageable<Animal>;
  protected page = 1;

  protected tableConfig = OperadoresAnimalTableConfig;
  protected tableAccionesView = OperadoresAnimalViewAccionesConfig;
  protected tableAccionesEdit = OperadoresAnimalEditAccionesConfig;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private animalSvc: AnimalHttpService,
              private modalSvc: ModalService) {
    super();

    this.idOperador = this.activatedRoute.snapshot.params['idOperador'];
    this.action = this.activatedRoute.snapshot.params['action'];

    this.data = new Pageable<Animal>();
    this.page = 1;

  }

  async ngOnInit() {
    this.data = await lastValueFrom(this.animalSvc.getAnimalesPage(this.idOperador, this.page));
  }

  public async onPageChange(page: number) {
    this.page = page;
    this.data = await lastValueFrom(this.animalSvc.getAnimalesPage(this.idOperador, page));
  }

  public onAction($event: any) {

    switch($event.action){
      case "view":
        this.viewAnimal($event.item);
        break;
      case "edit":
        this.editAnimal($event.item)
        break;
      case "delete":
        this.deleteAnimal($event.item);
    }
  }


  public viewAnimal(animal: Animal) {
    this.router.navigate(
        ['animal', animal.numGranja, animal.idEspecie, animal.codigoRaza, animal.codigoTipoGanado, "view"],
        {relativeTo: this.activatedRoute})
  }

  public createAnimal() {
    this.router.navigate(['animal', "create"], {relativeTo: this.activatedRoute})
  }

  public editAnimal(animal: Animal) {
    this.router.navigate(
        ['animal', animal.numGranja, animal.idEspecie, animal.codigoRaza, animal.codigoTipoGanado, "edit"],
        {relativeTo: this.activatedRoute})
  }

  private deleteAnimal(item){
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: 'OPERADORES.animales.animalDisclaimerEliminar'}, {maxWidth: '30vw'})
        .afterClosed().subscribe(accepted => {
      if(accepted){
        this.animalSvc.eliminarAnimal(this.idOperador, item).subscribe( value =>this.reloadCurrentRoute());
      }
    });
  }



  //Routings
  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  onBack(){
    this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
  }


}
