import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {EtiquetasCompuestoBaseComponent} from "../etiquetas-compuesto-base.component";
import {ModalService} from "../../../../core/services/components/modal.service";
import {SolicitudProductoCompuesto} from "../../../../core/model/etiquetas/solicitud-producto-compuesto";
import {
  EditProductoElaboradoModal
} from "../../modals/edit-producto-elaborado-modal/edit-producto-elaborado-modal.component";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {
  AccionesProductos,
  AccionesProductosCompuestosConfirmacion,
  ProductoTablePreviewConfig
} from "../../etiquetas.config";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalEdicion} from "../etiquetas-base.component";
import {TramiteGuard} from "../../guards/tramite.guard";
import {EtiquetasCompuestoService} from "../../services/etiquetas-compuesto.service";
import {TramiteElaboradosGuard} from "../../guards/tramite-elaborados.guard";
import {EditAdjuntosModalComponent} from "../../modals/edit-adjuntos-modal/edit-adjuntos-modal.component";


@Component({
  selector: 'app-etiquetas-elaborado-visualizacion',
  templateUrl: './etiquetas-elaborado-visualizacion.component.html',
  styleUrls: ['./etiquetas-elaborado-visualizacion.component.scss'],
  providers: [
    {provide: EtiquetasCompuestoService},
    {provide: TramiteGuard, useClass: TramiteElaboradosGuard}
  ]
})
export class EtiquetasElaboradoVisualizacionComponent extends EtiquetasCompuestoBaseComponent implements OnInit {

  protected tableConfig = ProductoTablePreviewConfig;
  protected tableAcciones = AccionesProductosCompuestosConfirmacion;

  constructor(injector: Injector,
              modalSvc: ModalService,
              tramiteGuard: TramiteGuard,
              cdr: ChangeDetectorRef) {
    super(injector, modalSvc, tramiteGuard, cdr);

  }

  override onEditarProducto(producto: SolicitudProductoCompuesto): MatDialogRef<EditProductoElaboradoModal> {
    let modal = this.modalSvc.openDialog(EditProductoElaboradoModal, {producto});
    modal.componentInstance.enableReadOnly();
    return modal;
  }

  override async onHandleEditarProducto(idSolicitudProducto: number, result: SolicitudProducto) {
  }

  override onEditarIngredientes(producto: SolicitudProductoCompuesto): MatDialogRef<ModalEdicion> {
    let modal = super.onEditarIngredientes(producto);
    modal.componentInstance.enableReadOnly();
    return modal;
  }

  override onActionTable($event: any) {
    if ($event.action == AccionesProductos.VIEW_ETIQUETA) {
      this.solicitudProductoSvc.visualizarEtiqueta($event.item.idSolicitudProducto);
    }
    else if($event.action == AccionesProductos.VIEW_ADJUNTOS) {
      this.modalSvc.openDialog(EditAdjuntosModalComponent, {producto: $event.item, readOnly: true})
    }
    else if($event.action == AccionesProductos.ACEPTAR) {
    }
    else if($event.action == AccionesProductos.RECHAZAR) {
    }
    else {
      super.onActionTable($event);
    }
  }

  override validateForm(productos: SolicitudProducto[]): boolean {
    return true;
  }

  override onHandleConfirmar() {
  }

}
