import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../services/security/auth.service";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export abstract class ExternalGuard implements CanActivate {

	private token: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

	constructor(protected authSvc: AuthService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		this.preIsAllowed(route);
		return this.isAllowed();
	}

	isAllowed(): Observable<boolean> {
		return this.token.asObservable()
			.pipe(map((value: string | null) => value != null));
	}

	protected getToken(): string | null {
		return this.token.value;
	}

	preIsAllowed(route: ActivatedRouteSnapshot): void {
		const token = route.queryParams.token;
		this.token.next(token);
		this.authSvc.loadTokenData(token);
	}

}
