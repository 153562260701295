import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Page} from "../../../../core/model/core/pages/page";
import {Parcela} from "../../../../core/model/parcela/parcela";
import {ParcelaHttpService} from "../../../../core/services/http/operadores/parcela-http-service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../../core/services/security/auth.service";
import {Municipio} from "../../../../core/model/localizacion/municipio";
import {EMPTY, Observable} from "rxjs";
import {Producto} from "../../../../core/model/autorizacion/producto";
import {Variedad} from "../../../../core/model/parcela/variedad";
import {Cualificacion} from "../../../../core/model/animal/cualificacion";

@Component({
  selector: 'app-operadores-detalle-parcela',
  templateUrl: './operadores-detalle-parcela.component.html',
  styleUrls: ['./operadores-detalle-parcela.component.scss']
})
export class OperadoresDetalleParcelaComponent extends Page implements OnInit {

  private idOperador: string;
  private idParcela: number;

  protected usuario;

  public parcela: Parcela;
  protected parcelaAction;
  protected formGroup: FormGroup;
  protected title;
  protected titleParams;
  protected municipio: Municipio;
  protected municipioValido = false;

  protected cultivos : Observable<Producto[]> = EMPTY;
  protected variedades : Observable<Variedad[]> = EMPTY;
  protected cualificaciones : Observable<Cualificacion[]> = EMPTY;

  constructor(private parcelaSvc: ParcelaHttpService,
              private activatedRoute: ActivatedRoute,
              private authSvc: AuthService,
              private router: Router) {
    super();
    this.idOperador = activatedRoute.snapshot.params['idOperador'];
    this.idParcela = activatedRoute.snapshot.params['idParcela'];

    this.parcela = new Parcela();
    this.municipio = new Municipio();

    this.formGroup = new FormGroup({
      "numOrdenParcela": new FormControl(null, Validators.pattern("^[0-9]+$")),
      "poligono": new FormControl(null, [Validators.required, Validators.maxLength(4)]),
      "parcela": new FormControl(null, [Validators.required, Validators.maxLength(7)]),
      "recinto": new FormControl(null, [Validators.required, Validators.maxLength(7)]),
      "superficie": new FormControl(null, [Validators.required, Validators.pattern("^[0-9]{0,8}([.][0-9]{1,4})?$")]),
      "idCultivo": new FormControl(null, [Validators.required, Validators.maxLength(6)]),
      "idVariedad": new FormControl(null, Validators.maxLength(10)),
      "idCualificacion": new FormControl(3, [Validators.required, Validators.maxLength(10)]),
      "fechaCualificacion": new FormControl(null, Validators.required),
      "fechaInicio": new FormControl(null, Validators.required),
      "sigpac": new FormControl(null, Validators.maxLength(30)),
      "recSigpac": new FormControl(null, Validators.maxLength(33)),
      "rvc": new FormControl(null, Validators.maxLength(5)),
      "modificacionManual": new FormControl(false),
      "indicadorConversion": new FormControl(false),
      "observaciones": new FormControl(null),
      "numPar": new FormControl(null, Validators.pattern("^[0-9]+$")),
      "codigoProduccionSigCosecha": new FormControl(null, Validators.maxLength(6)),
      "codigoVariedadSigCosecha": new FormControl(null, Validators.maxLength(10)),
      "superficieNeta": new FormControl(null, Validators.pattern("^([0-9]{0,8}([.][0-9]{1,4})?)?$")),
      "usuario": new FormControl('', [Validators.required, Validators.maxLength(100)])

    })
  }

  ngOnInit() {

    this.authSvc.fullName.subscribe(value => {
      this.usuario = value;
      this.formGroup.patchValue({'usuario': value})
    });

    this.activatedRoute.params.subscribe(params => {
      this.parcelaAction = params['parcelaAction'];

      if (this.parcelaAction == 'edit') {
        this.formGroup.enable();
        this.formGroup.controls['fechaCualificacion'].disable();
        this.formGroup.controls['idCualificacion'].disable();
      } else if(this.parcelaAction == 'create'){
        this.formGroup.enable();
        this.title ='OPERADORES.parcelas.titleAlta';
        this.formGroup.controls['fechaCualificacion'].disable();
        this.formGroup.controls['idCualificacion'].disable();
      } else {
        this.formGroup.disable();
      }
    });

    if (this.parcelaAction != 'create'){
      this.parcelaSvc.getParcela(this.idOperador, this.idParcela)
          .subscribe(parcela => this.onHandleParcela(parcela));
    }

    this.cultivos = this.parcelaSvc.getCultivos();
    this.variedades = this.parcelaSvc.getVariedades();
    this.cualificaciones = this.parcelaSvc.getCualificaciones();

    this.handleModificacionManual();
    this.handleCualificacion();
  }

  public async onHandleParcela(parcela: Parcela) {

    this.parcela = parcela;

    this.title = 'OPERADORES.parcelas.titleDetall';
    this.titleParams = {
      'poligono': parcela.poligono,
      'parcela': parcela.parcela,
      'recinto': parcela.recinto
    }
    this.formGroup.patchValue({...this.parcela, 'usuario': this.usuario});
    this.municipio = this.parcela.municipio;
  }

  async onGuardarForm() {

    this.parcela = this.formGroup.value;
    this.parcela.municipio = this.municipio;
    this.parcela.fechaCualificacion = this.formGroup.controls['fechaCualificacion'].value;
    this.parcela.idCualificacion = this.formGroup.controls['idCualificacion'].value;

    if (this.parcelaAction == 'edit'){

      this.parcelaSvc.updateParcela(this.idOperador, this.idParcela, this.parcela)
          .subscribe(value => this.reloadCurrentRoute());

    } else if (this.parcelaAction == 'create'){

      this.parcelaSvc.createParcela(this.idOperador, this.parcela).subscribe(parcela=>{
        if (parcela){
          this.router.navigate(["../", parcela.numOrdenParcela, 'edit'], {relativeTo: this.activatedRoute})
        }
      });
    }
  }

  protected handleModificacionManual(){
    this.formGroup.controls['indicadorConversion'].valueChanges.subscribe(indicadorConversion => {
      if (indicadorConversion){
        this.formGroup.controls['modificacionManual'].setValue(true);
        this.formGroup.controls['modificacionManual'].disable();
      } else if (!indicadorConversion && this.parcelaAction != 'view') {
          this.formGroup.controls['modificacionManual'].enable();
      }
    })
  }

  protected handleCualificacion(){
    this.formGroup.controls['modificacionManual'].valueChanges.subscribe(modificacionManual => {

      if (modificacionManual && this.parcelaAction != 'view'){
        this.formGroup.controls['fechaCualificacion'].enable();
        this.formGroup.controls['idCualificacion'].enable();

      } else {
        let fechaInicio = this.formGroup.controls['fechaInicio'].value;

        this.formGroup.controls['fechaCualificacion'].setValue(fechaInicio);
        this.formGroup.controls['idCualificacion'].setValue(3);

        this.formGroup.controls['fechaCualificacion'].disable();
        this.formGroup.controls['idCualificacion'].disable();
      }
    })

    this.formGroup.controls['fechaInicio'].valueChanges.subscribe(fechaInicio => {

      let modificacionManual = this.formGroup.controls['modificacionManual'].value;

      if (!modificacionManual){
        this.formGroup.controls['fechaCualificacion'].setValue(fechaInicio);
        this.formGroup.controls['idCualificacion'].setValue(3);
      }
    })
  }


  // Routings

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  onBack(){
    if(this.parcelaAction == 'create')
      this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
    else
      this.router.navigate(["../../../"], {relativeTo: this.activatedRoute})
  }
}

