import {Component, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDrawer} from "@angular/material/sidenav";
import {BasePage} from "./core/model/core/pages/basePage";
import {PageInfoService} from "./core/services/core/page-info.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild("drawer", {static: true, read: MatDrawer}) drawer: MatDrawer | undefined;
  defaultLanguage = 'ca';

  constructor(public translate: TranslateService,
              public pageInfo: PageInfoService) {
    translate.setDefaultLang(this.defaultLanguage);
    translate.addLangs(['ca', 'es']);
    translate.use('ca');
  }

  activate(page: BasePage) {
    this.pageInfo.page = page;
  }

}
