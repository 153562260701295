import {Injectable} from '@angular/core';
import {SolicitudIngrediente} from "../../../core/model/etiquetas/solicitud-ingrediente";

export enum Ubicaciones {
  PROPI=1,
  SUBCONTRATO,
  ELABORADO
}

export enum UbicacionesEtiquetaje {
    PROPI=1,
    SUBCONTRATO ,
    ETIQUETADO,
    SIN_ETIQUETAR
}

@Injectable({
  providedIn: "root"
})
export class EtiquetasCompuestoService {

  public porcentajeRestante(ingredientes: SolicitudIngrediente[]): number {
    return ingredientes.reduce((acc, current) => acc - current.porcentaje, 100);
  }

  public esPorcentajeCompleto(ingredientes: SolicitudIngrediente[]): boolean {

      if (ingredientes.length == 0)
        return false;

      return ingredientes.map(item => item.porcentaje)
          .reduce((acc, porcentaje) => acc + porcentaje) >= 100.0;
  }

}
