import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-disclaimer-eliminar-solicitud',
  templateUrl: './disclaimer-eliminar-solicitud.component.html',
  styleUrls: ['./disclaimer-eliminar-solicitud.component.scss']
})
export class DisclaimerEliminarSolicitudComponent {

  protected textoDisclaimer: string;
  public formGroup: FormGroup;

  constructor(protected dialogRef: MatDialogRef<DisclaimerEliminarSolicitudComponent>,
              @Inject(MAT_DIALOG_DATA) data: {textoDisclaimer: string},
              private fb: FormBuilder) {

    this.textoDisclaimer = data.textoDisclaimer;

    this.formGroup = new FormGroup<any>({
      "motivoDelete": new FormControl(null, [Validators.required, Validators.maxLength(130)])
    });

  }

  onConfirm(): void {
    if (this.formGroup.valid) {
      this.dialogRef.close(this.formGroup.value.motivoDelete);
    }
  }

}
