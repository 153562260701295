<form [formGroup]="formGeneral">
    <mat-card class="mb-2 card shadow" formGroupName="raoSocial">
        <mat-card-title>
            {{'OPERADORES.raoSocial' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col-md-2 col-sm-12 checkProcedente">
                    <app-input-checkbox
                            formControlName="personaFisica"
                            (valueChanged)="disableRaoSocial($event)"
                            [label]="'OPERADORES.noProcedent_personaFisica' | translate">
                    </app-input-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 col-sm-12">
                    <app-input-text
                            formControlName="CIF"
                            [label]="'general.nif' | translate"
                            [campoObligatorio]="true">
                    </app-input-text>
                </div>

                <div class="col-md-4 col-sm-12">
                    <app-input-text
                            formControlName="companyia"
                            [label]="'OPERADORES.empresa' | translate"
                            [campoObligatorio]="true">
                    </app-input-text>
                </div>
            </div>

        </mat-card-content>
    </mat-card>

    <mat-card class="mb-2 card shadow" formGroupName="dadesRepLegal">
        <mat-card-title>
            {{'OPERADORES.datosRepLegal' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row" >
                <div class="col-md-2 col-sm-12">
                    <app-input-text
                            formControlName="nomRepLegal"
                            [label]="'general.nombre' | translate"
                            [campoObligatorio]="true">
                    </app-input-text>
                </div>
                <div class="col-md-2 col-sm-12">
                    <app-input-text
                            formControlName="primerCognomRepLegal"
                            [label]="'general.primerApellido' | translate"
                            [campoObligatorio]="true">
                    </app-input-text>
                </div>
                <div class="col-md-2 col-sm-12">
                    <app-input-text
                            formControlName="segonCognomRepLegal"
                            [label]="'general.segundoApellido' | translate">
                    </app-input-text>
                </div>
                <div class="col-md-2 col-sm-12">
                    <app-input-text
                            formControlName="NIFRepLegal"
                            [label]="'OPERADORES.dni_nie_nif' | translate"
                            [campoObligatorio]="true">
                    </app-input-text>
                </div>
                <div class="col-md-2 col-sm-12">
                    <app-input-select
                            formControlName="genereRepLegal"
                            [label]="'general.genero' | translate"
                            [options]="generos | async | arrayToSelectItem:'idGenero':'descripcion'"
                            [campoObligatorio]="true">
                    </app-input-select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 col-sm-12">
                    <app-input-number
                            formControlName="telefonoRepLegal"
                            [label]="'general.telefonoContacto' | translate"
                            [campoObligatorio]="true">
                    </app-input-number>
                </div>
                <div class="col-md-4 col-xl-4">
                    <app-input-text
                            formControlName="emailRepLegal"
                            [label]="'general.email' | translate"
                            [campoObligatorio]="true"
                    >
                    </app-input-text>
                </div>


            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="mb-2 card shadow" formGroupName="direccionSocial">
        <mat-card-title>
            {{'general.direccionSocial' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row justify-content-start">
                <app-operadores-localizacion
                    [municipioInput]="municipiSocial"
                    [campoObligatorio]="true"
                    (formValid)="municipiSocialValid = $event"
                    (municipioModified)="municipiSocial = $event"
                >
                </app-operadores-localizacion>
            </div>
            <div class="row justify-content-start">
                <div class="col-12">
                    <app-input-text
                            [formControlName]="'direccion'"
                            [label]="'general.direccion' | translate"
                            [campoObligatorio]="true"
                    >
                    </app-input-text>
                </div>
            </div>
        </mat-card-content>

    </mat-card>

    <mat-card class="mb-2 card shadow" formGroupName="direccionPostal">
        <mat-card-title>
            {{'general.direccionPostal' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row mt-1 justify-content-start">
                <app-operadores-localizacion
                [municipioInput]="municipiPostal"
                (formValid)="municipiPostalValid = $event"
                (municipioModified)="municipiPostal = $event"
                >
                </app-operadores-localizacion>
            </div>
            <div class="row justify-content-start">
                <div class="col-12">
                    <app-input-text
                            [formControlName]="'direccion'"
                            [label]="'general.direccion' | translate"
                    >
                    </app-input-text>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="mb-2 card shadow" formGroupName="dadesNotifElectronica">
        <mat-card-title>
            {{'OPERADORES.datosNotificacionElectronica' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col-sm-6 col-lg-3 col-xl-3">
                    <app-input-number
                            formControlName="mobil"
                            [label]="'OPERADORES.mobilNotifElectronica' | translate"
                            [campoObligatorio]="true"
                    >
                    </app-input-number>
                </div>
                <div class="col-md-4 col-xl-4">
                    <app-input-text
                            formControlName="email"
                            [label]="'OPERADORES.emailNotifElectronica' | translate"
                            [campoObligatorio]="true"
                    >

                    </app-input-text>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card  class="mb-2 card shadow" formGroupName="dadesContacteCCPAE">
        <mat-card-title>
            {{'OPERADORES.datosContactoCCPAE' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col-md-2 col-sm-12 checkProcedente">
                    <app-input-checkbox
                            [isDisabled]="!this.formGeneral.get('dadesRepLegal')?.valid"
                            formControlName="datosTitular"
                            [value]="formGeneral.get('dadesContacteCCPAE')?.get('datosTitular')?.value"
                            (valueChanged)="copiarDadesTitular($event)"
                            [label]="'OPERADORES.copiarDatosTitular' | translate">
                    </app-input-checkbox>
                </div>
            </div>
            <div class="row">
                <app-generic-table
                        [data]="personasContacto"
                        [textNoData]="'OPERADORES.noDataAddPersonasContacto' | translate"
                        [columnsConfig]="tableConfig"
                        [actionsConfig]="tableAcciones"
                        (onAction)="onActionTable($event)"
                >
                </app-generic-table>
            </div>
            <div *ngIf="!this.formGeneral.get('dadesContacteCCPAE.datosTitular').value" class="col-12 p-2 mt-2 d-flex justify-content-end">
                <button mat-raised-button color="basic" class="px-2 mx-1" (click)="onAddPersonaContacte()">
                    {{'general.add' | translate}}
                </button>
            </div>


        </mat-card-content>
    </mat-card>

    <mat-card  class="mb-2 card shadow" formGroupName="dadesRepDelegacion">
        <mat-card-title>
            {{'OPERADORES.datosRepDelegacion' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row" >
                <div class="col-md-2 col-sm-12 checkProcedente">
                    <app-input-checkbox
                            formControlName="repDelegacio"
                            (valueChanged)="disableDadesRepDelegacion($event)"
                            [label]="'OPERADORES.noProcedent_repDelegacio' | translate">
                    </app-input-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 col-sm-12">
                    <app-input-text
                            formControlName="nomRepDelegacion"
                            [label]="'general.nombre' | translate"
                            [campoObligatorio]="true">
                    </app-input-text>
                </div>
                <div class="col-md-2 col-sm-12">
                    <app-input-text
                            formControlName="primerCognomRepDelegacion"
                            [label]="'general.primerApellido' | translate"
                            [campoObligatorio]="true">
                    </app-input-text>
                </div>
                <div class="col-md-2 col-sm-12">
                    <app-input-text
                            formControlName="segonCognomRepDelegacion"
                            [label]="'general.segundoApellido' | translate">
                    </app-input-text>
                </div>
                <div class="col-md-2 col-sm-12">
                    <app-input-text
                            formControlName="nifRepDelegacion"
                            [label]="'OPERADORES.dni_nie_nif' | translate"
                            [campoObligatorio]="true">
                    </app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 col-sm-12">
                    <app-input-number
                            formControlName="telefonoRepDelegacion"
                            [label]="'general.telefonoContacto' | translate"
                            [campoObligatorio]="true">
                    </app-input-number>
                </div>
                <div class="col-md-4 col-sm-12">
                    <app-input-text
                            formControlName="emailRepDelegacion"
                            [label]="'general.email' | translate"
                            [campoObligatorio]="true">
                    </app-input-text>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card  class="mb-2 card shadow" formGroupName="webXarxesSocials">
        <mat-card-title>
            {{'OPERADORES.webXarxesSocials' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <app-input-text
                            formControlName="llocWeb"
                            [label]="'general.web' | translate">
                    </app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <app-input-text
                            formControlName="twitter"
                            [label]="'general.twitter' | translate">
                    </app-input-text>
                </div>
                <div class="col-md-4 col-sm-12">
                    <app-input-text
                            formControlName="facebook"
                            [label]="'general.facebook' | translate">
                    </app-input-text>
                </div>
                <div class="col-md-4 col-sm-12">
                    <app-input-text
                            formControlName="instagram"
                            [label]="'general.instagram' | translate">
                    </app-input-text>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="mb-2 card shadow" formGroupName="dadesProduccio">
        <mat-card-title>
            {{'OPERADORES.datosProduccion' | translate}}
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <p>{{'OPERADORES.actsRegistre' | translate}}</p>
                <div *ngFor="let act of activitats" class="col-md-2 col-sm-12 checksSection" >
                    <app-input-checkbox
                            [label]="act.nom"
                            [value]="checkSomeChildSelected(act) || act.selected"
                            (valueChanged)="onSelectActivitat(act)"
                    >
                    </app-input-checkbox>
                    <div *ngIf="checkSomeChildSelected(act) || act.selected">
                        <hr/>
                        <ng-container *ngIf="act.families.length > 0; else emptyFamilies">
                            <div *ngFor="let familia of act.families" class="subChecklist">
                                <app-input-checkbox
                                        [label]="'OPERADORES.families.' + familia.nombre | translate"
                                        [value]="familia.selected"
                                        (valueChanged)="onSelectFamilia($event, act, familia)">
                                </app-input-checkbox>
                                <ul *ngIf="familia.selected">
                                    <app-input-radio
                                            [label]="'OPERADORES.actExclusivaEcologica' | translate"
                                            [value]="'EE'"
                                            [id]="familia.nombre + 'EE'"
                                            [name]="'radioGroup_' + familia.nombre"
                                            [checked]="familia.mixta!=null ? !familia?.mixta : false"
                                            (valueChanged)="onExplotacioFamiliaChange($event, act, familia)"
                                    >
                                    </app-input-radio>
                                    <app-input-radio
                                            [label]="'OPERADORES.actMixta' | translate"
                                            [value]="'M'"
                                            [id]="familia.nombre + 'M'"
                                            [name]="'radioGroup_' + familia.nombre"
                                            [checked]="familia.mixta!=null ? familia?.mixta : false"
                                            (valueChanged)="onExplotacioFamiliaChange($event, act, familia)">
                                    </app-input-radio>
                                </ul>
                            </div>
                        </ng-container>
                        <ng-template #emptyFamilies>
                            <app-input-radio
                                    [label]="'OPERADORES.actExclusivaEcologica' | translate"
                                    [value]="'EE'"
                                    [id]="act.nom + 'EE'"
                                    [name]="'radioGroup_' + act.nom"
                                    [checked]="act.mixta!=null ? !act.mixta : false"
                                    (valueChanged)="onExplotacioActivitatChange($event, act)"
                            >
                            </app-input-radio>
                            <app-input-radio
                                    [label]="'OPERADORES.actMixta' | translate"
                                    [value]="'M'"
                                    [id]="act.nom + 'M'"
                                    [name]="'radioGroup_' + act.nom"
                                    [checked]="act.mixta!=null ? act.mixta : false"
                                    (valueChanged)="onExplotacioActivitatChange($event, act)"
                            >
                            </app-input-radio>
                        </ng-template>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-md-3 col-sm-12" >
                    <p class="sectionTitle">{{'OPERADORES.tipoOperador' | translate}}</p>
                    <app-input-radio
                            formControlName="tipusExplotacio"
                            [label]="'OPERADORES.expExclusivaEcologica' | translate"
                            [value]="'EE'"
                            [id]="'EE'"
                            [name]="'radioGroup_explotacioPrincipal'">
                    </app-input-radio>
                    <app-input-radio
                            formControlName="tipusExplotacio"
                            [label]="'OPERADORES.expMixta' | translate"
                            [value]="'M'"
                            [id]="'M'"
                            [name]="'radioGroup_explotacioPrincipal'">
                    </app-input-radio>
                </div>
                <div class="col-md-3 col-sm-12" >
                    <p class="sectionTitle">{{'OPERADORES.actPrincipal' | translate}}*</p>
                    <div *ngFor="let act of activitats">
                        <ng-container *ngIf="act.selected">
                            <app-input-radio
                                    [label]="act.nom | translate"
                                    [value]="'actPrincipal'"
                                    [id]="act.nom + 'Principal'"
                                    [name]="'radioGroup_actPrincipal'"
                                    [checked]="operador?.activitatProduccionPrincipal == act"
                                    (valueChanged)="onSelectActivitatPrincipal(act)">
                            </app-input-radio>
                        </ng-container>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div  *ngIf="!validateForm()" class="col-12 p-2 mt-2 d-flex invalid-feedback justify-content-end">
            <span>
                {{'formErrors.faltanCampos' | translate}}
            </span>
    </div>
    <div class="col-12 p-2 d-flex justify-content-end">
        <button mat-raised-button color="basic" class="px-2 mx-1" [disabled]="false"
                (click)="onChangeViewSiguiente()"
        >
            {{'general.siguiente' | translate}}
        </button>
    </div>

</form>
