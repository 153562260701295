import {Pipe, PipeTransform} from '@angular/core';
import {ObjetoToValorPipe} from "./objeto-to-valor.pipe";

@Pipe({
  name: 'evaluarCondicion',
  pure: false
})
export class EvaluarCondicionPipe implements PipeTransform {

  private objValue = new ObjetoToValorPipe();

  transform(condicion: string | undefined, item: object): string | boolean {

    if (!condicion)
      return false;

    let hasAnd = condicion.indexOf("&&") > 0 ? condicion.indexOf("&&") : 9999;
    let hasOr = condicion.indexOf("||") > 0 ? condicion.indexOf("||") : 9999;
    let firstOp = condicion.substring(0, Math.min(hasAnd, hasOr)).trim();
    let others = condicion.substring(Math.min(hasAnd, hasOr) + 2, condicion.length).trim();

    if (hasAnd < hasOr) {
      return this.evaluarCondicion(firstOp, item) && this.transform(others, item)
    } else if (hasOr < hasAnd) {
      return this.evaluarCondicion(firstOp, item) || this.transform(others, item)
    } else {
      return this.evaluarCondicion(condicion, item)
    }
  }

  private evaluarCondicion(condicion: string, item: any) {

    if (condicion == "true" || condicion == "false")
      return this.parseSpecificCases(condicion);

    let condicionSplit = condicion.split(" ");
    if (condicionSplit.length > 1) {
      let obj1 = this.objValue.transform(item, condicionSplit[0]);
      let obj2 = this.objValue.transform(item, condicionSplit[2]);
      let operador = condicionSplit[1];

      // Si no existeix el valor en el objecte ho tracta com a literal
      if (obj1 === undefined)
        obj1 = this.parseSpecificCases(condicionSplit[0]);

      if (obj2 === undefined)
        obj2 = this.parseSpecificCases(condicionSplit[2]);

      return this.evaluarComparacion(obj1, operador, obj2);
    } else {
      return this.objValue.transform(item, condicionSplit[0]);
    }
  }

  private evaluarComparacion(obj1: any, operador: any, obj2: any): boolean {
    switch(operador) {
      case ">":
        return obj1 > obj2;
      case ">=":
        return obj1 >= obj2;
      case "<":
        return obj1 < obj2;
      case "<=":
        return obj1 <= obj2;
      case "==":
        return obj1 == obj2;
      case "!=":
        return obj1 != obj2;
    }

    return false;
  }

  private parseSpecificCases(value: any): any {
    switch(value) {
      case "null":
          return null;
      case "true":
        return true;
      case "false":
        return false;
    }

    return value;
  }

}
