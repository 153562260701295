<div>
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div>
            {{'ETIQUETAS.addActualizado' | translate}}
        </div>

        <div class="clickable" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <div>
        <div class="my-2 col-6">
            <app-input-text
                [(ngModel)]="filtroProducto"
                (ngModelChange)="onChangeText()"
                [label]="'general.buscarPorNombreComercial' | translate"
                [showLabel]="false"
            >

            </app-input-text>
        </div>

        <div class="row">
            <div class="col-md-6 col-sm-12 my-2">
                <h3><b>Disponible</b> (premi <b>+</b> en accions per a seleccionar el producte)</h3>
                <div class="table-container">
                    <app-generic-table
                            [data]="visibles"
                            [columnsConfig]="tableConfig"
                            [actionsConfig]="tableAdd"
                            (onAction)="onActionTable($event)"
                    >
                    </app-generic-table>
                </div>
            </div>

            <div class="col-md-6 col-sm-12 my-2">
                <h3><b>Seleccionat</b></h3>
                <div class="table-container">
                    <app-generic-table
                            [data]="seleccionats"
                            [columnsConfig]="tableConfig"
                            [actionsConfig]="tableRemove"
                            (onAction)="onActionTable($event)"
                    >
                    </app-generic-table>
                </div>
            </div>

        </div>

        <div class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="primary" class="px-2" (click)="onSubmit()"
                [disabled]="seleccionats.length <= 0"
            >
                {{'general.add' | translate}}
            </button>
        </div>

    </div>
</div>
