import {Component, Inject, Injector, OnInit} from '@angular/core';
import {EditProductoBaseModalComponent} from "../edit-producto-base-modal/edit-producto-base-modal.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-edit-producto-importados-modal',
  templateUrl: './edit-producto-importados-modal.component.html',
  styleUrls: ['./edit-producto-importados-modal.component.scss']
})
export class EditProductoImportadosModalComponent extends EditProductoBaseModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) data : {producto: SolicitudProducto},
              dialogRef: MatDialogRef<EditProductoImportadosModalComponent>,
              injector: Injector) {
    super(data, dialogRef, injector);

    this.formGroup.addControl("requiereEtiqueta", new FormControl(false));
    this.formGroup.addControl("idCategoriaEcologica", new FormControl(null));

  }

  protected gestionarCategoria(): void {}

  protected onSubmit(): void {
    this.formGroup.controls["requiereEtiqueta"].setValue(false);
    this.dialogRef.close(this.formGroup.value);
  }

  enableReadOnly() {
    this.readOnly = true;
    this.formGroup.disable({emitEvent: false});
    this.formGroup.get("idSolicitudProducto")?.enable({emitEvent: false});
    this.formGroup.get("idProducto")?.enable({emitEvent: false});
  }

  enableReadOnlyEstado3() {
    this.enableReadOnly();
    this.formGroup.get("idCategoriaEcologica")?.enable({ emitEvent: false});
  }

}
