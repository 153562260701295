import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-albaran-modal',
  templateUrl: './albaran-modal.component.html',
  styleUrls: ['./albaran-modal.component.scss']
})
export class AlbaranModalComponent implements OnInit {

  protected formGroup: FormGroup;
  protected compromisos = AlbaranTexts;
  protected esPinsoFiltroPopUpAlbaran: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {esPinsoFiltroPopUpAlbaran: boolean},
              public dialogRef: MatDialogRef<AlbaranModalComponent>) {

    this.formGroup = new FormGroup({
      "disclaims": new FormArray([])
    });

    this.esPinsoFiltroPopUpAlbaran = data.esPinsoFiltroPopUpAlbaran;
  }

  ngOnInit(): void {
    this.loadDisclaims();
  }

  disclaims(): FormArray{
    return this.formGroup.get("disclaims") as FormArray;
  }

  private loadDisclaims() {

    let texts= this.esPinsoFiltroPopUpAlbaran ? this.compromisos.get(1) : this.compromisos.get(0);

    texts?.forEach(text => {
      this.disclaims().push(new FormGroup({
        "text": new FormControl(text),
        "checked": new FormControl(false, [Validators.requiredTrue])
      }));
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  protected onSubmit() : void {
    this.dialogRef.close(this.formGroup.value);
  }

}

export const AlbaranTexts: Map<number, string[]> = new Map([
  [0, [
    "Nom de l’operador",
    "Adreça operador",
    "Producte",
    "Qualificació",
    "ES-ECO-019-CT",
    "Origen de les matèries primeres",
    "Num de lot"
  ]],
  [1, [
    "Nom de l’operador",
    "Adreça operador",
    "Producte",
    "ES-ECO-019-CT",
    "Referència al mètode de producció",
    "% total d’aliments d’origen agrícola (expressat en pes de matèria seca)",
    "% total d’aliments d’origen agrícola procedents de l’agricultura ecològica (expressat en pes de matèria seca)",
    "% total d’aliments d’origen agrícola en conversió (expressat en pes de matèria seca)",
    "Llista  de les matèries primeres procedents de l’agricultura ecològica i dels productes en conversió",
    "Origen de les matèries primeres",
    "Num de lot",
    "% total de matèries primeres"
  ]]
]);
