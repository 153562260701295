import {Pipe, PipeTransform} from '@angular/core';
import {SelectItem} from "../model/select-item";

export type ParseFunction = (valor: any) => string;

@Pipe({
  name: 'toSelectItem'
})
export class ToSelectItemPipe implements PipeTransform {

  transform(item: object, idField: string, fieldOrFunction: string | ParseFunction): SelectItem {
    return {
      id: this.getValue(item, idField),
      valor: this.parseValue(item, fieldOrFunction),
      disabled: this.getValue(item, 'disabled')
    };
  }

  private parseValue(item: any, fieldOrFunction: string | ParseFunction): string {

    if (typeof fieldOrFunction == "string") {
      return this.getValue(item, fieldOrFunction)
    } else {
      return fieldOrFunction(item);
    }
  }

  private getValue(item: any, path: string) {
    let items = path.split('.')

    let result = item[items[0]];
    items = items.slice(1, items.length)

    while (items.length > 0) {
      result = result[items[0]]
      items = items.slice(1, items.length)
    }

    return result;
  }

}
