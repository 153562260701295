<app-page-template
        [title]="(estadoSolicitud === 4 || estadoSolicitud === 7)
          ? ('OPERADORES.productos.titleDetall' | translate: {
              'solicitud': producto.idSolicitud,
              'nombre': producto.nombreComercial,
              'marca': producto.marca,
              'envase': producto.tipoEnvase,
              'capacidad': producto.capacidad,
              'estado': ('OPERADORES.productos.estados.' + producto.aceptado | translate)
            })
          : ('OPERADORES.productos.titleDetallSinEstado' | translate: {
              'solicitud': producto.idSolicitud,
              'nombre': producto.nombreComercial,
              'marca': producto.marca,
              'envase': producto.tipoEnvase,
              'capacidad': producto.capacidad
            })"
>
    <form [formGroup]="formGroup">
        <mat-card class="card shadow">
            <mat-card-title>
                {{'OPERADORES.productos.titleCardDetall' | translate}}
            </mat-card-title>
            <mat-card-content>

                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <app-input-text
                                formControlName="nombreComercial"
                                [label]="'OPERADORES.productos.nombreComercial' | translate"
                        >
                        </app-input-text>
                    </div>

                    <div class="col-md-4 col-sm-12">
                        <app-input-text
                                formControlName="nombreProducto"
                                [label]="'OPERADORES.productos.nombreProducto' | translate"
                        >
                        </app-input-text>
                    </div>

                    <div class="col-md-4 col-sm-6">
                        <app-input-text
                                [value]="producto.categoriaEcologica?.descripcion?.toString()"
                                [label]="'ETIQUETAS.categoriaEcologica' | translate"
                                [isDisabled]="true"
                        >
                        </app-input-text>
                    </div>

                    <div class="col-md-4 col-sm-6">
                        <app-input-text
                                formControlName="marca"
                                [label]="'general.marca' | translate"
                        >
                        </app-input-text>
                    </div>

                    <div class="col-md-4 col-sm-6">
                        <app-input-text
                                formControlName="tipoEnvase"
                                [label]="'general.envase' | translate"
                        >
                        </app-input-text>
                    </div>

                    <div class="col-md-4 col-sm-6">
                        <app-input-text
                                formControlName="capacidad"
                                [label]="'general.capacidad' | translate"
                        >
                        </app-input-text>
                    </div>
                </div>
                <div class="row">

                    <div class="col-md-6" *ngIf="producto.esCompuesto">
                        <app-input-text
                                formControlName="auxiliaresTecnologicos"
                                [label]="'ETIQUETAS.elaborado.auxiliaresTecnologicos' | translate"
                        >

                        </app-input-text>
                    </div>
                    <div class="col-md-6" *ngIf="producto.esCompuesto">
                        <app-input-text
                                formControlName="tecnicaTransformacion"
                                [label]="'ETIQUETAS.elaborado.tecnicaTransformacion' | translate"
                        >

                        </app-input-text>
                    </div>
                </div>
                <div class="row mt-3 d-flex flex-column">
                    <div class="col" *ngIf="producto.esElaborado">
                        <app-input-checkbox
                                formControlName="usaNitratos"
                                [label]="'general.usaNitratos' | translate"
                        >
                        </app-input-checkbox>
                    </div>
                    <div class="col-md-2 col-sm-12" *ngIf="producto.esCompuesto && !producto.esElaborado">
                        <app-input-select
                                formControlName="tipoNormativa"
                                [label]="'ETIQUETAS.normativaUeNop' | translate"
                                [campoObligatorio]="true"
                                [options]="normativas | arrayToSelectItem:'descripcion':'descripcion'"
                        >
                        </app-input-select>
                    </div>
                    <div class="col" *ngIf="producto.esDistribuido==false">
                        <app-input-checkbox
                                formControlName="requiereEtiqueta"
                                [label]="'ETIQUETAS.etiquetaje' | translate"
                        >
                        </app-input-checkbox>
                    </div>
                </div>
                <div class="row mt-4" *ngIf="producto.esCompuesto">
                    <div class="row">

                        <div class="col-md-6 col-sm-12">
                            <app-input-text
                                    [value]="ubicacionesEtiquetaje[idUbicacionEtiqueta-1]?.descripcion?.toString()"
                                    [label]="'ETIQUETAS.etiquetaje' | translate"
                                    [isDisabled]="true"
                            >

                            </app-input-text>

                        </div>
                        <div class="col-md-6 col-sm-12">
                            <app-input-text
                                    *ngIf="idUbicacionEtiqueta == ubicacionEnum.PROPI || idUbicacionEtiqueta == ubicacionEnum.SUBCONTRATO"
                                    formControlName="textoEtiquetaje"
                                    [label]="literalesUbicaciones[idUbicacionEtiqueta] | translate"
                            >
                            </app-input-text>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <app-input-text
                                    [value]="ubicacionesElaboracion[idUbicacionElaboracion-1]?.descripcion?.toString()"
                                    [label]="'ETIQUETAS.elaboracio' | translate"
                                    [isDisabled]="true"
                            >

                            </app-input-text>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <app-input-text
                                    *ngIf="idUbicacionElaboracion == ubicacionEtiquetajeEnum.PROPI || idUbicacionElaboracion == ubicacionEtiquetajeEnum.SUBCONTRATO"
                                    formControlName="textoElaboracion"
                                    [label]="literalesUbicaciones[idUbicacionElaboracion] | translate"
                            >

                            </app-input-text>

                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <app-input-text
                                    [value]="ubicacionesEnvasado[idUbicacionEnvase-1]?.descripcion?.toString()"
                                    [label]="'Envasament del producte' | translate"
                                    [isDisabled]="true"
                            >

                            </app-input-text>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <app-input-text
                                    *ngIf="idUbicacionEnvase == ubicacionEnum.PROPI || idUbicacionEnvase == ubicacionEnum.SUBCONTRATO"
                                    formControlName="textoEnvase"
                                    [label]="literalesUbicaciones[idUbicacionEnvase] | translate"
                            >

                            </app-input-text>

                        </div>

                    </div>
                </div>

                <div class="row d-flex justify-content-start mt-3" *ngIf="producto.requiereEtiqueta">
                    <label class="font-weight-bold">
                        {{'general.etiqueta' | translate}}
                    </label>
                    <div *ngIf="etiqueta; else noEtiqueta" class="file-open d-flex align-items-center file" data-toggle="tooltip"
                         [title]="etiqueta?.nombre"
                         (click)="onDownloadEtiqueta()">

                        <mat-icon>file_present</mat-icon>
                        {{ (etiqueta?.nombre | slice:0:24) + (etiqueta?.nombre.length > 24? '...' : '')}}
                    </div>
                    <ng-template #noEtiqueta >
                        <div [ngStyle]="{'margin-left.px': 2}">
                            {{'general.noEtiqueta' | translate}}
                        </div>
                    </ng-template>
                </div>

                <div class="row d-flex justify-content-start mt-3">
                    <label class="font-weight-bold">
                        {{'general.adjuntos' | translate}}
                    </label>
                    <div *ngIf="producto.adjuntos.length > 0; else noAdjuntos" class="row">
                        <div class="col-auto file-box" *ngFor="let file of producto.adjuntos">
                            <div class="file-open d-flex align-items-center file" data-toggle="tooltip"
                                 [title]="file.nombre"
                                 (click)="onDownloadAdjunto(file.idSolicitudAdjunto)"
                                 [ngStyle]="{'margin-right.px': 10}">
                                <mat-icon>file_present</mat-icon>
                                {{ (file.nombre | slice:0:24) + (file.nombre.length > 24? '...' : '')}}
                            </div>
                        </div>
                    </div>
                    <ng-template #noAdjuntos >
                        <div [ngStyle]="{'margin-left.px': 2}">
                            {{'general.noAdjuntos' | translate}}
                        </div>
                    </ng-template>
                </div>


            </mat-card-content>

        </mat-card>

        <mat-card class="card shadow" *ngIf="producto.esConIngredientes">
            <mat-card-title>
                {{'general.ingredientes' | translate}}
            </mat-card-title>
            <mat-card-content>
                <app-generic-table
                        [data]="producto?.ingredientes"
                        [columnsConfig]="ingredientesConfigTable"
                >
                </app-generic-table>
            </mat-card-content>
        </mat-card>

    </form>
    <button mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
        <mat-icon>arrow_back</mat-icon>
        {{"general.volver" | translate}}
    </button>
</app-page-template>
