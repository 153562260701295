import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";

export const OperadoresInstalacionRegistroTableConfig: TablaColumna[] =  [

    {
        header: 'OPERADORES.instalaciones.numOrdenRegistro',
        field: "numOrdenRegistro",
        type: "string"
    },
    {
        header: 'OPERADORES.instalaciones.tipoRegistro',
        field: "descripcionRegistro",
        type: "string"
    },
    {
        header: 'OPERADORES.instalaciones.numRegistro',
        field: "numRegistro",
        type: "string"
    },
    {
        header: 'OPERADORES.instalaciones.actividadAutorizada',
        field: "actividadAutorizada",
        type: "string"
    },
    {
        header: 'OPERADORES.instalaciones.fechaCaducidad',
        field: "fechaCaducidad",
        type: "date"
    }
]

export const OperadoresInstalacionRegistroEditAccionesConfig: TablaAccion[] = [
    {
        action: 'edit',
        icon: "edit",
        toolTip: "Editar"
    },
    {
        action: "delete",
        icon: "delete",
        toolTip: "Eliminar"
    }
]

export const OperadoresInstalacionRegistroViewAccionesConfig: TablaAccion[] = [

]