
<div class="mt-2 col-12 m-0 p-0">
    <div class="my-1">
        <label *ngIf="label && !campoObligatorio" class="font-weight-bold">{{label}}</label>
        <label *ngIf="label && campoObligatorio" class="font-weight-bold">{{label}} *</label>
    </div>
    <div class="d-flex">
        <input autocomplete="off" class="form-control m-0 date" placeholder="dd/mm/yyyy"
               (change)="onChangeValue($event)"
               (keydown)="onKeyDown($event)"
               name="dp" ngbDatepicker [minDate]="minDate" [maxDate]="maxDate" (dateSelect)="onChangeDate($event)"
               [value]="value | dateFormat"
               [disabled]="isDisabled" #d="ngbDatepicker"/>
        
        <div class="button-input-align m-0">               
            <button *ngIf="!isDisabled" class="btn btn-sm" (click)="d.toggle()" type="button">
                <mat-icon>today</mat-icon>
            </button>
        </div>
        <div *ngIf="deletable" class="button-input-align m-0">
            <button *ngIf="!isDisabled" class="btn btn-sm" (click)="removeValue(d)" type="button">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="ngControl?.invalid" class="invalid-feedback">
        <span *ngIf="ngControl.errors['required']">
            {{'formErrors.obligatorio' | translate}}
        </span>
    </div>
</div>
