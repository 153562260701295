import {HttpService} from "../core/http.service";
import {Observable, shareReplay, tap} from "rxjs";
import {Pageable} from "../../../model/core/pageable";
import {Almacen} from "../../../model/almacen/almacen";
import {CualificacionAlmacen} from "../../../model/almacen/cualificacionAlmacen";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../components/toast.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class AlmacenHttpService extends HttpService {


    private BASE = `almacenes`;
    private ALMACENES = (id: string) => `${this.BASE}/${id}`;
    private ALMACEN = (idOperador: string, numOrdenAlmacen: number) =>
        `${this.BASE}/${idOperador}/${numOrdenAlmacen}`;
    private CUALIFICACIONES= `${this.BASE}/cualificaciones`;


    constructor(http: HttpClient,
                toastSvc: ToastService,
                private translateSvc: TranslateService) {
        super(http, toastSvc);
    }

    
    public getAlmacenes(idOperador: string, page: number | null = null, filtros: object = {}): Observable<Pageable<Almacen>> { // Almacen crear back
        let httpParams = this.objectToHttpParams(filtros);
        if (page) {
            httpParams = httpParams.set("page", page - 1);
        }
        return this.get(this.ALMACENES(idOperador), httpParams)
            .pipe(tap(page => page.content = page.content.map(item => Object.assign(new Almacen(), item))));
    }

    public getAlmacen(idOperador: string, numOrdenAlmacen: number): Observable<Almacen> {
        return this.get(this.ALMACEN(idOperador, numOrdenAlmacen));
    }

    public createAlmacen(idOperador: string, almacen: Almacen){
        return this.post(this.ALMACENES(idOperador), almacen)
            .pipe(
                tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("successes.createAlmacenOk")))
            );
    }

    public updateAlmacen(idOperador: string, numOrdenAlmacen: number, almacen: Almacen): Observable<any> {
        return this.put(this.ALMACEN(idOperador, numOrdenAlmacen), almacen)
            .pipe(
                tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("successes.editAlmacenOk")))
            );
    }

    public deleteAlmacen(idOperador: string, almacen: Almacen){
        return this.delete(this.ALMACEN(idOperador, almacen.numOrdenAlmacen))
            .pipe(
                tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("successes.deleteAlmacenOk")))
            );
    }

    public getCualificaciones(): Observable<CualificacionAlmacen[]> {
        return this.get(this.CUALIFICACIONES)
            .pipe(shareReplay());
    }

}