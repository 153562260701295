import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {EtiquetasBaseComponent, ModalEdicion} from "../etiquetas-base.component";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalService} from "../../../../core/services/components/modal.service";
import {TramiteGuard} from "../../guards/tramite.guard";
import {TranslateService} from "@ngx-translate/core";
import {firstValueFrom} from "rxjs";
import {AccionesProductosTable, ProductosTableConfig} from "../../etiquetas.config";
import {TramiteImportadosGuard} from "../../guards/tramite-importados.guard";
import {
  EditProductoImportadosModalComponent
} from "../../modals/edit-producto-importados-modal/edit-producto-importados-modal.component";

@Component({
  selector: 'app-etiquetas-importados-peticion',
  templateUrl: './etiquetas-importados-peticion.component.html',
  styleUrls: ['./etiquetas-importados-peticion.component.scss'],
  providers: [
    {provide: TramiteGuard, useClass: TramiteImportadosGuard}
  ]
})
export class EtiquetasImportadosPeticionComponent extends EtiquetasBaseComponent implements OnInit {
  protected tableConfig = ProductosTableConfig;
  protected tableAcciones = AccionesProductosTable;

  constructor(injector: Injector,
              modalSvc: ModalService,
              tramiteGuard: TramiteGuard,
              private translateSvc: TranslateService,
              cdr: ChangeDetectorRef) {
    super(injector, modalSvc, tramiteGuard, cdr);
  }

  override onEditarProducto(producto: SolicitudProducto): MatDialogRef<ModalEdicion>{
    return this.modalSvc.openDialog(EditProductoImportadosModalComponent, {producto});
  }

  override async onHandleEditarProducto(idSolicitudProducto: number | null, producto: SolicitudProducto) {
    if (!producto) return;
    if (idSolicitudProducto) {
      await firstValueFrom(this.solicitudProductoSvc.modificarProducto(idSolicitudProducto, producto));
    } else {
      await firstValueFrom(this.solicitudProductoSvc.crearProducto(producto));
    }

    await this.etiquetasSvc.cargarProductos();
  }

  onHandleConfirmar(): void {
    this.etiquetasSvc.enviarSolicitud()
        .subscribe(_ => {
          window.self.close();
        })
  }

  override validateForm(productos: SolicitudProducto[]): boolean {
    return  productos.every(item => item.informacionCompleta &&
            (!this.tramiteEsmena || (this.tramiteEsmena && item.infoPadreMod && item.adjuntosPadreMod)))
        && productos.length > 0;
  }

  onGuardar() {
    this.toastSvc.showSuccess(this.translateSvc.instant("ETIQUETAS.guardarSolicitudOk"));
  }

}
