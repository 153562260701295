import {Etiqueta} from "../etiquetas/etiqueta";
import {TranslateService} from "@ngx-translate/core";
import {SolicitudProducto} from "../etiquetas/solicitud-producto";

export enum SolicitudEstado {
	BORRADOR = 1,
	PEND_ECITY = 2,
	PEND_GESTIO = 3,
	PEND_ESMENA = 4,
	PEND_REVISIO_CC = 5,
	PEND_REVISIO_IE = 6,
	CONFIRMADO_CCPAE = 7,
	ELIMINAT = 8
}

export class Solicitud {
	codigoOperador!: string;
	idSolicitud!: string;
	idSolicitudPadre: string | null | undefined;
	codigoTramite!: string;
	tipoTramite: string;
	estado!: SolicitudEstado;
	etiqueta?: Etiqueta | null;
	fechaCreacion!: Date;
	fechaDelete?: Date;
	usuarioDelete: string;
	motivoDelete: string;
	productos: SolicitudProducto[]

	constructor(
		private translate: TranslateService
	) {}

	public getEstado(): string {
		return this.estado != null ? this.translate.instant("OPERADORES.solicitudes.estado")[this.estado-1] : "--";
	}
	public getCodigoTramite(): string {
		return this.codigoTramite  != null? this.translate.instant("OPERADORES.solicitudes.codigoTramite." + this.codigoTramite) : "--";
	}
	public getTipoTramite(): string {
		return this.tipoTramite  != null ? this.translate.instant("OPERADORES.solicitudes.tipoTramite." + this.tipoTramite) : "--";
	}
}
