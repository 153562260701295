<app-page-template id="top"
        [title]="'navbar.altaOperador' | translate"
>
    <div class="col-12 d-flex justify-content-center ">
        <div class="chip  d-flex justify-content-center" [ngClass]="{active: (actualView==viewEnumForm.VIEW_DADES_GENERALS)}">
            {{'OPERADORES.datosGenerales' | translate}}
        </div>
        <div class="chip  d-flex justify-content-center" [ngClass]="{active: (actualView==viewEnumForm.VIEW_DADES_MAGATZEMS)}">
            {{'OPERADORES.datosAlmacenes' | translate}}
        </div>
        <div class="chip  d-flex justify-content-center" [ngClass]="{active: (actualView==viewEnumForm.VIEW_DADES_PRODUCCIO)}">
            {{'OPERADORES.datosProduccion' | translate}}
        </div>
        <div class="chip d-flex justify-content-center" [ngClass]="{active: (actualView==viewEnumForm.VIEW_DOCUMENTACIO)}">
            {{'general.documentacion' | translate}}
        </div>
        <div class="chip d-flex justify-content-center" [ngClass]="{active: (actualView==viewEnumForm.VIEW_PAGAMENT)}">
            {{'OPERADORES.datosPago' | translate}}
        </div>
    </div>


    <form [formGroup]="formGroup">

        <div [ngSwitch]="actualView">
            <div *ngSwitchCase="viewEnumForm.VIEW_DADES_GENERALS">
                <ng-container *ngTemplateOutlet="dadesGeneralsView"></ng-container>
            </div>
            <div *ngSwitchCase="viewEnumForm.VIEW_DADES_MAGATZEMS">
                <ng-container *ngTemplateOutlet="dadesMagatzemsView"></ng-container>
            </div>
            <div *ngSwitchCase="viewEnumForm.VIEW_DADES_PRODUCCIO">
                <ng-container *ngTemplateOutlet="dadesProdView"></ng-container>
            </div>
            <div *ngSwitchCase="viewEnumForm.VIEW_DOCUMENTACIO">
                <ng-container *ngTemplateOutlet="dadesDocuView"></ng-container>
            </div>
            <div *ngSwitchCase="viewEnumForm.VIEW_PAGAMENT">
                <ng-container *ngTemplateOutlet="dadesPagamentView"></ng-container>
            </div>
        </div>

        <!-- VIEWS FORMULARI --->
        <!-- VIEW FORM DADES GENERALS --->
        <ng-template class="mt-5" #dadesGeneralsView>
            <app-operadores-alta-general
                    [form]="formGeneral"
                    [operador]="operador"
                    (changeViewSiguientePage)="onPageConfirmed($event)">
            </app-operadores-alta-general>
        </ng-template>

        <!-- VIEW FORM DADES MAGATZEMS --->
        <ng-template class="mt-5" #dadesMagatzemsView>
            <app-operadores-alta-almacenes
                [operador]="operador"
                (changeViewSiguientePage)="onPageConfirmed($event)"
                (changeViewAtras)="changeViewAtras()">
            >
            </app-operadores-alta-almacenes>
        </ng-template>

        <!-- VIEW FORM DADES PRODUCCIO --->
        <ng-template class="mt-5" #dadesProdView>
            <app-operadores-alta-produccion
                    [activitats]="operador?.actividadesProduccion"
                    [form]="formProduccion"
                    (changeViewSiguientePage)="onPageConfirmed($event)"
                    (changeViewAtras)="changeViewAtras()">
            </app-operadores-alta-produccion>
        </ng-template>

        <!-- VIEW FORM DOCUMENTACIO --->
        <ng-template class="mt-5" #dadesDocuView>
            <app-operadores-alta-documentacion
                    [activitats]="operador?.actividadesProduccion"
                    [form]="formDocumentacion"
                    (changeViewAtras)="changeViewAtras()"
                    (changeViewSiguientePage)="onPageConfirmed($event)">
            </app-operadores-alta-documentacion>
        </ng-template>

        <!-- VIEW FORM PAGAMENT --->
        <ng-template class="mt-5" #dadesPagamentView>
            <mat-card class="mb-2 card shadow" formGroupName="pagament">
                <mat-card-title>
                    {{'OPERADORES.pagoCuotas' | translate}}
                </mat-card-title>
                <mat-card-content>
                    <div class="row">
                        <div class="col-md-4 col-sm-12" >
                            <p>{{'OPERADORES.opcionPago' | translate}}</p>
                            <app-input-radio
                                    formControlName="tipusPagament"
                                    [label]="'OPERADORES.domicilio' | translate"
                                    [id]="'domicili'"
                                    [value]="'D'">
                            </app-input-radio>
                            <app-input-radio
                                    formControlName="tipusPagament"
                                    [label]="'OPERADORES.transferencia' | translate"
                                    [id]="'transferencia'"
                                    [value]="'T'">
                            </app-input-radio>
                            <app-input-radio
                                    formControlName="tipusPagament"
                                    [label]="'OPERADORES.facturacionElectronica' | translate"
                                    [id]="'facturacioElectronica'"
                                    [value]="'FE'">
                            </app-input-radio>
                            <app-input-radio
                                    formControlName="tipusPagament"
                                    [label]="'OPERADORES.tarjetaCredito' | translate"
                                    [id]="'targetaCredit'"
                                    [value]="'TC'">
                            </app-input-radio>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <div class="col-12 p-2 mt-2 d-flex justify-content-end">
                <button mat-raised-button color="basic" class="px-2 mx-1"
                        (click)="changeViewAtras()"
                >
                    {{'general.atras' | translate}}
                </button>
                <button mat-raised-button color="primary" class="px-2 mx-1" [disabled]="!this.validateFormPagament()"
                        (click)="onConfirmarFinal()"
                >
                    {{'general.enviar' | translate}}
                </button>
            </div>
        </ng-template>
    </form>
</app-page-template>


