import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";
import {Operador} from "../../../../core/model/operadores/operador";

export const OperadoresListadoTableConfig: TablaColumna[] = [{
        header: "OPERADORES.id",
        field: "idOperador",
        type: "string",
        sortable: true,
        order: 'ASC'
    },
    {
        header: "OPERADORES.tipoOperador",
        field: "tipoOperador",
        type: "string",
        sortable: true,
        order: 'ASC'
    },
    {
        header: "general.nif",
        field: "nif",
        type: "string",
        sortable: true,
        order: 'ASC'
    },
    {
        header: "general.nombre",
        field: "nombreCompleto",
        type: "function",
        fn: (operador: Operador) =>{
            let nombreCompleto = operador.primerApellido + " " + operador.segundoApellido + ", " + operador.nombre;
            return  nombreCompleto.length > 3 ? nombreCompleto : "--";
        },
        sortable: true,
        order: 'ASC'
    },
    {
        header: "general.empresa",
        field: "empresa",
        type: "string",
        sortable: true,
        order: 'ASC'
    },
    {
        header: "general.comarca",
        field: "comarcaSocial",
        type: "string",
        sortable: true,
        order: 'ASC'
    },
    {
        header: "general.municipio",
        field: "municipioSocial",
        type: "string",
        sortable: true,
        order: 'ASC'
    },
    {
        header: "OPERADORES.fechaAlta",
        field: "fechaAlta",
        type: "date",
        sortable: true,
        order: 'ASC'
}]

export const OperadoresListActionsConfig: TablaAccion[] = [
    {
        icon: 'edit',
        action: 'edit',
        toolTip: 'Editar operador',
        condicion: "!fechaBaja"
    },
    {
        icon: 'pageview',
        action: 'view',
        toolTip: 'Veure operador'
    }
]
