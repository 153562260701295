import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {PersonaContacto} from "../../../../core/model/operadores/persona-contacto";

@Component({
  selector: 'app-edit-persona-contacto-modal',
  templateUrl: './edit-persona-contacto-modal.component.html',
  styleUrls: ['./edit-persona-contacto-modal.component.scss']
})
export class EditPersonaContactoModalComponent implements OnInit {
  protected formGroup: any;
  protected persona: PersonaContacto;
  protected editing: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) data: {persona:PersonaContacto},
              public dialogRef: MatDialogRef<any>,
              injector: Injector) {
    this.persona = data.persona;

    if(this.persona) this.editing = this.persona.NIFContCCPAE != null;
    else this.editing = false;

    this.formGroup = new FormGroup({
      "carrecDeptEmpresa": new FormControl("", Validators.required),
      "nomContCCPAE": new FormControl("", Validators.required),
      "primerCognomContCCPAE": new FormControl("", Validators.required),
      "segonCognomContCCPAE": new FormControl(""),
      "telefonoContCCPAE": new FormControl("", Validators.required),
      "NIFContCCPAE": new FormControl("", [Validators.required, this.formatoNIFValidator()]),
      "emailContCCPAE": new FormControl("", [Validators.email, Validators.required])
    })
  }

  ngOnInit(): void {
    this.formGroup.patchValue({... this.persona});
  }

  onSubmit() {
    this.dialogRef.close(this.formGroup.value)
  }

  private formatoNIFValidator(): ValidatorFn{
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      const pattern =  /^[A-Za-z]?\d{8}[A-Za-z]$/;

      if(!value)
        return null;

      const NIFFormatOK = pattern.test(value);

      return !NIFFormatOK ? {NIFValid: true}: null;
    }
  }
}
