import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatTabChangeEvent} from "@angular/material/tabs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {OperadorFormularioView} from "../../pages/operadores-alta/operadores-alta.component";
import {TipoActividad} from "../../../../core/model/operadores/tipo-actividad";

@Component({
  selector: 'app-operadores-alta-documentacion',
  templateUrl: './operadores-alta-documentacion.component.html',
  styleUrls: ['./operadores-alta-documentacion.component.scss']
})
export class OperadoresAltaDocumentacionComponent implements OnInit{
  selectedIndex: any;
  formDocumentacion: FormGroup;

  @Input() activitats: Array<TipoActividad>;
  @Input() form: FormGroup;
  @Output() changeViewSiguientePage = new EventEmitter<any>;
  @Output() changeViewAtras = new EventEmitter<any>;

  constructor() {
    this.formDocumentacion = new FormGroup({
      "prodVegetal": new FormGroup({
        "docTitularitat": new FormControl(null, Validators.required),
        "docAnnex1": new FormControl(null, Validators.required),
        "docAnnex2": new FormControl(null),
        "docAnnex3": new FormControl(null),
        "docAnnex4": new FormControl(null),
        "docAnnex5": new FormControl(null),
        "docAnnex6": new FormControl(null),
        "docAnnex7": new FormControl(null),
        "docAnnex8": new FormControl(null),
        "docModelQuadCamp": new FormControl(null),
      }),

      "prodRamadera": new FormGroup({
        "docPlanesAlojamiento": new FormControl(null, Validators.required),
        "docHojaDatosExpl": new FormControl(null, Validators.required),
        "docFichaTecnicaProdLimpieza": new FormControl(null),
        "docDescripciones": new FormControl(null),
        "docDescFems": new FormControl(null),
        "docModelQuadernExplotacio": new FormControl(null),
        "docMemHerbivors": new FormControl(null),
        "docMemAvicola": new FormControl(null),
        "docMemPorcina": new FormControl(null),
        "docHelicicola": new FormControl(null),
      })
    })
  }

  ngOnInit() {
    this.formDocumentacion.patchValue(this.form?.value);
  }

  onTabChange($event: MatTabChangeEvent) {

  }

  validateForm() {
    /*for(let [key,value] of this.familiesSelected){
      if(value && !this.validateSubForm(key)) return false
    }*/
    return true;
  }

  validateSubForm(subForm: string) {
    return this.formDocumentacion.controls[subForm].valid;
  }

  onChangeViewSiguiente() {
    this.changeViewSiguientePage.emit({formGroup: this.formDocumentacion, page: OperadorFormularioView.VIEW_DOCUMENTACIO});
  }

  onChangeViewAtras() {
    this.changeViewAtras.emit();
  }

  onFileDrop($event, doc: string) {
    let file: File = $event.file;
    this.formDocumentacion.get(doc)?.setValue(file);
  }

  onFileDeleted(doc: string) {
    this.formDocumentacion.get(doc)?.setValue(null);
  }


  checkFamiliaSelected(nomFamilia: string) {
    const act = this.activitats.find(act => act.nom == 'Producció primària' && act.selected);
    if(act)
      return act.families.some(familia => familia.nombre == nomFamilia && familia.selected)
    else
      return false;
  }
}
