import {NavbarItem} from "./NavbarItem";

export const NavbarConfig : Array<NavbarItem> = [
    {
        icon: 'dashboard',
        text: 'navbar.dashboard',
        url: '/dashboard',
    },
    {
        icon: "assignment",
        groupName: "etiquetas",
        type: 'group',
        text: 'Etiquetes',
        active: false,
        subItems: [
            {
                icon: 'shop_two',
                text: 'Elaborats',
                url: ['etiquetas', 'elaborats'],
                active: true
            },
            {
                icon: 'shop_two',
                text: 'Elaborats Confirmació',
                url: ['etiquetas', 'elaborats', 'confirmacio'],
                active: true
            },
            {
                icon: 'shop_two',
                text: 'Elaborats Finalització',
                url: ['etiquetas', 'elaborats', 'finalitzacio'],
                active: true
            },
            {
                icon: 'shop',
                text: 'Hortofrutícoles',
                url: '/etiquetas/hortofruticoles',
                active: true
            },
            {
                icon: 'shop',
                text: 'Hortofrutícoles Confirmació',
                url: 'etiquetas/hortofruticoles/confirmacio',
                active: true
            },
            {
                icon: 'shop',
                text: 'Hortofrutícoles Finalització',
                url: 'etiquetas/hortofruticoles/finalitzacio',
                active: true
            },
            {
                icon: 'shop',
                text: 'Distribuïts',
                url: '/etiquetas/distribuits',
                active: true
            },
            {
                icon: 'shop',
                text: 'Distribuïts Confirmació',
                url: 'etiquetas/distribuits/confirmacio',
                active: true
            },
            {
                icon: 'shop',
                text: 'Distribuïts Finalització',
                url: 'etiquetas/distribuits/finalitzacio',
                active: true
            },
            {
                icon: 'shop',
                text: 'Vins i derivats',
                url: '/etiquetas/vins-derivats',
                active: true
            },
            {
                icon: 'shop',
                text: 'Vins i derivats Confirmació',
                url: '/etiquetas/vins-derivats/confirmacio',
                active: true
            },
            {
                icon: 'shop',
                text: 'Vins i derivats Finalització',
                url: '/etiquetas/vins-derivats/finalitzacio',
                active: true
            },
            {
                icon: 'shop',
                text: 'Importats',
                url: '/etiquetas/importats',
                active: true
            },
            {
                icon: 'shop',
                text: 'Importats Confirmació',
                url: '/etiquetas/importats/confirmacio',
                active: true
            },
            {
                icon: 'shop',
                text: 'Importats Finalització',
                url: '/etiquetas/importats/finalitzacio',
                active: true
            },
        ]
    },
    {
        icon: 'people',
        text: 'navbar.operadores',
        url: '/operadors'
    }
/*    {
        icon: 'person',
        text: 'navbar.altaOperador',
        url: '/operadors/alta-operador'
    }*/
];
/*{
        icon: 'attach_money',
        text: 'general.presupuestos',
        url: '/presupuestos'
    }, {
        icon: 'description',
        text: 'general.partidasPresupuestarias',
        url: '/partidas-presupuestarias'
    }, {
        icon: 'fact_check',
        text: 'general.oportunidadesDerivadas',
        url: '/oportunidades-derivadas'
    }, {
        icon: 'assignment',
        text: 'general.oportunidadesSalesforce',
        url: '/oportunidades-salesforce'
    }, {
        icon: "build",
        groupName: "configuracion",
        type: 'group',
        text: 'general.configuracion',
        configurador: true,
        subItems: [{
            icon: 'business_center',
            text: 'general.oportunidades',
            url: '/oportunidades'
          }, {
            icon: 'description',
            text: 'general.normativas',
            url: '/normativas'
          }, {
            icon: 'dns',
            text: 'general.tiposServicio',
            url: '/tipo-servicio'
          }, {
            icon: 'list',
            text: 'general.camposEspecificos',
            url: '/campos-especificos'
          }
    ]
    }, {
        icon: "construction",
        groupName: "configuracionPresupuestos",
        type: 'group',
        text: 'general.configuracionPresupuestos',
        configuradorPresupuestos: true,
        subItems: [
            {
                icon: 'book',
                text: 'general.plantillaPresupuestos',
                url: '/plantillas-presupuestos'
            }, {
                icon: 'list',
                text: 'general.clienteTsDescuentoComision',
                url: '/cliente-ts-descuentos'
            }, {
                icon: 'view_week',
                text: 'general.calculosTipoServicio',
                url: '/calculos-tipo-servicio'
            }, {
                icon: 'people',
                text: 'general.proveedores',
                url: '/proveedores',
            }


        ]
    }, {
        icon: "settings",
        groupName: "administracion",
        type: 'group',
        text: 'general.administracion',
        configurador: true,
        subItems: [{
                icon: 'supervisor_account',
                text: 'usuarios.table.title',
                url: '/usuarios'
            }, {
                icon: 'card_travel',
                text: 'cuentas-sensedi.table.title',
                url: '/cuentas-sensedi'
            }, {
                icon: 'card_membership',
                text: 'cuentas-empresa.table.title',
                url: '/cuentas-empresa'
            }, {
                icon: 'send',
                text: 'general.sincronizacion',
                url: '/sincronizacion'
            }]
    }]*/
