import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {SolicitudProductoHttpService} from "../../../../core/services/http/solicitud/solicitud-producto.http.service";
import {SolicitudAdjunto} from "../../../../core/model/etiquetas/solicitud-adjunto";

@Component({
  selector: 'app-edit-adjuntos-modal',
  templateUrl: './edit-adjuntos-modal.component.html',
  styleUrls: ['./edit-adjuntos-modal.component.scss']
})
export class EditAdjuntosModalComponent implements OnInit {
  producto: SolicitudProducto;
  adjuntos: SolicitudAdjunto[] = [];
  readOnly: boolean = false;

  protected solicitudProductoSvc: SolicitudProductoHttpService;

  constructor(@Inject(MAT_DIALOG_DATA) data : {producto: SolicitudProducto, readOnly: boolean},
              public dialogRef: MatDialogRef<EditAdjuntosModalComponent>,
              injector: Injector) {

    this.solicitudProductoSvc = injector.get(SolicitudProductoHttpService);

    this.producto = data.producto;
    this.readOnly = data.readOnly;
  }

  ngOnInit(): void {
    this.solicitudProductoSvc.getAdjuntos(this.producto.idSolicitudProducto)
        .subscribe(items => {
          this.adjuntos = items;
        })
  }
}
