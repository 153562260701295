import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'emptyOutput'
})
export class EmptyOutputPipe implements PipeTransform {

  transform(value: any): any {

    if (value == null || value == undefined || value === "") {

      return "--";
    }

    return value;
  }

}
