<form [formGroup]="formLocalizacion">
    <div class="row">
        <div *ngIf="enableProvincia" class="col-md-3 col-xl-3">
            <app-input-select
                    [label]="'general.provincia' | translate"
                    formControlName="idProvincia"
                    [options]="provincias | async | arrayToSelectItem:'idProvincia':'descripcion'"
                    [campoObligatorio]="campoObligatorio"
            >
            </app-input-select>
        </div>
        <div class="col-md-3 col-xl-3">
            <app-input-select
                    [label]="'general.comarca' | translate"
                    formControlName="idComarca"
                    [options]="comarcas | async | arrayToSelectItem:'idComarca':'descripcion'"
                    [campoObligatorio]="campoObligatorio"
            >
            </app-input-select>
        </div>
        <div class="col-md-4 col-xl-3">
            <app-input-select
                    [label]="'general.municipio' | translate"
                    formControlName="municipio"
                    [options]="municipios | async | arrayToSelectItem:'descripcion':'descripcion'"
                    [campoObligatorio]="campoObligatorio"
            >
            </app-input-select>
        </div>
        <div class="col-md-3 col-xl-3">
            <app-input-select
                    [label]="'general.codigoPostal' | translate"
                    formControlName="codigoPostal"
                    [options]="codigosPostales | async | arrayToSelectItem:'codigoPostal': showCodigos"
                    [campoObligatorio]="campoObligatorio"
            >
            </app-input-select>
        </div>
        <div *ngIf="enableInspector" class="col-md-3 col-xl-2">
            <app-input-text
                    formControlName="inspector"
                    [label]="'general.inspector' | translate"
            >
            </app-input-text>
        </div>
        <div *ngIf="!readonly" class="col-md-1 col-xl-1">
            <button class="btn" type="button"
                    [disabled]="formLocalizacion.get('idProvincia')?.value === undefined ||
                                formLocalizacion.get('idProvincia')?.value === null"
                    (click)="onReset()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</form>
