<div>
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div>
            {{'ETIQUETAS.motivoRechazo' | translate}} "{{producto.nombreComercial}}"
        </div>

        <div class="clickable" (click)="dialogRef.close(producto)">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <form [formGroup]="formGroup">
        <div class="row">
            <div class="col-md-6 col-xl-3">
                <app-input-select
                        formControlName="idTipoRechazo"
                        [label]="'ETIQUETAS.tipoRechazo' | translate"
                        [options]="tiposRechazo | async | arrayToSelectItem:'idTipoRechazo':'descripcionTipoRechazo'"
                        [campoObligatorio]=true
                        [isDisabled]="esVisualizacion"
                >
                </app-input-select>
            </div>
            <div class="col-md-6 col-xl-9">
                <app-input-text
                        formControlName="motivoRechazo"
                        [campoObligatorio]="true"
                        [label]="'ETIQUETAS.motivoRechazo' | translate"
                        [isDisabled]="esVisualizacion"
                >
                </app-input-text>
            </div>
        </div>
    </form>

    <div *ngIf="!esVisualizacion" class="col-12 p-2 mt-2 d-flex justify-content-end">
        <button mat-raised-button color="primary" class="px-2" [disabled]="!formGroup.valid" (click)="onSubmit(this.formGroup.value)">
            {{'general.guardar' | translate}}
        </button>
    </div>

</div>
