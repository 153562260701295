import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private duration = 5000;

  constructor(private _snackBar: MatSnackBar) {
  }

  show(textOrTpl: string) {
    if(!textOrTpl || textOrTpl == "")
      return;

    this._snackBar.open(textOrTpl, "Close", {
      duration: this.duration,
      horizontalPosition: "start",
      verticalPosition: "top"
    });
  }

  showError(textOrTpl: string) {
    if(!textOrTpl || textOrTpl == "")
      return;

    this._snackBar.open(textOrTpl, "Close", {
      duration: this.duration,
      horizontalPosition: "end",
      verticalPosition: "top",
      panelClass: "toast-error"
    });
  }

  showSuccess(textOrTpl: string) {
    if(!textOrTpl || textOrTpl == "")
      return;

    this._snackBar.open(textOrTpl, "Close", {
      duration: this.duration,
      horizontalPosition: "end",
      verticalPosition: "top",
      panelClass: "toast-success"
    });
  }

}
