import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";

export const ProductosConfigTable: TablaColumna[] = [
    {
        header: 'OPERADORES.productos.idProducto',
        field: "idSolicitudProducto",
        type: "function",
        fn: (rowData) => {
            return rowData.idSolicitudProductoPadre ? rowData.idSolicitudProductoPadre : rowData.idSolicitudProducto;
        }
    },
    {
        header: 'ETIQUETAS.nombreComercial',
        field: "nombreComercial",
        type: "string"
    },
    {
        header: 'general.marca',
        field: "marca",
        type: "string"
    },
    {
        header: 'general.envase',
        field: "tipoEnvase",
        type: "string"
    },
    {
        header: 'general.capacidad',
        field: "capacidad",
        type: "string"
    }
]

export const SolicitudProductoAccionesConfig: TablaAccion[] = [
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure dades"
    }
]
