import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from "rxjs/operators";
import {LoaderService} from "../services/components/loader.service";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private REFRESH_URL = "/ccpae-api/api/login/refresh";

  private peticionesPendientes = 0;

  constructor(private loaderService: LoaderService){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.endsWith(this.REFRESH_URL)) {
      return next.handle(req);
    }

    this.mostrarLoader();
    return next.handle(req).pipe(
      finalize(() => {
        this.onEnd();
      })
    )
  }

  private onEnd(): void {
    this.peticionesPendientes--;

    if (this.peticionesPendientes == 0)
      this.ocultarLoader();
  }

  private mostrarLoader(): void {
    this.peticionesPendientes++;
    this.loaderService.show();
  }
  private ocultarLoader(): void {
    this.loaderService.hide();
  }

}
