import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {InputNumberFormatPipe} from "./pipe/input-number-format.pipe";

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit, ControlValueAccessor, OnChanges {

  private inputNumberFormat = new InputNumberFormatPipe();

  @Input() id : string;
  @Input() label;
  @Input() value: "" | number | string = "";
  @Input() min: any = 0;
  @Input() max: any = 999999999;
  @Input() units = null;
  @Input() placeholder = "";
  @Input() numerosDecimales: number | string;
  @Input() campoObligatorio: boolean = false;

  modificado = false;
  @Input("disabled") isDisabled: boolean;
  onChange = (_: any) => {
  }
  onTouch = () => { }

  public ngControl!: NgControl;

  constructor(private inj: Injector) {
    this.id = "noName"
    this.label = "";
    this.isDisabled = false;
    this.numerosDecimales = 0;
  }

  ngOnInit(): void {
    try {
      this.ngControl = this.inj.get(NgControl)
      this.ngControl.valueAccessor = this;
    } catch (e) {

    }

    if(!this.placeholder) {
      this.placeholder = this.label;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] != null) {
      if (isFinite(changes['value'].currentValue)) {
        if (this.numerosDecimales) {
          this.value = this.inputNumberFormat.transform(changes['value'].currentValue, this.numerosDecimales);
        }
      }

    }
  }

  keyUp(_: any) {
    this.modificado = true;
  }

  change($event: any) {
    if(this.numerosDecimales > 0) {
      this.onChange(this.inputNumberFormat.transform($event.target.value, this.numerosDecimales));
    } else {
      this.onChange($event.target.value);
    }

    this.writeValue($event.target.value);
  }

  writeValue(value: any): void {
    this.value = Math.min(this.max, Number(value));
    this.value = Math.max(this.min, Number(this.value));
    this.value = this.inputNumberFormat.transform(this.value, this.numerosDecimales);
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}
