import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {EtiquetasCompuestoBaseComponent} from "../etiquetas-compuesto-base.component";
import {ModalService} from "../../../../core/services/components/modal.service";
import {SolicitudProductoCompuesto} from "../../../../core/model/etiquetas/solicitud-producto-compuesto";
import {EditProductoVinoModalComponent} from "../../modals/edit-producto-vino-modal/edit-producto-vino-modal.component";
import {AccionesProductosCompuestos, ProductosTableConfig} from "../../etiquetas.config";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalEdicion} from "../etiquetas-base.component";
import {firstValueFrom} from "rxjs";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {TramiteGuard} from "../../guards/tramite.guard";
import {TramiteVinosGuard} from "../../guards/tramite-vinos.guard";
import {TranslateService} from "@ngx-translate/core";

@Component({
  templateUrl: './etiquetas-vinosderivados-peticion.component.html',
  styleUrls: ['./etiquetas-vinosderivados-peticion.component.scss'],
  providers: [
    {provide: TramiteGuard, useClass: TramiteVinosGuard}
  ]
})
export class EtiquetasVinosderivadosPeticionComponent extends EtiquetasCompuestoBaseComponent implements OnInit {

  protected tableConfig = ProductosTableConfig;
  protected tableAcciones = AccionesProductosCompuestos;

  constructor(injector: Injector,
              modalSvc: ModalService,
              tramiteGuard: TramiteGuard,
              private translateSvc: TranslateService,
              cdr: ChangeDetectorRef) {
    super(injector, modalSvc, tramiteGuard, cdr);
  }

  override onEditarProducto(producto: SolicitudProductoCompuesto): MatDialogRef<ModalEdicion> {
    return this.modalSvc.openDialog(EditProductoVinoModalComponent, {producto});
  }

  override async onHandleEditarProducto(idSolicitudProducto: number | null, producto: SolicitudProducto) {
    let productoCompuesto = producto as SolicitudProductoCompuesto;

    if (!productoCompuesto) {
      return;
    }

    if (idSolicitudProducto) {
      await firstValueFrom(this.solicitudProductoCompuestoSvc.modificarProducto(idSolicitudProducto, productoCompuesto));
    } else {
      await firstValueFrom(this.solicitudProductoCompuestoSvc.crearProducto(productoCompuesto));
    }
    await this.etiquetasSvc.cargarProductos();
  }

  override validateForm(productos: SolicitudProductoCompuesto[]): boolean {
    return (productos.every(item => item.ingredientesCorrectos && item.informacionCompleta
        &&  (!this.tramiteEsmena || (this.tramiteEsmena && item.infoPadreMod && item.ingredientesPadreMod && (!item.requiereEtiqueta || item.etiquetaPadreMod) && item.adjuntosPadreMod))
        && (item.tieneEtiqueta || !item.requiereEtiqueta)) && productos.length > 0);
  }

  override onHandleConfirmar() {
    this.etiquetasSvc.enviarSolicitud()
        .subscribe(_ => {
          window.self.close();
        })
  }

  onGuardar() {
    this.toastSvc.showSuccess(this.translateSvc.instant("ETIQUETAS.guardarSolicitudOk"));
  }

}
