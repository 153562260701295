import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {OperadoresHttpService, TipoAutorizacionEnum} from "../../../../core/services/http/operadores-http.service";
import {lastValueFrom, Observable} from "rxjs";
import {
  OperadoresSolicitudAccionesConfig,
  OperadoresSolicitudTableConfig
} from "./operadores-solicitud-table.config";
import {Pageable} from "../../../../core/model/core/pageable";
import {ActivatedRoute, Router} from "@angular/router";
import {Page} from "../../../../core/model/core/pages/page";
import {ModalService} from "../../../../core/services/components/modal.service";
import {Solicitud} from "../../../../core/model/solicitud/solicitud";
import {SolicitudHttpService} from "../../../../core/services/http/solicitud/solicitud.http.service";
import {
  DisclaimerEliminarSolicitudComponent
} from "../../modals/disclaimer-eliminar-solicitud/disclaimer-eliminar-solicitud.component";
import {AuthService} from "../../../../core/services/security/auth.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CodigoTramite} from "../../../../core/model/solicitud/codigoTramite";
import {TipoTramite} from "../../../../core/model/solicitud/tipoTramite";
import {EstadoSolicitud} from "../../../../core/model/solicitud/estadoSolicitud";


@Component({
  selector: 'app-operadores-listado-solicitud',
  templateUrl: './operadores-listado-solicitud.component.html',
  styleUrls: ['./operadores-listado-solicitud.component.scss']
})
export class OperadoresListadoSolicitudComponent extends Page implements OnInit {

  @ViewChild('formElement') formElement: ElementRef;

  protected modalSvc: ModalService;
  protected operadorSvc: OperadoresHttpService;
  protected solicitudSvc: SolicitudHttpService;
  protected authSvc: AuthService;

  protected tableConfig = OperadoresSolicitudTableConfig;
  protected tableAcciones = OperadoresSolicitudAccionesConfig;

  protected data: Pageable<Solicitud>;
  protected page = 1;
  protected idOperador: string;
  protected usuarioLogeado;
  protected estadosSolicitud: Observable<EstadoSolicitud[]>;
  protected tiposTramites: Observable<TipoTramite[]>;
  protected codigosTramites: Observable<CodigoTramite[]>;

  protected formGroup: FormGroup;
  protected filtros: object;

  constructor(private router: Router,
              private activateRoute: ActivatedRoute,
              injector: Injector) {
    super();

    this.modalSvc = injector.get(ModalService)
    this.operadorSvc = injector.get(OperadoresHttpService);
    this.solicitudSvc = injector.get(SolicitudHttpService);
    this.authSvc = injector.get(AuthService);

    this.data = new Pageable<Solicitud>();
    this.page = 1;
    this.idOperador = this.activateRoute.snapshot.params['idOperador'];

    this.formGroup = new FormGroup({
      'idSolicitud': new FormControl(null, [Validators.maxLength(20)]),
      'idSolicitudProducto': new FormControl(null, [Validators.pattern(/^[0-9]*$/)]),
      'idSolicitudProductoPadre': new FormControl(null, [Validators.pattern(/^[0-9]*$/)]),
      'estado': new FormControl(null),
      'tipoTramite': new FormControl(null),
      'codigoTramite': new FormControl(null),
      'fechaIni': new FormControl(null),
      'fechaFi': new FormControl(null)
    })

  }

  async ngOnInit() {
    this.data = await lastValueFrom(this.operadorSvc.getSolicitudes(this.idOperador, this.page));
    this.estadosSolicitud = this.solicitudSvc.getEstadosSolicitud();
    this.tiposTramites = this.solicitudSvc.getTiposTramites();
    this.codigosTramites = this.solicitudSvc.getCodigosTramites();
    this.authSvc.fullName.subscribe(value => {this.usuarioLogeado = value;});
  }

  public async onPageChange(page: number) {
    this.page = page;
    this.data = await lastValueFrom(this.operadorSvc.getSolicitudes(this.idOperador, page));
  }

  public onAction($event: any) {
    switch($event.action){
      case "view":
        this.viewSolicitud($event.item);
        break;
      case "delete":
        this.deleteSolicitud($event.item);
        break;
    }
  }

  public viewSolicitud(item){
    this.router.navigate(['solicitud', item.idSolicitud],
        {relativeTo: this.activateRoute})
  }

  private deleteSolicitud(item) {
    this.modalSvc.openDialog(DisclaimerEliminarSolicitudComponent, {textoDisclaimer: 'OPERADORES.solicitudes.disclaimerEliminar'}, {maxWidth: '30vw'})
        .afterClosed().subscribe((motivoDelete: string | undefined) => {
      if (motivoDelete) {
        this.solicitudSvc.eliminarSolicitud(item.idSolicitud, motivoDelete, this.usuarioLogeado)
            .subscribe(_ => this.onPageChange(this.page));
      }
    });
  }

  onBack(){
    this.router.navigate(["../../"], {relativeTo: this.activateRoute})
  }

  protected onReset() {
    this.formGroup.reset();
    this.filtros = {};
    this.onSearch(this.filtros);
  }

  protected async onSearch(value) {
    this.page = 1;
    this.filtros = value;
    //this.data = await lastValueFrom(this.autorizacionesSvc.getAutorizaciones(this.idOperador, this.tipoActual, this.page, this.filtros));
    this.data = await lastValueFrom(this.operadorSvc.getSolicitudes(this.idOperador, this.page, this.filtros));

  }

  ngAfterViewInit() {
    this.formElement.nativeElement.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        this.onSearch(this.formGroup.value);
        event.preventDefault();
      }
    });
  }





}
