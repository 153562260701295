import {Injectable} from '@angular/core';
import {HttpService} from "./core/http.service";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../components/toast.service";
import {Observable, tap} from "rxjs";
import {Pageable} from "../../model/core/pageable";
import {Animal} from "../../model/animal/animal";
import {AnimalCenso} from "../../model/animal/animalCenso";
import {Cualificacion} from "../../model/animal/cualificacion";
import {ClasificacionUbm} from "../../model/animal/clasificacionUbm";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class AnimalHttpService extends HttpService {

  private BASE = "animales";

  private ANIMALES_PAGE = (id: string) => `${this.BASE}/${id}/page`
  private ANIMALES_LIST = (id: string) => `${this.BASE}/${id}/list`

  private ANIMAL_WITHGRANJA = (idOperador: string, numGranja: string, idEspecie: number, idRaza: string, idTipoGanado: string) =>
      `${this.BASE}/${idOperador}/${numGranja}/${idEspecie}/${idRaza}/${idTipoGanado}`;
  private ANIMAL_NOGRANJA = (idOperador: string, idEspecie: number, idRaza: string, idTipoGanado: string) =>
      `${this.BASE}/${idOperador}/${idEspecie}/${idRaza}/${idTipoGanado}`;

  private ANIMAL_CREATE = (idOperador: string) => `${this.BASE}/${idOperador}/create`;

  private ANIMAL_CENSO = (idOperador: string, numGranja: string, idEspecie: number, idRaza: string, idTipoGanado: string, numOrden: number) =>
      `${this.BASE}/${idOperador}/${numGranja}/${idEspecie}/${idRaza}/${idTipoGanado}/animalCenso/${numOrden}`;

  private ANIMAL_CENSO_CREATE  = (idOperador: string, numGranja: string, idEspecie: number, idRaza: string, idTipoGanado: string) =>
      `${this.BASE}/${idOperador}/${numGranja}/${idEspecie}/${idRaza}/${idTipoGanado}/animalCenso/create`;

  private CUALIFICACIONES = `${this.BASE}/cualificaciones`
  private CLASIFICACIONES_UBM = (idProducto: number) => `${this.BASE}/clasificacionesUbm/${idProducto}`

  constructor(http: HttpClient,
              toastSvc: ToastService,
              private translateSvc: TranslateService) {
    super(http, toastSvc);
  }

  // Animales genéricos
  public getAnimalesPage(idOperador: string, page: number | null = null, filtros: object = {}): Observable<Pageable<Animal>> {
    let httpParams = this.objectToHttpParams(filtros);
    if (page) {
      httpParams = httpParams.set("page", page - 1);
    }
    return this.get(this.ANIMALES_PAGE(idOperador), httpParams)
        .pipe(tap(page => page.content = page.content.map(item => Object.assign(new Animal(), item))));
  }

  public getAnimalesList(idOperador: string): Observable<Animal[]> {

    return this.get(this.ANIMALES_LIST(idOperador));
  }

  public getAnimal(idOperador: string, numGranja: string, idEspecie: number, idRaza: string, idTipoGanado: string): Observable<Animal> {

    if (numGranja != "")  return this.get(this.ANIMAL_WITHGRANJA(idOperador, numGranja, idEspecie, idRaza, idTipoGanado));
    else return this.get(this.ANIMAL_NOGRANJA(idOperador, idEspecie, idRaza, idTipoGanado));
  }

  public createAnimal(idOperador: string, animal: Animal): Observable<Animal> {
    return this.post(this.ANIMAL_CREATE(idOperador), animal)
        .pipe(
            tap(_ =>this.toastSvc.showSuccess(this.translateSvc.instant("successes.createAnimalOk")))
        );
  }

  public updateAnimal(idOperador: string, numGranja: string, idEspecie: number, idRaza: string, idTipoGanado: string, animal: Animal): Observable<Animal> {
    return this.put(this.ANIMAL_WITHGRANJA(idOperador, numGranja, idEspecie, idRaza, idTipoGanado), animal)
        .pipe(
            tap(_ =>this.toastSvc.showSuccess(this.translateSvc.instant("successes.modificacionAnimalOk")))
        );
  }

  public eliminarAnimal(idOperador: string, animal: Animal) {

    return this.delete(this.ANIMAL_WITHGRANJA(idOperador, animal.numGranja, animal.idEspecie, animal.codigoRaza, animal.codigoTipoGanado), animal);
  }

  // Animales Censo
  public getAnimalCenso(idOperador: string, numGranja: string, idEspecie: number, idRaza: string, idTipoGanado: string, numOrden: number): Observable<AnimalCenso> {
    return this.get(this.ANIMAL_CENSO(idOperador, numGranja, idEspecie, idRaza, idTipoGanado, numOrden));
  }

  public createAnimalCenso(idOperador: string, numGranja: string, idEspecie: number, idRaza: string, idTipoGanado: string, animalCenso: AnimalCenso): Observable<AnimalCenso> {
    return this.post(this.ANIMAL_CENSO_CREATE(idOperador, numGranja, idEspecie, idRaza, idTipoGanado), animalCenso)
        .pipe(
            tap(_ =>this.toastSvc.showSuccess(this.translateSvc.instant("successes.createAnimalCensoOk")))
        );
  }

  public updateAnimalCenso(idOperador: string, numGranja: string, idEspecie: number, idRaza: string, idTipoGanado: string, numOrden: number, animalCenso: AnimalCenso): Observable<AnimalCenso> {
    return this.put(this.ANIMAL_CENSO(idOperador, numGranja, idEspecie, idRaza, idTipoGanado, numOrden), animalCenso)
        .pipe(
            tap(_ =>this.toastSvc.showSuccess(this.translateSvc.instant("successes.modificacionAnimalCensoOk")))
        );
  }

  public deleteAnimalCenso(idOperador: string, animalCenso: AnimalCenso) {
    return this.delete(this.ANIMAL_CENSO(idOperador, animalCenso.numGranja, animalCenso.idEspecie, animalCenso.codigoRaza, animalCenso.codigoTipoGanado, animalCenso.numOrden), animalCenso);
  }

  // extras

  public getCualificaciones(): Observable<Cualificacion[]> {

    return this.get(this.CUALIFICACIONES);
  }

  public getClasificacionesUbm(idProducto: number): Observable<ClasificacionUbm[]> {

    return this.get(this.CLASIFICACIONES_UBM(idProducto));
  }

}
