import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  AccionesTable,
  AnimalesTableConfig,
  ParcelasTableConfig
} from "../../pages/operadores-alta/operadores-alta.config";
import {TableEvent} from "../../../../shared/components/generic-table/generic-table.component";
import {OperadorFormularioView} from "../../pages/operadores-alta/operadores-alta.component";
import {TipoActividad} from "../../../../core/model/operadores/tipo-actividad";
import {Municipio} from "../../../../core/model/localizacion/municipio";

@Component({
  selector: 'app-operadores-alta-produccion',
  templateUrl: './operadores-alta-produccion.component.html',
  styleUrls: ['./operadores-alta-produccion.component.scss']
})
export class OperadoresAltaProduccionComponent implements OnInit{
  formProduccion: FormGroup;
  selectedIndex: any;
  parcelasVisibles = [
    {
      municipio: "municipi1",
      sigpac: "aaa",
      superficie: 34,
      cultivo: "BLAT",
      variedad: "varietat 1",
      rvc: "bbb"
    },
    {
      municipio: "municipi2",
      sigpac: "aaa",
      superficie: 34,
      cultivo: "PASTANAGA",
      variedad: "varietat 2",
      rvc: "bbb"
    }
  ];
  parcelasTableConfig = ParcelasTableConfig;
  tableAcciones = AccionesTable;
  animalesVisibles =  [
    {
      especie: "Vaca",
      raza: "aaa",
      tipusBestiar: "bbb",
      censo: 100
    },
    {
      especie: "Ovella",
      raza: "aaa",
      tipusBestiar: "bbb",
      censo: 100
    },
  ];
  animalesTableConfig = AnimalesTableConfig;

  @Input() form: FormGroup;
  @Input() activitats: Array<TipoActividad>;
  @Output() changeViewSiguientePage = new EventEmitter<any>;
  @Output() changeViewAtras = new EventEmitter<any>;
  municipioFemer: Municipio;
  municipioAlojamiento: any;


  constructor() {
    this.formProduccion = new FormGroup({
      "prodRamadera": new FormGroup({
        "dadesGeneralRamaderia": new FormGroup({
          "numCodiREGA": new FormControl("", Validators.required),
          "marcaOficial": new FormControl("", Validators.required),
          "classZootecnica": new FormControl(""),
          "coordGeoUP": new FormControl(""),
        }),
        "ubiFemer": new FormGroup({
          "UFDireccion": new FormControl(""),
          "UFProvincia": new FormControl(""),
          "UFComarca": new FormControl(""),
          "UFCodigoPostal": new FormControl(""),
          "UFMunicipio": new FormControl("")
        }),
        "ubiAlojamiento": new FormGroup({
          "UADireccion": new FormControl(""),
          "UAProvincia": new FormControl(""),
          "UAComarca": new FormControl(""),
          "UACodigoPostal": new FormControl(""),
          "UAMunicipio": new FormControl("")
        }),
        "ubiAbellars": new FormGroup({
          "UAbDireccion": new FormControl(""),
          "UAbProvincia": new FormControl(""),
          "UAbComarca": new FormControl(""),
          "UAbCodigoPostal": new FormControl(""),
          "UAbMunicipio": new FormControl("")
        })
      })
    })
  }

  ngOnInit() {
    this.formProduccion.patchValue(this.form?.value);
  }

  onTableCheckValueChange($event: any) {

  }

  onActionTableParcela($event: TableEvent<any>) {

  }

  onActionTableAnimales($event: TableEvent<any>) {

  }

  onAddAnimal() {

  }

  onTabChange($event: any) {

  }

  onChangeViewSiguiente() {
    this.changeViewSiguientePage.emit({formGroup: this.formProduccion, page: OperadorFormularioView.VIEW_DADES_PRODUCCIO});
  }

  onChangeViewAtras() {
    this.changeViewAtras.emit();
  }

  validateForm() {
    for(const act of this.activitats){
      if(act.selected && !this.validateSubForm(act.nom)) return false
    }
    return true;
  }

  validateSubForm(subForm: string) {
    //TODO adapt to correct checks for vegetal
    if(subForm == 'prodRamadera') return this.formProduccion.controls[subForm]?.valid;
    else return true;
  }

  checkFamiliaSelected(nomFamilia: string) {
    const act = this.activitats.find(act => act.nom == 'Producció primària' && act.selected);
    if(act)
      return act.families.some(familia => familia.nombre == nomFamilia && familia.selected)
    else
      return false;
  }
}
