<div>
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div>
            {{'general.etiqueta' | translate}} "{{producto.nombreComercial}}"
        </div>

        <div class="clickable" (click)="onCancel()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <hr/>

    <div>
        <div class="row" *ngIf="etiqueta">
            <div class="col-10">
                <app-input-text
                    [value]="etiqueta.nombre"
                    [isDisabled]="true"
                >
                </app-input-text>
            </div>
            <div class="col-2 d-flex align-self-center clickable">
                <mat-icon (click)="onDownload()">preview</mat-icon>
                <mat-icon (click)="onDelete()">delete_forever</mat-icon>
            </div>
        </div>
        <div *ngIf="!etiqueta">
            <app-drop-file-zone
                (onFilesDrop)="onFileDrop($event)"
                [accept]="'.jpg,.jpeg,.png,.pdf'"
            >
            </app-drop-file-zone>
        </div>
    </div>

    <div *ngIf="!etiqueta" [formGroup]="formGroup" class="mt-2 col-12">
        <h2 *ngIf="this.solicitudEstado !== 3">{{'general.requisitos' | translate}}</h2>

        <div formArrayName="disclaims">
            <div *ngFor="let disclaim of disclaims().controls; let i=index">
                <div [formGroupName]="i" class="col-12">
                    <app-input-checkbox
                        formControlName="checked"
                        [label]="disclaim.get('text').value"
                    >
                    </app-input-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!etiqueta" class="row mt-3">
        <div class="d-flex justify-content-end">
            <button mat-raised-button color="warn" class="mx-2" type="button" (click)="onCancel()">
                Cancelar
            </button>
            <button mat-raised-button color="primary" type="submit" (click)="onSubmit()"
                    [disabled]="formGroup.invalid || !this.file">
                Desar
            </button>
        </div>
    </div>

</div>
