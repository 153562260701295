import {HttpService} from "../core/http.service";
import {HttpClient} from "@angular/common/http";
import {Observable, shareReplay,tap} from "rxjs";
import {ToastService} from "../../components/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {Pageable} from "../../../model/core/pageable";
import {Instalacion} from "../../../model/instalacion/instalacion";
import {Registro} from "../../../model/instalacion/registro";
import {Injectable} from "@angular/core";
import {TipoRegistro} from "../../../model/instalacion/tipoRegistro";
import {Actividad} from "../../../model/instalacion/actividad";


@Injectable({
    providedIn: 'root'
})
export class InstalacionHttpService extends HttpService {


    private BASE = `instalaciones`
    private INSTALACIONES = (id: string) => `${this.BASE}/${id}`
    private INSTALACION = (idOperador: string, numOrdenInstalacion: number) =>
        `${this.BASE}/${idOperador}/${numOrdenInstalacion}`;
    private REGISTROS = (idOperador: string, numOrdenInstalacion: number) =>
        `${this.BASE}/${idOperador}/${numOrdenInstalacion}/registros`;
    private REGISTRO = (idOperador: string, numOrdenInstalacion: number, numOrdenRegistro: number) =>
        `${this.BASE}/${idOperador}/${numOrdenInstalacion}/registros/${numOrdenRegistro}`;
    private TIPOS_REGISTRO= `${this.BASE}/registros`;
    private ACTIVIDADES= `${this.BASE}/actividades`;


    constructor(http: HttpClient,
                toastSvc: ToastService,
                private translateSvc: TranslateService) {
        super(http, toastSvc);
    }


    public getInstalaciones(idOperador: string, page: number | null = null, filtros: object = {}): Observable<Pageable<Instalacion>> {
        let httpParams = this.objectToHttpParams(filtros);
        if (page) {
            httpParams = httpParams.set("page", page - 1);
        }
        return this.get(this.INSTALACIONES(idOperador), httpParams)
            .pipe(tap(page => page.content = page.content.map(item => Object.assign(new Instalacion(), item))));
    }

    public getInstalacion(idOperador: string, numOrdenInstalacion: number): Observable<Instalacion> {
        return this.get(this.INSTALACION(idOperador, numOrdenInstalacion));
    }

    public createInstalacion(idOperador: string, instalacion: Instalacion): Observable<any> {
        return this.post(this.INSTALACIONES(idOperador), instalacion)
            .pipe(
                tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("successes.createInstalacionOk")))
            );
    }

    public updateInstalacion(idOperador: string, numOrdenInstalacion: number, instalacion: Instalacion): Observable<any> {
        return this.put(this.INSTALACION(idOperador, numOrdenInstalacion), instalacion)
            .pipe(
                tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("successes.editInstalacionOk")))
            );
    }

    public deleteInstalacion(idOperador: string, instalacion: Instalacion){
        return this.delete(this.INSTALACION(idOperador, instalacion.numOrdenInstalacion))
            .pipe(
                tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant("successes.deleteInstalacionOk")))
            );
    }

    public getRegistros(idOperador: string, numOrdenInstalacion: number, page: number | null = null, filtros: object = {}): Observable<Pageable<Registro>>{
        let httpParams = this.objectToHttpParams(filtros);
        if (page) {
            httpParams = httpParams.set("page", page - 1);
        }
        return this.get(this.REGISTROS(idOperador, numOrdenInstalacion), httpParams)
            .pipe(tap(page => page.content = page.content.map(item => Object.assign(new Registro(), item))));
    }

    public getRegistro(idOperador: string, numOrdenInstalacion: number, numOrdenRegistro: number): Observable<Registro> {
        return this.get(this.REGISTRO(idOperador, numOrdenInstalacion, numOrdenRegistro));
    }

    public createRegistro(idOperador: string, numOrdenInstalacion: number, registro: Registro): Observable<any> {
        return this.post(this.REGISTROS(idOperador, numOrdenInstalacion), registro)
            .pipe(
                tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant('successes.createRegistroOk')))
            );
    }

    public updateRegistro(idOperador: string, numOrdenInstalacion: number, numOrdenRegistro: number, registro: Registro): Observable<any> {
        return this.put(this.REGISTRO(idOperador, numOrdenInstalacion, numOrdenRegistro), registro)
            .pipe(
                tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant('successes.editRegistroOk')))
            );
    }

    public deleteRegistro(idOperador: string, registro: Registro): Observable<any> {
        return this.delete(this.REGISTRO(idOperador, registro.numOrdenInstalacion, registro.numOrdenRegistro), registro)
            .pipe(
                tap(_ => this.toastSvc.showSuccess(this.translateSvc.instant('successes.deleteRegistroOk')))
            );
    }

    public getTiposRegistro(): Observable<TipoRegistro[]> {
        return this.get(this.TIPOS_REGISTRO)
            .pipe(shareReplay());
    }

    public getActividades(): Observable<Actividad[]> {
        return this.get(this.ACTIVIDADES)
            .pipe(shareReplay());
    }

}