import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup} from "@angular/forms";
import {Almacen} from "../../../../core/model/almacen/almacen";
import {AlmacenHttpService} from "../../../../core/services/http/operadores/almacen-http-service";


@Component({
  selector: 'app-edit-operador-almacen-modal',
  templateUrl: './edit-operador-almacen-modal.component.html',
  styleUrls: ['./edit-operador-almacen-modal.component.scss']
})
export class EditOperadorAlmacenModalComponent implements OnInit {

    protected almacenSvc: AlmacenHttpService;
    protected idOperador: string;
    protected almacen: Almacen;
    protected formGroup: FormGroup;
    protected readOnly: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) data: {idOperador: string, almacen: Almacen},
                protected dialogRef: MatDialogRef<EditOperadorAlmacenModalComponent>,
                injector: Injector) {

        this.almacenSvc = injector.get(AlmacenHttpService);
        this.idOperador = data.idOperador;
        this.almacen = data.almacen;

        this.formGroup = new FormGroup({
            "idInscrito": new FormControl(this.almacen.idInscrito),
            "numOrdenAlmacen": new FormControl(this.almacen.numOrdenAlmacen),
            "numAlmacen": new FormControl(this.almacen.numAlmacen),
            "nombreAlmacen": new FormControl(this.almacen.nombreAlmacen),
            "comarca": new FormControl(this.almacen.municipio.comarca),
            "municipio": new FormControl(this.almacen.municipio),
            "poligono": new FormControl(this.almacen.poligono),
            "parcela": new FormControl(this.almacen.parcela),
            "direccion": new FormControl(this.almacen.direccion),
            "codigoPostal": new FormControl(this.almacen.municipio.codigoPostal),
            "superficie": new FormControl(this.almacen.superficie),
            "cualificacion": new FormControl(this.almacen.cualificacionAlmacen),
            "compartido": new FormControl(this.almacen.compartido),
            "prodAlmacenados.maquinaria": new FormControl(this.almacen.prodAlmacenados?.maquinaria),
            "prodAlmacenados.fertilizantes": new FormControl(this.almacen.prodAlmacenados?.fertilizantes),
            "prodAlmacenados.semillas": new FormControl(this.almacen.prodAlmacenados?.semillas),
            "prodAlmacenados.ferraje": new FormControl(this.almacen.prodAlmacenados?.ferraje),
            "prodAlmacenados.contProductos": new FormControl(this.almacen.prodAlmacenados?.contProductos),

        });
    }

    ngOnInit(): void {
        if (this.almacen.numOrdenAlmacen) {
            this.almacenSvc.getAlmacen(this.idOperador, this.almacen.numOrdenAlmacen)
                .subscribe(almacen => {
                    this.almacen = almacen;
                    this.formGroup.patchValue({
                        ...this.almacen,
                    });
                });
        }
    }

    enableReadOnly() {
        this.readOnly = true;
        this.formGroup.disable({emitEvent: false});
        this.formGroup.get("idOperador")?.enable({emitEvent: false});
        this.formGroup.get("numOrdenAlmacen")?.enable({ emitEvent: false});
    }

    onSubmit() {
      this.dialogRef.close(this.formGroup.value);
    }

}
