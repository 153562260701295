<div class="mt-2 p-0">
    <label *ngIf="showLabel && label && !campoObligatorio" [for]="id" class="font-weight-bold">{{label}}</label>
    <label *ngIf="showLabel && label && campoObligatorio" [for]="id" class="font-weight-bold">{{label}} *</label>
    <select #select class="form-select col-12 m-0" [ngClass]="{'is-invalid': ngControl?.invalid && modificado}"
            [(ngModel)]="value"
            [disabled]="isDisabled"
            (change)="change($event)">
        <option *ngIf="hasNullValue "
                [selected]="value == '' || value === null || value === undefined ? 'selected' : ''"
                [value]="null">
            -- {{placeholder ? placeholder : label}} --
        </option>
        <option [selected]="value == option.id" *ngFor="let option of options"
                [value]="option.id" [disabled]="option.disabled">{{option.valor}}</option>
    </select>

    <small *ngIf="small" class="text-muted w-100 pt-1">{{ small }}</small>

    <div *ngIf="ngControl?.invalid" class="invalid-feedback">
        <span *ngIf="ngControl.errors['required']">
            {{'formErrors.obligatorio' | translate}}
        </span>
    </div>
</div>
