import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {SelectItem} from "../../../model/select-item";

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss']
})
export class InputSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() id;
  @Input() label = "";
  @Input() value = "";
  @Input() options: Array<SelectItem>;
  @Input() campoObligatorio: boolean = false;
  @Input() orderOptions = true;
  @Input() showLabel = true;
  @Input() hasNullValue = true;
  @Input() placeholder= "";
  @Input() small: string = '';

  @ViewChild('select') selectControl: any;

  modificado = false;
  @Input() isDisabled: boolean = false;
  onChange = (_:any) => { }
  onTouch = () => { }

  public ngControl!: NgControl;

  constructor(private inj: Injector) {
    this.id = -1;
    this.options = [];
  }

  ngOnInit(): void {

    try {
      this.ngControl = this.inj.get(NgControl)
      this.ngControl.valueAccessor = this;
    } catch (e) {

    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.orderOptions && changes['options']) {
      this.options?.sort((a : SelectItem, b: SelectItem) => {
        return a.valor > b.valor ? 1 : -1;
      });
    }

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    if (obj == undefined || obj == null) {
      obj = null;
      this.modificado = false;
    }

    if (this.selectControl)
      this.selectControl.nativeElement.value = obj;

    this.value = obj;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  change($event: any) {
    if ($event.target.value == "null") {
      this.onChange(null);
    } else {
      this.modificado = true;
      this.onChange($event.target.value);
    }
  }

}
