<div class="mt-2 m-0 p-0">
    <label *ngIf="label && !campoObligatorio" [for]="id" class="font-weight-bold">{{label}}</label>
    <label *ngIf="label && campoObligatorio" [for]="id" class="font-weight-bold">{{label}} *</label>

    <div class="input-group">
        <input type="number"
               class="form-control col-12 m-0"
               [ngClass]="{'is-invalid': ngControl?.invalid && modificado}"
               [min]="min"
               [max]="max"
               (keyup)="keyUp($event)"
               (change)="change($event)"
               [(ngModel)]="value"
               [value]="value"
               [placeholder]="placeholder"
               [disabled]="isDisabled"/>
        <div *ngIf="units" class="input-group-append">
            <span class="input-group-text">{{units}}</span>
        </div>
    </div>

    <div *ngIf="ngControl?.invalid" class="invalid-feedback">
        <span *ngIf="ngControl.errors['required']">
            {{'formErrors.obligatorio' | translate}}
        </span>
    </div>
</div>