import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../components/toast.service";
import {HttpService} from "../core/http.service";
import {Observable, shareReplay} from "rxjs";
import {CategoriaEcologica} from "../../../model/master/categoria-ecologica";

@Injectable({
  providedIn: 'root'
})
export class CategoriasEcologicasHttpService extends HttpService {

  protected BASE = "categorias-ecologicas";

  constructor(http: HttpClient,
              toastSvc: ToastService) {
    super(http, toastSvc);
  }

  public getCategorias(): Observable<CategoriaEcologica[]> {
    return this.get(this.BASE)
        .pipe(shareReplay());
  }

}