import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {EtiquetasActionAdd} from "../../enum/EtiquetasActionAdd";
import {EtiquetasActionNew} from "../../enum/EtiquetasActionNew";

@Component({
  templateUrl: './etiqueta-add-new-modal.component.html',
  styleUrls: ['./etiqueta-add-new-modal.component.scss']
})
export class EtiquetaAddNewModalComponent {

  protected actionsNew = EtiquetasActionNew;

  constructor(protected dialogRef: MatDialogRef<EtiquetaAddNewModalComponent>) { }

}
