import {Component, Input} from '@angular/core';
import {AuthService} from "../../services/security/auth.service";
import {PageInfoService} from "../../services/core/page-info.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() mostrarCabecera: boolean = true;

  @Input() mostrarHideButton: boolean = true;

  @Input() drawer: any;

  constructor(public authSvc: AuthService,
              public pageInfoSvc: PageInfoService) { }

}
