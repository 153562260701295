<div class="title-container col-10 px-2 d-flex align-items-end">
    <div>
        <div *ngIf="icon">
            <mat-icon [inline]="true" class="title mr-0">{{icon}}</mat-icon>
        </div>
        <span class="title">{{title}}</span>
        <span class="subtitle">{{subtitle}}</span>
    </div>
</div>
<hr/>
