import {Component, Injector, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html'
})
export class InputTextComponent implements OnInit, ControlValueAccessor {

  @Input() id : string = "noName";
  @Input() label = "";
  @Input() value = "";
  @Input() campoObligatorio: boolean = false;
  @Input() readonly: boolean = false;
  @Input() append = "";
  @Input() showLabel = true;
  @Input() isDisabled: boolean = false;
  @Input() copy: boolean = false;
  @Input() helpText: string = '';
  @Input() small: string = '';
  @Input() emailFormat = false;
  @Input() placeholder: string = "";

  modificado = false;
  onChange = (_:any) => { }
  onTouch = () => { }

  public ngControl!: NgControl;

  constructor(private inj: Injector,
              private clipboard: Clipboard) {
  }

  ngOnInit(): void {
    try {
      this.ngControl = this.inj.get(NgControl)
      this.ngControl.valueAccessor = this;
    } catch (e) {

    }
  }

  change($event: any) {
    this.modificado = true;
    this.value = $event.target.value.toUpperCase();
    this.onChange(this.value);
  }

  writeValue(obj: any): void {
    this.value = obj;   
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  copyText() {
    this.clipboard.copy(this.value);
  }

}
