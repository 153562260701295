import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'operadores-attachments',
  templateUrl: './operadores-attachments.component.html',
  styleUrls: ['./operadores-attachments.component.scss']
})
export class OperadoresAttachmentsComponent {
  @Input() protected readOnly: false;
  @Input() protected file: File | null;
  @Output() fileDrop = new EventEmitter()
  @Output() fileDeleted = new EventEmitter();

  onFileDrop(files: File[]) {
    this.file = files[0];
    this.fileDrop.emit({file: this.file});
  }

  onDownload() {

  }

  onDelete() {
    this.file = null;
    this.fileDeleted.emit();
  }

}
