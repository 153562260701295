import {BaseObj} from "../core/baseObj";
import {Municipio} from "../localizacion/municipio";
import {PersonaContacto} from "./persona-contacto";
import {TipoActividad} from "./tipo-actividad";
import {Almacen} from "../almacen/almacen";

export class Operador extends BaseObj {

    idOperador!: number;
    tipoOperador: string = "";
    numInscrito!: string;
    nombre: string = "";
    primerApellido: string  = "";
    segundoApellido: string  = "";
    nif: string  = "";
    nombreCompleto: any  = "";
    empresa: string  = "";
    cif: string  = "";
    idGenero: string  = "";
    fechaAlta: Date = new Date();
    fechaBaja: boolean;
    direccionSocial: string = "";
    municipioSocial: Municipio;

    direccionPostal: string = "";
    municipioPostal: Municipio;
    telefono1: string = "";
    telefono2: string = "";
    movil: string = "";
    fax: string = "";
    email: string = "";
    emailNot: string = "";
    movilNot: string = "";
    web: string = "";
    facebook: string = "";
    twitter: string = "";
    municipioInspeccion: Municipio;
    inspector: string = "";
    mostrarDatosDirectorio: boolean;

    personasContactoCCPAE: PersonaContacto[];
    actividadesProduccion: TipoActividad[];
    activitatProduccionPrincipal: TipoActividad | null;
    almacenes: Almacen[] | null;


    constructor(json?: any) {
        super(json);
    }

}
