import {Municipio} from "../localizacion/municipio";

export class AltaAlmacen {
    idAltaAlmacen!: number;
    idAltaInscrito!: string;
    idOperador!: number;
    numOrdenAlmacen!: number;
    fechaCreacion!: Date;
    nombreAlmacen!: string;
    poligono!: string;
    parcela!: string;
    recinto!: string;
    direccion!: string;
    superficie!: number;
    cualificacionAlmacen!: string;
    localizadoEnPoblacion!: boolean;
    compartido!: boolean;
    compartidoCon!: string;
    maquinaria!: boolean;
    fertilizantes!: boolean;
    semillas!: boolean;
    contProductos!: boolean;
    pajaForraje!: boolean;
    veterinarios!: boolean;
    camaraFrigorifica!: boolean;
    externo!: boolean;
    registroSanitario!: string;
    municipio!: Municipio;

    constructor(init?: Partial<AltaAlmacen>) {
        Object.assign(this, init);
    }
}
