import {CodigoTramite, TipoTramite} from "../../modules/etiquetas/services/etiquetas.service";

export const JWTExternalMock = {
	"iss": "ccpae",
	"sub": "CT000002PEC",
	"codigoTramite": CodigoTramite.ETIQUETATGE_ELABORATS,
	"tipoTramite": TipoTramite.ALTA,
	"fullname": "DANIEL VALLS COTS",
	"idSolicitud": "SOL85",
	"authorities": "operador_view_external",
	"codigoOperador": "CT000002PEC",
	"iat": 1676380410,
	"exp": 1676384010,
	"username": "Testing",
	"visualizar": false
};
