import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from "@angular/forms";
import {LoginPage} from "../../../../core/model/core/pages/login-page";
import {HttpErrorResponse} from "@angular/common/http";
import {AuthService} from "../../../../core/services/security/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends LoginPage {

  public errorActual: string | null = null;
  public formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    public authSvc: AuthService) {

    super();

    this.formGroup = this.fb.group({
      'username': this.fb.control(''),
      'password': this.fb.control(''),
    })
  }

  login() {
    this.authSvc.authenticate(this.formGroup.value)
        .subscribe({
          next: (logged: boolean) => {
            if (logged) {
              this.router.navigate(["/operadors"]);
            } else {
                this.errorActual = 'Usuari/password incorrecte.'
            }
          },
          error: (httpError: HttpErrorResponse) => {
            this.errorActual = httpError.error.title;
          }
        });
  }

}
