<mat-card *ngIf="operadorAction=='view'" class="card shadow mt-2">
    <mat-card-content>
        <div class="py-2">
            <app-generic-table
                    [data]="data.content"
                    [columnsConfig]="tableConfig"
                    [actionsConfig]="tableAccionesView"
                    [headerRows]="2"
                    [paginated]="true"
                    [collectionSize]="data.totalElements"
                    [totalPages]="data.totalPages"
                    (onPageChange)="onPageChange($event)"
                    [page]="page"
                    (onAction)="onAction($event)"
            >
            </app-generic-table>
        </div>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="operadorAction=='edit'" class="card shadow mt-2">
    <mat-card-content>
        <div class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" type="button"
                    (click)="createAlmacen()"
            >
                {{'OPERADORES.almacenes.anadirAlmacen' | translate}}
            </button>
        </div>
        <br/>
        <div class="py-2">
            <app-generic-table
                    [data]="data.content"
                    [columnsConfig]="tableConfig"
                    [actionsConfig]="tableAccionesEdit"
                    [headerRows]="2"
                    [paginated]="true"
                    [collectionSize]="data.totalElements"
                    [totalPages]="data.totalPages"
                    (onPageChange)="onPageChange($event)"
                    [page]="page"
                    (onAction)="onAction($event)"
            >
            </app-generic-table>
        </div>
    </mat-card-content>
</mat-card>

<button mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
    <mat-icon>arrow_back</mat-icon>
    {{"general.volver" | translate}}
</button>

