<form [formGroup]="formProduccion">
    <mat-tab-group [selectedIndex]="selectedIndex"
                   (selectedTabChange)="onTabChange($event)">
        <mat-tab *ngIf="checkFamiliaSelected('prodVegetal')">
            <ng-template mat-tab-label>
                {{'OPERADORES.datosProdVegetal' | translate}}
                <mat-icon class="example-tab-icon">{{validateSubForm('prodVegetal') ? 'check': 'close'}}</mat-icon>
            </ng-template>
            <ng-container *ngTemplateOutlet="prodVegetalView"></ng-container>
        </mat-tab>

        <mat-tab *ngIf="checkFamiliaSelected('prodRamadera')">
            <ng-template mat-tab-label>
                {{'OPERADORES.datosProdRamadera' | translate}}
                <mat-icon class="example-tab-icon">{{validateSubForm('prodRamadera') ? 'check': 'close'}}</mat-icon>
            </ng-template>
            <ng-container *ngTemplateOutlet="prodRamaderaView"></ng-container>
        </mat-tab>
    </mat-tab-group>

    <div class="col-12 p-2 mt-2 d-flex justify-content-end">
        <button mat-raised-button color="basic" class="px-2 mx-1"
                (click)="onChangeViewAtras()"
        >
            {{'general.atras' | translate}}
        </button>

        <button mat-raised-button color="basic" class="px-2 mx-1"
                (click)="onChangeViewSiguiente()"
        >
            {{'general.siguiente' | translate}}
        </button>
    </div>

    <!-- VIEWS PRODUCCIO --->
    <!-- VIEW PROD VEGETAL --->
    <ng-template #prodVegetalView class="mt-5" >
        <mat-card class="mb-2 card shadow">
            <mat-card-title>
                {{'OPERADORES.parcelasInscribir' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="py-2">
                    <app-generic-table
                            [data]="parcelasVisibles"
                            [columnsConfig]="parcelasTableConfig"
                            [actionsConfig]="tableAcciones"
                            (onCheckValueChange)="onTableCheckValueChange($event)"
                            (onAction)="onActionTableParcela($event)"
                    >
                    </app-generic-table>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-template>

    <!-- VIEW PROD RAMADERA --->
    <ng-template #prodRamaderaView class="mt-5" formGroupName="prodRamadera" >
        <mat-card class="mb-2 card shadow" formGroupName="dadesGeneralRamaderia">
            <mat-card-title>
                {{'OPERADORES.datosRamGeneral' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row" >
                    <div class="col-md-3 col-sm-12">
                        <app-input-text
                                formControlName="numCodiREGA"
                                [label]="'OPERADORES.numCodiREGA' | translate">
                        </app-input-text>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <app-input-text
                                formControlName="marcaOficial"
                                [label]="'OPERADORES.marcaOficial' | translate">
                        </app-input-text>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <app-input-text
                                formControlName="classZootecnica"
                                [label]="'OPERADORES.classZootecnica' | translate">
                        </app-input-text>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <app-input-text
                                formControlName="coordGeoUP"
                                [label]="'OPERADORES.coordGeograficas' | translate">
                        </app-input-text>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="mb-2 card shadow">
            <mat-card-title>
                {{'OPERADORES.especiesAnimales' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="py-2">
                    <app-generic-table
                            [data]="animalesVisibles"
                            [columnsConfig]="animalesTableConfig"
                            [actionsConfig]="tableAcciones"
                            (onAction)="onActionTableAnimales($event)"
                    >
                    </app-generic-table>
                </div>

                <div class="col-12 mt-1 d-flex justify-content-end">
                    <button mat-mini-fab color="basic" (click)="onAddAnimal()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="mb-2 card shadow" formGroupName="ubiFemer">
            <mat-card-title>
                {{'OPERADORES.datosFemer' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row mt-1 justify-content-start">
                    <app-operadores-localizacion
                            [municipioInput]="municipioFemer"
                    >
                    </app-operadores-localizacion>
                </div>
                <div class="row justify-content-start">
                    <div class="col-12">
                        <app-input-text
                                formControlName="UFDireccion"
                                [label]="'general.direccion' | translate"
                                [campoObligatorio]="true"
                        >
                        </app-input-text>
                    </div>

                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="mb-2 card shadow" formGroupName="ubiAlojamiento">
            <mat-card-title>
                {{'OPERADORES.datosAlojamiento' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row mt-1 justify-content-start">
                    <app-operadores-localizacion
                            [municipioInput]="municipioAlojamiento"
                    >
                    </app-operadores-localizacion>
                </div>
                <div class="row justify-content-start">
                    <div class="col-12">
                        <app-input-text
                                formControlName="UADireccion"
                                [label]="'general.direccion' | translate"
                                [campoObligatorio]="true"
                        >
                        </app-input-text>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="false" class="mb-2 card shadow" formGroupName="ubiAbellars">
            <mat-card-title>
                {{'OPERADORES.datosAbellars' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row justify-content-start">
                    <div class="col-12">
                        <app-input-text
                                formControlName="UAbDireccion"
                                [label]="'general.direccion' | translate"
                                [campoObligatorio]="true"
                        >
                        </app-input-text>
                    </div>

                </div>
                <div class="row mt-1 justify-content-start">
                    <div class="col-sm-6 col-md-3">
                        <app-input-select
                                formControlName="UAbProvincia"
                                [label]="'general.provincia' | translate"
                                [campoObligatorio]="true"
                        >
                        </app-input-select>
                    </div>

                    <div class="col-sm-6 col-md-3">
                        <app-input-select
                                formControlName="UAbComarca"
                                [label]="'general.comarca' | translate"
                                [campoObligatorio]="true"
                        >
                        </app-input-select>
                    </div>

                    <div class="col-sm-4 col-md-2">
                        <app-input-text
                                formControlName="UAbCodigoPostal"
                                [label]="'general.codigoPostal' | translate"
                                [campoObligatorio]="true"
                        >
                        </app-input-text>
                    </div>

                    <div class="col-sm-8 col-md-4">
                        <app-input-text
                                formControlName="UAbMunicipio"
                                [label]="'general.municipio' | translate"
                                [campoObligatorio]="true"
                        >
                        </app-input-text>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-template>
</form>
