<div>

    <h2 mat-dialog-title class="d-flex justify-content-between">

        {{'OPERADORES.instalaciones.titleModalRegistro' | translate}}

        <div class="clickable" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <hr/>

    <form [formGroup]="formGroup" class="col-12" (submit)="dialogRef.close(this.formGroup.value)">

        <div class="row">
            <div class="col-md-3 col-sm-12">
                <app-input-text
                        [label]="'OPERADORES.numeroInscrito' | translate"
                        formControlName="idInscrito"
                        [isDisabled] = true
                >
                </app-input-text>
            </div>
            <div class="col-md-3 col-sm-12">
                <app-input-text
                        [label]="'OPERADORES.instalaciones.numOrdenCentro' | translate"
                        formControlName="numOrdenInstalacion"
                        [isDisabled] = true
                >
                </app-input-text>
            </div>
            <div class="col-md-3 col-sm-12">
                <app-input-text
                        [label]="'OPERADORES.instalaciones.numOrdenRegistro' | translate"
                        formControlName="numOrdenRegistro"
                        [isDisabled] = true
                >
                </app-input-text>
            </div>
            <div class="col-md-3 col-sm-12">
                <app-input-datepicker
                        [label]="'OPERADORES.instalaciones.fechaCaducidad' | translate"
                        [formControlName]="'fechaCaducidad'"
                        [minDate]="{year: 1990, month:1, day: 1}"
                        [maxDate]="{year: 2070, month:1, day: 1}"
                        [campoObligatorio]=true
                >
                </app-input-datepicker>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-xl-3">
                <app-input-select
                        formControlName="codigoRegistro"
                        [label]="'OPERADORES.instalaciones.tipoRegistro' | translate"
                        [options]="tiposRegistro | async | arrayToSelectItem:'codigoRegistro':'descripcionRegistro'"
                        [campoObligatorio]=true
                >
                </app-input-select>
            </div>
            <div class="col-md-3 col-sm-12">
                <app-input-text
                        [label]="'OPERADORES.instalaciones.numRegistro' | translate"
                        formControlName="numRegistro"
                        [campoObligatorio]=true

                >
                </app-input-text>
            </div>
            <div class="col-md-6 col-sm-12">
                <app-input-text
                        [label]="'OPERADORES.instalaciones.actividadAutorizada' | translate"
                        formControlName="actividadAutorizada"
                        [campoObligatorio]=true
                >
                </app-input-text>
            </div>
        </div>

        <div class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button type="submit" mat-raised-button color="primary" class="px-2" [disabled]="formGroup.invalid">
                {{'general.guardar' | translate}}
            </button>
        </div>

    </form>

</div>