import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LoginPage} from "../../../../core/model/core/pages/login-page";
import {ActivatedRoute, Router} from "@angular/router";
import {LoginService} from "../../../../core/services/http/login.service";

@Component({
  selector: 'app-change-password-recover',
  templateUrl: './change-password-recover.component.html',
  styleUrls: ['./change-password-recover.component.scss']
})
export class ChangePasswordRecoverComponent extends LoginPage implements OnInit {

  formGroup: FormGroup;
  formValid: boolean = false;
  token: string = "";
  loginSvc: LoginService;
  succesful = false;
  error = false;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              injector: Injector) {
    super();

    this.loginSvc = injector.get(LoginService);
    this.token = activatedRoute.snapshot.params['token'];
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      'password1': new FormControl('', [Validators.required, Validators.minLength(6)]),
      'password2': new FormControl('', [Validators.required, Validators.minLength(6)])
    });

    this.formGroup.valueChanges.subscribe(values => {
      this.formValid = this.formGroup.valid && values.password1 == values.password2;
    });
  }

  onSubmit() {
    //Send to back and send mail with token
    this.loginSvc.canviarContrasenya(this.token, this.formGroup.get('password1')?.value)
        .subscribe(value => {
          if(value.code == 200) this.succesful = true;
          else this.error = true;
        })
  }

  onBackToLogin() {
    this.router.navigate(['']);
  }
}
