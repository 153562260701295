import {Injectable, Injector} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ToastService} from "../../components/toast.service";
import {HttpService} from "../core/http.service";
import {Observable, shareReplay} from "rxjs";
import {Etiqueta} from "../../../model/etiquetas/etiqueta";
import {SolicitudProducto} from "../../../model/etiquetas/solicitud-producto";
import {SolicitudProductoCompuesto} from "../../../model/etiquetas/solicitud-producto-compuesto";
import {CodigoTramite} from "../../../../modules/etiquetas/services/etiquetas.service";
import {SolicitudIngrediente} from "../../../model/etiquetas/solicitud-ingrediente";
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {SolicitudAdjunto} from "../../../model/etiquetas/solicitud-adjunto";
import {TipoRechazo} from "../../../model/etiquetas/tipoRechazo";


@Injectable({
  providedIn: 'root'
})
export class SolicitudProductoHttpService extends HttpService {

  protected BASE = "solicitud-producto";
  protected BASE_ID = (idSolicitudProducto: number) => `${this.BASE}/${idSolicitudProducto}`;
  protected ETIQUETA = (idSolicitudProducto: number) => `${this.BASE}/${idSolicitudProducto}/etiqueta`;
  protected ETIQUETA_DOWN = (idSolicitudProducto: number) => `${this.BASE}/${idSolicitudProducto}/etiqueta/download`;
  protected PLANTILLA_DOWN = (codigoTramite: string) => `${this.BASE}/plantilla/${codigoTramite}`;
  protected PLANTILLA_LOAD = (codigoTramite: string) => `${this.BASE}/plantilla/load/${codigoTramite}`;
  protected PLANTILLA_LOAD_HORTOFRUTICOLA = (codigoTramite: string) => `${this.BASE}/plantilla/load/hortofruticola/${codigoTramite}`;
  protected BASE_INGREDIENTES = (idProducto: number) => `${this.BASE_ID(idProducto)}/ingredientes`;
  protected BASE_INGREDIENTES_ID = (idProducto: number, idSolicitudIngrediente: number) =>
      `${this.BASE_INGREDIENTES(idProducto)}/${idSolicitudIngrediente}`;
  private ADJUNTOS =  (idSolicitudProducto: number) => `${this.BASE}/${idSolicitudProducto}/adjuntos`;
  private ADJUNTOS_ID =  (idSolicitudProducto: number, idSolicitudAdjunto: number) =>
      `${this.BASE}/${idSolicitudProducto}/adjuntos/${idSolicitudAdjunto}`;
  private ADJUNTOS_DOWNLOAD =  (idSolicitudProducto: number, idSolicitudAdjunto: number) =>
      `${this.BASE}/${idSolicitudProducto}/adjuntos/${idSolicitudAdjunto}/download`;
  private TIPOS_RECHAZO = `${this.BASE}/tiposRechazo`;


  translateSvc: TranslateService;

  constructor(http: HttpClient,
              toastSvc: ToastService,
              injector: Injector) {
    super(http, toastSvc);
    this.translateSvc = injector.get(TranslateService);
  }

  public getAllFiltered(idOperador: string): Observable<SolicitudProducto[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("codigoOperador", idOperador);
    return this.get(this.BASE, httpParams);
  }

  public getProducto(idSolicitudProducto: number): Observable<SolicitudProducto> {
    return this.get(this.BASE_ID(idSolicitudProducto));
  }

  public crearProducto(producto: SolicitudProducto): Observable<any> {
    return this.post(this.BASE, producto);
  }

  public modificarProducto(idSolicitudProducto: number, producto: SolicitudProducto): Observable<any> {
    return this.put(this.BASE_ID(idSolicitudProducto), producto);
  }

  public patchProducto(idSolicitudProducto: number, json: object): Observable<SolicitudProducto> {
    return this.patch(this.BASE_ID(idSolicitudProducto), json);
  }

  public eliminar(item: SolicitudProductoCompuesto) {
    return this.delete(this.BASE_ID(item.idSolicitudProducto));
  }

  public getEtiqueta(idSolicitudProducto: number): Observable<Etiqueta> {
    return this.get(this.ETIQUETA(idSolicitudProducto));
  }

  public crearEtiqueta(idSolicitudProducto: number, file: File): Observable<Etiqueta> {
    let formData = new FormData();
    formData.append("file", file);
    return this.post(this.ETIQUETA(idSolicitudProducto), formData);
  }

  public eliminarEtiqueta(idSolicitudProducto: number): Observable<any> {
    return this.delete(this.ETIQUETA(idSolicitudProducto));
  }

  public visualizarEtiqueta(idSolicitudProducto: number): void {
    this.downloadFile(this.ETIQUETA_DOWN(idSolicitudProducto));
  }

  // Ingredientes
  public getIngredientes(idSolicitudProducto: number): Observable<SolicitudIngrediente[]> {
    return this.get(this.BASE_INGREDIENTES(idSolicitudProducto))
        .pipe(map((arr: any[]) => arr.map(item => Object.assign(new SolicitudIngrediente(), item))));
  }

  public crearIngrediente(idProducto: number, ingrediente: SolicitudIngrediente): Observable<SolicitudIngrediente> {
    return this.post(this.BASE_INGREDIENTES(idProducto), ingrediente);
  }

  public modificarIngrediente(idProducto: number, idSolicitudIngrediente: number, ingrediente: SolicitudIngrediente) {
    return this.put(this.BASE_INGREDIENTES_ID(idProducto, idSolicitudIngrediente), ingrediente);
  }

  public eliminarIngrediente(idProducto: number, idSolicitudIngrediente: number) {
    return this.delete(this.BASE_INGREDIENTES_ID(idProducto, idSolicitudIngrediente));
  }

  // Plantillas
  public descargarPlantillaHortofructicola(): void {
    this.downloadFile(this.PLANTILLA_DOWN(CodigoTramite.ETIQUETATGE_HORTOFRUTICOLES), 'Plantilla_hortofruticola.xls');
  }

  public descargarPlantillaDistribuits(): void {
    this.downloadFile(this.PLANTILLA_DOWN(CodigoTramite.ETIQUETATGE_DISTRIBUITS), "Plantilla_distribuidos.xls");
  }

  public cargarProductosExcel(idSolicitud: string, file : File, codigoTramite: string) {
    let formData = new FormData;
    formData.append('idSolicitud', idSolicitud);
    formData.append('file', file);

    return this.toastHttpDecorator(
        this.post(this.PLANTILLA_LOAD(codigoTramite), formData),
        this.translateSvc.instant("ETIQUETAS.cargarExcelDoing"),
        this.translateSvc.instant("ETIQUETAS.cargarExcelSuccess"),
        this.translateSvc.instant("ETIQUETAS.cargarExcelError"))
  }

  public cargarProductosExcelHortofruticolas(idSolicitud: string, file: File, fileEtiqueta: File | null, codigoTramite: string, idCategoriaEcologica: string) {
    let formData = new FormData;
    formData.append('idSolicitud', idSolicitud);
    formData.append('file', file);
    if (fileEtiqueta) {
      formData.append('fileEtiqueta', fileEtiqueta);
    }
    formData.append('idCategoriaEcologica', idCategoriaEcologica);

    return this.toastHttpDecorator(
        this.post(this.PLANTILLA_LOAD_HORTOFRUTICOLA(codigoTramite), formData),
        this.translateSvc.instant("ETIQUETAS.cargarExcelDoing"),
        this.translateSvc.instant("ETIQUETAS.cargarExcelSuccess"),
        this.translateSvc.instant("ETIQUETAS.cargarExcelError"))
  }

  // Adjuntos
  public getAdjuntos(idSolicitudProducto: number): Observable<SolicitudAdjunto[]> {
    return this.get(this.ADJUNTOS(idSolicitudProducto));
  }

  public crearAdjunto(idSolicitudProducto: number, idSolicitud: string, file: File) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append('idSolicitud', idSolicitud);
    return this.post(this.ADJUNTOS(idSolicitudProducto), formData);
  }

  public eliminarAdjunto(idSolicitudProducto: number, idSolicitudAdjunto: number) {
    return this.delete(this.ADJUNTOS_ID(idSolicitudProducto, idSolicitudAdjunto));
  }

  public downloadAdjunto(idSolicitudProducto: number, idSolicitudAdjunto: number) {
    return this.downloadFile(this.ADJUNTOS_DOWNLOAD(idSolicitudProducto, idSolicitudAdjunto));
  }

  public getTiposRechazo(): Observable<TipoRechazo[]> {
    return this.get(this.TIPOS_RECHAZO)
        .pipe(shareReplay());
  }

  public modificarFlagAdjuntos(idSolicitudProducto: number) {
    return this.put(this.ADJUNTOS(idSolicitudProducto),{flag: true})
  }

  public modificarFlagEtiqueta(idSolicitudProducto: number) {
    return this.put(this.ETIQUETA(idSolicitudProducto),{flag: true})
  }

}
