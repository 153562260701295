import {Component, EventEmitter, Inject, Injector, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EtiquetasActionAdd} from "../../enum/EtiquetasActionAdd";
import {SolicitudProductoHttpService} from "../../../../core/services/http/solicitud/solicitud-producto.http.service";

@Component({
  selector: 'app-etiqueta-add-modal',
  templateUrl: './etiqueta-crear-productos-plantilla-modal.component.html',
  styleUrls: ['./etiqueta-crear-productos-plantilla-modal.component.scss']
})
export class EtiquetaCrearProductosPlantillaModalComponent implements OnInit {
  protected solicitudProductoSvc: SolicitudProductoHttpService;
  protected tipoSolicitud: string;
  protected actions = EtiquetasActionAdd;

  constructor(
      protected dialogRef: MatDialogRef<EtiquetaCrearProductosPlantillaModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      protected injector: Injector
  ) {
    this.solicitudProductoSvc = injector.get(SolicitudProductoHttpService);
    this.tipoSolicitud = data.tipoSolicitud;
  }

  ngOnInit(): void {
  }

  protected readonly EtiquetasActionAdd = EtiquetasActionAdd;

  onDownloadPlantilla() {
    if (this.tipoSolicitud == 'ETIQUETATGE_DISTRIBUITS') {
      this.solicitudProductoSvc.descargarPlantillaDistribuits();
    } else {
      this.solicitudProductoSvc.descargarPlantillaHortofructicola();
    }
  }
}
