import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";

export const OperadoresAutorizacionBajaTableConfig: TablaColumna[] =  [
    {
        header: 'OPERADORES.autorizaciones.numOrdenAutorizacion',
        field: "numOrdenAutorizacion",
        type: "number"
    },
    {
        header: 'ETIQUETAS.nombreComercial',
        field: "nombreProducto",
        type: "string"
    },
    {
        header: 'general.marca',
        field: "marca",
        type: "string"
    },
    {
        header: 'general.envase',
        field: "tipoEnvase",
        type: "string"
    },
    {
        header: 'general.capacidad',
        field: "capacidad",
        type: "string"
    },
    {
        header: 'OPERADORES.autorizaciones.fechaAutorizacion',
        field: "fechaAutorizacion",
        type: "date"
    },
    {
        header: 'general.estado',
        field: "activo",
        type: "boolean",
        trueText: "Alta",
        falseText: "Baja"
    }
]

export const OperadoresAutorizacionBajaAccionesViewConfig: TablaAccion[] = [
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure dades"
    }
]

export const OperadoresAutorizacionBajaAccionesEditConfig: TablaAccion[] = [
    {
        action: "download",
        icon: "get_app",
        toolTip: "Descarregar autorització",
        //condicion: "!impreso",
        condicionColor: [
            {condicion: 'impreso', color: "green"},
            {condicion: '!impreso', color: "orange"}
        ]
    },
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure dades"
    },
    {
        action: "alta",
        icon: "check",
        toolTip: "Donar d'alta"
    }
]
