import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, switchMap} from "rxjs";
import {Router} from "@angular/router";
import {AuthData, LoginReq, LoginService} from "../http/login.service";
import {Buffer} from 'buffer';
import {RolEnum} from "../../enum/rol.enum";

export interface JWTData {
  authorities: string;
  exp: number;
  fullname: string;
  iat: number;
  iss: string;
  sub: string;
  username: string;
}

export interface JWTDataExternal extends JWTData {
  codigoOperador: string;
  codigoTramite: string;
  tipoTramite: string;
  idSolicitud: string;
  visualizar: boolean;
}

export interface JWTDataExternalAltaInscrito extends JWTData {
    idSolicitudInscrito: string;
    cif: string;
    nif: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private ACCESS_TOKEN = "access-token";
  private REFRESH_TOKEN = "refresh-token";

  private data!: JWTData | null;

  private dataSubject: BehaviorSubject<JWTData | null> = new BehaviorSubject<JWTData | null>(this.data);
  private dataObs = this.dataSubject.asObservable();
  private refreshTokenTimeout;
  private currentToken: string | null = null;

  private roles: string[];

  constructor(private router: Router,
              private loginSvc: LoginService) {
    this.roles = [];

    if (this.hasLocalStorage(this.ACCESS_TOKEN) &&
        this.hasLocalStorage(this.REFRESH_TOKEN)) {
      this.loadTokenData(this.getLocalStorageItem(this.ACCESS_TOKEN));
      this.loadRefreshTokenTimeout();
    }
  }

  public authenticate(login: LoginReq): Observable<boolean> {
    return this.loginSvc.login(login)
        .pipe(switchMap((res: AuthData) => {
          this.onAuthenticate(res);
          return of(true);
        }));
  }

  public onAuthenticate(authData: AuthData) {
    localStorage.setItem(this.REFRESH_TOKEN, authData.refreshToken);
    this.saveAccessToken(authData.accessToken);

    this.loadTokenData(authData.accessToken);
    this.loadRefreshTokenTimeout();
  }

  public saveAccessToken(token: string) {
    localStorage.setItem(this.ACCESS_TOKEN, token);
  }

  public loadTokenData(token: string) {
    this.currentToken = token;
    this.data = AuthService.extractData(token);
    this.roles = this.data ? this.data?.authorities.split(",") : [];
    this.dataSubject.next(this.data);
  }

  public loadRefreshTokenTimeout() {

    if (this.hasLocalStorage(this.REFRESH_TOKEN) && this.data?.exp) {
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken(),
          this.getTimeoutRefreshToken(this.data?.exp));
    }

  }

  protected getTimeoutRefreshToken(expiration: number): number {
    return (expiration * 1000) - Date.now() - (5 * 1000);
  }

  protected refreshToken() {

    let refreshToken = localStorage.getItem(this.REFRESH_TOKEN);

    if (this.data?.username && refreshToken != null) {
      this.loginSvc.refresh(this.data?.username, refreshToken)
          .subscribe(
              (res: AuthData) => this.onAuthenticate(res),
              (error: any) => {
                    console.error('Error refreshing access token: ', error);
                    this.logout();
          } );
    } else {
      this.logout();
    }
  }

  public logout() {
    this.dataSubject.next(null);
    localStorage.clear();
    this.router.navigate(['']);
    this.currentToken = null;
    clearTimeout(this.refreshTokenTimeout);
  }

  public static extractData(jwt: string): JWTData | JWTDataExternal | JWTDataExternalAltaInscrito | null {
    if (jwt) {
      let buffer = Buffer.from(jwt.split(".")[1], "base64");
      return JSON.parse(buffer.toString());
    }

    return null;
  }

  public isAuthenticated(): Observable<boolean> {
    return of(this.currentToken != null);
  }

  public get authToken(): string | null {
    return this.currentToken;
  }

  public get fullName(): Observable<string | undefined> {
    return this.dataObs.pipe(switchMap(data => of(data?.fullname)));
  }

  public hasRole(role: RolEnum): boolean {
    return this.roles.indexOf(role) > -1;
  }

  getLocalStorageItem(key: string): string {
    let item = localStorage.getItem(key)
    return item ? item : "";
  }

  private hasLocalStorage(key: string) {
    return localStorage.getItem(key) != null;
  }

}
