import {BaseObj} from "../core/baseObj";
import {Municipio} from "../localizacion/municipio";

export class Parcela extends BaseObj {

    idInscrito!: number;
    numOrdenParcela: number;
    municipio: Municipio;
    poligono: string = "";
    parcela: string = "";
    recinto: string = "";
    superficie: string = "";
    idCultivo: string = "";
    idVariedad: string = "";
    idCualificacion: string = "";
    cultivo: string = "";
    variedad: string = "";
    cualificacion: string = "";
    fechaCualificacion?: Date = new Date();
    fechaInicio?: Date = new Date();
    sigpac: string = "";
    recSigpac: string = "";
    rvc: string = "";
    modificacionManual: boolean;
    indicadorConversion: boolean;
    observaciones: string = "";
    numPar: number;
    codigoProduccionSigCosecha: string = "";
    codigoVariedadSigCosecha: string = "";
    superficieNeta: number;
    usuario: string = "";

    constructor(json?: object) {
        super(json);
    }
}