<div>
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div>
            {{'ETIQUETAS.crearNuevos' | translate}}
        </div>

        <div class="clickable" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <div class="col-7 d-flex align-self-center justify-content-end">
        <button mat-raised-button color="basic" class="px-2 mx-1"
                (click)="onDownloadPlantilla()">
            {{'ETIQUETAS.descargarPlantilla' | translate}}
        </button>
        <button mat-raised-button color="basic" class="px-2 mx-1 fileUpload"
                (click)="dialogRef.close(actions.CARREGAR_PRODUCTES)">
            {{'ETIQUETAS.cargarPlantilla' | translate}}
        </button>
    </div>
</div>
