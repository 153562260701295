import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../../services/security/auth.service";
import {RolEnum} from "../../enum/rol.enum";

@Injectable({
  providedIn: 'root'
})
export class RolCcpaeExternalGuard implements CanActivate {

  constructor(protected authSvc: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authSvc.hasRole(RolEnum.CCPAE_EXT);
  }

}