<div class="col-12 m-0 header d-flex align-items-center row" *ngIf="!pageInfoSvc.isLogin">
    <div class="col-4 d-flex d-inline">

        <div *ngIf="!pageInfoSvc.isApantallamiento && drawer">
            <button mat-icon-button (click)="drawer.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
        </div>

    </div>
    <div class="col-8 d-flex justify-content-end px-4">
        <ul>
            <li>
                <div ngbDropdown>
                    <div *ngIf="!pageInfoSvc.isApantallamiento;else baseInfo" class="userName" ngbDropdownToggle>
                        {{authSvc.fullName | async}}
                    </div>
                    <ng-template #baseInfo>
                        <div class="userName">
                            {{authSvc.fullName | async}}
                        </div>
                    </ng-template>

                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="authSvc.logout()">Tancar sessió</button>
                    </div>
                </div>
            </li>
        </ul>                
    </div>
</div>
