import {Component, OnInit} from '@angular/core';
import {lastValueFrom} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {
  OperadoresParcelaEditAccionesConfig,
  OperadoresParcelaTableConfig,
  OperadoresParcelaImportTableConfig,
  OperadoresParcelaViewAccionesConfig,
  OperadoresParcelaImportAccionesConfig
} from "./operadores-parcela.table-config";
import {Pageable} from "../../../../core/model/core/pageable";
import {ActivatedRoute, Router} from "@angular/router";
import {Page} from "../../../../core/model/core/pages/page";
import {ModalService} from "../../../../core/services/components/modal.service";
import {Parcela} from "../../../../core/model/parcela/parcela";
import {ResultadoImportDarp, LogDun} from "../../../../core/model/parcela/resultadoImportDarp";
import {ParcelaHttpService} from "../../../../core/services/http/operadores/parcela-http-service";
import {
  DisclaimerEliminarGenericoComponent
} from "../../modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";

@Component({
  selector: 'app-operadores-listado-parcela',
  templateUrl: './operadores-listado-parcela.component.html',
  styleUrls: ['./operadores-listado-parcela.component.scss']
})
export class OperadoresListadoParcelaComponent extends Page implements OnInit {

  protected tableConfig = OperadoresParcelaTableConfig;
  protected tableAccionesView = OperadoresParcelaViewAccionesConfig;
  protected tableAccionesEdit = OperadoresParcelaEditAccionesConfig;

  protected tableConfigImport = OperadoresParcelaImportTableConfig;
  protected tableAccionesImport = OperadoresParcelaImportAccionesConfig;

  protected data: Pageable<Parcela>;
  protected page = 1;
  protected idOperador: string;
  protected action;

  protected formGroup: FormGroup;
  protected resultadoImportDarp: ResultadoImportDarp;
  protected viewResultadoImportDarp = false;
  protected logDun: LogDun[];

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private parcelaSvc: ParcelaHttpService,
              private modalSvc: ModalService) {
    super();

    this.data = new Pageable<Parcela>();
    this.page = 1;
    this.idOperador = this.activatedRoute.snapshot.params['idOperador'];
    this.action = this.activatedRoute.snapshot.params['action'];
    this.resultadoImportDarp = new ResultadoImportDarp();

    this.formGroup = new FormGroup({
      "ident": new FormControl(this.resultadoImportDarp.ident),
      "dataProces": new FormControl(''),
      "numProces": new FormControl(''),
      "registresProcessats": new FormControl(null),
      "registresCarregats": new FormControl(null),
      "registresRebutjats": new FormControl(null),
      "nomFitxer": new FormControl(null),
    })

  }

  async ngOnInit() {
    this.viewResultadoImportDarp = false;
    this.data = await lastValueFrom(this.parcelaSvc.getParcelas(this.idOperador, this.page));
  }

  public async onPageChange(page: number) {
    this.page = page;
    this.data = await lastValueFrom(this.parcelaSvc.getParcelas(this.idOperador, page));
  }

  public onAction($event: any) {
    switch($event.action){
      case "view":
        this.viewParcela($event.item);
        break;
      case "edit":
        this.editParcela($event.item);
        break;
      case "delete":
        this.deleteParcela($event.item);
        break;
    }
  }

  private viewParcela(item) {
    this.router.navigate(['parcela', item.numOrdenParcela, 'view'],
        {relativeTo: this.activatedRoute})
  }

  private editParcela(item) {
    this.router.navigate(['parcela', item.numOrdenParcela, 'edit'],
        {relativeTo: this.activatedRoute})
  }

  private deleteParcela(item: Parcela) {
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent,
        {"textoDisclaimer": 'OPERADORES.parcelas.disclaimerEliminar'}, {maxWidth: '30vw'})
        .afterClosed().subscribe(accepted => {
      if(accepted){
        this.parcelaSvc.eliminarParcela(this.idOperador, item.numOrdenParcela).subscribe( value =>this.reloadCurrentRoute());
      }
    });
  }

  public createParcela() {
    this.router.navigate(['parcela', "create"], {relativeTo: this.activatedRoute})
  }

  public async importDARP(){
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent,
        {"textoDisclaimer": 'OPERADORES.parcelas.disclaimerUploadParcelasFromCSV'}, {maxWidth: '30vw'})
        .afterClosed().subscribe(accepted => {
      if(accepted) {
        this.parcelaSvc.importDARP(this.idOperador).subscribe(value => {
          this.resultadoImportDarp = value;
          this.formGroup.patchValue(this.resultadoImportDarp);
          this.viewResultadoImportDarp = true;
          this.logDun = this.resultadoImportDarp.llistaLog;
        });
      }
    });
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  onBack(){
    this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
  }
}
