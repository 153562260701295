<div *ngIf="animalCenso">

    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div *ngIf="animalCenso?.numOrden; else titleNuevo">
            {{'OPERADORES.animales.titleEditAnimalCenso' | translate}}
        </div>

        <ng-template #titleNuevo>
            {{'OPERADORES.animales.anadirAnimalCenso' | translate}}
        </ng-template>

        <div class="clickable" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <form [formGroup]="animalCensoFormGroup" class="col-12" (submit)="onSubmit()">

        <div class="row">
            <div class="col-sm-4 col-lg-2 ">
                <app-input-text
                        formControlName="idInscrito"
                        [label]="'OPERADORES.numeroInscrito' | translate"
                        [campoObligatorio]="true"
                        [isDisabled] = "true"
                >
                </app-input-text>
            </div>
            <div class="col-sm-4 col-lg-2">
                <app-input-text
                        formControlName="numGranja"
                        [label]="'OPERADORES.granjas.numGranja' | translate"
                        [campoObligatorio]="true"
                        [isDisabled] = "true"
                >
                </app-input-text>
            </div>
            <div class="col-sm-4 col-lg-2">
                <app-input-text
                        formControlName="numOrden"
                        [label]="'OPERADORES.animales.numOrdenUbm' | translate"
                        [campoObligatorio]="true"
                        [isDisabled] = "true"
                >
                </app-input-text>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 col-lg-2">
                <app-input-text
                        [label]="'OPERADORES.animales.censo' | translate"
                        formControlName="censo"
                        [campoObligatorio]="true"
                >
                </app-input-text>
            </div>
            <div class="col-sm-8 col-lg-4">
                <app-input-select
                        formControlName="codigoUbm"
                        [label]="'OPERADORES.animales.clasificacionUbm' | translate"
                        [options]="clasificacionesUbm | async | arrayToSelectItem:'codigoUbm':'descripcion'"
                        [campoObligatorio]=true
                >
                </app-input-select>
            </div>
            <div class="col-sm-4 col-lg-3">
                <app-input-select
                        formControlName="idCualificacion"
                        [label]="'OPERADORES.animales.cualiEcologica' | translate"
                        [options]="cualificaciones | async | arrayToSelectItem:'idCualificacion':'descripcion'"
                        [campoObligatorio]=true
                >
                </app-input-select>
            </div>

            <div class="col-sm-8 col-lg-3">
                <app-input-datepicker
                        [label]="'OPERADORES.animales.fechaCualificacion' | translate"
                        formControlName="fechaCualificacion"
                        [campoObligatorio]=true
                >
                </app-input-datepicker>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 col-lg-2">
                <app-input-text
                        formControlName="valorUbm"
                        [label]="'OPERADORES.animales.valorUbm' | translate"
                        [isDisabled] = "true"
                >
                </app-input-text>
            </div>
        </div>

        <div class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button type="submit" mat-raised-button color="primary" class="px-2"
                    [disabled]="animalCensoFormGroup.invalid">
                {{'general.guardar' | translate}}
            </button>
        </div>
    </form>
</div>
