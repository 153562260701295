import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {delay} from "rxjs/operators";
import {LoaderState} from "../../../shared/components/loader/loader.component";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable()
      .pipe(delay(0));

  constructor() { }

  show() {
    this.loaderSubject.next(<LoaderState>{ show: true });
  }
  hide() {
    this.loaderSubject.next(<LoaderState>{ show: false });
  }

}
