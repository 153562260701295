import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Operador} from "../../../../core/model/operadores/operador";
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {Page} from "../../../../core/model/core/pages/page";
import {NavigationService} from "../../../../core/services/navigation.service";
import {MatTabChangeEvent} from "@angular/material/tabs";

@Component({
    selector: 'app-operadores-detalle',
    templateUrl: './operadores-detalle.component.html',
    styleUrls: ['./operadores-detalle.component.scss']
})
export class OperadoresDetalleComponent extends Page implements OnInit {

    public idOperador: string = "";
    public operador: Operador = new Operador();
    public selectedIndex: number = 0;
    public action;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private navService: NavigationService,
                private operadorSvc: OperadoresHttpService) {
        super();

        this.idOperador = this.activatedRoute.snapshot.params['idOperador'];
        this.operador = new Operador();
        this.selectedIndex = this.navService.currentTabIndex;
    }

    ngOnInit(): void {
        if(this.idOperador) {
            this.operadorSvc.getOperador(this.idOperador)
                .subscribe({
                    next: value => this.operador = value,
                    error: (e) => {
                        this.router.navigate(["../"], {relativeTo: this.activatedRoute})
                    }
                });
        }

        this.activatedRoute.params.subscribe(params => {
            this.action = params['action'];
        });
    }

    onTabChange(event: MatTabChangeEvent) {
        this.navService.currentTabIndex = event.index
    }
}
