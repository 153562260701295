import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Almacen} from "../../../../core/model/almacen/almacen";
import {AlmacenHttpService} from "../../../../core/services/http/operadores/almacen-http-service";
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import {CualificacionAlmacen} from "../../../../core/model/almacen/cualificacionAlmacen";
import {Municipio} from "../../../../core/model/localizacion/municipio";
import {AuthService} from "../../../../core/services/security/auth.service";
import {Operador} from "../../../../core/model/operadores/operador";


@Component({
  selector: 'app-operadores-detalle-almacen',
  templateUrl: './operadores-detalle-almacen.component.html',
  styleUrls: ['./operadores-detalle-almacen.component.scss']
})
export class OperadoresDetalleAlmacenComponent implements OnInit {

  protected almacenSvc: AlmacenHttpService;
  protected authSvc: AuthService;

  @Input() form: FormGroup;

  protected idOperador: string;
  protected numOrdenAlmacen: number;
  protected almacen: Almacen;
  protected formGroup: FormGroup;
  protected municipio: Municipio = new Municipio();
  protected cualificacionesAlmacen: Observable<CualificacionAlmacen[]>
  protected filteredOptions: string[] = ['1', '2', '3'];
  protected usuario;
  protected poligono: string;

  protected action;
  protected editing = false;
  protected creating = false;
  protected readonly = false;
  protected municipioValido = false;
  protected produccioRamadera = false;

  //Data for Alta operador
  @Input() altaOperador = false;
  @Input() idAltaInscrito;
  @Input() numOrdenAlmacenAltaOp;
  @Input() almacenAltaOp;
  @Input() altaInscrito;
  @Output() saveAlmacenAltaOp = new EventEmitter<any>;
  @Output() closeModalAltaOp = new EventEmitter<any>;

  camaraFrigorificaOptions = [
    { id: '1', valor: true, label: 'Cambra frigorífica', disabled: false },
    { id: '2', valor: false, label: 'Magatzem', disabled: false }
  ];

  externoOptions = [
    { id: '1', valor: true, label: 'Extern', disabled: false },
    { id: '2', valor: false, label: 'Propi', disabled: false }
  ];

  constructor(private operadorSvc: OperadoresHttpService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              injector: Injector) {

    this.almacenSvc = injector.get(AlmacenHttpService);
    this.authSvc = injector.get(AuthService);

    this.idOperador = activatedRoute.snapshot.params['idOperador'];
    this.numOrdenAlmacen = activatedRoute.snapshot.params['numOrdenAlmacen'];

    this.almacen = new Almacen();

    this.formGroup = new FormGroup({
      "idAltaAlmacen": new FormControl(null),
      "idAltaInscrito": new FormControl(null),
      "numOrdenAlmacen": new FormControl(null),
      "numAlmacen": new FormControl(null, Validators.maxLength(2)),
      "nombreAlmacen": new FormControl('', Validators.maxLength(50)),
      "poligono": new FormControl('', Validators.maxLength(20)),
      "parcela": new FormControl('', Validators.maxLength(35)),
      "recinto": new FormControl('', Validators.maxLength(7)),
      "direccion": new FormControl('', Validators.maxLength(100)),
      "superficie": new FormControl('', [Validators.required, Validators.pattern("^(?!0\\d)\\d{1,6}(?:\\.\\d{1,2})?$")]),
      "cualificacionAlmacen": new FormControl(null, [Validators.required]),
      "compartido": new FormControl(false),
      "maquinaria": new FormControl(false),
      "fertilizantes": new FormControl(false),
      "semillas": new FormControl(false),
      "pajaForraje": new FormControl(false),
      "veterinarios": new FormControl(false),
      "contProductos": new FormControl(false),
      "municipio": new FormControl(null),
      "localizadoEnPoblacion": new FormControl(null, [Validators.required]),
      "compartidoCon": new FormControl(null, Validators.maxLength(100)),
      "camaraFrigorifica": new FormControl(false, [Validators.required]),
      "externo": new FormControl(false, [Validators.required]),
      "registroSanitario": new FormControl('', Validators.maxLength(50))
    });

  }

  async ngOnInit() {
    this.cualificacionesAlmacen = this.almacenSvc.getCualificaciones().pipe(
        map((cualificaciones) =>
            cualificaciones.filter((cualificacion) =>
                this.filteredOptions.includes(cualificacion.codigo)
            )
        )
    );

    if (this.altaOperador) {
      this.creating = true;
      this.editing = true;
      this.produccioRamadera = this.altaInscrito._1_2_produccioRamadera;
      this.formGroup.patchValue({
        camaraFrigorifica: this.almacenAltaOp.camaraFrigorifica ? '1' : '2',
        externo: this.almacenAltaOp.externo ? '1' : '2'
      });
    } else {
      this.operadorSvc.getOperador(this.idOperador).subscribe((operador: Operador) => {
        this.produccioRamadera = operador.familiaP.includes("PR");
      });
    }

    this.authSvc.fullName.subscribe(value => { this.usuario = value; });

    if (this.numOrdenAlmacen) {
      this.almacenSvc.getAlmacen(this.idOperador, this.numOrdenAlmacen)
          .subscribe({
            next: value => {
              this.almacen = value;
              this.formGroup.patchValue({
                ...this.almacen,
                camaraFrigorifica: this.almacen.camaraFrigorifica ? '1' : '2',
                externo: this.almacen.externo ? '1' : '2'
              });
            },
            error: (e) => this.router.navigate(["../../"], { relativeTo: this.activatedRoute })
          });
    } else {
      this.formGroup.patchValue({
        camaraFrigorifica: null,
        externo: null
      });
    }

    this.activatedRoute.params.subscribe(params => {
      this.action = params['almacenAction'];

      if (this.action == 'view') {
        this.editing = false;
        this.formGroup.disable();
      } else if (this.action == 'edit') {
        this.editing = true;
        this.formGroup.enable();
      } else if (this.action == 'create' || this.action == null) {
        this.creating = true;
        this.editing = true;
        this.formGroup.enable();
      }
    });

    if (this.altaOperador) {
      if (this.almacenAltaOp != null) {
        this.creating = false;
        this.editing = true;
        this.almacen = this.almacenAltaOp;
        this.formGroup.patchValue({
          ...this.almacenAltaOp,
          camaraFrigorifica: this.almacenAltaOp.camaraFrigorifica ? '1' : '2',
          externo: this.almacenAltaOp.externo ? '1' : '2'
        });
      }
    }
  }


  public onChangeEnPoblacion(event: Event, buttonId: string){
    const enPoblacion = (buttonId=='localizadoEnPoblacion');

    this.formGroup.get('localizadoEnPoblacion')?.setValue(enPoblacion);
    this.almacen.localizadoEnPoblacion = enPoblacion;
  }

  public onChangeCompartido(event: Event, buttonId: string){
    const esCompartido = (buttonId=='esCompartido');

    this.formGroup.get('compartido')?.setValue(esCompartido);
    this.almacen.compartido = esCompartido;
    this.formGroup.get('compartidoCon')?.setValue(null);
  }

  protected validateForm() {
    return this.municipioValido && this.formGroup.valid;
  }

  onGuardarForm() {
    if (this.numOrdenAlmacen != null) {
      let updatedAlmacen = this.transformFormValues(this.formGroup.value);
      updatedAlmacen.usuario = this.usuario;
      this.almacenSvc.updateAlmacen(this.idOperador, this.numOrdenAlmacen, updatedAlmacen).subscribe(value => {
        this.almacen = value;
        this.formGroup.patchValue({
          ...this.almacen,
          camaraFrigorifica: this.almacen.camaraFrigorifica ? '1' : '2',
          externo: this.almacen.externo ? '1' : '2'
        });
      });
    } else if (this.numOrdenAlmacen == null) {
      let newAlmacen = this.transformFormValues(this.formGroup.value);
      newAlmacen.municipio = this.municipio;
      newAlmacen.usuario = this.usuario;
      this.almacenSvc.createAlmacen(this.idOperador, newAlmacen).subscribe(value => {
        this.almacen = value;
        this.formGroup.patchValue({
          ...this.almacen,
          camaraFrigorifica: this.almacen.camaraFrigorifica ? '1' : '2',
          externo: this.almacen.externo ? '1' : '2'
        });
        this.router.navigate(["../../"], { relativeTo: this.activatedRoute });
      });
    }
  }

  onBack(){
    this.router.navigate(["../../../"], {relativeTo: this.activatedRoute})
  }

  onBackCreate(){
    this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
  }


  onGuardarFormAltaOp() {

    let newAlmacen = this.transformFormValues(this.formGroup.value);

    if (this.almacen.numOrdenAlmacen != null) {
      newAlmacen.municipio = this.almacen.municipio;
    } else {
      newAlmacen.municipio = this.municipio;
    }

    newAlmacen.idAltaInscrito = this.idAltaInscrito; // Set the idAltaInscrito value

    this.saveAlmacenAltaOp.emit(newAlmacen);
  }

  private transformFormValues(values: any): any {
    return {
      ...values,
      camaraFrigorifica: values.camaraFrigorifica === '1' || values.camaraFrigorifica === true,
      externo: values.externo === '1' || values.externo === true
    };
  }

}
