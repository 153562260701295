import {Component, Injector, Input, OnInit} from '@angular/core';
import {NgControl} from '@angular/forms';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html'
})
export class InputPasswordComponent implements OnInit {

  @Input() id : string = "noName";
  @Input() label = "";
  @Input() value = "";
  @Input() campoObligatorio: boolean = false;
  @Input() readonly: boolean = false;

  modificado = false;
  isDisabled: boolean;
  onChange = (_:any) => { }
  onTouch = () => { }

  public ngControl: NgControl;

  constructor(private inj: Injector) {


  }

  ngOnInit(): void {
    try {
      this.ngControl = this.inj.get(NgControl)
      this.ngControl.valueAccessor = this;
    } catch (e) {

    }
  }

  change($event) {
    this.modificado = true;
    this.onChange($event.target.value);
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}
