import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ToastService} from "../components/toast.service";
import {HttpService} from "./core/http.service";
import {Observable, shareReplay} from "rxjs";
import {Comarca} from "../../model/localizacion/comarca";
import {Municipio} from "../../model/localizacion/municipio";
import {CodigoPostal} from "../../model/localizacion/codigoPostal";
import {Provincia} from "../../model/localizacion/provincia";
import {Inspector} from "../../model/localizacion/inspector";

@Injectable({
  providedIn: 'root'
})
export class LocalizacionHttpService extends HttpService {

    private BASE = `localizacion`;
    private PROVINCIA = `${this.BASE}/provincia`;
    private COMARCA = `${this.BASE}/comarca`;
    private MUNICIPIO = `${this.BASE}/municipio`;
    private CODIGO_POSTAL = `${this.BASE}/codigoPostal`;
    private INSPECTOR = `${this.BASE}/inspector`;

    private COMARCA_BY_PROVINCIA = (provincia: string) => `${this.BASE}/comarca/${provincia}`;
    private MUNICIPIO_BY_COMARCA = (comarca: string) => `${this.BASE}/municipio/comarca/${comarca}`;
    private CODIGO_POSTAL_BY_MUNICIPIO = (municipio: string) => `${this.BASE}/codigoPostal/${municipio}`;

    constructor(http: HttpClient,
                toastSvc: ToastService) {
        super(http, toastSvc);
    }

    public getProvincias(): Observable<Provincia[]> {
        return this.get(this.PROVINCIA).pipe(shareReplay());
    }

    public getComarcas(): Observable<Comarca[]> {
        return this.get(this.COMARCA).pipe(shareReplay());
    }

    public getMunicipios(): Observable<Municipio[]> {
        return this.get(this.MUNICIPIO).pipe(shareReplay());
    }

    public getComarcasByProvincia(provincia): Observable<Comarca[]> {
        return this.get(this.COMARCA_BY_PROVINCIA(provincia)).pipe(shareReplay());
    }

    public getMunicipiosByComarcas(comarca): Observable<Municipio[]> {
        return this.get(this.MUNICIPIO_BY_COMARCA(comarca)).pipe(shareReplay());
    }

    public getCodigosPostalesByMunicipios(municipio): Observable<CodigoPostal[]> {
        return this.get(this.CODIGO_POSTAL_BY_MUNICIPIO(municipio)).pipe(shareReplay());
    }

    public getInspectorByMunicipio(municipio): Observable<Inspector>{
        let params = {
                codigoPostal: municipio.codigoPostal,
                codigoLocal: municipio.codigoLocal,
                descripcion: municipio.descripcion
        };
        let httpParams = this.objectToHttpParams(params);
        return this.get(this.INSPECTOR, httpParams).pipe(shareReplay());
    }
}
