import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../components/toast.service";
import {HttpService} from "../core/http.service";
import {Observable, shareReplay} from "rxjs";
import {Ubicacion} from "../../../model/master/ubicacion";

@Injectable({
  providedIn: 'root'
})
export class UbicacionesHttpService extends HttpService {

  protected BASE = "ubicaciones";
  protected ELABORACION = this.BASE + "/elaboracion";
  protected ENVASADO = this.BASE + "/envasado";
  protected ETIQUETAJE = this.BASE + "/etiquetaje";

  constructor(http: HttpClient,
              toastSvc: ToastService) {
    super(http, toastSvc);
  }

  public getUbicacionesElaboracion(): Observable<Ubicacion[]> {
    return this.get(this.ELABORACION)
        .pipe(shareReplay());
  }

  public getUbicacionesEnvasado(): Observable<Ubicacion[]> {
    return this.get(this.ENVASADO)
        .pipe(shareReplay());
  }

  public getUbicacionesEtiquetaje(): Observable<Ubicacion[]> {
    return this.get(this.ETIQUETAJE)
        .pipe(shareReplay());
  }

  public getUbicaciones(): Observable<Ubicacion[]> {
    return this.get(this.BASE)
        .pipe(shareReplay());
  }

}
