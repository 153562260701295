import {Component, Inject, Injector, OnInit} from '@angular/core';
import {Operador} from "../../../../core/model/operadores/operador";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AltaAlmacen} from "../../../../core/model/operadores/altaAlmacen";
import {FormControl, FormGroup} from "@angular/forms";
import {AltaInscrito} from "../../../../core/model/operadores/altaInscrito";

@Component({
  selector: 'app-operadores-alta-almacen-modal',
  templateUrl: './operadores-alta-almacen-modal.component.html',
  styleUrls: ['./operadores-alta-almacen-modal.component.scss']
})
export class OperadoresAltaAlmacenModalComponent implements OnInit {

  operador: Operador;
  numOrdenAlmacen: number;
  altaAlmacen: AltaAlmacen;
    altaInscrito: AltaInscrito;
  idSolicitudInscrito: string;

  camaraFrigorificaOptions = [
    { id: '1', valor: true, label: 'Cambra frigorífica', disabled: false },
    { id: '2', valor: false, label: 'Magatzem', disabled: false }
  ];

  externoOptions = [
    { id: '1', valor: true, label: 'Extern', disabled: false },
    { id: '2', valor: false, label: 'Propi', disabled: false }
  ];

  formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) data: {idSolicitudInscrito: string, altaAlmacen: AltaAlmacen, altaInscrito: AltaInscrito},
              public dialogRef: MatDialogRef<OperadoresAltaAlmacenModalComponent>,
              injector: Injector) {
    this.altaAlmacen = data.altaAlmacen;
    this.altaInscrito = data.altaInscrito;
    this.idSolicitudInscrito = data.idSolicitudInscrito;
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      camaraFrigorifica: new FormControl(this.altaAlmacen.camaraFrigorifica ? '1' : '2'),
      externo: new FormControl(this.altaAlmacen.externo ? '1' : '2'),
      cualificacionAlmacen: new FormControl(this.altaAlmacen.cualificacionAlmacen)
    });
  }

  onCreateAltaAlmacen($event) {
    this.dialogRef.close($event);
  }

  onCloseModal() {
    this.dialogRef.close();
  }
}
