<div *ngIf="producto">
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div *ngIf="producto.idSolicitudProducto; else titleNuevo">
            {{'general.edicion' | translate}} "{{producto.nombreComercial}}"
        </div>
        <ng-template #titleNuevo>
            {{'ETIQUETAS.addProducto' | translate}}
        </ng-template>

        <div class="clickable" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <hr/>
    <form [formGroup]="formGroup" class="col-12" (submit)="onSubmit()">

        <div class="row">
            <div class="col-md-4 col-sm-6">
                <app-input-text
                        formControlName="nombreComercial"
                        [label]="'ETIQUETAS.nombreComercial' | translate"
                        [campoObligatorio]="true"
                >
                </app-input-text>
            </div>
            <div class="col-md-4 col-sm-6">
                <app-input-autocomplete
                        formControlName="idProducto"
                        [observable]="productoSvc.getProductosAutocomplete()"
                        [label]="'ETIQUETAS.producto' | translate"
                        [campoObligatorio]="true"
                        [isDisabled]="esVisualizacion"
                        [small]="'ETIQUETAS.productoHelp' | translate"
                        (change)="handleFiltroPopUpAlbaran()"
                >
                </app-input-autocomplete>
            </div>
            <div class="col-md-4 col-sm-6">
                <app-input-select
                        formControlName="idCategoriaEcologica"
                        [label]="'ETIQUETAS.categoriaEcologica' | translate"
                        [options]="categorias | async | arrayToSelectItem:'idCategoriaEcologica':'descripcion'"
                        [campoObligatorio]="true"
                >
                </app-input-select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 col-sm-6">
                <app-input-text
                        formControlName="marca"
                        [label]="'general.marca' | translate"
                        [campoObligatorio]="true"
                >
                </app-input-text>
            </div>
            <div class="col-md-4 col-sm-6">
                <app-input-select
                        formControlName="idEnvase"
                        [label]="'general.envase' | translate"
                        [campoObligatorio]="true"
                        [options]="envases | async | arrayToSelectItem:'idEnvase':'descripcion'"
                >
                </app-input-select>
            </div>
            <div class="col-md-4 col-sm-6">
                <app-input-text
                        formControlName="capacidad"
                        [label]="'general.capacidad' | translate"
                        [campoObligatorio]="true"
                        [small]="'general.capacidadHelp' | translate"
                >
                </app-input-text>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 col-sm-6">
                <app-input-text
                        formControlName="tecnicaTransformacion"
                        [label]="'ETIQUETAS.elaborado.tecnicaTransformacion' | translate"
                        [campoObligatorio]="true"
                >
                </app-input-text>
            </div>
            <div class="col-md-4 col-sm-6">
                <app-input-select
                        formControlName="tipoNormativa"
                        [label]="'ETIQUETAS.normativaUeNop' | translate"
                        [campoObligatorio]="true"
                        [options]="normativas | arrayToSelectItem:'descripcion':'descripcion'"
                >
                </app-input-select>
            </div>
        </div>

        <div class="mt-4">
            <div class="row">
                <br/>
                <div class="col-md-12 col-sm-6">
                    <app-input-textarea
                            formControlName="auxiliaresTecnologicos"
                            [label]="'ETIQUETAS.vinos.auxiliaresTecnologicos' | translate"
                            [rows]="5"
                    >

                    </app-input-textarea>
                </div>
            </div>
        </div>

        <div *ngIf="false" class="col-md-12 d-flex align-items-center">
            <app-input-checkbox
                    class="mx-2"
                    formControlName="requiereEtiqueta"
                    [label]="'ETIQUETAS.caldraEtiqueta' | translate"
                    [campoObligatorio]="true"
            >
            </app-input-checkbox>
        </div>

        <div class="mt-4">
            <div class="row">
                <div class="col-4">
                    <app-input-select
                            formControlName="idUbicacionElaboracion"
                            [label]="'ETIQUETAS.elaboracio' | translate"
                            [campoObligatorio]="true"
                            [options]="ubicacionesElaboracion | async | arrayToSelectItem:'idUbicacion':'descripcion'"
                            [orderOptions]="false"
                    >
                    </app-input-select>
                </div>
                <div class="col-4">
                    <app-input-text
                            *ngIf="idUbicacionElaboracion == ubicacionEnum.PROPI
                            || idUbicacionElaboracion == ubicacionEnum.SUBCONTRATO"
                            formControlName="textoElaboracion"
                            [label]="literalesUbicaciones[idUbicacionElaboracion] | translate"
                    >
                    </app-input-text>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <app-input-select
                            formControlName="idUbicacionEnvase"
                            [label]="'Envasament del producte' | translate"
                            [campoObligatorio]="true"
                            [options]="ubicacionesEnvasado | async | arrayToSelectItem:'idUbicacion':'descripcion'"
                            [orderOptions]="false"
                    >
                    </app-input-select>
                </div>
                <div class="col-4">
                    <app-input-text
                            *ngIf="idUbicacionEnvase == ubicacionEnum.PROPI
                            || idUbicacionEnvase == ubicacionEnum.SUBCONTRATO"
                            formControlName="textoEnvase"
                            [label]="literalesUbicaciones[idUbicacionEnvase]  | translate"
                    >
                    </app-input-text>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <app-input-select
                            formControlName="idUbicacionEtiqueta"
                            [label]="'Etiquetatge del producte' | translate"
                            [campoObligatorio]="true"
                            [options]="ubicacionesEtiquetaje | async | arrayToSelectItem:'idUbicacion':'descripcion'"
                            [orderOptions]="false"
                            (change)="handlePopUpAlbaran()"
                    >
                    </app-input-select>
                </div>
                <div class="col-4">
                    <app-input-text
                            *ngIf="idUbicacionEtiqueta == ubicacionEnum.PROPI
                            || idUbicacionEtiqueta == ubicacionEnum.SUBCONTRATO"
                            formControlName="textoEtiquetaje"
                            [label]="literalesUbicaciones[idUbicacionEtiqueta]  | translate"
                    >
                    </app-input-text>
                </div>
            </div>
        </div>

            <div>
                <br/>
                <p>{{'general.camposObligatorios' | translate}}</p>
            </div>

        <div *ngIf="!esVisualizacion" class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button type="submit" mat-raised-button color="primary" class="px-2"
                    [disabled]="formGroup.invalid">
                {{'general.guardar' | translate}}
            </button>
        </div>

    </form>
</div>
