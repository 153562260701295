import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {EtiquetasCompuestoBaseComponent} from "../etiquetas-compuesto-base.component";
import {ModalService} from "../../../../core/services/components/modal.service";
import {SolicitudProductoCompuesto} from "../../../../core/model/etiquetas/solicitud-producto-compuesto";
import {
  EditProductoElaboradoModal
} from "../../modals/edit-producto-elaborado-modal/edit-producto-elaborado-modal.component";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {
  AccionesProductos,
  AccionesProductosCompuestosConfirmacion,
  ProductoTablePreviewConfig
} from "../../etiquetas.config";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalEdicion} from "../etiquetas-base.component";
import {firstValueFrom} from "rxjs";
import {TramiteGuard} from "../../guards/tramite.guard";
import {EtiquetasCompuestoService} from "../../services/etiquetas-compuesto.service";
import {TramiteElaboradosGuard} from "../../guards/tramite-elaborados.guard";
import {EditAdjuntosModalComponent} from "../../modals/edit-adjuntos-modal/edit-adjuntos-modal.component";
import {
  DisclaimerEliminarGenericoComponent
} from "../../../operadores/modals/disclaimer-eliminar-generico/disclaimer-eliminar-generico.component";

@Component({
  selector: 'app-etiquetas-elaborado-peticion-confirmacion',
  templateUrl: './etiquetas-elaborado-confirmacion.component.html',
  styleUrls: ['./etiquetas-elaborado-confirmacion.component.scss'],
  providers: [
    {provide: EtiquetasCompuestoService},
    {provide: TramiteGuard, useClass: TramiteElaboradosGuard}
  ]
})
export class EtiquetasElaboradoConfirmacionComponent extends EtiquetasCompuestoBaseComponent implements OnInit {

  protected tableConfig = ProductoTablePreviewConfig;
  protected tableAcciones = AccionesProductosCompuestosConfirmacion;

  constructor(injector: Injector,
              modalSvc: ModalService,
              tramiteGuard: TramiteGuard,
              cdr: ChangeDetectorRef) {
    super(injector, modalSvc, tramiteGuard, cdr);

  }

  override onEditarProducto(producto: SolicitudProductoCompuesto): MatDialogRef<EditProductoElaboradoModal> {
    let modal = this.modalSvc.openDialog(EditProductoElaboradoModal, {producto});
    modal.componentInstance.enableReadOnly();
    return modal;
  }

  override async onHandleEditarProducto(idSolicitudProducto: number, result: SolicitudProducto) {
    let toSend = result as SolicitudProductoCompuesto;

    if (toSend) {
      await firstValueFrom(this.solicitudProductoSvc.patchProducto(idSolicitudProducto, toSend));
      await this.etiquetasSvc.cargarProductos();
    }
  }

  override onEditarIngredientes(producto: SolicitudProductoCompuesto): MatDialogRef<ModalEdicion> {
    let modal = super.onEditarIngredientes(producto);
    modal.componentInstance.enableReadOnly();
    return modal;
  }

  override onActionTable($event: any) {
    if ($event.action == AccionesProductos.VIEW_ETIQUETA) {
      this.solicitudProductoSvc.visualizarEtiqueta($event.item.idSolicitudProducto);
    }
    else if($event.action == AccionesProductos.VIEW_ADJUNTOS) {
      this.modalSvc.openDialog(EditAdjuntosModalComponent, {producto: $event.item, readOnly: true})
    }
    else {
      super.onActionTable($event);
    }

  }

  override validateForm(productos: SolicitudProducto[]): boolean {
    return productos.every(item => item.aceptado === true || (item.aceptado === false && item.idTipoRechazo != null && item.motivoRechazo != null)) && productos.length > 0;
  }

  override onHandleConfirmar() {
    this.etiquetasSvc.enviarFinalizacion()
        .subscribe(_ => {
          window.self.close();
        })
  }

  override onConfirmar(): void {
    this.modalSvc.openDialog(DisclaimerEliminarGenericoComponent, {textoDisclaimer: "ETIQUETAS.disclaimerFinalizar"}, {maxWidth: '30vw'})
        .afterClosed()
        .subscribe(confirm => {
          if (confirm) this.onHandleConfirmar();
        });
  }

  onGuardar() {
    this.toastSvc.showSuccess("Sol·licitud guardada correctament");
  }
}
