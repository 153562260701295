<app-page-template
        [title]="title | translate: titleParams"
>
    <form [formGroup]="formGroup">
        <mat-card class="card shadow">
            <mat-card-title>
                {{'OPERADORES.granjas.titleCardDetall' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <div class="col-sm-4 col-lg-2">
                        <app-input-text
                                formControlName="numGranja"
                                [campoObligatorio]="action != 'view'"
                                [label]="'OPERADORES.granjas.numGranja' | translate"
                                [helpText]="textNumGranja | translate"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-sm-8 col-lg-3">
                        <app-input-text
                                formControlName="codigoRega"
                                [label]="'OPERADORES.granjas.codigoRega' | translate"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-lg-3">
                        <app-input-text
                                formControlName="nombreGranja"
                                [label]="'general.nombre' | translate"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-sm-6 col-lg-2">
                        <app-input-text
                                formControlName="marcaOficial"
                                [label]="'OPERADORES.granjas.marcaOficial' | translate"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-sm-6 col-lg-2">
                        <app-input-text
                                formControlName="libroExplotacion"
                                [label]="'OPERADORES.granjas.libroExplotacion' | translate"
                        >
                        </app-input-text>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-2 col-xxl-1">
                        <app-input-text
                                formControlName="poligono"
                                [campoObligatorio]="action != 'view'"
                                [label]="'OPERADORES.poligiono' | translate"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-6 col-md-3 col-xxl-2">
                        <app-input-text
                                formControlName="parcela"
                                [campoObligatorio]="action != 'view'"
                                [label]="'OPERADORES.parcela' | translate"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-md-7 col-xxl-9">
                        <app-input-text
                                formControlName="direccion"
                                [campoObligatorio]="action != 'view'"
                                [label]="'general.direccion' | translate"
                        >
                        </app-input-text>
                    </div>
                </div>
                <div class="row" >
                    <app-operadores-localizacion
                        [campoObligatorio]="action != 'view'"
                        [readonly] = "action == 'view'"
                        [municipioInput]="municipio"
                        (formValid)="municipioValido = $event">
                    >
                    </app-operadores-localizacion>
                </div>
                <div class="row">
                    <div class="col-sm-5 col-md-3">
                        <app-input-select
                                formControlName="orientacionProd"
                                [campoObligatorio]="action != 'view'"
                                [label]="'OPERADORES.granjas.orientacionProd' | translate"
                                [options]="orientacionesProductos | async | arrayToSelectItem:'descripcion': 'descripcion'"
                        >
                        </app-input-select>
                    </div>
                    <div class="col-sm-7 col-md-4">
                        <app-input-select
                                formControlName="idEspecieGanado"
                                [campoObligatorio]="action != 'view'"
                                [label]="'OPERADORES.granjas.especieGanado' | translate"
                                [options]="especiesGanado | async | arrayToSelectItem:'idProducto':showGanado"
                        >
                        </app-input-select>
                    </div>
                    <div class="col-sm-4 col-md-3 col-lg-2">
                        <p class="font-weight-bold mt-2 mb-0">{{'OPERADORES.granjas.alternanciaAvicola' | translate}}</p>
                        <div class="row d-flex flex-row">
                            <div class="col">
                                <app-input-radio
                                        formControlName="alternanciaAvicola"
                                        [label]="'general.si' | translate"
                                        [value]=true
                                        [id]="'true'"
                                        [isDisabled]="action == 'view'">

                                </app-input-radio>
                            </div>
                            <div class="col">
                                <app-input-radio
                                        formControlName="alternanciaAvicola"
                                        [label]="'general.no' | translate"
                                        [value]=false
                                        [id]="'false'"
                                        [isDisabled]="action == 'view'">
                                </app-input-radio>
                            </div>
                        </div>
                    </div>
                </div>

            </mat-card-content>

        </mat-card>
        <mat-card class="card shadow">
            <mat-card-title>
                {{'OPERADORES.datosSuperficie' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <div class="col-sm-5 col-md-2">
                        <app-input-text
                                formControlName="superficieCubierta"
                                [label]="'OPERADORES.granjas.superficieCubierta' | translate"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-sm-7 col-md-3">
                        <app-input-text
                                formControlName="capacidadMaxCubierta"
                                [label]="'OPERADORES.granjas.capacidadMaxCubierta' | translate"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-sm-5 col-md-2">
                        <app-input-text
                                formControlName="superficiePatio"
                                [label]="'OPERADORES.granjas.superficiePatio' | translate"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-sm-7 col-md-3">
                        <app-input-text
                                formControlName="capacidadMaxPatio"
                                [label]="'OPERADORES.granjas.capacidadMaxPatio' | translate"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-sm-5 col-md-2">
                        <app-input-text
                                formControlName="capacidadDarp"
                                [label]="'OPERADORES.granjas.capacidadDarp' | translate"
                        >
                        </app-input-text>
                    </div>
                </div>
            </mat-card-content>

        </mat-card>
        <mat-card class="card shadow">
            <mat-card-title>
                {{'general.infoAdiccional' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <div class="col">
                        <app-input-textarea
                                formControlName="observaciones"
                                [label]="'general.observaciones' | translate"
                        >
                        </app-input-textarea>
                    </div>
                </div>
            </mat-card-content>

        </mat-card>
        <div *ngIf="action != 'view'" class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" [disabled]="!validateForm()"
                    (click)="onGuardarForm()"
            >
                {{'OPERADORES.modificarOperador.guardarDatosGranja' | translate}}
            </button>
        </div>
    </form>


    <button mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
        <mat-icon>arrow_back</mat-icon>
        {{"general.volver" | translate}}
    </button>

</app-page-template>
