import {Component, Injector, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {Observable, of} from "rxjs";
import {debounceTime, distinctUntilChanged, map, switchMap} from "rxjs/operators";
import {SelectItem} from "../../../model/select-item";

@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html'
})
export class InputAutocompleteComponent implements OnInit, ControlValueAccessor {

  private static searchHttpDecorator = (callback : ($text: string) => Observable<any>,
                                        debounceTimeMs = 200,
                                        maxLength = 3,
                                        maxItems = 10) => {
    return (text$: Observable<string>) => text$.pipe(
        debounceTime(debounceTimeMs),
        distinctUntilChanged(),
        switchMap(term => term.length < maxLength ? of([]): callback(term)),
        map(res => res?.slice(0, maxItems))
    )
  }

  @Input() id : string = "noName";
  @Input() label = "";
  @Input() value! : SelectItem;
  @Input() observable!: (_: string) => Observable<any>;
  @Input() campoObligatorio: boolean = false;
  @Input() returnIdValue: boolean = true;
  @Input() helpText: string = '';
  @Input() small: string = '';

  typeaheadObs: (_: Observable<string>) => Observable<any>;
  @Input() isDisabled: boolean = false;
  onChange = (_:any) => { }
  onTouch = () => { }

  formatter = (x: {id: number, valor: string}) => x.valor;

  public ngControl!: NgControl;

  constructor(private inj: Injector) {
    this.typeaheadObs = (_: Observable<string>) => of( []);
  }

  ngOnInit(): void {
    this.typeaheadObs = InputAutocompleteComponent.searchHttpDecorator(this.observable);

    try {
      this.ngControl = this.inj.get(NgControl);
      this.ngControl.valueAccessor = this;
    } catch (e) {

    }
  }

  change($event: any) {
    if (this.returnIdValue) {
      this.onChange(this.value.id);
    } else {
      this.onChange(this.value.valor);
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (obj) {
      this.change(null);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}
