
export enum CategoriaEcologicaEnum {
	INGREDIENTS_AE = 1,
	AE_95 = 2,
	CONVERSION = 3,
	INGREDIENT_CAZA_PESCA,
	PAE_CONFORMITAT_PINSOS,
	AE_95_NO_ELABORADOS
}

export class CategoriaEcologica {
	idCategoriaEcologica!: CategoriaEcologicaEnum;
	descripcion!: string;
}