import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {EtiquetasBaseComponent, ModalEdicion} from "../etiquetas-base.component";
import {ModalService} from "../../../../core/services/components/modal.service";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {MatDialogRef} from "@angular/material/dialog";
import {
  AccionesProductos,
  AccionesProductosConfirmacion,
  ProductoTablePreviewConfig
} from "../../etiquetas.config";
import {
  EditProductoHortofructiculaModalComponent
} from "../../modals/edit-producto-hortofructicula-modal/edit-producto-hortofructicula-modal.component";
import {TramiteGuard} from "../../guards/tramite.guard";
import {TramiteHortofruticolaGuard} from "../../guards/tramite-hortofruticola.guard";
import {EditAdjuntosModalComponent} from "../../modals/edit-adjuntos-modal/edit-adjuntos-modal.component";

@Component({
  selector: 'app-etiquetas-hortofruticola-visualizacion',
  templateUrl: './etiquetas-hortofruticola-visualizacion.component.html',
  styleUrls: ['./etiquetas-hortofruticola-visualizacion.component.scss'],
  providers: [
    {provide: TramiteGuard, useClass: TramiteHortofruticolaGuard}
  ]
})
export class EtiquetasHortofruticolaVisualizacionComponent extends EtiquetasBaseComponent  implements OnInit {

  protected tableConfig = ProductoTablePreviewConfig;
  protected tableAcciones = AccionesProductosConfirmacion;

  constructor(injector: Injector,
              modalSvc: ModalService,
              tramiteGuard: TramiteGuard,
              cdr: ChangeDetectorRef) {
    super(injector, modalSvc, tramiteGuard, cdr);
  }

  override onActionTable($event: any) {
    if($event.action == AccionesProductos.VIEW_ETIQUETA){
      this.solicitudProductoSvc.visualizarEtiqueta($event.item.idSolicitudProducto);
    }
    else if($event.action == AccionesProductos.VIEW_ADJUNTOS) {
      this.modalSvc.openDialog(EditAdjuntosModalComponent, {producto: $event.item, readOnly: true})
    }
    else if($event.action == AccionesProductos.ACEPTAR) {
    }
    else if($event.action == AccionesProductos.RECHAZAR) {
    }
    else {
      super.onActionTable($event);
    }
  }

  override onEditarProducto(producto: SolicitudProducto): MatDialogRef<ModalEdicion> {
    let modal = this.modalSvc.openDialog(EditProductoHortofructiculaModalComponent, {producto});
    modal.componentInstance.enableReadOnly();
    return modal;
  }

  override async onHandleEditarProducto(idSolicitudProducto: number, producto: SolicitudProducto) {
  }

  override validateForm(productos: SolicitudProducto[]): boolean {
    return true;
  }

  override onHandleConfirmar() {
  }

}
