<div *ngIf="producto">
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div>
            {{'ETIQUETAS.tituloIngredientesModal' | translate}} "{{producto.nombreComercial}}"
        </div>

        <div class="clickable" (click)="dialogRef.close(ingredientes.value)">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <div class="col-12 table-container">
        <app-generic-table
            [data]="ingredientes | async"
            [columnsConfig]="tableIngredientesConfig"
            [actionsConfig]="tableIngredienteAcciones"
            [textNoData]="'ETIQUETAS.noHayIngredientes' | translate"
            (onAction)="onActionTable($event)"
        >
        </app-generic-table>
    </div>

    <div *ngIf="!readOnly && !showAddForm && showAddButton" class="col-12 mt-2 d-flex justify-content-end">
        <button mat-mini-fab color="basic" (click)="onAdd()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <form *ngIf="showAddForm" [formGroup]="formGroup" (submit)="onSubmit($event)" class="card shadow p-2 ">
        <div class="row">
            <div class="col-3">
                <app-input-text
                    formControlName="nombre"
                    [campoObligatorio]="true"
                    [label]="'general.nombre' | translate"
                    [isDisabled]="readOnly"
                >
                </app-input-text>
            </div>
            <div class="col-2">
                <app-input-number
                    formControlName="porcentaje"
                    [campoObligatorio]="true"
                    [label]="'general.porcentaje' | translate"
                    [max]="porcentajeDisponible"
                    min="0"
                    [numerosDecimales]="3"
                    [disabled]="readOnly"
                >
                </app-input-number>
            </div>
            <div class="col-3">
                <app-input-select
                    formControlName="idQualificacionIngrediente"
                    [campoObligatorio]="true"
                    [label]="'ETIQUETAS.qualificacionIngrediente' | translate"
                    [options]="qualiIngredientes | async| arrayToSelectItem:'idQualificacionIngrediente':'descripcion'"
                >
                </app-input-select>
            </div>
            <div class="col-2">
                <app-input-select
                    formControlName="agrario"
                    [label]="'general.tipo' | translate"
                    [campoObligatorio]="true"
                    [hasNullValue]="false"
                    [options]="[
                        {id: 'true', valor: 'general.agrario' | translate},
                        {id: 'false', valor: 'general.noAgrario' | translate}
                    ]"
                >
                </app-input-select>
            </div>

            <div class="col-2">
                <app-input-select
                    formControlName="idOrigenMateria"
                    [label]="('ETIQUETAS.origenMateria' | translate) + '?'"
                    [options]="origenes | async | arrayToSelectItem:'idOrigenMateria':'descripcion'"
                >
                </app-input-select>
            </div>
        </div>

        <div class="row mt-3">
            <div class="d-flex justify-content-end">
                <button mat-raised-button color="warn" class="mx-2" type="button" (click)="onCancel()">
                    Cancelar
                </button>
                <button mat-raised-button color="primary" type="submit" [disabled]="formGroup.invalid">
                    Desar
                </button>
            </div>
        </div>
    </form>
    <div class="col-12 mt-3 d-flex justify-content-end">
        <h4>{{'ETIQUETAS.porcentajeTotal' | translate}}</h4>
        <h4>
             <span [ngClass]="{'text-success': porcentajeRestante === 0, 'text-danger': porcentajeRestante !== 0}">
                : {{ 100 - porcentajeRestante }}%
            </span>
        </h4>
    </div>
    <div *ngIf="!esVisualizacion" class="col-12 p-2 mt-2 d-flex justify-content-end">
        <button mat-raised-button color="primary" class="px-2" [disabled]="porcentajeRestante !== 0" (click)="dialogRef.close(ingredientes.value)">
            {{'general.guardar' | translate}}
        </button>
    </div>

</div>
