import {Injectable} from '@angular/core';
import {HttpService} from "../core/http.service";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../components/toast.service";
import {Observable} from "rxjs";
import {Respuesta} from "../../../model/core/respuesta";
import {SolicitudEtiquetasHttpInterface} from "./solicitud-etiquetas.http.interface";
import {catchError, tap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ECityClicHttpService extends HttpService implements SolicitudEtiquetasHttpInterface {

  private BASE = `ecityclic`;
  private SEND_SOLICITUD_OPERADOR = (idSolicitud: string) => `${this.BASE}/solicitud/${idSolicitud}/enviar`;
  private SEND_CONFIRMAR = (idSolicitud: string) => `${this.BASE}/solicitud/${idSolicitud}/confirmar`;
  private SEND_FINALIZAR = (idSolicitud: string) => `${this.BASE}/solicitud/${idSolicitud}/finalizar`;
  private SEND_ESMENA = (idSolicitud: string) => `${this.BASE}/solicitud/${idSolicitud}/esmena`;
  private SEND_REVISAR = (idSolicitud: string) => `${this.BASE}/solicitud/${idSolicitud}/revision-ie`;
  private SEND_DESISTIR = (idSolicitud: string) => `${this.BASE}/solicitud/${idSolicitud}/desistir`;


    constructor(http: HttpClient,
              toastSvc: ToastService,
              protected translateSvc: TranslateService) {
    super(http, toastSvc);
  }

  enviarSolicitud(idSolicitud: string): Observable<Respuesta> {
    return this.post(this.SEND_SOLICITUD_OPERADOR(idSolicitud))
        .pipe(
            tap(item => this.toastSvc.showSuccess(this.translateSvc.instant("ETIQUETAS.enviarSolicitudOk"))),
            catchError(err => {
              this.toastSvc.showError(this.translateSvc.instant("ETIQUETAS.errorEnvioSolicitud"));
              throw this.translateSvc.instant("ETIQUETAS.errorEnvioSolicitud");
            })
        );
  }

  confirmarSolicitud(idSolicitud: string): Observable<Respuesta> {
    return this.post(this.SEND_CONFIRMAR(idSolicitud))
        .pipe(
            tap(item => this.toastSvc.showSuccess(this.translateSvc.instant("ETIQUETAS.confirmarSolicitudOk"))),
            catchError(err => {
              this.toastSvc.showError(this.translateSvc.instant("ETIQUETAS.errorEnvioSolicitud"));
              throw this.translateSvc.instant("ETIQUETAS.errorEnvioSolicitud");
            })
        );
  }

    finalizarSolicitud(idSolicitud: string): Observable<Respuesta> {
        return this.post(this.SEND_FINALIZAR(idSolicitud))
            .pipe(
                tap(item => this.toastSvc.showSuccess(this.translateSvc.instant("ETIQUETAS.finalizarSolicitudOk"))),
                catchError(err => {
                    this.toastSvc.showError(this.translateSvc.instant("ETIQUETAS.errorEnvioSolicitud"));
                    throw this.translateSvc.instant("ETIQUETAS.errorEnvioSolicitud");
                })
            );
    }

    esmenaSolicitud(idSolicitud: string): Observable<Respuesta> {
        return this.post(this.SEND_ESMENA(idSolicitud))
            .pipe(
                tap(item => this.toastSvc.showSuccess(this.translateSvc.instant("ETIQUETAS.esmenaSolicitudOk"))),
                catchError(err => {
                    this.toastSvc.showError(this.translateSvc.instant("ETIQUETAS.errorEnvioSolicitud"));
                    throw this.translateSvc.instant("ETIQUETAS.errorEnvioSolicitud");
                })
            );
    }

    revisarSolicitud(idSolicitud: string): Observable<Respuesta> {
        return this.post(this.SEND_REVISAR(idSolicitud))
            .pipe(
                tap(item => this.toastSvc.showSuccess(this.translateSvc.instant("ETIQUETAS.revisionSolicitudOk"))),
                catchError(err => {
                    this.toastSvc.showError(this.translateSvc.instant("ETIQUETAS.errorEnvioSolicitud"));
                    throw this.translateSvc.instant("ETIQUETAS.errorEnvioSolicitud");
                })
            );
    }

    desistirSolicitud(idSolicitud: string): Observable<Respuesta> {
        return this.post(this.SEND_DESISTIR(idSolicitud))
            .pipe(
                tap(item => this.toastSvc.showSuccess(this.translateSvc.instant("ETIQUETAS.desistimientoSolicitudOk"))),
                catchError(err => {
                    this.toastSvc.showError(this.translateSvc.instant("ETIQUETAS.errorDesistimientoSolicitud"));
                    throw this.translateSvc.instant("ETIQUETAS.errorDesistimientoSolicitud");
                })
            );
    }

}
