import {Component, Inject, Injector, OnInit} from '@angular/core';
import {EditProductoBaseModalComponent} from "../edit-producto-base-modal/edit-producto-base-modal.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {map, tap} from "rxjs/operators";

@Component({
  templateUrl: './edit-producto-hortofructicula-modal.component.html',
})
export class EditProductoHortofructiculaModalComponent extends EditProductoBaseModalComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) data : {producto: SolicitudProducto},
              dialogRef: MatDialogRef<EditProductoHortofructiculaModalComponent>,
              injector: Injector) {
        super(data, dialogRef, injector);

        this.formGroup.addControl("requiereEtiqueta", new FormControl(false));
        this.formGroup.addControl("idSolicitudIngrediente", new FormControl(''));
        this.formGroup.addControl("idCategoriaEcologica", new FormControl(null, Validators.required));

        this.formGroup.addControl("formIngrediente", new FormGroup({
                "idSolicitudProducto": new FormControl(''),
                "idSolicitudIngrediente": new FormControl(''),
                "porcentaje": new FormControl(100),
                "nombre": new FormControl(''),
                "agrario": new FormControl(true),
                "idOrigenMateria": new FormControl(1),
                "idQualificacionIngrediente": new FormControl(3)
        }));
    }

    override ngOnInit(): void {
        super.ngOnInit();

        this.categorias = this.categorias.pipe(
            map(categorias => categorias.slice(1, 3)) // Keep only positions 1 and 2
        );
    }

    protected gestionarCategoria(): void {}

    onSubmit() {
        this.dialogRef.close(this.formGroup.value);
    }

    onChangeCategoriaEcologica(){
        this.solicitudProductoSvc.eliminarEtiqueta(this.producto.idSolicitudProducto)
            .pipe(tap(_ => this.producto.tieneEtiqueta = false))
            .subscribe();
    }

  enableReadOnly() {
      this.readOnly = true;
      this.formGroup.disable({emitEvent: false});
      this.formGroup.get("idSolicitudProducto")?.enable({emitEvent: false});
      this.formGroup.get("idProducto")?.enable({emitEvent: false});
    }

}
