import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";

export const OperadoresAlmacenTableConfig: TablaColumna[] = [
    {
        header: "OPERADORES.almacenes.numAlmacen",
        field: "numOrdenAlmacen",
        type: "number",
    },
    {
        header: "OPERADORES.almacenes.nombreAlmacen",
        field: "nombreAlmacen",
        type: "string",
    },
    {
        header: "general.municipio",
        field: "municipio",
        type: "string",
    },
    {
        header: "OPERADORES.poligiono",
        field: "poligono",
        type: "number",
    },
    {
        header: "OPERADORES.parcela",
        field: "parcela",
        type: "number",
    },
    {
        header: "OPERADORES.almacenes.recinto",
        field: "recinto",
        type: "string",
    },
    {
        header: "general.direccion",
        field: "direccion",
        type: "string",
    },
    {
        header: "OPERADORES.almacenes.superficie",
        field: "superficie",
        type: "number",
    },
    {
        header: "OPERADORES.almacenes.usoAlmacen",
        field: "cualificacionAlmacen",
        type: "number",
    },
    {
        header: "OPERADORES.prodAlmacenados",
        field: "prodAlmacenados",
        type: "string",
        colspan: 6,
        subheaders: [
            {
                header: "OPERADORES.maquinaria",
                field: "maquinaria",
                type: "boolean"
            },
            {
                header: "OPERADORES.fertilizantes",
                field: "fertilizantes",
                type: "boolean"
            },
            {
                header: "OPERADORES.semillas",
                field: "semillas",
                type: "boolean"
            },
            {
                header: "OPERADORES.contProductos",
                field: "contProductos",
                type: "boolean"
            },
            {
                header: "OPERADORES.pajaForraje",
                field: "pajaForraje",
                type: "boolean"
            },
            {
                header: "OPERADORES.veterinarios",
                field: "veterinarios",
                type: "boolean"
            }
        ]
    },
]

export const OperadoresAltaAlmacenTableConfig: TablaColumna[] = [
    {
        header: "OPERADORES.almacenes.numAlmacen",
        field: "numOrdenAlmacen",
        type: "number",
    },
    {
        header: "OPERADORES.almacenes.nombreAlmacen",
        field: "nombreAlmacen",
        type: "string",
    },
    {
        header: "general.municipio",
        field: "municipio.descripcion",
        type: "string",
    },
    {
        header: "OPERADORES.poligiono",
        field: "poligono",
        type: "number",
    },
    {
        header: "OPERADORES.parcela",
        field: "parcela",
        type: "number",
    },
    {
        header: "OPERADORES.almacenes.recinto",
        field: "recinto",
        type: "string",
    },
    {
        header: "general.direccion",
        field: "direccion",
        type: "string",
    },
    {
        header: "OPERADORES.almacenes.superficie",
        field: "superficie",
        type: "number",
    },
    {
        header: "OPERADORES.almacenes.usoAlmacen",
        field: "cualificacionAlmacen",
        type: "number",
    },
    {
        header: "OPERADORES.maquinaria",
        field: "maquinaria",
        type: "boolean"
    },
    {
        header: "OPERADORES.fertilizantes",
        field: "fertilizantes",
        type: "boolean"
    },
    {
        header: "OPERADORES.semillas",
        field: "semillas",
        type: "boolean"
    },
    {
        header: "OPERADORES.contProductos",
        field: "contProductos",
        type: "boolean"
    },
    {
        header: "OPERADORES.pajaForraje",
        field: "pajaForraje",
        type: "boolean"
    },
    {
        header: "OPERADORES.veterinarios",
        field: "veterinarios",
        type: "boolean"
    }
]

export const OperadoresAlmacenEditAccionesConfig: TablaAccion[] = [
    {
        action: 'edit',
        icon: "edit",
        toolTip: "Editar"
    },
    {
        action: 'view',
        icon: "pageview",
        toolTip: "Veure"
    },
    {
        action: "delete",
        icon: "delete",
        toolTip: "Eliminar"
    }
]

export const OperadoresAlmacenViewAccionesConfig: TablaAccion[] = [
    {
        action: 'view',
        icon: "pageview",
        toolTip: "Veure"
    }
]

export const OperadoresAltaAlmacenEditAccionesConfig: TablaAccion[] = [
    {
        action: 'edit',
        icon: "edit",
        toolTip: "Editar"
    },
    {
        action: "delete",
        icon: "delete",
        toolTip: "Eliminar"
    }
]
