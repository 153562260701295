import {Component, Injector, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SolicitudAdjunto} from "../../../../core/model/etiquetas/solicitud-adjunto";
import {SolicitudHttpService} from "../../../../core/services/http/solicitud/solicitud.http.service";
import {EtiquetasService} from "../../services/etiquetas.service";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {SolicitudProductoHttpService} from "../../../../core/services/http/solicitud/solicitud-producto.http.service";
import { catchError } from 'rxjs/operators';
import {ToastService} from "../../../../core/services/components/toast.service";
import {TranslateService} from "@ngx-translate/core";

class AttachFile {
  solicitudAdjunto!: SolicitudAdjunto;
  file!: File;
  public get isUploaded() {
   return this.solicitudAdjunto != null;
  }
}

@Component({
  selector: "etiquetas-attachments",
  templateUrl: './etiquetas-attachments.component.html',
  styleUrls: ['./etiquetas-attachments.component.scss']
})
export class EtiquetasAttachmentsComponent implements OnChanges {

  protected files: AttachFile[];

  protected etiquetasSvc: EtiquetasService;
  protected solicitudSvc: SolicitudHttpService;
  protected solicitudProductoSvc: SolicitudProductoHttpService;

  @Input() protected readOnly = false;
  @Input() protected currentFiles!: SolicitudAdjunto[];
  @Input() protected producto: SolicitudProducto;

  constructor(protected injector: Injector,
              protected toastSvc: ToastService,
              protected translateSvc: TranslateService) {
    this.files = [];

    this.solicitudSvc = injector.get(SolicitudHttpService);
    this.etiquetasSvc = injector.get(EtiquetasService);
    this.solicitudProductoSvc = injector.get(SolicitudProductoHttpService);
  }

  ngOnChanges(changes: SimpleChanges) {
      if (changes.currentFiles) {
          this.files = this.currentFiles.map(item => {
              let attachFile = new AttachFile();
              attachFile.solicitudAdjunto = item;
              return attachFile;
          });
      }
  }

    onFileDrop(files: File[]) {
        let newsFiles: AttachFile[] = [];

        files.forEach(file => {
            if (file.size <= 25 * 1024 * 1024) {
                let attachFile = new AttachFile();
                attachFile.file = file;
                newsFiles.push(attachFile);
            } else {
                this.toastSvc.showError(this.translateSvc.instant("ETIQUETAS.errorFileSize"));
            }
        });

        if (newsFiles.length > 0) {
            this.files = [...this.files, ...newsFiles];

            newsFiles.forEach(file => {
                this.solicitudProductoSvc.crearAdjunto(this.producto.idSolicitudProducto, this.etiquetasSvc.idSolicitud, file.file)
                    .pipe(
                        catchError(err => {
                            this.files = this.files.filter(f => f !== file); // Remove the last added file
                            this.toastSvc.showError(this.translateSvc.instant("ETIQUETAS.errorFileSize"));
                            throw this.translateSvc.instant("ETIQUETAS.errorFileSize");
                        })
                    )
                    .subscribe(solicitudAdjunto => {
                        file.solicitudAdjunto = solicitudAdjunto;
                    });
            });
        }
    }

  onDelete(idSolicitudAdjunto: number) {
      this.solicitudProductoSvc.eliminarAdjunto(this.producto.idSolicitudProducto, idSolicitudAdjunto)
          .subscribe(_ =>{
              this.files = this.files.filter(item => item.solicitudAdjunto.idSolicitudAdjunto != idSolicitudAdjunto);
          })
  }

  onDownload(idSolicitudAdjunto: number) {
      this.solicitudProductoSvc.downloadAdjunto(this.producto.idSolicitudProducto, idSolicitudAdjunto);
  }
}
