<div class="col-12 login-page d-flex justify-content-center align-items-center align-middle">
    <div class="col-5 login-box d-flex justify-content-center align-items-center">
        <div class="login-form p-5">

            <div class="row logo d-flex justify-content-center mb-4">
                <img src="../../../../../assets/images/logo.webp" alt="logo"/>
            </div>

            <form [formGroup]="formGroup" (ngSubmit)="login()">
                <!-- Login fields -->
                <div class="row form-group">
                    <input formControlName="username" class="form-control mt-2" type="text"
                           [placeholder]="'general.usuario' | translate"/>
                </div>
                <div class="row form-group">
                    <input formControlName="password" class="form-control mt-2" type="password"
                           [placeholder]="'general.password' | translate"/>
                </div>

                <!-- Login error -->
                <div *ngIf="errorActual" class="row mt-2">
                    <div class="col-12 alert alert-danger" role="alert">
                        <div *ngIf="errorActual">
                            {{errorActual}}
                        </div>
                        <div *ngIf="errorActual == ''">
                            Error desconegut
                        </div>
                    </div>
                </div>

                <div class="row form-group mt-2">
                    <button class="form-control btn btn-success" type="submit">{{'general.entrar' | translate}}</button>
                </div>
            </form>

            <div class="mt-3 d-flex justify-content-center">
                <a [routerLink]="['restablir-contrasenya']">
                    {{'recuperarPw.restablirContrasenya' | translate}}
                </a>
            </div>

        </div>
    </div>
</div>
