import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild
} from '@angular/core';
import {OperadoresHttpService, TipoAutorizacionEnum} from "../../../../core/services/http/operadores-http.service";
import {lastValueFrom, Observable} from "rxjs";
import {Pageable} from "../../../../core/model/core/pageable";
import {ActivatedRoute, Router} from "@angular/router";
import {Page} from "../../../../core/model/core/pages/page";
import {ModalService} from "../../../../core/services/components/modal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Envase} from "../../../../core/model/master/envase";
import {EnvasesHttpService} from "../../../../core/services/http/solicitud/envases.http.service";
import {NavigationService} from "../../../../core/services/navigation.service";
import {
  OperadoresNoAutorizacionAccionesViewConfig,
  OperadoresNoAutorizacionTableConfig
} from "./operadores-no-autorizacion.table-config";
import {NoAutorizacion} from "../../../../core/model/autorizacion/noAutorizacion";
import {NoAutorizacionesHttpService} from "../../../../core/services/http/no-autorizaciones.http.service";

@Component({
  selector: 'app-operadores-listado-no-autorizacion',
  templateUrl: './operadores-listado-no-autorizacion.component.html',
  styleUrls: ['./operadores-listado-no-autorizacion.component.scss']
})
export class OperadoresListadoNoAutorizacionComponent extends Page implements OnInit {

  @ViewChild('formElement') formElement: ElementRef;

  protected modalSvc: ModalService;
  protected operadorSvc: OperadoresHttpService;
  protected envasesSvc: EnvasesHttpService;
  protected noAutorizacionesSvc : NoAutorizacionesHttpService;

  public views = TipoAutorizacionEnum;
  protected tipoActual = TipoAutorizacionEnum.ETIQUETATGE_IMPORTATS

  protected tableConfig = OperadoresNoAutorizacionTableConfig;
  protected tableAccionesView = OperadoresNoAutorizacionAccionesViewConfig;
  protected tableAccionesEdit = OperadoresNoAutorizacionAccionesViewConfig;

  protected data: Pageable<NoAutorizacion>;
  protected page = 1;
  protected idOperador: string;

  protected formGroup: FormGroup;
  protected envases: Observable<Envase[]>;
  protected filtros: object;
  protected operadorAction: any;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private navService: NavigationService,
              private cdr: ChangeDetectorRef,
              injector: Injector) {
    super();

    this.modalSvc = injector.get(ModalService)
    this.operadorSvc = injector.get(OperadoresHttpService);
    this.envasesSvc = injector.get(EnvasesHttpService);
    this.noAutorizacionesSvc = injector.get(NoAutorizacionesHttpService);

    this.data = new Pageable<NoAutorizacion>();
    this.page = 1;
    this.idOperador = this.activatedRoute.snapshot.params['idOperador'];

    this.formGroup = new FormGroup({
      'idSolicitudProducto': new FormControl(null, [Validators.pattern(/^[0-9]*$/)]),
      'nombreComercial': new FormControl(null),
      'marca': new FormControl(null),
      'fechaIni': new FormControl(null),
      'fechaFi': new FormControl(null)
    })
  }

  async ngOnInit() {

    this.handleActiveChipItem();
    this.envases = this.envasesSvc.getEnvases();
    this.noAutorizacionesSvc

    if(this.tipoActual == TipoAutorizacionEnum.ETIQUETATGE_ELABORATS) {
      this.mergeElaboratsVins();
    } else {
      this.data = await lastValueFrom(this.noAutorizacionesSvc.getNoAutorizaciones(this.idOperador, this.tipoActual, this.page, this.filtros));
    }

    this.activatedRoute.params.subscribe(params => {
      this.operadorAction = params['action'];
    })

  }

  public handleActiveChipItem() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['tipoActual']) {
        this.tipoActual = params['tipoActual'];
      }
    });
  }

  public async changeView(viewId: string) {
    this.page = 1;
    this.tipoActual = viewId as TipoAutorizacionEnum;

    if(this.tipoActual === TipoAutorizacionEnum.ETIQUETATGE_ELABORATS) {
      this.mergeElaboratsVins();
    } else {
      this.data = await lastValueFrom(this.noAutorizacionesSvc.getNoAutorizaciones(this.idOperador, this.tipoActual, this.page));
    }
  }

  public async mergeElaboratsVins(){
    this.data = await lastValueFrom(this.noAutorizacionesSvc.getNoAutorizaciones(this.idOperador, this.tipoActual, this.page, this.filtros));
    const vinsData = await lastValueFrom(this.noAutorizacionesSvc.getNoAutorizaciones(this.idOperador, TipoAutorizacionEnum.ETIQUETATGE_VINS, this.page, this.filtros));

    if (vinsData) {
      const dataArray = this.data.content;
      const vinsDataArray = vinsData.content;
      dataArray.push(...vinsDataArray);
      dataArray.sort((a, b) => b.idSolicitudProducto - a.idSolicitudProducto);
      this.data.content = dataArray;
      this.data.totalElements+=vinsDataArray.length;
    }
  }

  public async onPageChange(page: number) {
    this.page = page;
    if(this.tipoActual === TipoAutorizacionEnum.ETIQUETATGE_ELABORATS) {
      this.mergeElaboratsVins();
    } else {
      this.data = await lastValueFrom(this.noAutorizacionesSvc.getNoAutorizaciones(this.idOperador, this.tipoActual, this.page, this.filtros));
    }
  }

  public onAction($event: any) {
    switch($event.action){
      case "download":
        this.downloadNoAutorizacion($event.item);
        break;
      case "view":
        this.viewNoAutorizacion($event.item);
        break;
    }
  }

  private viewNoAutorizacion(item) {
    this.router.navigate(['noAutoritzacio', item.idSolicitudProducto, 'view'], {
      relativeTo: this.activatedRoute,
      queryParams: {tipoActual: this.tipoActual}
    });
  }

  private async downloadNoAutorizacion(item) {
      await this.noAutorizacionesSvc.downloadNoAutorizacion(this.idOperador, item).then(() => {
        setTimeout(() => {
          this.onPageChange(this.page)
        }, 2000);
      });
  }

  //Filtres cerca

  protected onReset() {
    this.formGroup.reset();
    this.filtros = {};
    this.onSearch(this.filtros);
  }

  protected async onSearch(value) {
    this.page = 1;
    this.filtros = value;
    if(this.tipoActual === TipoAutorizacionEnum.ETIQUETATGE_ELABORATS) {
      this.mergeElaboratsVins();
    } else {
      this.data = await lastValueFrom(this.noAutorizacionesSvc.getNoAutorizaciones(this.idOperador, this.tipoActual, this.page, this.filtros));
    }
  }

  ngAfterViewInit() {
    this.formElement.nativeElement.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        this.onSearch(this.formGroup.value);
        event.preventDefault();
      }
    });
  }

  onBack(){
    this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
  }

}
