import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {Almacen} from "../../../../core/model/almacen/almacen";
import {Acciones, AccionesTable, MagAltaOpTableConfig} from "../../pages/operadores-alta/operadores-alta.config";
import {TableEvent} from "../../../../shared/components/generic-table/generic-table.component";
import {
  OperadorAddMagatzemModalComponent
} from "../../modals/operador-add-magatzem-modal/operador-add-magatzem-modal.component";
import {ModalService} from "../../../../core/services/components/modal.service";
import {OperadorFormularioView} from "../../pages/operadores-alta/operadores-alta.component";
import {Operador} from "../../../../core/model/operadores/operador";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-operadores-alta-almacenes',
  templateUrl: './operadores-alta-almacenes.component.html',
  styleUrls: ['./operadores-alta-almacenes.component.scss']
})
export class OperadoresAltaAlmacenesComponent implements OnInit {

  //Services
  protected modalSvc: ModalService;

  //Data
  protected tableAcciones = AccionesTable;
  protected magatzems: Almacen[] | null = [];
  protected magTableConfig = MagAltaOpTableConfig;
  protected numOrdenMagatzem = 1;

  @Input() operador: Operador;
  @Output() changeViewSiguientePage = new EventEmitter<any>;
  @Output() changeViewAtras = new EventEmitter<any>;

  constructor(injector: Injector) {
    this.modalSvc = injector.get(ModalService);
  }

  ngOnInit(): void {
    if(this.operador.almacenes)
      this.magatzems = this.operador?.almacenes;
    else
      this.magatzems =  [];
  }

  onActionTableMag($event: TableEvent<any>) {
    switch ($event.action) {
      case Acciones.EDIT:
        this.onEditarAlmacen($event.item).afterClosed()
            .subscribe(item => this.handleEditarMagatzem(item));
        break;
      case Acciones.REMOVE:
        this.onRemoveAlmacen($event.item);
        break;
    }
  }

  onAddMagatzem() {
    this.modalSvc.openDialog(OperadorAddMagatzemModalComponent, {operador: this.operador})
        .afterClosed().subscribe(newMag => this.handleCrearMagatzem(newMag));
  }

  handleCrearMagatzem(newMag: Almacen){
    if(newMag != null){
      newMag.numOrdenAlmacen = this.numOrdenMagatzem++;
      newMag.numAlmacen = this.numOrdenMagatzem;
      this.magatzems?.push(newMag)
    }
  }

  private handleEditarMagatzem(item: any) {
    if(item != null && this.magatzems?.find(a => a.numOrdenAlmacen == item.numOrdenAlmacen)){
      this.magatzems = this.magatzems?.map(a => {
        if(a.numOrdenAlmacen == item.numOrdenAlmacen){
          a = item
        }
        a.numAlmacen = a.numOrdenAlmacen;
        return a;
      })
    }
  }

  validateForm(){
    if(this.magatzems)
      return this.magatzems.length > 0;
    else return false;
  }

  onChangeViewAtras() {
    this.changeViewAtras.emit({almacenes: this.magatzems, page: OperadorFormularioView.VIEW_DADES_PRODUCCIO});
  }

  onChangeViewSiguiente() {
    this.changeViewSiguientePage.emit({almacenes: this.magatzems, page: OperadorFormularioView.VIEW_DADES_PRODUCCIO});
  }

  private onEditarAlmacen(item: any): MatDialogRef<any> {
    return this.modalSvc.openDialog(OperadorAddMagatzemModalComponent, {almacen: item});
  }

  private onRemoveAlmacen(item: any) {
    if(this.magatzems)
    {
      this.magatzems = this.magatzems?.filter(a => a.numOrdenAlmacen != item.numOrdenAlmacen)
      this.handleNumOrden();
    }
  }

  private handleNumOrden() {
    let countNumOrdenAlmacen = 1;
    if(this.magatzems){
      for(let a of this.magatzems){
        a.numOrdenAlmacen = countNumOrdenAlmacen;
        ++countNumOrdenAlmacen;
      }
    }
    this.numOrdenMagatzem = countNumOrdenAlmacen;

  }
}
