// Productos table
import {TablaColumna} from "../../shared/components/generic-table/model/tabla-columna";
import {SolicitudIngrediente} from "../../core/model/etiquetas/solicitud-ingrediente";
import {TablaAccion} from "../../shared/components/generic-table/model/tabla-accion";

export enum AccionesProductos {
	EDIT_PRODUCTO = "EDIT_PRODUCTO",
	EDIT_INGREDIENTE = "EDIT_INGREDIENTE",
	EDIT_ETIQUETA = "EDIT_ETIQUETA",
	VIEW_ETIQUETA = "VIEW_ETIQUETA",
	REMOVE = "REMOVE",
	ACEPTAR = "ACEPTAR",
	RECHAZAR = "RECHAZAR",
	EDIT_ADJUNTOS='EDIT_ADJUNTOS',
	VIEW_ADJUNTOS='VIEW_ADJUNTOS',
	EDIT_RECHAZO='EDIT_RECHAZO'
}

export enum AccionesIngredientes {
	REMOVE,
	EDIT
}

// Productos table
export const ProductosTableConfig: TablaColumna[] = [
	{
		header: "general.idSolicitudProducto",
		field: "idSolicitudProducto",
		type: "function",
		fn: (rowData) => {
			return rowData.idSolicitudProductoPadre ? rowData.idSolicitudProductoPadre : rowData.idSolicitudProducto;
		}
	},{
		header: "general.tipo",
		field: "tipoProducto",
		type: "function",
		fn: (rowData) => {

			return rowData.esActualizacion ? "Actualització" : "Alta";
		}
	},{
		header: "general.numAutorizacion",
		field: "numAutorizacion",
		type: "number"
	},{
		header: "ETIQUETAS.nombreComercial",
		field: "nombreComercial",
		type: "string"
	},{
		header: "ETIQUETAS.producto",
		field: "nombreProducto",
		type: "string"
	},{
		header: "general.marca",
		field: "marca",
		type: "string"
	}, {
		header: "general.envase",
		field: "tipoEnvase",
		type: "string"
	}, {
		header: "general.capacidad",
		field: "capacidad",
		type: "string"
	}
]

export const ProductoTablePreviewConfig: TablaColumna[] = [
	...ProductosTableConfig,
	{
		header: 'ETIQUETAS.nombreCorrecto',
		greenValue: "nombreCorrecto == true",
		orangeValue: "nombreCorrecto == false",
		redValue: "false",
		type: "semaphore",
	}
]

export const AccionesProductosCompuestos: TablaAccion[] = [
	{
		icon: 'edit',
		action: AccionesProductos.EDIT_PRODUCTO,
		toolTip: "Editar producte",
		condicionColor: [
			{condicion: "infoPadreMod == false && idSolicitudPadre != null", color: "orange"},
			{condicion: "infoPadreMod != null &&  informacionCompleta == true", color: "green"},
			{condicion: "infoPadreMod == null || informacionCompleta != true", color: "red"}
		]
	},
	{
		icon: 'receipt',
		action: AccionesProductos.EDIT_INGREDIENTE,
		toolTip: "Editar ingredients",
		condicionColor: [
			{condicion: "ingredientesPadreMod == false && idSolicitudPadre != null", color: "orange"},
			{condicion: "ingredientesPadreMod != null && ingredientesCorrectos == true", color: "green"},
			{condicion: "ingredientesPadreMod == null || ingredientesCorrectos != true", color: "red"}
		]
	},
	{
		icon: 'label',
		action: AccionesProductos.EDIT_ETIQUETA,
		toolTip: "Veure etiqueta",
		condicionColor: [
			{condicion: "idSolicitudPadre != null && etiquetaPadreMod == false && tieneEtiqueta == true", color: "orange"},
			{condicion: "etiquetaPadreMod != false && tieneEtiqueta == true", color: "green"},
			{condicion: "tieneEtiqueta != true", color: "red"}
		],
		condicion: "requiereEtiqueta"
	},
	{
		icon: 'attach_file',
		action: AccionesProductos.EDIT_ADJUNTOS,
		toolTip: "Adjuntar fitxers",
		condicionColor: [
			{condicion: "idSolicitudPadre != null && adjuntosPadreMod == false && tieneAdjuntos != false", color: "orange"},
			{condicion: "idSolicitudPadre != null && adjuntosPadreMod == true && tieneAdjuntos == true", color: "green"},
			{condicion: "idSolicitudPadre == null && tieneAdjuntos == true", color: "green"}
		]
	},
	{
		icon: 'delete',
		action: AccionesProductos.REMOVE,
		toolTip: 'Eliminar producte',
		condicion: "!idSolicitudPadre"
	}
]

export const AccionesProductosGestion : TablaAccion[] = [
	{
		icon: 'check',
		action: AccionesProductos.ACEPTAR,
		toolTip: "Aceptar",
		condicionColor: [
			{condicion: "aceptado == true", color: "green"},
		]
	},
	{
		icon: 'block',
		action: AccionesProductos.RECHAZAR,
		toolTip: "Rebutjar",
		condicionColor: [
			{condicion: "aceptado == false", color: "red"},
		]
	},
	{
		icon: 'preview',
		action: AccionesProductos.EDIT_PRODUCTO,
		toolTip: "Visualitzar dades"
	},
	{
		icon: 'label',
		action: AccionesProductos.EDIT_ETIQUETA,
		toolTip: "Veure etiqueta",
		condicion: "requiereEtiqueta"
	},
	{
		icon: 'file_present',
		action: AccionesProductos.EDIT_ADJUNTOS,
		toolTip: "Veure fitxers"
	}
]

export const AccionesProductosReview : TablaAccion[] = [
	{
		icon: 'check',
		action: AccionesProductos.ACEPTAR,
		toolTip: "Aceptar",
		condicionColor: [
			{condicion: "aceptado == true", color: "green"},
		]
	},
	{
		icon: 'block',
		action: AccionesProductos.RECHAZAR,
		toolTip: "Rebutjar",
		condicionColor: [
			{condicion: "aceptado == false", color: "red"},
		]
	},
	{
		icon: 'preview',
		action: AccionesProductos.EDIT_PRODUCTO,
		toolTip: "Visualitzar dades"
	},
	{
		icon: 'label',
		action: AccionesProductos.VIEW_ETIQUETA,
		toolTip: "Veure etiqueta",
		condicion: "requiereEtiqueta"
	},
	{
		icon: 'file_present',
		action: AccionesProductos.VIEW_ADJUNTOS,
		toolTip: "Veure fitxers",
		condicion: "tieneAdjuntos"
	}
]

export const AccionesProductosConfirmacion : TablaAccion[] = [
	{
		icon: 'check',
		action: AccionesProductos.ACEPTAR,
		toolTip: "Aceptar",
		condicionColor: [
			{condicion: "aceptado == true", color: "green"},
		]
	},
	{
		icon: 'block',
		action: AccionesProductos.RECHAZAR,
		toolTip: "Rebutjar",
		condicionColor: [
			{condicion: "aceptado == false", color: "red"},
		]
	},
	{
		icon: 'help_outline',
		action: AccionesProductos.EDIT_RECHAZO,
		toolTip: "Motiu del rebuig",
		condicion: "!aceptado",
		condicionColor: [
			{condicion: "idTipoRechazo == null || motivoRechazo == null", color: "orange"},
			{condicion: "idTipoRechazo != null && motivoRechazo != null", color: "green"},
		]
	},
	{
		icon: 'preview',
		action: AccionesProductos.EDIT_PRODUCTO,
		toolTip: "Visualitzar dades"
	},
	{
		icon: 'label',
		action: AccionesProductos.VIEW_ETIQUETA,
		toolTip: "Veure etiqueta",
		condicion: "requiereEtiqueta"
	},
	{
		icon: 'file_present',
		action: AccionesProductos.VIEW_ADJUNTOS,
		toolTip: "Veure fitxers",
		condicion: "tieneAdjuntos"
	}
]

export const AccionesProductosDistribuidosGestion : TablaAccion[] = [
	{
		icon: 'check',
		action: AccionesProductos.ACEPTAR,
		toolTip: "Aceptar",
		condicionColor: [
			{condicion: "aceptado == true", color: "green"},
		]
	},
	{
		icon: 'block',
		action: AccionesProductos.RECHAZAR,
		toolTip: "Rebutjar",
		condicionColor: [
			{condicion: "aceptado == false", color: "red"},
		]
	},
	{
		icon: 'preview',
		action: AccionesProductos.EDIT_PRODUCTO,
		toolTip: "Visualitzar dades"
	},
	{
		icon: 'file_present',
		action: AccionesProductos.EDIT_ADJUNTOS,
		toolTip: "Veure fitxers",
	}
]

export const AccionesProductosDistribuidosReview : TablaAccion[] = [
	{
		icon: 'check',
		action: AccionesProductos.ACEPTAR,
		toolTip: "Aceptar",
		condicionColor: [
			{condicion: "aceptado == true", color: "green"},
		]
	},
	{
		icon: 'block',
		action: AccionesProductos.RECHAZAR,
		toolTip: "Rebutjar",
		condicionColor: [
			{condicion: "aceptado == false", color: "red"},
		]
	},
	{
		icon: 'preview',
		action: AccionesProductos.EDIT_PRODUCTO,
		toolTip: "Visualitzar dades"
	},
	{
		icon: 'file_present',
		action: AccionesProductos.VIEW_ADJUNTOS,
		toolTip: "Veure fitxers",
		condicion: "tieneAdjuntos"
	}
]

export const AccionesProductosDistribuidosConfirmacion : TablaAccion[] = [
	{
		icon: 'check',
		action: AccionesProductos.ACEPTAR,
		toolTip: "Aceptar",
		condicionColor: [
			{condicion: "aceptado == true", color: "green"},
		]
	},
	{
		icon: 'block',
		action: AccionesProductos.RECHAZAR,
		toolTip: "Rebutjar",
		condicionColor: [
			{condicion: "aceptado == false", color: "red"},
		]
	},
	{
		icon: 'help_outline',
		action: AccionesProductos.EDIT_RECHAZO,
		toolTip: "Motiu del rebuig",
		condicion: "!aceptado",
		condicionColor: [
			{condicion: "idTipoRechazo == null || motivoRechazo == null", color: "orange"},
			{condicion: "idTipoRechazo != null && motivoRechazo != null", color: "green"},
		]
	},
	{
		icon: 'preview',
		action: AccionesProductos.EDIT_PRODUCTO,
		toolTip: "Visualitzar dades"
	},
	{
		icon: 'file_present',
		action: AccionesProductos.VIEW_ADJUNTOS,
		toolTip: "Veure fitxers",
		condicion: "tieneAdjuntos"
	}
]

export const AccionesProductosCompuestosGestion : TablaAccion[] = [
	{
		icon: 'check',
		action: AccionesProductos.ACEPTAR,
		toolTip: "Aceptar",
		condicionColor: [
			{condicion: "aceptado == true", color: "green"},
		]
	},
	{
		icon: 'block',
		action: AccionesProductos.RECHAZAR,
		toolTip: "Rebutjar",
		condicionColor: [
			{condicion: "aceptado == false", color: "red"},
		]
	},
	{
		icon: 'preview',
		action: AccionesProductos.EDIT_PRODUCTO,
		toolTip: "Visualitzar dades"
	},
	{
		icon: 'receipt',
		action: AccionesProductos.EDIT_INGREDIENTE,
		toolTip: "Ingredients",
	},
	{
		icon: 'label',
		action: AccionesProductos.EDIT_ETIQUETA,
		toolTip: "Editar etiqueta",
		condicion: "requiereEtiqueta",
	},
	{
		icon: 'file_present',
		action: AccionesProductos.EDIT_ADJUNTOS,
		toolTip: "Veure fitxers",
	}
]

export const AccionesProductosCompuestosReview : TablaAccion[] = [
	{
		icon: 'check',
		action: AccionesProductos.ACEPTAR,
		toolTip: "Aceptar",
		condicionColor: [
			{condicion: "aceptado == true", color: "green"},
		]
	},
	{
		icon: 'block',
		action: AccionesProductos.RECHAZAR,
		toolTip: "Rebutjar",
		condicionColor: [
			{condicion: "aceptado == false", color: "red"},
		]
	},
	{
		icon: 'preview',
		action: AccionesProductos.EDIT_PRODUCTO,
		toolTip: "Visualitzar dades"
	},
	{
		icon: 'receipt',
		action: AccionesProductos.EDIT_INGREDIENTE,
		toolTip: "Ingredients",
	},
	{
		icon: 'label',
		action: AccionesProductos.VIEW_ETIQUETA,
		toolTip: "Veure etiqueta",
		condicion: "requiereEtiqueta"
	},
	{
		icon: 'file_present',
		action: AccionesProductos.VIEW_ADJUNTOS,
		toolTip: "Veure fitxers",
		condicion: "tieneAdjuntos"
	}
]

export const AccionesProductosCompuestosConfirmacion : TablaAccion[] = [
	{
		icon: 'check',
		action: AccionesProductos.ACEPTAR,
		toolTip: "Aceptar",
		condicionColor: [
			{condicion: "aceptado == true", color: "green"},
		]
	},
	{
		icon: 'block',
		action: AccionesProductos.RECHAZAR,
		toolTip: "Rebutjar",
		condicionColor: [
			{condicion: "aceptado == false", color: "red"},
		]
	},
	{
		icon: 'help_outline',
		action: AccionesProductos.EDIT_RECHAZO,
		toolTip: "Motiu del rebuig",
		condicion: "!aceptado",
		condicionColor: [
			{condicion: "idTipoRechazo == null || motivoRechazo == null", color: "orange"},
			{condicion: "idTipoRechazo != null && motivoRechazo != null", color: "green"},
		]
	},
	{
		icon: 'preview',
		action: AccionesProductos.EDIT_PRODUCTO,
		toolTip: "Visualitzar dades"
	},
	{
		icon: 'receipt',
		action: AccionesProductos.EDIT_INGREDIENTE,
		toolTip: "Ingredients",
	},
	{
		icon: 'label',
		action: AccionesProductos.VIEW_ETIQUETA,
		toolTip: "Veure etiqueta",
		condicion: "requiereEtiqueta"
	},
	{
		icon: 'file_present',
		action: AccionesProductos.VIEW_ADJUNTOS,
		toolTip: "Veure fitxers",
		condicion: "tieneAdjuntos"
	}
]

export const AccionesProductosTable : TablaAccion[] = [
	{
		icon: 'edit',
		action: AccionesProductos.EDIT_PRODUCTO,
		toolTip: "Editar producte",
		condicionColor: [
			{condicion: "infoPadreMod == false && idSolicitudPadre != null", color: "orange"},
			{condicion: "informacionCompleta == true", color: "green"},
			{condicion: "informacionCompleta != true", color: "red"}
		]
	},
	{
		icon: 'attach_file',
		action: AccionesProductos.EDIT_ADJUNTOS,
		toolTip: "Adjuntar fitxers",
		condicionColor: [
			{condicion: "idSolicitudPadre != null && adjuntosPadreMod == false && tieneAdjuntos != false", color: "orange"},
			{condicion: "idSolicitudPadre != null && adjuntosPadreMod == true && tieneAdjuntos == true", color: "green"},
			{condicion: "idSolicitudPadre == null && tieneAdjuntos == true", color: "green"}
		]
	},
	{
		icon: 'delete',
		action: AccionesProductos.REMOVE,
		toolTip: 'Eliminar producte',
		condicion: "!idSolicitudPadre"
	}
]

export const AccionesProductosHortofruticolasTable : TablaAccion[] = [
	{
		icon: 'edit',
		action: AccionesProductos.EDIT_PRODUCTO,
		toolTip: "Editar producte",
		condicionColor: [
			{condicion: "infoPadreMod == false && idSolicitudPadre != null", color: "orange"},
			{condicion: "informacionCompleta == true", color: "green"},
			{condicion: "informacionCompleta != true", color: "red"}
		]
	},
	{
		icon: 'label',
		action: AccionesProductos.EDIT_ETIQUETA,
		toolTip: "Veure etiqueta",
		condicionColor: [
			{condicion: "idSolicitudPadre != null && etiquetaPadreMod == false && tieneEtiqueta == true", color: "orange"},
			{condicion: "etiquetaPadreMod != false && tieneEtiqueta == true", color: "green"},
			{condicion: "tieneEtiqueta != true", color: "red"}
		],
		condicion: "requiereEtiqueta"
	},
	{
		icon: 'attach_file',
		action: AccionesProductos.EDIT_ADJUNTOS,
		toolTip: "Adjuntar fitxers",
		condicionColor: [
			{condicion: "idSolicitudPadre != null && adjuntosPadreMod == false && tieneAdjuntos != false", color: "orange"},
			{condicion: "idSolicitudPadre != null && adjuntosPadreMod == true && tieneAdjuntos == true", color: "green"},
			{condicion: "idSolicitudPadre == null && tieneAdjuntos == true", color: "green"}
		]
	},
	{
		icon: 'delete',
		action: AccionesProductos.REMOVE,
		toolTip: 'Eliminar producte',
		condicion: "!idSolicitudPadre"
	}
]


// Ingredientes table
export const IngredientesTableConfig: TablaColumna[] = [
	{
		header: "general.nombre",
		field: "nombre",
		type: "string"
	},
	{
		header: "general.porcentaje",
		field: "porcentaje",
		type: "percentage"
	},
	{
		header: "ETIQUETAS.qualificacionIngrediente",
		field: "qualificacionIngrediente.descripcion",
		type: "string"
	},
	{
		header: "general.tipo",
		type: "function",
		fn: (ingrediente: SolicitudIngrediente) =>{
			return ingrediente.agrario ? 'general.agrario' : 'general.noAgrario';
		}
	},
	{
		header: "ETIQUETAS.origenMateria",
		field: "origenMateria.descripcion",
		type: "string"
	}
]

export const IngredientesTableAction : TablaAccion[] = [
	{
		icon: 'edit',
		action: AccionesIngredientes.EDIT,
		toolTip: "Editar ingredient",
		condicionColor: [
			{condicion: 'isValido == true', color: "green"},
			{condicion: 'isValido != true', color: "red"}
		]
	},
	{
		icon: 'delete',
		action: AccionesIngredientes.REMOVE,
		toolTip: "Eliminar ingredient"
	}
]

export const IngredientesTableActionEstado3 : TablaAccion[] = [
	{
		icon: 'edit',
		action: AccionesIngredientes.EDIT,
		toolTip: "Editar ingredient",
		condicionColor: [
			{condicion: 'isValido == true', color: "green"},
			{condicion: 'isValido != true', color: "red"}
		]
	}
]
