<h2 mat-dialog-title class="d-flex justify-content-between">
    <div *ngIf="editing; else titleNuevo">
        {{'general.edicion' | translate}} "{{persona.nomContCCPAE}}"
    </div>
    <ng-template #titleNuevo>
        {{'OPERADORES.afegirPersonaContacte' | translate}}
    </ng-template>

    <div class="clickable" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </div>
</h2>
<hr/>
<form [formGroup]="formGroup" class="col-12" (submit)="onSubmit()">
    <div class="row">
        <div class="col-md-3 col-sm-12">
            <app-input-text
                    formControlName="carrecDeptEmpresa"
                    [label]="'OPERADORES.carrecDeptEmpresa' | translate"
                    [campoObligatorio]="true">
            </app-input-text>
        </div>
        <div class="col-md-2 col-sm-12">
            <app-input-text
                    formControlName="nomContCCPAE"
                    [label]="'general.nombre' | translate"
                    [isDisabled]="editing"
                    [campoObligatorio]="true">
            </app-input-text>
        </div>
        <div class="col-md-2 col-sm-12">
            <app-input-text
                    formControlName="primerCognomContCCPAE"
                    [label]="'general.primerApellido' | translate"
                    [isDisabled]="editing"
                    [campoObligatorio]="true">
            </app-input-text>
        </div>
        <div class="col-md-2 col-sm-12">
            <app-input-text
                    formControlName="segonCognomContCCPAE"
                    [label]="'general.segundoApellido' | translate"
                    [isDisabled]="editing">
            </app-input-text>
        </div>
        <div class="col-md-2 col-sm-12">
            <app-input-text
                    formControlName="NIFContCCPAE"
                    [label]="'OPERADORES.dni_nie_nif' | translate"
                    [isDisabled]="editing"
                    [campoObligatorio]="true">
            </app-input-text>
        </div>
        <div class="col-md-2 col-sm-12">
            <app-input-number
                    formControlName="telefonoContCCPAE"
                    [label]="'general.telefonoContacto' | translate"
                    [disabled]="editing"
                    [campoObligatorio]="true">
            </app-input-number>
        </div>
        <div class="col-md-4 col-xl-4">
            <app-input-text
                    formControlName="emailContCCPAE"
                    [label]="'general.email' | translate"
                    [isDisabled]="editing"
                    [campoObligatorio]="true">
            </app-input-text>
        </div>

        <div class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button type="submit" mat-raised-button color="primary" class="px-2"
                    [disabled]="formGroup.invalid">
                {{'general.guardar' | translate}}
            </button>
        </div>
    </div>
</form>

