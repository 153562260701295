import {Injectable} from '@angular/core';
import {CodigoTramite, EtiquetasService} from "../services/etiquetas.service";
import {TramiteGuard} from "./tramite.guard";

@Injectable({
  providedIn: 'root'
})
export class TramiteDistribuidosGuard implements TramiteGuard {

  constructor(private etiquetasSvc: EtiquetasService) {
  }

  canActivate(): boolean {
    return this.etiquetasSvc.codigoTramite == CodigoTramite.ETIQUETATGE_DISTRIBUITS;
  }

}
