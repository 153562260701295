import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";


export enum Acciones{
    EDIT = "EDIT",
    REMOVE = "REMOVE"
}

export const AccionesTable: TablaAccion[] =  [
    {
        icon: 'edit',
        action: Acciones.EDIT,
        toolTip: "Editar parcel·la"
    },
    {
        icon: 'delete',
        action: Acciones.REMOVE,
        toolTip: "Eliminar parcel·la"
    }
]

export const AccionesTablePersonaContacto: TablaAccion[] =  [
    {
        icon: 'edit',
        action: Acciones.EDIT,
        toolTip: "Editar persona de contacte",
        condicionColor: [
            {condicion: "carrecDeptEmpresa == null", color: "red"},
        ]
    },
    {
        icon: 'delete',
        action: Acciones.REMOVE,
        toolTip: "Eliminar persona de contacte"
    }
]


export const ParcelasTableConfig: TablaColumna[] = [
    {
        header: 'general.municipio',
        field: "municipio",
        type: "string"
    },
    {
        header: 'OPERADORES.parcelas.sigpac',
        field: "sigpac",
        type: "string"
    },
    {
        header: 'OPERADORES.parcelas.superficie',
        field: "superficie",
        type: "number"
    },
    {
        header: 'OPERADORES.parcelas.cultivo',
        field: "cultivo",
        type: "string"
    },
    {
        header: 'OPERADORES.parcelas.variedad',
        field: "variedad",
        type: "string"
    },
    {
        header: 'OPERADORES.parcelas.registrarInscripcion',
        field: "inscripcion",
        type: "checkbox"
    },
    {
        header: 'OPERADORES.parcelas.rvc',
        field: "rvc",
        type: "string"
    }
]

export const MagTableConfig: TablaColumna[] = [
    {
        header: "OPERADORES.almacenes.numAlmacen",
        field: "numOrdenAlmacen",
        type: "number",
    },
    {
        header: "OPERADORES.almacenes.nombreAlmacen",
        field: "nombreAlmacen",
        type: "string",
    },
    {
        header: "general.municipio",
        field: "municipio.descripcion",
        type: "string",
    },
    {
        header: "OPERADORES.poligiono",
        field: "poligono",
        type: "number",
    },
    {
        header: "OPERADORES.parcela",
        field: "parcela",
        type: "number",
    },
    {
        header: "OPERADORES.almacenes.recinto",
        field: "recinto",
        type: "string",
    },
    {
        header: "general.direccion",
        field: "direccion",
        type: "string",
    },
    {
        header: "OPERADORES.almacenes.superficie",
        field: "superficie",
        type: "number",
    },
    {
        header: "OPERADORES.almacenes.tipoAlmacen",
        field: "cualificacionAlmacen",
        type: "number",
    },
    {
        header: "OPERADORES.prodAlmacenados",
        field: "prodAlmacenados",
        type: "string",
        colspan: 5,
        subheaders: [
            {
                header: "OPERADORES.maquinaria",
                field: "maquinaria",
                type: "boolean"
            },
            {
                header: "OPERADORES.fertilizantes",
                field: "fertilizantes",
                type: "boolean"
            },
            {
                header: "OPERADORES.semillas",
                field: "semillas",
                type: "boolean"
            },
            {
                header: "OPERADORES.pajaForraje",
                field: "ferraje",
                type: "boolean"
            },
            {
                header: "OPERADORES.contProductos",
                field: "contProductos",
                type: "boolean"
            }
        ]
    },
]

export const MagAltaOpTableConfig: TablaColumna[] = [
    {
        header: "OPERADORES.almacenes.nombreAlmacen",
        field: "nombreAlmacen",
        type: "string",
    },
    {
        header: "general.municipio",
        field: "municipio.descripcion",
        type: "string",
    },
    {
        header: "OPERADORES.poligiono",
        field: "poligono",
        type: "number",
    },
    {
        header: "OPERADORES.parcela",
        field: "parcela",
        type: "number",
    },
    {
        header: "OPERADORES.almacenes.recinto",
        field: "recinto",
        type: "string",
    },
    {
        header: "general.direccion",
        field: "direccion",
        type: "string",
    },
    {
        header: "OPERADORES.almacenes.superficie",
        field: "superficie",
        type: "number",
    },
    {
        header: "OPERADORES.almacenes.tipoAlmacen",
        field: "cualificacionAlmacen",
        type: "number",
    },
    {
        header: "OPERADORES.prodAlmacenados",
        field: "prodAlmacenados",
        type: "string",
        colspan: 5,
        subheaders: [
            {
                header: "OPERADORES.maquinaria",
                field: "maquinaria",
                type: "boolean"
            },
            {
                header: "OPERADORES.fertilizantes",
                field: "fertilizantes",
                type: "boolean"
            },
            {
                header: "OPERADORES.semillas",
                field: "semillas",
                type: "boolean"
            },
            {
                header: "OPERADORES.pajaForraje",
                field: "ferraje",
                type: "boolean"
            },
            {
                header: "OPERADORES.contProductos",
                field: "contProductos",
                type: "boolean"
            }
        ]
    },
]

export const AnimalesTableConfig: TablaColumna[] = [
    {
        header: "OPERADORES.especie",
        field: "especie",
        type: "string"
    },
    {
        header: "OPERADORES.raza",
        field: "raza",
        type: "string"
    },
    {
        header: "OPERADORES.tipusBestiar",
        field: "tipusBestiar",
        type: "string"
    },
    {
        header: "OPERADORES.censo",
        field: "censo",
        type: "number"
    },
    {
        header: 'OPERADORES.parcelas.registrarInscripcion',
        field: "inscripcion",
        type: "checkbox"
    }

]

export const PersonaContactoCCPAETableConfig: TablaColumna[] = [
    {
        header: "OPERADORES.carrecDeptEmpresa",
        field: "carrecDeptEmpresa",
        type: "string"
    },
    {
        header: "general.nombre",
        field: "nomContCCPAE",
        type: "string"
    },
    {
        header: "general.primerApellido",
        field: "primerCognomContCCPAE",
        type: "string"
    },
    {
        header: "general.segundoApellido",
        field: "segonCognomContCCPAE",
        type: "string"
    },
    {
        header: "OPERADORES.dni_nie_nif",
        field: "NIFContCCPAE",
        type: "string"
    },
    {
        header: "general.telefonoContacto",
        field: "telefonoContCCPAE",
        type: "number"
    },
    {
        header: "general.email",
        field: "emailContCCPAE",
        type: "string"
    },
]