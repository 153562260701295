import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";

export const AnimalesCensoConfigTable: TablaColumna[] = [
    {
        header: 'OPERADORES.animales.numOrdenAnimal',
        field: "numOrden",
        type: "number"
    },
    {
        header: 'OPERADORES.animales.censo',
        field: "censo",
        type: "number"
    },
    {
        header: 'OPERADORES.animales.cualiEcologica',
        field: "cualiEcologica",
        type: "string"
    },
    {
        header: 'OPERADORES.animales.fechaCualificacion',
        field: "fechaCualificacion",
        type: "date"
    },
    {
        header: 'OPERADORES.animales.clasificacionUbm',
        field: "clasificacionUbm",
        type: "string"
    },
    {
        header: 'OPERADORES.animales.valorUbm',
        field: "valorUbm",
        type: "number"
    }
]

export const AnimalesCensoActionTable: TablaAccion[] = [

    {
        action: "edit",
        icon: "edit",
        toolTip: "Editar animal cens"
    },
    {
        action: "delete",
        icon: "delete",
        toolTip: "Eliminar Cens Ubm"
    }
]
