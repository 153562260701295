import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {OperadorFormularioView} from "../../pages/operadores-alta/operadores-alta.component";
import {TipoActividad} from "../../../../core/model/operadores/tipo-actividad";
import {Observable} from "rxjs";
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {GenerosService} from "../../../../core/services/http/generos.service";
import {Genero} from "../../../../core/model/operadores/genero";
import {Municipio} from "../../../../core/model/localizacion/municipio";
import {
  Acciones,
  AccionesTablePersonaContacto,
  PersonaContactoCCPAETableConfig
} from "../../pages/operadores-alta/operadores-alta.config";
import {PersonaContacto} from "../../../../core/model/operadores/persona-contacto";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalService} from "../../../../core/services/components/modal.service";
import {
  EditPersonaContactoModalComponent
} from "../../modals/edit-persona-contacto-modal/edit-persona-contacto-modal.component";
import {FamiliaActividad} from "../../../../core/model/operadores/familia-actividad";
import {Operador} from "../../../../core/model/operadores/operador";

@Component({
  selector: 'app-operadores-alta-general',
  templateUrl: './operadores-alta-general.component.html',
  styleUrls: ['./operadores-alta-general.component.scss']
})
export class OperadoresAltaGeneralComponent implements OnInit{

  //Services
  protected operadoresSvc: OperadoresHttpService;
  protected generoSvc: GenerosService;
  protected modalSvc: ModalService;

  @Input() form: FormGroup;
  @Input() operador: Operador;
  @Output() changeViewSiguientePage = new EventEmitter<any>;

  //Data
  protected formGeneral: FormGroup;
  protected activitats: Array<TipoActividad>
  protected generos: Observable<Genero[]>
  protected municipiSocial: Municipio;
  protected municipiPostal: Municipio;
  protected personasContacto: PersonaContacto[] = [];
  protected tableConfig = PersonaContactoCCPAETableConfig;
  protected tableAcciones = AccionesTablePersonaContacto;
  protected municipiSocialValid = false;
  protected municipiPostalValid = false;
  protected actPrincipalAuto = true;

  constructor(injector: Injector) {
    this.operadoresSvc = injector.get(OperadoresHttpService);
    this.generoSvc = injector.get(GenerosService);
    this.modalSvc = injector.get(ModalService);

    this.formGeneral = new FormGroup({
      "raoSocial": new FormGroup({
        "personaFisica": new FormControl(false),
        "CIF": new FormControl("", [Validators.required, this.formatoCIFValidator()]),
        "companyia": new FormControl("", Validators.required)
      }),
      "dadesRepLegal": new FormGroup({
        "nomRepLegal": new FormControl("", Validators.required),
        "primerCognomRepLegal": new FormControl("", Validators.required),
        "segonCognomRepLegal": new FormControl(""),
        "telefonoRepLegal": new FormControl("", Validators.required),
        "genereRepLegal": new FormControl(null, Validators.required),
        "NIFRepLegal": new FormControl("", [Validators.required, this.formatoNIFValidator()]),
        "emailRepLegal": new FormControl("", Validators.email),
      }),
      "direccionSocial": new FormGroup({
        "direccion": new FormControl('', Validators.required),
        "municipiSocial": new FormControl(null)
      }),
      "direccionPostal": new FormGroup({
        "direccion": new FormControl('', Validators.required),
        "municipiPostal": new FormControl(null)
      }),
      "dadesNotifElectronica": new FormGroup({
        "mobil": new FormControl(""),
        "email": new FormControl("", Validators.email),
      }),
      "dadesContacteCCPAE": new FormGroup({
        "datosTitular": new FormControl(false),
      }),
      "dadesRepDelegacion": new FormGroup({
        "repDelegacio": new FormControl(false),
        "nifRepDelegacion": new FormControl("", [Validators.required, this.formatoNIFValidator()]),
        "nomRepDelegacion": new FormControl("", Validators.required),
        "primerCognomRepDelegacion": new FormControl("", Validators.required),
        "segonCognomRepDelegacion": new FormControl(""),
        "telefonoRepDelegacion": new FormControl("", Validators.required),
        "emailRepDelegacion": new FormControl("", [Validators.email, Validators.required])
      }),
      "webXarxesSocials": new FormGroup({
        "llocWeb": new FormControl(""),
        "twitter": new FormControl(""),
        "facebook": new FormControl(""),
        "instagram": new FormControl("")
      }),
      "dadesProduccio": new FormGroup({
        "tipusExplotacio": new FormControl('EE'),
      })
    })

    this.formGeneral.get('dadesProduccio.tipusExplotacio')?.disable();
  }

  ngOnInit() {
    this.formGeneral.patchValue(this.form?.value);
    this.disableRaoSocial(this.formGeneral.get('raoSocial.personaFisica')?.value);
    this.disableDadesRepDelegacion(this.formGeneral.get('dadesRepDelegacion.repDelegacio')?.value);

    if(this.operador != null){
      this.municipiSocial = this.operador?.municipioSocial;
      this.municipiPostal = this.operador?.municipioPostal;
      this.activitats = this.operador?.actividadesProduccion;
      this.personasContacto = this.operador?.personasContactoCCPAE;
      this.handleTipusExplotacio();
    }
    else{
      this.operador = new Operador();
      //TODO Get activitats from backend
      this.activitats = [
        {nom: "Producció primària",
          selected: false,
          families: [
            {nombre: "prodVegetal",
              mixta: null,
              selected: false},
            {nombre: "prodRamadera",
              mixta: null,
              selected: false},
            {nombre: "recSilvestre",
              mixta: null,
              selected: false},
            {nombre: "prodApicola",
              mixta: null,
              selected: false},
            {nombre: "prodSal",
              mixta: null,
              selected: false},
            {nombre: "prodAlguesFito",
              mixta: null,
              selected: false}
          ],
          mixta: null,
        },
        {nom: "Preparació",
          selected: false,
          families: [],
          mixta: null,
        },
        {nom: "Importació i/o exportació",
          selected: false,
          families: [
            {nombre: "importacio",
              mixta: null,
              selected: false},
            {nombre: "exportacio",
              mixta: null,
              selected: false}
          ],
          mixta: null,
        },
        {nom: "Distribució majorista",
          selected: false,
          families: [],
          mixta: null,
        },
        {nom: "Distribució minorista",
          selected: false,
          families: [],
          mixta: null,
        },
        {nom: "Altres",
          selected: false,
          families: [],
          mixta: null,
        }
      ]
    }

    this.generos = this.generoSvc.getGeneros();
  }

  private formatoCIFValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      const pattern =  /^[A-Za-z]\d{8}$/;

      if(!value)
        return null;

      const CIFFormatOK = pattern.test(value);

      return !CIFFormatOK ? {CIFValid: true}: null;
    }
  }

  private formatoNIFValidator(): ValidatorFn{
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      const pattern =  /^[A-Za-z]?\d{8}[A-Za-z]$/;

      if(!value)
        return null;

      const NIFFormatOK = pattern.test(value);

      return !NIFFormatOK ? {NIFValid: true}: null;
    }
  }

  protected validateForm() {
    return this.formGeneral.controls["raoSocial"].valid && this.formGeneral.controls["dadesRepLegal"].valid &&
        (this.municipiSocialValid || this.municipiSocial==null) && (this.municipiPostalValid || this.municipiPostal==null) &&
        this.personasContacto.length > 0 &&
        this.checkValidityDadesProduccio() &&
        this.formGeneral.controls["dadesNotifElectronica"].valid && this.formGeneral.controls["dadesContacteCCPAE"].valid &&
        this.formGeneral.controls["dadesRepDelegacion"].valid && this.formGeneral.controls["webXarxesSocials"].valid;
  }

  protected onChangeViewSiguiente() {
    this.operador.municipioSocial = this.municipiSocial;
    this.operador.municipioPostal = this.municipiPostal;
    this.operador.personasContactoCCPAE = this.personasContacto;
    this.operador.actividadesProduccion = this.activitats;

    this.changeViewSiguientePage.emit({formGroup: this.formGeneral, page: OperadorFormularioView.VIEW_DADES_GENERALS,
                                             operador: this.operador});
  }

  protected disableRaoSocial($event: any) {
    if ($event){
      this.formGeneral.get('raoSocial')?.disable();
      this.formGeneral.get('raoSocial')?.get('personaFisica')?.enable();
    }
    else {
      this.formGeneral.get('raoSocial')?.enable();
    }
  }

  protected disableDadesRepDelegacion($event: any) {
    if ($event){
      this.formGeneral.get('dadesRepDelegacion')?.disable();
      this.formGeneral.get('dadesRepDelegacion')?.get('repDelegacio')?.enable();
    }
    else {
      this.formGeneral.get('dadesRepDelegacion')?.enable();
    }
  }


  //Gestio dades de produccio
  protected onSelectActivitat(activitat: TipoActividad) {
    activitat.selected = !activitat.selected;

    if(!activitat.selected) {
      activitat.mixta = null;
      for (let item of activitat.families) {
        item.selected = false;
        item.mixta = null;
      }

      if(this.operador.activitatProduccionPrincipal == activitat)
        this.operador.activitatProduccionPrincipal = null;
    }

    else if(activitat.selected && this.actPrincipalAuto){
      this.operador.activitatProduccionPrincipal = activitat;
    }

    this.handleActPrincipal();
  }

  private handleActPrincipal(){
    const selectedActivitats = this.activitats.filter(a => a.selected);

    switch (selectedActivitats.length) {
      case 0:
        this.operador.activitatProduccionPrincipal = null;
        break;
      case 1:
        this.operador.activitatProduccionPrincipal = selectedActivitats[0];
        this.actPrincipalAuto = true;
        break;
      case 2:
        if(this.actPrincipalAuto){
          this.operador.activitatProduccionPrincipal = null;
          this.actPrincipalAuto = false;
        }
        break;
    }
  }

  protected onSelectFamilia($event, act, familia) {
    familia.selected = !familia.selected;

    if(!familia.selected)
      familia.mixta = null;

    if(act.families.filter(f => f.selected && f.mixta != null).length == 0)
      act.mixta = null;
    else
      act.mixta = act.families.some(f => f.selected && f.mixta);
  }

  protected checkSomeChildSelected(act: TipoActividad) {
    const selectedFamilies = act.families.filter(f => f.selected);
    if(selectedFamilies.length > 0){
      act.selected = true;
      return true;
    }
    return false;
  }

  protected onExplotacioFamiliaChange($event: any, act: TipoActividad, familia: FamiliaActividad) {
    if($event == 'EE') {
      familia.mixta = false;
    }
    else if($event == 'M') {
      familia.mixta = true;
    }

    act.mixta = act.families.some(f => f.selected && f.mixta);
    this.handleTipusExplotacio();
  }

  protected onExplotacioActivitatChange($event: any, act: TipoActividad) {
    if($event == 'EE') {
      act.mixta = false;
    }
    else if($event == 'M') {
      act.mixta = true;
    }
    this.handleTipusExplotacio();
  }

  private handleTipusExplotacio(){
    let mixtaGeneral = this.activitats.some(act => act.selected && act.mixta);
    if(mixtaGeneral)
      this.formGeneral.get('dadesProduccio.tipusExplotacio')?.setValue('M')
    else
      this.formGeneral.get('dadesProduccio.tipusExplotacio')?.setValue('EE')
  }

  protected onSelectActivitatPrincipal(act: TipoActividad) {
    this.operador.activitatProduccionPrincipal = act;
  }

  private checkValidityDadesProduccio() {
    function checkAllFamiliesValid(a: TipoActividad) {
      return a.families.every(f => (f.selected && f.mixta != null) || !f.selected);
    }

    const allSelectedHaveExp = this.activitats.every(a => (a.selected && a.mixta != null && checkAllFamiliesValid(a)) || !a.selected);
    const minOneSelected = this.activitats.some(a => a.selected);

    return allSelectedHaveExp && minOneSelected;
  }


  //Gestio persona de contacte
  protected copiarDadesTitular($event: any){
    this.formGeneral.get('dadesContacteCCPAE')?.get('datosTitular')?.setValue($event);
    if($event){
        this.personasContacto = [];
        this.personasContacto.push({
          carrecDeptEmpresa: null,
          nomContCCPAE: this.formGeneral.get('dadesRepLegal')?.get('nomRepLegal')?.value,
          primerCognomContCCPAE: this.formGeneral.get('dadesRepLegal')?.get('primerCognomRepLegal')?.value,
          segonCognomContCCPAE: this.formGeneral.get('dadesRepLegal')?.get('segonCognomRepLegal')?.value,
          NIFContCCPAE: this.formGeneral.get('dadesRepLegal')?.get('NIFRepLegal')?.value,
          telefonoContCCPAE: this.formGeneral.get('dadesRepLegal')?.get('telefonoRepLegal')?.value,
          emailContCCPAE: this.formGeneral.get('dadesRepLegal')?.get('emailRepLegal')?.value});
      }
    else{
      this.personasContacto = this.personasContacto.filter(persona => persona.NIFContCCPAE != this.formGeneral.get('dadesRepLegal')?.get('NIFRepLegal')?.value)
    }
  }

  protected onActionTable($event: any) {
    switch ($event.action) {
      case Acciones.EDIT:
        this.onEditarPersonaContacto($event.item).afterClosed()
            .subscribe(item => this.onHandleEditarPersonaContacto(item));
        break;
      case Acciones.REMOVE:
        this.onRemovePersonaContacto($event.item);
        break;
    }
  }

  private onEditarPersonaContacto(persona: PersonaContacto): MatDialogRef<any> {
    return this.modalSvc.openDialog(EditPersonaContactoModalComponent, {persona: persona});
  }

  private onHandleEditarPersonaContacto(personaMod: PersonaContacto) {
    if(personaMod != null){
      if(this.personasContacto.find(persona => persona.NIFContCCPAE == personaMod.NIFContCCPAE)){
        this.personasContacto = this.personasContacto.map(persona => {
          if(persona.NIFContCCPAE == personaMod.NIFContCCPAE) {
            return {...persona, carrecDeptEmpresa: personaMod.carrecDeptEmpresa}
          }
          return persona;
        })
      }

      else{
        this.personasContacto.push(personaMod);
      }
    }
  }

  private onRemovePersonaContacto(item) {
    this.personasContacto = this.personasContacto.filter(persona => persona.NIFContCCPAE != item.NIFContCCPAE)
  }

  protected onAddPersonaContacte() {
    this.modalSvc.openDialog(EditPersonaContactoModalComponent, {})
        .afterClosed().subscribe(persona => this.onHandleEditarPersonaContacto(persona));
  }



}
