import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";

export const OperadoresNoAutorizacionTableConfig: TablaColumna[] =  [
    {
        header: 'OPERADORES.noAutorizaciones.idSolicitudProducto',
        field: "idSolicitudProducto",
        type: "number"
    },
    {
        header: 'ETIQUETAS.nombreComercial',
        field: "nombreProducto",
        type: "string"
    },
    {
        header: 'general.marca',
        field: "marca",
        type: "string"
    },
    {
        header: 'general.envase',
        field: "tipoEnvase",
        type: "string"
    },
    {
        header: 'general.capacidad',
        field: "capacidad",
        type: "string"
    },
    {
        header: 'OPERADORES.noAutorizaciones.fechaNoAutorizacion',
        field: "fecha",
        type: "date"
    }
]

export const OperadoresNoAutorizacionAccionesViewConfig: TablaAccion[] = [
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure dades"
    }
]

export const OperadoresNoAutorizacionAccionesEditConfig: TablaAccion[] = [
    {
        action: "download",
        icon: "get_app",
        toolTip: "Descarregar no autorització",
        //condicion: "!impreso",
        condicionColor: [
            {condicion: 'impreso', color: "green"},
            {condicion: '!impreso', color: "orange"}
        ]
    },
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure dades"
    }
]
