import {AfterViewInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {ChipItemComponent} from "./components/chip-item/chip-item.component";

@Component({
  selector: 'app-chip-cards',
  templateUrl: './chip-cards.component.html',
  styleUrls: ['./chip-cards.component.scss']
})
export class ChipCardsComponent implements AfterViewInit {

  private selectedIndex: string | number = 0;

  @ContentChildren(ChipItemComponent) itemChips: QueryList<ChipItemComponent>;

  @Output() viewChange: EventEmitter<number | string> = new EventEmitter<number | string>();

  @Input() initialActiveItem: number | string = 0;

  constructor() {
    this.itemChips = new QueryList<ChipItemComponent>();
  }

  ngAfterViewInit() {
    this.itemChips.forEach((item, index) => {
      if (item.value == 0) {
        item.value = index;
      }
      item.click.subscribe((index) => {
        this.onSelectItem(index)
      });
    });

    if (this.itemChips.first) {
      this.selectedIndex = this.initialActiveItem;
    }

    this.changeActiveItem(this.selectedIndex);
  }

  onSelectItem(activeIndex: number | string) {
    this.selectedIndex = activeIndex;
    this.changeActiveItem(activeIndex);
    this.viewChange.emit(activeIndex);
  }

  changeActiveItem(activeIndex: number | string) {

    this.itemChips.forEach((item, index) => {
      if (item) {
        item.activeSubj.next(activeIndex == item.value);
      }
    });
  }

}
