<div [formGroup]="formGroup">
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div>
            {{textoDisclaimer | translate}}
        </div>

        <div class="clickable" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <div class="d-flex justify-content-between mt-12">
        <div class="col-md-12 col-xl-12">
            <app-input-text
                    formControlName="motivoDelete"
                    [campoObligatorio]="true"
                    [label]="'OPERADORES.solicitudes.motivoDelete' | translate"
            >
            </app-input-text>
        </div>
        <br>
        <br>
    </div>

    <div class="d-flex justify-content-center mt-2">
        <br>
        <button mat-raised-button color="white" class="m-2" (click)="dialogRef.close(false)">
            {{'general.cancelar' | translate}}
        </button>
        <button mat-raised-button color="primary" class="m-2" [disabled]="!formGroup.controls['motivoDelete'].valid" (click)="onConfirm()">
            {{'general.aceptar' | translate}}
        </button>
    </div>
</div>
