import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SolicitudProductoCompuesto} from "../../../../core/model/etiquetas/solicitud-producto-compuesto";
import {
  EditProductoCompuestoBaseModalComponent
} from "../edit-producto-compuesto-base-modal/edit-producto-compuesto-base-modal.component";
import {FormControl, Validators} from "@angular/forms";
import {ModalEdicion} from "../../pages/etiquetas-base.component";
import {Normativa} from "../../../../core/model/master/normativa";


@Component({
  templateUrl: './edit-producto-vino-modal.component.html'
})
export class EditProductoVinoModalComponent extends EditProductoCompuestoBaseModalComponent implements OnInit, ModalEdicion {

  normativas: Normativa[] = [];
  protected idNormativa;

  private readonly NormativaEnum = {
    'UE': { idNormativa: 1, descripcion: 'UE' },
    'NOP': { idNormativa: 2, descripcion: 'NOP' }
  };


  constructor(@Inject(MAT_DIALOG_DATA) data: { producto: SolicitudProductoCompuesto },
              dialogRef: MatDialogRef<EditProductoVinoModalComponent>,
              injector: Injector) {
    super(data, dialogRef, injector);

    this.formGroup.addControl("tipoNormativa", new FormControl(null, [Validators.required]));

  }

  override ngOnInit() {
      super.ngOnInit();
      this.normativas = Object.values(this.NormativaEnum);
      this.idNormativa = this.formGroup.get('tipoNormativa')?.value;
  }

  enableReadOnly() {
    this.readOnly = true;
    this.formGroup.disable({emitEvent: false});
    this.formGroup.get("idSolicitudProducto")?.enable({ emitEvent: false});
    this.formGroup.get("idProducto")?.enable({emitEvent: false});
  }

  enableReadOnlyEstado3() {
    this.enableReadOnly();
    this.formGroup.get("idCategoriaEcologica")?.enable({ emitEvent: false});
  }

  protected onSubmit(): void {
    this.formGroup.get("requiereEtiqueta")?.setValue(true);
    this.dialogRef.close(this.formGroup.value);
  }

}
