<div>
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div>
            {{'ETIQUETAS.cargarExcel' | translate}}
        </div>
        <div class="clickable" (click)="onCancel()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <form [formGroup]="formGroup">
        <hr/>
        <div class="col-md-4 col-sm-6">
            <app-input-select
                    formControlName="idCategoriaEcologica"
                    [label]="'ETIQUETAS.categoriasEcologicas' | translate"
                    [options]="categorias | async | arrayToSelectItem:'idCategoriaEcologica':'descripcion'"
                    [campoObligatorio]="true"
                    (change)="handleIdCategoriaEcologica()"
                    [small]="'ETIQUETAS.categoriaHelp' | translate"
            >
            </app-input-select>
        </div>
        <div class="col-md-4 col-sm-6" >
            <app-input-select
                    formControlName="requiereEtiqueta"
                    [label]="'ETIQUETAS.caldraEtiqueta' | translate"
                    [isDisabled]="!categoriaEcologicaEnum"
                    [campoObligatorio]="true"
                    [options]="requiereEtiquetaOpciones | arrayToSelectItem:'idRequiereEtiqueta':'descripcion'"
                    [small]="'ETIQUETAS.caldraEtiquetaHelp' | translate"
                    (change)="handleRequiereEtiqueta()"
            >
            </app-input-select>
        </div>
        <div>
            <br/>
            <p>{{'general.camposObligatorios' | translate}}</p>
        </div>
    </form>

    <div *ngIf="solicitudRequiereEtiqueta">
        <br/>
        <hr/>
        <h2>{{'ETIQUETAS.addEtiqueta' | translate}}</h2>
        <small class="text-muted w-100 pt-1">{{'ETIQUETAS.addEtiquetaNota' | translate}}</small>
        <div>
            <div class="row" *ngIf="etiqueta">
                <div class="col-10">
                    <app-input-text
                            [value]="etiqueta.nombre"
                            [isDisabled]="true"
                    >
                    </app-input-text>
                </div>
                <div class="col-2 d-flex align-self-center clickable">
                    <mat-icon (click)="onDownload()">preview</mat-icon>
                    <mat-icon (click)="onDelete()">delete_forever</mat-icon>
                </div>
            </div>
            <div>
                <app-drop-file-zone
                        (onFilesDrop)="onFileDropEtiqueta($event)"
                        [accept]="'.jpg,.jpeg,.png,.pdf'"
                >
                </app-drop-file-zone>
            </div>
        </div>

        <div [formGroup]="formGroupDisclaims" class="mt-2 col-12">
            <h2>{{'general.requisitos' | translate}}</h2>

            <div formArrayName="disclaims">
                <div *ngFor="let disclaim of disclaims().controls; let i=index">
                    <div [formGroupName]="i" class="col-12">
                        <app-input-checkbox
                                formControlName="checked"
                                [label]="disclaim.get('text').value"
                        >
                        </app-input-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row mt-3">
        <div class="d-flex justify-content-end">
            <button mat-raised-button color="warn" class="mx-2" type="button" (click)="onCancel()">
                Cancelar
            </button>
            <input type="file" class="file-input" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" (change)="onLoadExcel($event.target)" #fileUpload>
            <button mat-raised-button color="primary" class="px-2 mx-1 fileUpload"
                    [disabled]="((formGroupDisclaims.invalid || !this.fileEtiqueta) && (solicitudRequiereEtiqueta == true)) || (solicitudRequiereEtiqueta == null)"
                    (click)="fileUpload.click()">
                {{'ETIQUETAS.cargarExcel' | translate}}
            </button>

        </div>
    </div>

</div>
