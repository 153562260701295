import {Component, Injector, OnInit} from '@angular/core';
import {Operador} from "../../../../core/model/operadores/operador";
import {Page} from "../../../../core/model/core/pages/page";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {Observable} from "rxjs";
import {Genero} from "../../../../core/model/operadores/genero";
import {GenerosService} from "../../../../core/services/http/generos.service";
import {Municipio} from "../../../../core/model/localizacion/municipio";
import {ActividadPrincipal} from "../../../../core/model/operadores/actividadPrincipal";
import {DecretoOperador} from "../../../../core/model/operadores/decretoOperador";
import {Respuesta} from "../../../../core/model/core/respuesta";

enum OperadorEditView {
  VIEW_GENERAL
}

@Component({
  selector: 'app-operadores-detalle-general',
  templateUrl: './operadores-detalle-general.component.html',
  styleUrls: ['./operadores-detalle-general.component.scss']
})
export class OperadoresDetalleGeneralComponent extends Page implements OnInit {

  protected operadorSvc: OperadoresHttpService;
  protected generoSvc: GenerosService;

  public actualView = OperadorEditView.VIEW_GENERAL;
  public viewEnum = OperadorEditView;
  public action;
  protected formInscrit: FormGroup;
  protected formDecreto: FormGroup;

  protected municipiSocial: Municipio;

  protected operador: Operador;
  protected idOperador: string;
  protected editing = false;
  protected generos: Observable<Genero[]>
  protected municipio_aux = new Municipio();
  municipioPostalValid: boolean;
  municipioSocialValid: boolean;

  protected actividadesPrincipales: Observable<ActividadPrincipal[]>
  protected existingDecretos: DecretoOperador[] = [];

  constructor(private router: Router,
              protected activatedRoute: ActivatedRoute,
              injector: Injector) {
    super()
    this.idOperador = activatedRoute.snapshot.params['idOperador'];
    this.operadorSvc = injector.get(OperadoresHttpService);
    this.generoSvc = injector.get(GenerosService);

    this.formInscrit = new FormGroup({
      'numInscrito': new FormControl(this.operador?.numInscrito), //Dades generals
      'empresa': new FormControl('', Validators.maxLength(150)),
      'cif': new FormControl('', this.formatoCIFValidator()),
      'nif': new FormControl('', [Validators.required, this.formatoNIFValidator()]),
      'nombre': new FormControl('', [Validators.maxLength(30), Validators.required]),
      'primerApellido': new FormControl('', Validators.maxLength(30)),
      'segundoApellido': new FormControl('', Validators.maxLength(30)),
      'idGenero': new FormControl(''),
      'direccionSocial': new FormControl('', Validators.maxLength(60)), //Adreça social
      'direccionPostal': new FormControl('', Validators.maxLength(60)), //Adreça postal
      'telefono1': new FormControl('', [Validators.maxLength(9), Validators.pattern('^[0-9]*$')]), //Dades de contacte
      'telefono2': new FormControl('', [Validators.maxLength(9), Validators.pattern('^[0-9]*$')]),
      'movil': new FormControl('', [Validators.maxLength(9), Validators.pattern('^[0-9]*$')]),
      'fax': new FormControl('', [Validators.maxLength(9), Validators.pattern('^[0-9]*$')]),
      'web': new FormControl('', Validators.maxLength(70)),
      'email': new FormControl('', [Validators.maxLength(70), this.formatMailListValidator()]),
      'emailNot': new FormControl('', [Validators.maxLength(70), this.formatMailListValidator()]),
      'movilNot': new FormControl('', [Validators.maxLength(20), this.movilValidator()]),
      'facebook': new FormControl('', Validators.maxLength(200)),
      'twitter': new FormControl('', Validators.maxLength(100)),
      'mostrarDatosDirectorio': new FormControl(),
      'actividadPrincipal': new FormControl(null, Validators.required), //Dades de producció
      'fechaAlta': new FormControl('', Validators.required),
      'fechaBaja': new FormControl(''),
      'fechaPrimeraCertificacion': new FormControl(''),
      'fechaUltimaOperacion': new FormControl(''),
      'fechaSuspension': new FormControl(''),
      'fechaRetirada': new FormControl(''),
      'observaciones': new FormControl(''),
      'enviarRegoe': new FormControl(),
      'elaboradorPienso': new FormControl(),
      'facturacionElectronica': new FormControl(),
      'maquilador': new FormControl(),
      'produccionMixta': new FormControl()
    })

    this.formDecreto = new FormGroup({
      'produccioPrimaria': new FormControl(false),
      'produccioPrimariaGroup': new FormGroup({
        'produccioVegetal': new FormControl(false),
        'produccioVegetalGroup': new FormGroup({
          'llenyosos': new FormControl(false),
          'extensius': new FormControl(false),
          'horticoles': new FormControl(false),
          'obtentorsMRV': new FormControl(false),
          'pastures': new FormControl(false),
          'forestals': new FormControl(false)
        }),
        'produccioRamadera': new FormControl(false),
        'produccioRamaderaGroup': new FormGroup({
          'herbivors': new FormControl(false),
          'porcs': new FormControl(false),
          'avicola': new FormControl(false),
          'apicola': new FormControl(false),
          'helicicola': new FormControl(false),
          'insectes': new FormControl(false),
          'conills': new FormControl(false)
        }),
        'recolleccioSilvestre': new FormControl(false),
        'produccioSal': new FormControl(false),
        'aquicultura': new FormControl(false),
        'aquiculturaGroup': new FormGroup({
          'alguesFitoplancton': new FormControl(false),
          'moluscs': new FormControl(false),
          'peixos': new FormControl(false)
        })
      }),
      'preparacio': new FormControl(false),
      'importacioExportacio': new FormControl(false),
      'importacioExportacioGroup': new FormGroup({
        'importacio': new FormControl(false),
        'exportacio': new FormControl(false)
      }),
      'distribucioMajorista': new FormControl(false),
      'distribucioMinorista': new FormControl(false),
      'distribucioMinoristaGroup': new FormGroup({
        'certificatGran': new FormControl(false),
        'certificatPetit': new FormControl(false),
        'noCertificatEximit': new FormControl(false)
      }),
      'altresActivitats': new FormControl(false)
    }, { validators: this.formDecretoValidator() });

  }

  private formatMailListValidator(): ValidatorFn{
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      const pattern =  /^((([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?);?\s*)+)?$/i;

      if(!value)
        return null;

      const emailListOK = pattern.test(value);

      return !emailListOK ? {emailListValid: true}: null;
    }
  }

  private formatoCIFValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const pattern = /^[A-Za-z]\d{7}[A-Za-z0-9]$/;

      if (!value) {
        return null;
      }

      const CIFFormatOK = pattern.test(value);

      return !CIFFormatOK ? { CIFValid: true } : null;
    };
  }

  private formatoNIFValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const pattern = /^(\d{8}[A-Za-z]|[A-Za-z]\d{7}[A-Za-z0-9])$/;

      if (!value) {
        return null;
      }

      const NIFFormatOK = pattern.test(value);

      return !NIFFormatOK ? { NIFValid: true } : null;
    };
  }

  private movilValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = /^\+?\d{1,19}$/.test(control.value);
      return valid ? null : { 'invalidMobileNumber': { value: control.value } };
    };
  }

  private formDecretoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;

      // List of main sections to check
      const mainSections = [
        'produccioPrimaria',
        'preparacio',
        'importacioExportacio',
        'distribucioMajorista',
        'distribucioMinorista',
        'altresActivitats'
      ];

      // Check if at least one main section is selected
      const isAtLeastOneSelected = mainSections.some(section => formGroup.get(section)?.value);

      // Check if at least one family in produccioPrimariaGroup is selected
      const produccioPrimariaGroup = formGroup.get('produccioPrimariaGroup') as FormGroup;
      const isProduccioPrimariaValid = !formGroup.get('produccioPrimaria')?.value ||
          Object.keys(produccioPrimariaGroup.controls).some(key => {
            const familyControl = produccioPrimariaGroup.get(key);
            if (familyControl instanceof FormGroup) {
              return Object.keys(familyControl.controls).some(subKey => familyControl.get(subKey)?.value);
            }
            return familyControl?.value;
          });

      // Check if at least one family in importacioExportacioGroup is selected
      const importacioExportacioGroup = formGroup.get('importacioExportacioGroup') as FormGroup;
      const isImportacioExportacioValid = !formGroup.get('importacioExportacio')?.value ||
          Object.keys(importacioExportacioGroup.controls).some(key => importacioExportacioGroup.get(key)?.value);

      // Ensure that all conditions are met
      return isAtLeastOneSelected && isProduccioPrimariaValid && isImportacioExportacioValid ? null : { atLeastOneSectionSelected: true };
    };
  }

  ngOnInit() {

    this.operadorSvc.getOperador(this.idOperador)
        .subscribe(op => {
          this.operador = op;
          console.log(this.operador)
          this.formInscrit.patchValue(this.operador);
        })

    this.operadorSvc.getDecretoOperador(this.idOperador)
        .subscribe((decretos: DecretoOperador[] | undefined) => {
          if (decretos) {
            this.existingDecretos = decretos;
            decretos.forEach(item => this.setFormControlValues(item));
          }
        });

    this.generos = this.generoSvc.getGeneros();
    this.actividadesPrincipales = this.operadorSvc.getActividadesPrincipales();

    this.activatedRoute.params.subscribe(params => {
      this.action = params['action'];

      if (this.action == 'edit' || this.action == 'create') {
        this.editing = true;
        this.formInscrit.enable();
        this.formInscrit.get('numInscrito')?.disable();
        this.formDecreto.enable();
      }
      else if(this.action == 'view'){
        this.editing = false;
        this.formInscrit.disable();
        this.formDecreto.disable();
      }
    });

  }

  changeView($event: number) {
    this.actualView = $event;
  }

  onBack(){
    this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
  }

  public onChangeProduccionMixta(event: Event, buttonId: string){
    const isMixta = (buttonId=='produccionMixta');

    this.formInscrit.get('produccionMixta')?.setValue(isMixta);
    this.operador.produccionMixta = isMixta;
  }

  onGuardarForm(form: FormGroup) {
    // Directly assign form values to operador
    this.operador = { ...this.operador, ...form.value };

    // Handle create or modify action
    if (this.action === 'create') {
      this.operadorSvc.crearOperador(this.operador).subscribe((response: Respuesta) => {
        console.log("Operador created:", response);
        // Handle the response, navigate to another page or update the UI
        this.router.navigate([`operadors`, `CT00${response.idOperador}P`, `edit`]);
      });
    } else {
      this.operadorSvc.modificarOperador(this.idOperador, this.operador).subscribe((response: Respuesta) => {
        console.log("Operador updated:", response);
        // Handle the response, navigate to another page or update the UI
      });
    }
  }

  onGuardarFormDecreto(form: FormGroup) {
    const existingDecretos: DecretoOperador[] = this.existingDecretos || [];
    const decretoOperador: DecretoOperador[] = [];

    // Helper function to create a DecretoOperador object
    const createDecretoOperador = (idRelacion: number, idInscrito: number, seccion: any, familia: any, subfamilia: any) => ({
      idRelacion,
      idInscrito,
      seccion,
      familia,
      subfamilia
    });

    // Iterate over form controls
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);

      if (control instanceof FormGroup) {
        Object.keys(control.controls).forEach(subKey => {
          const subControl = control.get(subKey);

          if (subControl instanceof FormGroup) {
            Object.keys(subControl.controls).forEach(grandChildKey => {
              const grandChildControl = subControl.get(grandChildKey);

              if (grandChildControl?.value) {
                const subfamilia = this.getSubfamiliaByKey(grandChildKey);

                if (subfamilia) {
                  decretoOperador.push(createDecretoOperador(
                      0,
                      Number(this.idOperador),
                      { idSeccion: subfamilia.familia.seccion.idSeccion },
                      { idFamilia: subfamilia.familia.idFamilia, seccion: { idSeccion: subfamilia.familia.seccion.idSeccion } },
                      { idSubfamilia: subfamilia.idSubfamilia, familia: { idFamilia: subfamilia.familia.idFamilia, seccion: { idSeccion: subfamilia.familia.seccion.idSeccion } } }
                  ));
                }
              }
            });
          } else if (subControl?.value) {
            const familia = this.getFamiliaByKey(subKey);

            if (familia) {
              decretoOperador.push(createDecretoOperador(
                  0,
                  Number(this.idOperador),
                  { idSeccion: familia.idSeccion },
                  { idFamilia: familia.idFamilia, seccion: { idSeccion: familia.idSeccion } },
                  null
              ));
            }
          }
        });
      } else if (control?.value) {
        const existingDecreto = existingDecretos.find(decreto => this.getSeccionIdByKey(key) === decreto.seccion.idSeccion);

        decretoOperador.push(createDecretoOperador(
            existingDecreto ? existingDecreto.idRelacion : 0,
            Number(this.idOperador),
            { idSeccion: this.getSeccionIdByKey(key) },
            null,
            null
        ));
      }
    });

    this.operadorSvc.modificarDecretoOperador(this.idOperador, decretoOperador).subscribe();
  }

  onSectionChange(section: string) {
    const sectionControl = this.formDecreto.get(section);
    if (sectionControl) {
      if (section === 'produccioPrimaria' && sectionControl.value) {
        this.formDecreto.get('produccioPrimariaGroup.produccioVegetal')?.setValue(true);
      } else if (section === 'importacioExportacio' && sectionControl.value) {
        this.formDecreto.get('importacioExportacioGroup.importacio')?.setValue(true);
      } else if (!sectionControl.value) {
        const groupControl = this.formDecreto.get(`${section}Group`);
        if (groupControl instanceof FormGroup) {
          Object.keys(groupControl.controls).forEach(subKey => {
            const subControl = groupControl.get(subKey);
            if (subControl instanceof FormGroup) {
              Object.keys(subControl.controls).forEach(grandChildKey => {
                subControl.get(grandChildKey)?.setValue(false);
              });
            } else {
              subControl?.setValue(false);
            }
          });
        }
      }
    }
  }

  onFamilyChange(family: string) {
    const familyControl = this.formDecreto.get(`produccioPrimariaGroup.${family}`);
    const familyGroupControl = this.formDecreto.get(`produccioPrimariaGroup.${family}Group`) as FormGroup;

    if (familyControl && familyGroupControl) {
      if (!familyControl.value) {
        // Uncheck all subfamilies
        Object.keys(familyGroupControl.controls).forEach(subKey => {
          familyGroupControl.get(subKey)?.setValue(false);
        });
      } else {
        // Reset all subfamilies to their default values
        Object.keys(familyGroupControl.controls).forEach(subKey => {
          familyGroupControl.get(subKey)?.setValue(false); // or any default value
        });
      }
    }
  }

  private getSeccionIdByKey(key: string): number {
    const mapping: { [key: string]: number } = {
      'produccioPrimaria': 1,
      'preparacio': 2,
      'importacioExportacio': 3,
      'distribucioMajorista': 4,
      'distribucioMinorista': 5,
      'altresActivitats': 6
    };
    return mapping[key];
  }

  private getKeyBySeccionId(idSeccion: number): string {
    const mapping: { [key: number]: string } = {
      1: 'produccioPrimaria',
      2: 'preparacio',
      3: 'importacioExportacio',
      4: 'distribucioMajorista',
      5: 'distribucioMinorista',
      6: 'altresActivitats'
    };
    return mapping[idSeccion];
  }

  private getFamiliaByKey(key: string): { idFamilia: number, idSeccion: number } | null {
    const mapping: { [key: string]: { idFamilia: number, idSeccion: number } } = {
      'produccioVegetal': { idFamilia: 1, idSeccion: 1 },
      'produccioRamadera': { idFamilia: 2, idSeccion: 1 },
      'recolleccioSilvestre': { idFamilia: 3, idSeccion: 1 },
      'produccioSal': { idFamilia: 4, idSeccion: 1 },
      'aquicultura': { idFamilia: 5, idSeccion: 1 },
      'importacio': { idFamilia: 6, idSeccion: 3 },
      'exportacio': { idFamilia: 7, idSeccion: 3 },
      'certificatGran': { idFamilia: 8, idSeccion: 5 },
      'certificatPetit': { idFamilia: 9, idSeccion: 5 },
      'noCertificatEximit': { idFamilia: 10, idSeccion: 5 }
    };
    return mapping[key] || null;
  }

  private getKeyByFamiliaId(idFamilia: number): string | null {
    const mapping: { [key: number]: string } = {
      1: 'produccioPrimariaGroup.produccioVegetal',
      2: 'produccioPrimariaGroup.produccioRamadera',
      3: 'produccioPrimariaGroup.recolleccioSilvestre',
      4: 'produccioPrimariaGroup.produccioSal',
      5: 'produccioPrimariaGroup.aquicultura',
      6: 'importacioExportacioGroup.importacio',
      7: 'importacioExportacioGroup.exportacio',
      8: 'distribucioMinoristaGroup.certificatGran',
      9: 'distribucioMinoristaGroup.certificatPetit',
      10: 'distribucioMinoristaGroup.noCertificatEximit'
    };
    return mapping[idFamilia] || null;
  }

  private getSubfamiliaByKey(key: string): { idSubfamilia: number, familia: { idFamilia: number, seccion: { idSeccion: number } } } | null {
    const mapping: { [key: string]: { idSubfamilia: number, familia: { idFamilia: number, seccion: { idSeccion: number } } } } = {
      'llenyosos': { idSubfamilia: 1, familia: { idFamilia: 1, seccion: { idSeccion: 1 } } },
      'extensius': { idSubfamilia: 2, familia: { idFamilia: 1, seccion: { idSeccion: 1 } } },
      'horticoles': { idSubfamilia: 3, familia: { idFamilia: 1, seccion: { idSeccion: 1 } } },
      'obtentorsMRV': { idSubfamilia: 4, familia: { idFamilia: 1, seccion: { idSeccion: 1 } } },
      'pastures': { idSubfamilia: 5, familia: { idFamilia: 1, seccion: { idSeccion: 1 } } },
      'forestals': { idSubfamilia: 6, familia: { idFamilia: 1, seccion: { idSeccion: 1 } } },
      'herbivors': { idSubfamilia: 7, familia: { idFamilia: 2, seccion: { idSeccion: 1 } } },
      'porcs': { idSubfamilia: 8, familia: { idFamilia: 2, seccion: { idSeccion: 1 } } },
      'avicola': { idSubfamilia: 9, familia: { idFamilia: 2, seccion: { idSeccion: 1 } } },
      'apicola': { idSubfamilia: 10, familia: { idFamilia: 2, seccion: { idSeccion: 1 } } },
      'helicicola': { idSubfamilia: 11, familia: { idFamilia: 2, seccion: { idSeccion: 1 } } },
      'insectes': { idSubfamilia: 12, familia: { idFamilia: 2, seccion: { idSeccion: 1 } } },
      'conills': { idSubfamilia: 13, familia: { idFamilia: 2, seccion: { idSeccion: 1 } } },
      'alguesFitoplancton': { idSubfamilia: 14, familia: { idFamilia: 5, seccion: { idSeccion: 1 } } },
      'moluscs': { idSubfamilia: 15, familia: { idFamilia: 5, seccion: { idSeccion: 1 } } },
      'peixos': { idSubfamilia: 16, familia: { idFamilia: 5, seccion: { idSeccion: 1 } } }
    };
    return mapping[key] || null;
  }

  onCertificatChange(changedControlName: string, event: any) {
    const distribucioMinoristaGroup = this.formDecreto.get('distribucioMinoristaGroup') as FormGroup;
    const controls = ['certificatGran', 'certificatPetit', 'noCertificatEximit'];

    if (event) {
      controls.forEach(controlName => {
        if (controlName !== changedControlName) {
          distribucioMinoristaGroup.get(controlName)?.setValue(false);
        } else {
          distribucioMinoristaGroup.get(controlName)?.setValue(true);
        }
      });
    }
  }

  checkDadesInscritValid() {
    if (this.action === 'create') {
      return this.formInscrit.valid;
    } else {
      return this.formInscrit.valid && this.municipioPostalValid && this.municipioSocialValid;
    }
  }

  private setFormControlValues(item: DecretoOperador) {
    const seccionId = item.seccion.idSeccion;
    const familiaId = item.familia?.idFamilia;
    const subfamiliaId = item.subfamilia?.idSubfamilia;

    switch (seccionId) {
      case 1:
        this.formDecreto.get('produccioPrimaria')?.setValue(true);
        if (familiaId) {
          switch (familiaId) {
            case 1:
              this.formDecreto.get('produccioPrimariaGroup.produccioVegetal')?.setValue(true);
              if (subfamiliaId) {
                switch (subfamiliaId) {
                  case 1:
                    this.formDecreto.get('produccioPrimariaGroup.produccioVegetalGroup.llenyosos')?.setValue(true);
                    break;
                  case 2:
                    this.formDecreto.get('produccioPrimariaGroup.produccioVegetalGroup.extensius')?.setValue(true);
                    break;
                  case 3:
                    this.formDecreto.get('produccioPrimariaGroup.produccioVegetalGroup.horticoles')?.setValue(true);
                    break;
                  case 4:
                    this.formDecreto.get('produccioPrimariaGroup.produccioVegetalGroup.obtentorsMRV')?.setValue(true);
                    break;
                  case 5:
                    this.formDecreto.get('produccioPrimariaGroup.produccioVegetalGroup.pastures')?.setValue(true);
                    break;
                  case 6:
                    this.formDecreto.get('produccioPrimariaGroup.produccioVegetalGroup.forestals')?.setValue(true);
                    break;
                }
              }
              break;
            case 2:
              this.formDecreto.get('produccioPrimariaGroup.produccioRamadera')?.setValue(true);
              if (subfamiliaId) {
                switch (subfamiliaId) {
                  case 7:
                    this.formDecreto.get('produccioPrimariaGroup.produccioRamaderaGroup.herbivors')?.setValue(true);
                    break;
                  case 8:
                    this.formDecreto.get('produccioPrimariaGroup.produccioRamaderaGroup.porcs')?.setValue(true);
                    break;
                  case 9:
                    this.formDecreto.get('produccioPrimariaGroup.produccioRamaderaGroup.avicola')?.setValue(true);
                    break;
                  case 10:
                    this.formDecreto.get('produccioPrimariaGroup.produccioRamaderaGroup.apicola')?.setValue(true);
                    break;
                  case 11:
                    this.formDecreto.get('produccioPrimariaGroup.produccioRamaderaGroup.helicicola')?.setValue(true);
                    break;
                  case 12:
                    this.formDecreto.get('produccioPrimariaGroup.produccioRamaderaGroup.insectes')?.setValue(true);
                    break;
                  case 13:
                    this.formDecreto.get('produccioPrimariaGroup.produccioRamaderaGroup.conills')?.setValue(true);
                    break;
                }
              }
              break;
            case 3:
              this.formDecreto.get('produccioPrimariaGroup.recolleccioSilvestre')?.setValue(true);
              break;
            case 4:
              this.formDecreto.get('produccioPrimariaGroup.produccioSal')?.setValue(true);
              break;
            case 5:
              this.formDecreto.get('produccioPrimariaGroup.aquicultura')?.setValue(true);
              if (subfamiliaId) {
                switch (subfamiliaId) {
                  case 14:
                    this.formDecreto.get('produccioPrimariaGroup.aquiculturaGroup.alguesFitoplancton')?.setValue(true);
                    break;
                  case 15:
                    this.formDecreto.get('produccioPrimariaGroup.aquiculturaGroup.moluscs')?.setValue(true);
                    break;
                  case 16:
                    this.formDecreto.get('produccioPrimariaGroup.aquiculturaGroup.peixos')?.setValue(true);
                    break;
                }
              }
              break;
          }
        }
        break;
      case 2:
        this.formDecreto.get('preparacio')?.setValue(true);
        break;
      case 3:
        this.formDecreto.get('importacioExportacio')?.setValue(true);
        if (familiaId) {
          switch (familiaId) {
            case 6:
              this.formDecreto.get('importacioExportacioGroup.importacio')?.setValue(true);
              break;
            case 7:
              this.formDecreto.get('importacioExportacioGroup.exportacio')?.setValue(true);
              break;
          }
        }
        break;
      case 4:
        this.formDecreto.get('distribucioMajorista')?.setValue(true);
        break;
      case 5:
        this.formDecreto.get('distribucioMinorista')?.setValue(true);
        if (familiaId) {
          switch (familiaId) {
            case 8:
              this.formDecreto.get('distribucioMinoristaGroup.certificatGran')?.setValue(true);
              break;
            case 9:
              this.formDecreto.get('distribucioMinoristaGroup.certificatPetit')?.setValue(true);
              break;
            case 10:
              this.formDecreto.get('distribucioMinoristaGroup.noCertificatEximit')?.setValue(true);
              break;
          }
        }
        break;
      case 6:
        this.formDecreto.get('altresActivitats')?.setValue(true);
        break;
    }
  }

}
