import {Injectable} from "@angular/core";
import {HttpService} from "./core/http.service";
import {Observable, tap} from "rxjs";
import {Pageable} from "../../model/core/pageable";
import {TipoAutorizacionEnum} from "./operadores-http.service";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../components/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {NoAutorizacion} from "../../model/autorizacion/noAutorizacion";
import {Etiqueta} from "../../model/etiquetas/etiqueta";
import {NoAutorizacionAdjunto} from "../../model/autorizacion/no-autorizacion-adjunto";

@Injectable({
    providedIn: 'root'
})
export class NoAutorizacionesHttpService extends HttpService {

    private BASE = (idOperador: string) => `noAutorizaciones/${idOperador}`;
    private BASE_ID = (idOperador: string, idSolicitudProducto: number) =>
        `${this.BASE(idOperador)}/${idSolicitudProducto}`;
    private ETIQUETA = (idOperador: string, idSolicitudProducto: number) =>
        `${this.BASE_ID(idOperador, idSolicitudProducto)}/etiqueta`;
    private ETIQUETA_DOWN = (idOperador: string, idSolicitudProducto: number) =>
        `${this.BASE_ID(idOperador, idSolicitudProducto)}/etiqueta/download`;
    private ADJUNTOS = (idOperador: string, idSolicitudProducto: number) =>
        `${this.BASE_ID(idOperador, idSolicitudProducto)}/adjuntos`;
    private ADJUNTOS_DOWN = (idOperador: string, idSolicitudProducto: number, idNoAutorizacionAdjunto: number) =>
        `${this.BASE_ID(idOperador, idSolicitudProducto)}/adjuntos/${idNoAutorizacionAdjunto}/download`;
    private NO_AUTO_DOWN = (idOperador: string, idSolicitudProducto: number) =>
        `${this.BASE_ID(idOperador, idSolicitudProducto)}/download`;

    constructor(http: HttpClient,
                toastSvc: ToastService,
                private translateSvc: TranslateService) {
        super(http, toastSvc);
    }

    public getNoAutorizaciones(idOperador: string, tipoAutorizacion: TipoAutorizacionEnum, page: number | null = null, filtros: object = {}): Observable<Pageable<NoAutorizacion>> {
        let httpParams = this.objectToHttpParams(filtros);
        if (page) {
            httpParams = httpParams.set("page", page - 1);
        }

        httpParams = httpParams.set("tipoAutorizacion", tipoAutorizacion);
        return this.get(this.BASE(idOperador), httpParams)
            .pipe(tap(page => page.content = page.content.map(item => Object.assign(new NoAutorizacion(), item))));
    }

    public getNoAutorizacion(idOperador: string, idSolicitudProducto: number): Observable<NoAutorizacion> {
        return this.get(this.BASE_ID(idOperador, idSolicitudProducto));
    }

    public async downloadNoAutorizacion(idOperador: string, noAutorizacion: NoAutorizacion) : Promise<void> {
        return this.downloadFile(this.NO_AUTO_DOWN(idOperador, noAutorizacion.idSolicitudProducto), "FR335_" + noAutorizacion.idInscrito + "_" + noAutorizacion.idSolicitudProducto + ".pdf");
    }

    //ETIQUETAS

    public getEtiquetaNoAutorizacion(idOperador: string, idSolicitudProducto: number): Observable<Etiqueta> {
        return this.get(this.ETIQUETA(idOperador, idSolicitudProducto));
    }

    public downloadEtiquetaNoAutorizacion(idOperador: string, idSolicitudProducto: number){
        return this.downloadFile(this.ETIQUETA_DOWN(idOperador, idSolicitudProducto));
    }

    //ADJUNTOS

    public getAdjuntosNoAutorizacion(idOperador: string, idSolicitudProducto: number): Observable<NoAutorizacionAdjunto[]> {
        return this.get(this.ADJUNTOS(idOperador, idSolicitudProducto));
    }

    public downloadAdjuntoNoAutorizacion(idOperador: string, idSolicitudProducto: number, idNoAutorizacionAdjunto: number) : void {
        return this.downloadFile(this.ADJUNTOS_DOWN(idOperador, idSolicitudProducto, idNoAutorizacionAdjunto));
    }

}
