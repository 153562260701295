import {Component, Inject, OnInit} from '@angular/core';
import { forkJoin } from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  DuplicarActionsAdd,
  DuplicarActionsEnum,
  DuplicarActionsRemove,
  DuplicarColumns
} from "./actualizar-producto-modal.config";
import {EtiquetasService} from "../../services/etiquetas.service";
import {OperadoresHttpService, TipoAutorizacionEnum} from "../../../../core/services/http/operadores-http.service";
import {Autorizacion} from "../../../../core/model/autorizacion/autorizacion";
import {Pageable} from "../../../../core/model/core/pageable";
import {AutorizacionesHttpService} from "../../../../core/services/http/autorizaciones.http.service";

@Component({
  templateUrl: './actualizar-producto-modal.component.html',
  styleUrls: ['./actualizar-producto-modal.component.scss']
})
export class ActualizarProductoModalComponent implements OnInit {

  protected productos: Autorizacion[];
  protected disponibles: Autorizacion[];
  protected visibles: Autorizacion[];
  protected seleccionats: Autorizacion[];
  protected filtroProducto: string;

  protected tableConfig = DuplicarColumns;
  protected tableAdd = DuplicarActionsAdd;
  protected tableRemove = DuplicarActionsRemove;
  protected actionsEnum = DuplicarActionsEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ActualizarProductoModalComponent>,
              private operadorSvc: OperadoresHttpService,
              private etiquetasSvc: EtiquetasService,
              private autorizacionesSvc: AutorizacionesHttpService) {
    this.productos = this.disponibles = [];
    this.seleccionats = [];
    this.visibles = [];
    this.filtroProducto = "";
  }

  ngOnInit(): void {
    let tipoAut = this.etiquetasSvc.convertCodigoTramiteToTipoAutorizacion(this.etiquetasSvc.codigoTramite)
    this.autorizacionesSvc.getAutorizacionesActualizacion(this.etiquetasSvc.codigoOperador, tipoAut, this.etiquetasSvc.idSolicitud)
        .subscribe((autorizaciones: Pageable<Autorizacion>) => {
          this.productos = autorizaciones.content;
          this.visibles = this.productos;
          this.disponibles = this.productos;
          this.ordenarListas();
        });
  }

  onActionTable($event: any) {

    let autorizacion = $event.item;

    switch ($event.action) {
      case this.actionsEnum.Add:
        this.disponibles = this.disponibles.filter(item => !item.equals(autorizacion));
        this.seleccionats = [...this.seleccionats, autorizacion];
        break;

      case this.actionsEnum.Remove:
        this.seleccionats = this.seleccionats.filter(item => !item.equals(autorizacion));
        this.disponibles = [...this.disponibles, autorizacion];
        break;
    }

    this.ordenarListas();
    this.visibles = this.disponibles;
    this.filtroProducto = "";
  }

  ordenarListas() {
    this.disponibles.sort((a, b) => a.numOrdenAutorizacion > b.numOrdenAutorizacion ? 1 : -1);
    this.seleccionats.sort((a, b) => a.numOrdenAutorizacion > b.numOrdenAutorizacion ? 1 : -1);
  }

  onChangeText() {
    this.visibles = this.disponibles.filter(item => item.nombreProducto.toLowerCase()
        .includes(this.filtroProducto.toLowerCase()));
  }

  onSubmit() {
    this.dialogRef.close(this.seleccionats);
  }
}
