import {Component, Inject, Injector, OnInit, ViewChild} from '@angular/core';
import {Etiqueta} from "../../../../core/model/etiquetas/etiqueta";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable, of, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {EditEtiquetaCompromisosNOPTexts, EditEtiquetaCompromisosTexts} from "./edit-etiqueta-compromisos-texts";
import {ToastService} from "../../../../core/services/components/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {
	SolicitudProductoCompuestoHttpService
} from "../../../../core/services/http/solicitud/solicitud-producto-compuesto.http.service";
import {TipoAutorizacionEnum} from "../../../../core/services/http/operadores-http.service";
import {DropFileZoneComponent} from "../../../../shared/components/drop-file-zone/drop-file-zone.component";
import {SolicitudHttpService} from "../../../../core/services/http/solicitud/solicitud.http.service";

interface Data {
	producto: SolicitudProducto,
	tipoAutorizacion: TipoAutorizacionEnum,
	onLoad: () => Observable<Etiqueta>,
	onSave: (file: File) => Observable<Etiqueta>,
	onRemove: () => Observable<any>,
	onDownload: () => any
}

@Component({
	templateUrl: './edit-etiqueta-modal.component.html',
	styleUrls: ['./edit-etiqueta-modal.component.scss']
})
export class EditEtiquetaModalComponent implements OnInit {

	@ViewChild(DropFileZoneComponent) dropFileZone!: DropFileZoneComponent;

	protected etiqueta: Etiqueta | null;
	protected producto: SolicitudProducto;
	protected tipoAutorizacion: TipoAutorizacionEnum;
	protected solicitudEstado: number;

	protected file: File | null;
	protected solicitudProductoMultiSvc: SolicitudProductoCompuestoHttpService;
	protected solicitudSvc: SolicitudHttpService;

	protected formGroup: FormGroup;
	protected compromisos = EditEtiquetaCompromisosTexts;
	protected compromisosNOP = EditEtiquetaCompromisosNOPTexts;

	constructor(@Inject(MAT_DIALOG_DATA) public data: Data,
				public dialogRef: MatDialogRef<EditEtiquetaModalComponent>,
				protected toastSvc: ToastService,
				protected translateSvc: TranslateService,
				injector: Injector) {
		this.etiqueta = null;
		this.file = null;
		this.solicitudProductoMultiSvc = injector.get(SolicitudProductoCompuestoHttpService);
		this.solicitudSvc = injector.get(SolicitudHttpService);
		this.producto = data.producto;
		this.tipoAutorizacion = data.tipoAutorizacion;

		this.formGroup = new FormGroup({
			"disclaims": new FormArray([])
		});
	}
	ngOnInit(): void {

		this.data.onLoad().subscribe(item => {
			this.etiqueta = item;
		});

		this.solicitudSvc.getSolicitud(this.producto.idSolicitud).subscribe(solicitud => {
			this.solicitudEstado = solicitud.estado;
			if(this.solicitudEstado !== 3) {
				this.loadTipoNormativa();
				if(this.tipoAutorizacion === TipoAutorizacionEnum.ETIQUETATGE_HORTOFRUTICOLES) {
					this.loadDisclaims();
				}
			}
		});

	}

	onFileDrop($event: File[]) {
		const maxSizeInBytes = 25 * 1024 * 1024; // 25MB
		const maxFileNameLength = 90;
		const fileDropZone = $event[0];

		if (fileDropZone.name.length > maxFileNameLength) {
			const errorMessage = this.translateSvc.instant("ETIQUETAS.errorFileNameSize");
			this.toastSvc.showError(errorMessage);
			this.file = null;
			this.dropFileZone.clearFiles();
			return throwError(errorMessage);
		}

		if (fileDropZone.size <= maxSizeInBytes) {
			this.file = fileDropZone;
			return of(null);
		} else {
			const errorMessage = this.translateSvc.instant("ETIQUETAS.errorFileSize");
			this.toastSvc.showError(errorMessage);
			this.file = null;
			this.dropFileZone.clearFiles();
			return throwError(errorMessage);
		}
	}

	onDelete() {
		this.data.onRemove().subscribe(_ => {
			this.etiqueta = null;
		})
	}

	onDownload() {
		this.data.onDownload();
	}

	onCancel() {
		this.dialogRef.close();
	}

	disclaims(): FormArray{
		return this. formGroup.get("disclaims") as FormArray;
	}

	private loadTipoNormativa() {
		this.solicitudProductoMultiSvc.getProducto(this.producto.idSolicitudProducto).subscribe(producto => {
			let tipoNormativa = producto.tipoNormativa;
			if (tipoNormativa === "NOP") {
				this.loadDisclaimsNOP();
			} else {
				this.loadDisclaims();
			}
		});
	}

	private loadDisclaims() {
		let idCategoriaEcologica = this.producto.categoriaEcologica.idCategoriaEcologica;
		let texts = this.compromisos.get(parseInt(idCategoriaEcologica.toString(),10));
		texts?.forEach(text => {
			this.disclaims().push(new FormGroup({
				"text": new FormControl(text),
				"checked": new FormControl(false, [Validators.requiredTrue])
			}));
		});
	}

	private loadDisclaimsNOP(){
		let texts = this.compromisosNOP.get(parseInt("0", 10));
		texts?.forEach(text => {
			this.disclaims().push(new FormGroup({
				"text": new FormControl(text),
				"checked": new FormControl(false, [Validators.requiredTrue])
			}));
		});

	}

	onSubmit() {
		if (this.file) {
			this.data.onSave(this.file)
				.pipe(
					catchError(err => {
						this.dialogRef.close();
						this.toastSvc.showError(this.translateSvc.instant("ETIQUETAS.errorFileSize"));
						throw this.translateSvc.instant("ETIQUETAS.errorFileSize");
					})
				)
				.subscribe(item => {
					if (item) {
						this.etiqueta = item;
						this.dialogRef.close();
					}
				});
		}
	}
}
