<app-page-template
    [title]="title | translate:
    titleParams"
>
    <form [formGroup]="formGroup">
        <mat-card class="card shadow">
            <mat-card-title>
                {{'OPERADORES.autorizaciones.titleCardDetall' | translate}}
            </mat-card-title>
            <mat-card-content>

                <div class="row">
                    <div class="col-md-3 col-sm-12">
                        <app-input-text
                                formControlName="nombreComercial"
                                [label]="'OPERADORES.productos.nombreComercial' | translate"
                                [campoObligatorio]="true"
                        >
                        </app-input-text>
                    </div>

                    <div class="col-md-3 col-sm-12">
                        <app-input-autocomplete
                                formControlName="idProducto"
                                [observable]="productoSvc.getProductosAutocomplete()"
                                [label]="'OPERADORES.productos.nombreProducto' | translate"
                                [campoObligatorio] = true
                                [small]="action == 'view'? null : 'ETIQUETAS.productoHelp' | translate"
                                (change)="handleFiltroPopUpAlbaran()"
                        >
                        </app-input-autocomplete>
                    </div>

                    <div class="col-md-3 col-sm-12">
                        <app-input-text
                                formControlName="marca"
                                [label]="'general.marca' | translate"
                                [campoObligatorio]="true"
                        >
                        </app-input-text>
                    </div>

                    <div class="col-md-3 col-sm-12">
                        <app-input-text
                                formControlName="capacidad"
                                [label]="'general.capacidad' | translate"
                                [campoObligatorio]
                                [small]="action == 'view'? null : 'general.capacidadHelp' | translate"
                        >
                        </app-input-text>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3 col-sm-12">
                        <app-input-select
                                formControlName="idEnvase"
                                [label]="'general.envase' | translate"
                                [campoObligatorio]="true"
                                [options]="envases | async | arrayToSelectItem:'idEnvase':'descripcion'"
                        >
                        </app-input-select>
                    </div>

                    <div class="col-md-3 col-sm-12">
                        <app-input-select
                                formControlName="idCategoriaEcologica"
                                [label]="'ETIQUETAS.categoriaEcologica' | translate"
                                [options]="categorias | async | arrayToSelectItem:'idCategoriaEcologica':'descripcion'"
                                [campoObligatorio]="true"
                        >
                        </app-input-select>
                    </div>

                    <div class="col-md-3 col-sm-12">
                        <app-input-datepicker
                                formControlName="fechaAutorizacion"
                                [label]="'OPERADORES.autorizaciones.fechaAutorizacion' | translate"
                        >
                        </app-input-datepicker>
                    </div>

                    <div class="col-md-3 col-sm-12">
                        <app-input-datepicker
                                formControlName="fechaCambioEstado"
                                [label]="'OPERADORES.autorizaciones.fechaCambioEstado' | translate"
                        >
                        </app-input-datepicker>
                    </div>
                </div>

                <div *ngIf="action =='view'" class="row">
                    <div class="col-md-3 col-sm-12">
                        <app-input-text
                                formControlName="usuario"
                                [label]="'OPERADORES.autorizaciones.usuario' | translate"
                                [isDisabled]="true"
                                [readonly]="true"
                        >
                        </app-input-text>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="action!='create'" class="card shadow">
            <mat-card-title>
                {{'general.infoAdiccional' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row" *ngIf="autorizacion.tipoAutorizacion == tipoAutorizacion.ETIQUETATGE_ELABORATS ||
                  autorizacion.tipoAutorizacion == tipoAutorizacion.ETIQUETATGE_VINS">

                    <div *ngIf="autorizacion.tipoAutorizacion == tipoAutorizacion.ETIQUETATGE_ELABORATS" class="col-md-3 col-sm-12">
                        <app-input-text
                                formControlName="auxiliaresTecnologicos"
                                [label]="autorizacion.tipoAutorizacion == tipoAutorizacion.ETIQUETATGE_ELABORATS?
                            ('ETIQUETAS.elaborado.auxiliaresTecnologicos' | translate) : ('ETIQUETAS.vinos.auxiliaresTecnologicos' | translate)"
                        >
                        </app-input-text>
                    </div>

                    <div class="col-md-3 col-sm-12">
                        <app-input-text
                                formControlName="tecnicaTransformacion"
                                [label]="'ETIQUETAS.elaborado.tecnicaTransformacion' | translate"
                                [campoObligatorio]="true"
                                >
                        </app-input-text>
                    </div>

                    <div class="col-md-3 col-sm-12" *ngIf="autorizacion.tipoAutorizacion == tipoAutorizacion.ETIQUETATGE_VINS">
                        <app-input-select
                                formControlName="tipoNormativa"
                                [label]="'ETIQUETAS.normativaUeNop' | translate"
                                [campoObligatorio]="true"
                                [options]="normativas | arrayToSelectItem:'descripcion':'descripcion'"
                        >
                        </app-input-select>
                    </div>

                    <div *ngIf="autorizacion.tipoAutorizacion == tipoAutorizacion.ETIQUETATGE_VINS" class="mt-3">
                        <div class="col-md-12 col-sm-12">
                            <app-input-textarea
                                    formControlName="auxiliaresTecnologicos"
                                    [label]="autorizacion.tipoAutorizacion == tipoAutorizacion.ETIQUETATGE_ELABORATS?
                            ('ETIQUETAS.elaborado.auxiliaresTecnologicos' | translate) : ('ETIQUETAS.vinos.auxiliaresTecnologicos' | translate)"
                                    [rows]="3"
                            >
                            </app-input-textarea>
                        </div>
                    </div>


                    <div class="mt-3">
                        <div class="row">
                            <div class="col-md-3 col-sm-12">
                                <app-input-select
                                        formControlName="idUbicacionElaboracion"
                                        [label]="'ETIQUETAS.elaboracio' | translate"
                                        [campoObligatorio]="true"
                                        [options]="ubicacionesElaboracion | async | arrayToSelectItem:'idUbicacion':'descripcion'"
                                        [orderOptions]="false"
                                >
                                </app-input-select>
                            </div>
                            <div class="col-md-3 col-sm-12" *ngIf="idUbicacionElaboracion == ubicacionEnum.PROPI
                                || idUbicacionElaboracion == ubicacionEnum.SUBCONTRATO">
                                <app-input-text
                                        [label]="literalesUbicaciones[idUbicacionElaboracion] | translate"
                                        formControlName="textoElaboracion"
                                >

                                </app-input-text>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-sm-12">
                                <app-input-select
                                        formControlName="idUbicacionEnvase"
                                        [label]="'ETIQUETAS.envasado' | translate"
                                        [campoObligatorio]="true"
                                        [options]="ubicacionesEnvasado | async | arrayToSelectItem:'idUbicacion':'descripcion'"
                                        [orderOptions]="false"
                                >
                                </app-input-select>
                            </div>
                            <div class="col-md-3 col-sm-12" *ngIf="idUbicacionEnvase == ubicacionEnum.PROPI
                                || idUbicacionEnvase == ubicacionEnum.SUBCONTRATO">
                                <app-input-text
                                        [label]="literalesUbicaciones[idUbicacionEnvase] | translate"
                                        formControlName="textoEnvase"
                                >

                                </app-input-text>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-sm-12">
                                <app-input-select
                                        formControlName="idUbicacionEtiqueta"
                                        [label]="'ETIQUETAS.etiquetaje' | translate"
                                        [campoObligatorio]="true"
                                        [options]="ubicacionesEtiquetaje | async | arrayToSelectItem:'idUbicacion':'descripcion'"
                                        [orderOptions]="false"
                                        (change)="handlePopUpAlbaran()"
                                >
                                </app-input-select>
                            </div>
                            <div class="col-md-3 col-sm-12" *ngIf="idUbicacionEtiqueta == ubicacionEtiquetajeEnum.PROPI
                                || idUbicacionEtiqueta == ubicacionEtiquetajeEnum.SUBCONTRATO">
                                <app-input-text
                                        [label]="literalesUbicaciones[idUbicacionEtiqueta] | translate"
                                        formControlName="textoEtiquetaje"
                                >

                                </app-input-text>
                            </div>
                        </div>

                        <div *ngIf="autorizacion.tipoAutorizacion == tipoAutorizacion.ETIQUETATGE_ELABORATS" class="col-md-2 col-sm-12">
                            <app-input-checkbox
                                    class="mx-2"
                                    formControlName="usaNitratos"
                                    [label]="'general.usaNitratos' | translate"
                            >
                            </app-input-checkbox>
                        </div>
                    </div>
                </div>

                <div class="row d-flex justify-content-start mt-3" *ngIf="autorizacion.tipoAutorizacion != tipoAutorizacion.ETIQUETATGE_DISTRIBUITS &&
                                                                          autorizacion.tipoAutorizacion != tipoAutorizacion.ETIQUETATGE_IMPORTATS &&
                                                                          this.idUbicacionEtiqueta != 4">
                    <label class="font-weight-bold">
                        {{'general.etiqueta' | translate}}
                    </label>
                    <div *ngIf="action == 'edit' && etiqueta == null" class="my-2">
                        <div>
                            <app-drop-file-zone
                                    (onFilesDrop)="onFileDropEtiqueta($event)"
                                    [multiple]="false"
                                    [accept]="'.jpg,.jpeg,.png,.pdf'"
                            >
                            </app-drop-file-zone>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto file-box" *ngIf="etiqueta; else noEtiqueta">
                            <div class="file-open d-flex align-items-center file" data-toggle="tooltip"
                                 [title]="etiqueta?.nombre"
                                 (click)="onDownloadEtiqueta()"
                                 [ngStyle]="{'margin-right.px': 10}">
                                <mat-icon>file_present</mat-icon>
                                {{ (etiqueta.nombre | slice:0:24) + (etiqueta.nombre.length > 24? '...' : '')}}
                            </div>

                            <div *ngIf="action == 'edit'" class="remove-file" (click)="onDeleteEtiqueta()">
                                <mat-icon>close</mat-icon>
                            </div>
                        </div>
                    </div>

                    <ng-template #noEtiqueta >
                        <div [ngStyle]="{'margin-left.px': 2}">
                            {{'general.noEtiqueta' | translate}}
                        </div>
                    </ng-template>
                </div>


                <div class="row d-flex justify-content-start mt-3">
                    <label class="font-weight-bold">
                        {{'general.adjuntos' | translate}}
                    </label>
                    <div *ngIf="action == 'edit' || action == 'create'" class="my-2">
                        <div>
                            <app-drop-file-zone
                                    (onFilesDrop)="onFileDropAdjunto($event)"
                                    [multiple]="true"
                                    [accept]="'.jpg,.jpeg,.png,.pdf'"
                            >
                            </app-drop-file-zone>
                        </div>
                    </div>

                    <div *ngIf="adjuntos.length > 0; else noAdjuntos" class="row">
                        <div class="col-auto file-box" *ngFor="let file of adjuntos">
                            <div class="file-open d-flex align-items-center file" data-toggle="tooltip"
                                 [title]="file.nombre"
                                 (click)="onDownloadAdjunto(file.idAutorizacionAdjunto)"
                                 [ngStyle]="{'margin-right.px': 10}">
                                <mat-icon>file_present</mat-icon>
                                {{ (file.nombre | slice:0:24) + (file.nombre.length > 24? '...' : '')}}
                            </div>

                            <div *ngIf="action == 'edit'" class="remove-file" (click)="onDeleteAdjunto(file.idAutorizacionAdjunto)">
                                <mat-icon>close</mat-icon>
                            </div>
                        </div>
                    </div>

                    <ng-template #noAdjuntos >
                        <div [ngStyle]="{'margin-left.px': 2}">
                            {{'general.noAdjuntos' | translate}}
                        </div>
                    </ng-template>
                </div>

            </mat-card-content>
        </mat-card>

        <mat-card class="card shadow"
                  *ngIf="autorizacion.tipoAutorizacion == tipoAutorizacion.ETIQUETATGE_ELABORATS ||
                  autorizacion.tipoAutorizacion == tipoAutorizacion.ETIQUETATGE_VINS">
            <mat-card-title>
                {{'general.ingredientes' | translate}}
            </mat-card-title>
            <mat-card-content>
                <app-generic-table
                    [data]="autorizacion.ingredientes"
                    [columnsConfig]="ingredientesConfigTable"
                    [actionsConfig]="action == 'view'? tableAccionesView : tableAccionesEdit"
                    (onAction)="onActionTableIngredientes($event)"
                >

                </app-generic-table>

                <div *ngIf="autorizacion.ingredientes.length > 0" class="col-12 mt-3 d-flex justify-content-end">
                    <h4>{{'ETIQUETAS.porcentajeTotal' | translate}}</h4>
                    <h4>
                         <span [ngClass]="{'text-success': porcentajeTotal === 100, 'text-danger': porcentajeTotal !== 100}">
                            : {{ porcentajeTotal }}%
                        </span>
                    </h4>
                </div>

                <div *ngIf="action == 'edit' && !porcentajeOK" class="col-12 mt-2 d-flex justify-content-end">
                    <button mat-mini-fab color="basic" (click)="onAddIngrediente()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </mat-card-content>
        </mat-card>

                <div *ngIf="action == 'edit' || action=='create'" class="col-12 p-2 mt-2 d-flex justify-content-end">
                    <button mat-raised-button color="basic" class="px-2" [disabled]="!formGroup.valid"
                            (click)="onGuardarForm()"
                    >
                        {{'OPERADORES.modificarOperador.guardarDatosAutorizacion' | translate}}
                    </button>
                </div>

    </form>
    <button mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
        <mat-icon>arrow_back</mat-icon>
        {{"general.volver" | translate}}
    </button>
</app-page-template>
