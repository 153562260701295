<div class="col-12 login-page d-flex justify-content-center align-items-center align-middle">
    <div class="col-6 login-box">
        <div class="login-box-content d-flex justify-content-center">
            <div class="col-9 d-flex justify-content-center align-items-center">
                <div class="col-8 login-form-box">

                    <div class="d-flex justify-content-center logo mb-4">
                        <img src="../../../../../assets/images/logo.webp" alt="logo"/>
                    </div>

                    <div class="d-flex justify-content-center">
                        <h2>{{'recuperarPw.canviarContrasenya' | translate}}</h2>
                    </div>


                    <ng-container *ngIf="!succesful && !error; else noInput">
                        <div class="d-flex justify-content-center">
                            <h3>{{'recuperarPw.normesContrasenya' | translate}}</h3>
                        </div>

                        <form [formGroup]="formGroup" (submit)="onSubmit()" *ngIf="!error">

                            <div class="row form-group">
                                <input formControlName="password1" class="form-control" type="password"
                                       [placeholder]="'general.password' | translate"/>
                            </div>

                            <div class="row form-group mt-2">
                                <input formControlName="password2" class="form-control" type="password"
                                       [placeholder]="'recuperarPw.password-repeat' | translate"/>
                            </div>

                            <div class="row form-group mt-3">

                                <button class="form-control btn btn-success" type="submit"
                                        [disabled]="!formValid">
                                    {{'Canviar contrasenya' | translate}}
                                </button>
                            </div>
                        </form>
                    </ng-container>
                    <ng-template #noInput>
                        <div class="d-flex justify-content-center" *ngIf="error">
                            <h3 class="error">{{'recuperarPw.errorLink' | translate}}</h3>
                        </div>

                        <div class="d-flex justify-content-center" *ngIf="!error">
                            <h3 class="success">{{'recuperarPw.canviSuccesful' | translate}}</h3>
                        </div>

                        <div class="row form-group mt-2">
                            <button class="form-control btn btn-success" (click)="onBackToLogin()">{{'recuperarPw.backToLogin' | translate}}</button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
