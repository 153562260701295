import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AuthService} from "../services/security/auth.service";
import {LoginService} from "../services/http/login.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private exepctionsURLs = [
        this.loginSvc.LOGIN_URL
    ];

    constructor(private authSvc: AuthService,
                private loginSvc: LoginService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        if (this.exepctionsURLs.find(item => item == request.url) ||
            request.url.startsWith("/")) {
            return next.handle(request);
        }

        if (this.authSvc.isAuthenticated()) {
            return next.handle(request.clone({
                headers: request.headers.set('Authorization', "Bearer " + this.authSvc.authToken),
            }));
        } else {
            this.authSvc.logout();
            return of({} as HttpEvent<any>);
        }
    }
}
