import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";
import {Solicitud} from "../../../../core/model/solicitud/solicitud";

export const OperadoresSolicitudTableConfig: TablaColumna[] =  [
    {
        header: 'OPERADORES.solicitudes.idSolicitud',
        field: "idSolicitud",
        type: "string"
    },
    {
        header: 'OPERADORES.solicitudes.rangoIdProductos',
        field: "productos",
        type: "function",
        fn: (solicitud: Solicitud) => {
            if (solicitud.productos && solicitud.productos.length > 0) {
                const firstProducto = solicitud.productos[0].idSolicitudProductoPadre ? solicitud.productos[0].idSolicitudProductoPadre : solicitud.productos[0].idSolicitudProducto;
                const lastProducto = solicitud.productos[solicitud.productos.length - 1].idSolicitudProductoPadre ? solicitud.productos[solicitud.productos.length - 1].idSolicitudProductoPadre : solicitud.productos[solicitud.productos.length - 1].idSolicitudProducto;
                return firstProducto === lastProducto ? `${firstProducto}` : `${firstProducto} - ${lastProducto}`;
            }
            return '';
        }
    },
    {
        header: 'OPERADORES.solicitudes.estadoTitle',
        field: "estado",
        type: "function",
        fn: (solicitud: Solicitud) => {
            return solicitud.getEstado();
        }
    },
    {
        header: 'OPERADORES.solicitudes.tipoTramiteTitle',
        field: "tipoTramite",
        type: "function",
        fn: (solicitud: Solicitud) => {
            return solicitud.getTipoTramite();
        }
    },
    {
        header: 'OPERADORES.solicitudes.codigoTramiteTitle',
        field: "codigoTramite",
        type: "function",
        fn: (solicitud: Solicitud) => {
            return solicitud.getCodigoTramite();
        }
    },
    {
        header: 'OPERADORES.solicitudes.fecha',
        field: "fechaCreacion",
        type: "date"
    }

]

export const OperadoresSolicitudAccionesConfig: TablaAccion[] = [
    {
        action: "delete",
        icon: "delete",
        toolTip: "Eliminar sol·licitud",
        condicion: "estado == 1 || estado == 2 || estado == 3"
    },
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure dades"
    }
]
