import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {
  NgbDate,
  NgbDateNativeAdapter,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbInputDatepicker
} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from '@ngx-translate/core';
import {NgbDateCustomParserFormatter} from "./configuration/ngb-date-custom-parser-formatter";
import {TranslationWidth} from "@angular/common";

@Component({
  selector: 'app-input-datepicker',
  templateUrl: './input-date-picker.component.html',
  styleUrls: ['./input-date-picker.component.scss'],
  providers: [{provide: NgbDatepickerI18n, useClass: InputDatePickerComponent}]
})
export class InputDatePickerComponent extends NgbDatepickerI18n implements OnInit, ControlValueAccessor {

  @Input() label;
  @Input() value: string | null;
  @Input() campoObligatorio: boolean;
  @Input() deletable = true;
  @Input() minDate = null;
  @Input() maxDate = null;

  @ViewChild('d') dpElementRef: any;

  DATE_PICKER_WEEKDAYS = "date-picker.weekDays";
  DATE_PICKER_SHORTMONTHS = "date-picker.shortMonths";
  DATE_PICKER_LONGMONTHS = "date-picker.longMonths";

  isDisabled: boolean;
  onChange = (_:any) => { }
  onTouch = () => { }
  public ngControl!: NgControl;

  constructor(private inj: Injector,
    private translate: TranslateService) {
    super();
    this.label = "";
    this.value = "";
    this.isDisabled = false;
    this.campoObligatorio = false;
  }

  ngOnInit(): void {
    try {
      this.ngControl = this.inj.get(NgControl)
      this.ngControl.valueAccessor = this;
    } catch (e) {

    }
  }

  getWeekdayShortName(weekday: number): string {
    return this.translate.instant(this.DATE_PICKER_WEEKDAYS)[weekday-1];
  }
  
  getMonthShortName(month: number, year?: number): string {
    return this.translate.instant(this.DATE_PICKER_SHORTMONTHS)[month-1];
  }
  
  getMonthFullName(month: number, year?: number): string {
    return this.translate.instant(this.DATE_PICKER_LONGMONTHS)[month-1];
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return '';
  }



  override getDayAriaLabel(date: NgbDateStruct): string {
    return '';
  }
  
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    if(obj != null) {
      this.value = new NgbDateCustomParserFormatter().format(
        new NgbDateNativeAdapter().fromModel(new Date(obj))
      );
    } else {
      this.value = null;
      this.removeValue(this.dpElementRef);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  onChangeValue($event: any){
    this.value = $event.target.value;
    this.onChangeDate(NgbDate.from(new NgbDateCustomParserFormatter().parse(this.value)));
  }

  onChangeDate($event: NgbDate | null) {

    if($event){
      this.value = new Date(Date.UTC($event.year, $event.month - 1, $event.day)).toISOString();
      this.onChange(this.value);
    }
    else {
      this.onChange(null)
    }
  }

  onKeyDown($event: KeyboardEvent) {
    $event.preventDefault()
  }

  removeValue(d: NgbInputDatepicker) {
    if (d){
      d.writeValue('');
      this.onChangeValue({
        target: {value: null}
      });
    }
  }
}
