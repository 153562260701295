<div>
    <h2 mat-dialog-title class="d-flex justify-content-between">
        <div>
            {{'ETIQUETAS.disclaimer' | translate}}
        </div>

        <div class="clickable" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>

    <div>
        {{'ETIQUETAS.reglamentEnviarSol' | translate}}
    </div>

    <div>
        <app-input-checkbox
            [(ngModel)]="checkboxAceptado"
            [label]="'He llegit i acepto els requisits del tramit'"
        >
        </app-input-checkbox>
    </div>

    <div *ngIf="checkboxAceptado" class="d-flex justify-content-center mt-2">
        <h4> <strong>{{'ETIQUETAS.disclaimerEnviar' | translate}}</strong></h4>
    </div>
    <div *ngIf="checkboxAceptado" class="d-flex justify-content-center mt-2">
        <h4 style="color: red; margin-top: 10px;"> <strong>{{'ETIQUETAS.disclaimerTramite' | translate}}</strong></h4>
    </div>
    <div *ngIf="checkboxAceptado" class="d-flex justify-content-center mt-2">
        <img style="max-width: 400px; margin-bottom: 30px;" src="../../../../assets/images/tramits-i-gestions.png" alt="tràmits i gestions"><img>
    </div>

    <div class="d-flex justify-content-center mt-2">
        <button mat-raised-button color="white" class="m-2" (click)="dialogRef.close(false)">
            {{'general.cancelar' | translate}}
        </button>
        <button mat-raised-button color="primary" class="m-2" (click)="dialogRef.close(true)"
                [disabled]="!checkboxAceptado">
            {{'general.aceptar' | translate}}
        </button>
    </div>
</div>
