import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss']
})
export class InputRadioComponent implements OnInit, ControlValueAccessor {

  @Output() valueChanged = new EventEmitter();
  @Input() id : string = "noName";
  @Input() label = "";
  @Input() value: string | boolean = "";
  @Input() name;
  @Input() campoObligatorio: boolean = false;
  @Input() checked = false;
  @Input() isDisabled: boolean;

  onChange = (_:any) => { }
  onTouch = () => { }

  public ngControl: NgControl;

  constructor(private inj: Injector) {

  }

  ngOnInit(): void {
    try {
      this.ngControl = this.inj.get(NgControl)
      this.ngControl.valueAccessor = this;
    } catch (e) {

    }
  }

  change($event) {
    this.onChange($event.target.value);
    this.valueChanged.emit($event.target.value);
  }

  writeValue(obj: any): void {
    this.checked = obj == this.value;
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
      this.isDisabled = isDisabled;
  }

}
