import { Component, OnInit } from '@angular/core';
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Page} from "../../../../core/model/core/pages/page";
import {Solicitud, SolicitudEstado} from "../../../../core/model/solicitud/solicitud";
import {
  SolicitudProductoAccionesConfig,
  ProductosConfigTable
} from "./operadores-detalle-solicitud-productos.table-config";
import {TranslateService} from "@ngx-translate/core";
import {FormGroup} from "@angular/forms";
import {SolicitudProducto} from "../../../../core/model/etiquetas/solicitud-producto";
import {SolicitudHttpService} from "../../../../core/services/http/solicitud/solicitud.http.service";
import {Pageable} from "../../../../core/model/core/pageable";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-operadores-detalle-solicitud',
  templateUrl: './operadores-detalle-solicitud.component.html',
  styleUrls: ['./operadores-detalle-solicitud.component.scss']
})
export class OperadoresDetalleSolicitudComponent extends Page implements OnInit {

  private idOperador: string;
  private idSolicitud: string;
  public solicitud: Solicitud;
  public productos: Pageable<SolicitudProducto>;
  public productosConfigTable = ProductosConfigTable;
  public productosActionsConfig = SolicitudProductoAccionesConfig;
  public formGroup: FormGroup;
  public page = 1;
  public solicitudEstado: SolicitudEstado;

    constructor(private operadorSvc: OperadoresHttpService,
              private solicitudSvc: SolicitudHttpService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public translate: TranslateService) {
    super();
    this.idOperador = activatedRoute.snapshot.params['idOperador'];
    this.idSolicitud = activatedRoute.snapshot.params['idSolicitud'];

    this.solicitud = new Solicitud(translate);
  }

  async ngOnInit(): Promise<void> {
    this.operadorSvc.getSolicitud(this.idOperador, this.idSolicitud)
        .subscribe({
          next: value => {
            this.solicitud = value;
            this.solicitudEstado = this.solicitud.estado;
          },
          error: (e) => this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
        });
    this.productos = await lastValueFrom(this.solicitudSvc.getProductosPageable(this.idSolicitud, this.page));
  }

  public onAction($event: any) {
    if ($event.action === "view") {
      this.viewProducto($event.item);
    }
  }

  private viewProducto(item) {
    this.router.navigate(['producte', item.idSolicitudProducto],
        {relativeTo: this.activatedRoute})
  }

  public async onPageChange(page: number) {
    this.page = page;
    this.productos = await lastValueFrom(this.solicitudSvc.getProductosPageable(this.idSolicitud, this.page));
  }

  //Botó de retorn
  onBack(){
      this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
  }

  protected readonly SolicitudEstado = SolicitudEstado;
}
