import {Injectable} from '@angular/core';
import {HttpService} from "./core/http.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ToastService} from "../components/toast.service";
import {Observable} from "rxjs";
import {Buffer} from 'buffer';
import {Respuesta} from "../../model/core/respuesta";

export interface LoginReq {
  username: string;
  password: string;
}

export interface AuthData {
  accessToken: string;
  refreshToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService extends HttpService {

  public LOGIN_URL = this.baseUrl + 'login';
  public REFRESH_URL = this.baseUrl + 'login/refresh';
  public RESTABLIR_PASSWORD_URL = this.baseUrl + 'login/restablir-contrasenya?email=';
  public CANVIAR_PASSWORD_URL = this.baseUrl + 'login/canviar-contrasenya';

  constructor(http: HttpClient,
              toastSvc: ToastService) {
    super(http, toastSvc);
  }

  public login(loginReq: LoginReq): Observable<AuthData> {

    const encodedLogin = Buffer.from(`${loginReq.username}:${loginReq.password}`).toString('base64');

    let headers = new HttpHeaders();
    headers = headers.set("Authorization", "Basic " + encodedLogin);

    return this.httpClient.post<AuthData>(this.LOGIN_URL, {}, {
      headers: headers
    })
  }

  public refresh(username: string, refreshToken: string): Observable<AuthData> {
    return this.httpClient.post<AuthData>(this.REFRESH_URL, {
      username,
      refreshToken
    });
  }

  public enviarMailRestablecerPassword(email: string): Observable<any>{
    return this.httpClient.post(this.RESTABLIR_PASSWORD_URL + email, {});
  }

  public canviarContrasenya(token: string, newPassword: string){
    const encodedPassword= Buffer.from(`${newPassword}`).toString('base64');

    return this.httpClient.post<Respuesta>(this.CANVIAR_PASSWORD_URL, {token: token, newPassword: encodedPassword});
  }
}
