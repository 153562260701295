import {Component, Inject, Injector, OnInit} from '@angular/core';
import {SolicitudProductoHttpService} from "../../../../core/services/http/solicitud/solicitud-producto.http.service";
import {
  SolicitudProductoCompuestoHttpService
} from "../../../../core/services/http/solicitud/solicitud-producto-compuesto.http.service";
import {Ubicaciones, UbicacionesEtiquetaje} from "../../services/etiquetas-compuesto.service";
import {SolicitudProductoCompuesto} from "../../../../core/model/etiquetas/solicitud-producto-compuesto";
import {CategoriaEcologica} from "../../../../core/model/master/categoria-ecologica";
import {Envase} from "../../../../core/model/master/envase";
import {Ubicacion} from "../../../../core/model/master/ubicacion";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {ProductoService} from "../../../../core/services/http/producto.service";
import {EnvasesHttpService} from "../../../../core/services/http/solicitud/envases.http.service";
import {
  CategoriasEcologicasHttpService
} from "../../../../core/services/http/solicitud/categorias-ecologicas.http.service";
import {UbicacionesHttpService} from "../../../../core/services/http/solicitud/ubicaciones.http.service";
import {EtiquetasService} from "../../services/etiquetas.service";
import {ModalService} from "../../../../core/services/components/modal.service";
import {AlbaranModalComponent} from "../albaran-modal/albaran-modal.component";
import {map} from "rxjs/operators";
import {SolicitudHttpService} from "../../../../core/services/http/solicitud/solicitud.http.service";

@Component({
  template: '<div></div>'
})
export abstract class EditProductoCompuestoBaseModalComponent implements OnInit {

  // Services
  protected productoSvc: ProductoService;
  protected solicitudProductoSvc: SolicitudProductoHttpService;
  protected solicitudProductoMultiSvc: SolicitudProductoCompuestoHttpService;
  protected envasesSvc: EnvasesHttpService;
  protected categoriaEcologicaSvc: CategoriasEcologicasHttpService;
  protected ubicacionesSvc: UbicacionesHttpService;
  protected etiquetasSvc: EtiquetasService;
  protected modalSvc: ModalService;
  protected solicitudSvc: SolicitudHttpService;

  // Data
  protected producto: SolicitudProductoCompuesto;
  protected categorias: Observable<CategoriaEcologica[]>;
  protected envases: Observable<Envase[]>;
  protected ubicacionesElaboracion: Observable<Ubicacion[]>;
  protected ubicacionesEnvasado: Observable<Ubicacion[]>;
  protected ubicacionesEtiquetaje: Observable<Ubicacion[]>;
  protected ubicacionEnum = Ubicaciones;
  protected ubicacionEtiquetajeEnum = UbicacionesEtiquetaje;
  protected esPinsoFiltroPopUpAlbaran: Boolean;
  protected solicitudEstado: number;

  // FormGroup and auxiliars
  protected formGroup: FormGroup;
  protected readOnly: boolean = false;
  protected idUbicacionElaboracion!: number;
  protected idUbicacionEnvase!: number;
  protected idUbicacionEtiqueta!: number;
  protected esVisualizacion: boolean;

  protected literalesUbicaciones = {
    1: 'ETIQUETAS.instalacionesDireccionMunicipio',
    2: 'ETIQUETAS.instalacionesSubcontratada'
  }

  constructor(@Inject(MAT_DIALOG_DATA) data: { producto: SolicitudProductoCompuesto },
              protected dialogRef: MatDialogRef<any>,
              injector: Injector) {

    this.productoSvc = injector.get(ProductoService);
    this.solicitudProductoSvc = injector.get(SolicitudProductoHttpService);
    this.solicitudProductoMultiSvc = injector.get(SolicitudProductoCompuestoHttpService);
    this.envasesSvc = injector.get(EnvasesHttpService);
    this.categoriaEcologicaSvc = injector.get(CategoriasEcologicasHttpService);
    this.ubicacionesSvc = injector.get(UbicacionesHttpService);
    this.etiquetasSvc = injector.get(EtiquetasService);
    this.modalSvc = injector.get(ModalService);
    this.solicitudSvc = injector.get(SolicitudHttpService);
    this.producto = data.producto;

    this.formGroup = new FormGroup({
      "idSolicitudProducto": new FormControl(this.producto.idSolicitudProducto),
      "idSolicitud": new FormControl(this.producto.idSolicitud),
      "idProducto": new FormControl('', [Validators.required]),
      "nombreComercial": new FormControl('', [Validators.required, Validators.maxLength(90)]),
      "idCategoriaEcologica": new FormControl(null, [Validators.required]),
      "marca": new FormControl('', [Validators.required, Validators.maxLength(90)]),
      "idEnvase": new FormControl(null, [Validators.required]),
      "capacidad": new FormControl('', [Validators.required, Validators.maxLength(30)]),
      "requiereEtiqueta": new FormControl(false),
      "auxiliaresTecnologicos": new FormControl('', Validators.maxLength(1800)),
      "tecnicaTransformacion": new FormControl('', [Validators.required, Validators.maxLength(90)]),
      "idUbicacionElaboracion": new FormControl(null, [Validators.required]),
      "idUbicacionEnvase": new FormControl(null, [Validators.required]),
      "idUbicacionEtiqueta": new FormControl(null, [Validators.required]),
      "textoElaboracion": new FormControl('', Validators.maxLength(90)),
      "textoEnvase": new FormControl('', Validators.maxLength(90)),
      "textoEtiquetaje": new FormControl('', Validators.maxLength(90)),
      "nombreCorrecto": new FormControl(null)
    });

  }

  ngOnInit(): void {
    this.esVisualizacion = this.etiquetasSvc.esVisualizacion;
    this.envases = this.envasesSvc.getEnvases();
    this.categorias = this.categoriaEcologicaSvc.getCategorias();
    this.ubicacionesElaboracion = this.ubicacionesSvc.getUbicacionesElaboracion();
    this.ubicacionesEnvasado = this.ubicacionesSvc.getUbicacionesEnvasado();
    this.ubicacionesEtiquetaje = this.ubicacionesSvc.getUbicacionesEtiquetaje();
    this.ubicacionesEtiquetaje = this.ubicacionesEtiquetaje.pipe(
        map(ubicacionesEtiquetaje => [ubicacionesEtiquetaje[0], ubicacionesEtiquetaje[1], ubicacionesEtiquetaje[3]])
    )

    if (this.producto.idSolicitudProducto) {

      this.solicitudProductoMultiSvc.getProducto(this.producto.idSolicitudProducto).subscribe(producto => {
        this.producto = producto;
        this.formGroup.patchValue({
          ...this.producto,
          "idProducto": {id: this.producto.idProducto, valor: this.producto.nombreProducto}
        });

        this.handleUbicacionesChanges(this.producto)
        this.handleFiltroPopUpAlbaran();
      });
    }

    this.solicitudSvc.getSolicitud(this.producto.idSolicitud).subscribe(solicitud => {
      this.solicitudEstado = solicitud.estado;

      if (this.solicitudEstado == 1) {
        this.formGroup.valueChanges.subscribe(values => this.handleUbicacionesChanges(values));
      }
    });
  }

  handleUbicacionesChanges(values: any) {
    this.idUbicacionElaboracion = values.idUbicacionElaboracion;
    this.idUbicacionEnvase = values.idUbicacionEnvase;
    this.idUbicacionEtiqueta = values.idUbicacionEtiqueta;

    this.normalizeTextosUbicaciones(this.formGroup.get('textoElaboracion'), values.idUbicacionElaboracion);
    this.normalizeTextosUbicaciones(this.formGroup.get('textoEnvase'), values.idUbicacionEnvase);
    this.normalizeTextosUbicacionesEtiquetaje(this.formGroup.get('textoEtiquetaje'), values.idUbicacionEtiqueta);
  }

  protected abstract onSubmit() : void;

  private normalizeTextosUbicaciones(formControl: AbstractControl | null, ubicacion: Ubicaciones): void {
    if (formControl && ubicacion == Ubicaciones.ELABORADO) {
      formControl.setValue('', {emitEvent: false});
    }
  }

  private normalizeTextosUbicacionesEtiquetaje(formControl: AbstractControl | null, ubicacion: UbicacionesEtiquetaje): void {
    if (formControl && (ubicacion == UbicacionesEtiquetaje.ETIQUETADO || ubicacion == UbicacionesEtiquetaje.SIN_ETIQUETAR)) {
      formControl.setValue('', {emitEvent: false});
    }
  }

  handleFiltroPopUpAlbaran() {
    this.resetidUbicacionEtiqueta();

    this.productoSvc.getProducto(this.formGroup.get('idProducto')?.value).subscribe(producto => {
      let orientacionProducto = producto.orientacionProducto.idOrientacionProd;
      if (orientacionProducto === '900000') {
        this.esPinsoFiltroPopUpAlbaran = true;
      } else {
        this.esPinsoFiltroPopUpAlbaran = false;
      }
    });
  }

  resetidUbicacionEtiqueta(){
    if (this.formGroup.get('idUbicacionEtiqueta')?.value == 4 && this.esPinsoFiltroPopUpAlbaran != null) {
      this.formGroup.get('idUbicacionEtiqueta')?.setValue(null);
    }
  }

  handlePopUpAlbaran(){
    if(this.idUbicacionEtiqueta == UbicacionesEtiquetaje.SIN_ETIQUETAR){
      return this.modalSvc.openDialog(AlbaranModalComponent, {esPinsoFiltroPopUpAlbaran: this.esPinsoFiltroPopUpAlbaran}, {width: "900px"});
    } else {
      return null;
    }
  }

}
