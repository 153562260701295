import {CategoriaEcologicaEnum} from "../../../../core/model/master/categoria-ecologica";

export const EditEtiquetaCompromisosTexts: Map<CategoriaEcologicaEnum, string[]> = new Map([
	[CategoriaEcologicaEnum.AE_95, [
		"Referència al mètode de producció ecològica a la denominació de venda del producte",
		"Referència al mètode de producció ecològica a la llista dels ingredients, si aplica.",
		"Logotip de producció ecològica europeu (eurofulla)",
		"Codi de l’autoritat de control (ES-ECO-019-CT) en el mateix camp visual que l’eurofulla",
		"Indicació l’origen de les matèries primeres agràries (Agricultura UE, Agricultura no UE o Agricultura UE/no UE) Sota del codi ES-ECO-019-CT"
	]],
	[CategoriaEcologicaEnum.AE_95_NO_ELABORADOS, [
		"Referència al mètode de producció ecològica a la denominació de venda del producte",
		"Logotip de producció ecològica europeu (eurofulla)",
		"Codi de l’autoritat de control (ES-ECO-019-CT) en el mateix camp visual que l’eurofulla",
		"Indicació l’origen de les matèries primeres agràries (Agricultura UE, Agricultura no UE o Agricultura UE/no UE) Sota del codi ES-ECO-019-CT"
	]],
	[CategoriaEcologicaEnum.CONVERSION,[
		"Referència al mètode de producció en conversió a l’agricultura ecològica a la denominació de venda del producte",
		"Codi de l’autoritat de control (ES-ECO-019-CT)"
	]],
	[CategoriaEcologicaEnum.INGREDIENTS_AE, [
		"Referència al mètode de producció ecològica únicament a la llista d’ingredients i en relació amb els ingredients ecològics",
		"Percentatge total dels ingredients ecològics en relació a la quantitat total d’ingredients agraris",
		"Codi de l’autoritat de control (ES-ECO-019-CT)"
	]],
	[CategoriaEcologicaEnum.INGREDIENT_CAZA_PESCA, [
		"Referència al mètode de producció ecològica a la denominació de venda, clarament relacionada amb un ingredient ecològic o grup d’ingredients diferent de l’ingredient principal",
		"Referència al mètode de producció ecològica a la llista d’ingredients, en relació amb els ingredients ecològics",
		"Percentatge total dels ingredients ecològics en relació a la quantitat total d’ingredients agraris",
		"Codi de l’autoritat de control (ES-ECO-019-CT) "]
	],
	[CategoriaEcologicaEnum.PAE_CONFORMITAT_PINSOS, [
		"La menció “Pot utilitzar-se en l’agricultura ecològica de conformitat amb el Reglament (UE) nº 2018/848” en relació a la denominació de venda del producte",
		"Referència al mètode de producció ecològica a la llista d’ingredients i en relació amb els ingredients ecològics i/o en conversió a l’agricultura ecològica",
		"Percentatge total dels ingredients d’origen agrícola,  expressat en pes de matèria seca",
		"Percentatge total dels ingredients d’origen agrícola procedents de l’agricultura ecològica, expressat en pes de matèria seca",
		"Percentatge total dels ingredients d’origen agrícola en conversió a l’agricultura ecològica, expressat en pes de matèria seca",
		"Codi de l’autoritat de control (ES-ECO-019-CT)"
	]]
]);

export const EditEtiquetaCompromisosNOPTexts: Map<number, string[]> = new Map([
	[0, [
		"CERTIFIED ORGANIC BY CCPAE a sota de les dades d’identificació de l’elaborador o embotellador del producte.",
		"CONTAIN SULFITES (si el sulfurós total està entre 10 i 100 ppm)",
		"<p>GOVERNMENT WARNING: <br> (1) According to the surgeon general, women should not drink alcoholic beverages during pregnancy because of the risk of birth defects.<br>(2) Consumption of alcoholic beverages impairs your ability to drive a car or operate machinery, and may cause health problems.</p>"
	]]
]);
