import {Pipe, PipeTransform} from '@angular/core';
import {ParseFunction, ToSelectItemPipe} from "./to-select-item.pipe";
import {SelectItem} from "../model/select-item";

@Pipe({
  name: 'arrayToSelectItem'
})
export class ArrayToSelectItemPipe implements PipeTransform {

  private toSelectItemPipe = new ToSelectItemPipe();

  transform(list: any[], idField: string, fieldOrFunction: string | ParseFunction): SelectItem[] {

    if (!list)
      return []

    return list.map(item => this.toSelectItemPipe.transform(item, idField, fieldOrFunction));
  }


}
