<div class="col-12 mt-2 p-0">
    <label *ngIf="label && !campoObligatorio" [for]="id" class="font-weight-bold">{{label}}</label>
    <label *ngIf="label && campoObligatorio" [for]="id" class="font-weight-bold">{{label}} *</label>
    <div class="input-group">
        <textarea
            [rows]="rows"
            [value]="value" [readOnly]="isDisabled" (keyup)="change($event)"
            class="form-control col-12 m-0" autocomplete="off"
            [ngClass]="{'is-invalid': ngControl?.invalid && modificado}"
            [disabled]="isDisabled"
        >
        </textarea>
        <div class="input-group-append">
            <button
                    (click)="copyText()"
                    *ngIf="copy"
                    class="btn btn-outline-secondary"
                    type="button" [ngbTooltip]="'general.copiado' | translate" triggers="click:blur">
                <mat-icon>file_copy</mat-icon>
            </button>
        </div>
        <div *ngIf="ngControl?.invalid" class="invalid-feedback">
            <span *ngIf="ngControl.errors['required']">
                {{'formErrors.obligatorio' | translate}}
            </span>
                <span *ngIf="ngControl.errors['maxlength']">
                {{'formErrors.longitudExcedida'
                    | translate:{value: ngControl.errors['maxlength']['requiredLength']}
                    }}
            </span>
        </div>
    </div>
</div>
