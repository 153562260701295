import { Component, Inject, Injector, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Registro} from "../../../../core/model/instalacion/registro";
import {InstalacionHttpService} from "../../../../core/services/http/operadores/instalacion-http.service";
import {TipoRegistro} from "../../../../core/model/instalacion/tipoRegistro";
import {Observable} from "rxjs";

@Component({
  selector: 'app-edit-operador-instalacion-registro-modal',
  templateUrl: './edit-operador-instalacion-registro-modal.component.html',
  styleUrls: ['./edit-operador-instalacion-registro-modal.component.scss']
})
export class EditOperadorInstalacionRegistroModalComponent implements OnInit {

  protected instalacionSvc: InstalacionHttpService;
  protected idOperador: string;
  protected numOrdenInstalacion: number;
  protected numOrdenRegistro: number;
  protected registro: Registro;
  protected tiposRegistro: Observable<TipoRegistro[]>;
  protected formGroup: FormGroup;
  protected readOnly: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) data: {idOperador: string, registro: Registro},
              public dialogRef: MatDialogRef<EditOperadorInstalacionRegistroModalComponent>,
              injector: Injector) {

    this.instalacionSvc = injector.get(InstalacionHttpService);
    this.idOperador = data.idOperador;
    this.registro = data.registro;

    this.formGroup = new FormGroup({
      "idInscrito": new FormControl(null), // not null DB
      "numOrdenInstalacion": new FormControl(null), // not null DB
      "numOrdenRegistro": new FormControl(null), // not null DB
      "codigoRegistro": new FormControl(null, Validators.required), // not null DB
      "descripcionRegistro": new FormControl(null), // not null DB
      "numRegistro": new FormControl(null, [Validators.required, Validators.maxLength(20)]), // not null DB
      "actividadAutorizada": new FormControl(null, [Validators.required, Validators.maxLength(80)]), // not null DB
      "fechaCaducidad": new FormControl(null, Validators.required), // not null DB
    });

  }

  ngOnInit(): void {
    this.tiposRegistro = this.instalacionSvc.getTiposRegistro();

    if (this.registro?.numOrdenRegistro) {
      this.instalacionSvc.getRegistro(this.idOperador, this.registro.numOrdenInstalacion, this.registro.numOrdenRegistro)
          .subscribe(registro => {
            this.registro = registro;
            this.formGroup.patchValue({
              ...this.registro,
            });
          });
    }

  }

  enableReadOnly() {
    this.readOnly = true;
    this.formGroup.disable({emitEvent: false});
    this.formGroup.get("idOperador")?.enable({emitEvent: false});
    this.formGroup.get("numOrdenRegistro")?.enable({ emitEvent: false});
  }

  onSubmit() {
    this.dialogRef.close(this.formGroup.value);
  }

}
