import {TablaColumna} from "../../../../shared/components/generic-table/model/tabla-columna";
import {TablaAccion} from "../../../../shared/components/generic-table/model/tabla-accion";
import {Animal} from "../../../../core/model/animal/animal";

export const OperadoresAnimalTableConfig: TablaColumna[] =  [
    {
        header: 'OPERADORES.granjas.numGranja',
        field: "numGranja",
        type: "string"
    },
    {
        header: 'OPERADORES.animales.especie',
        field: "especie",
        type: "string"
    },
    {
        header: 'OPERADORES.animales.raza',
        field: "raza",
        type: "string"
    },
    {
        header: 'OPERADORES.animales.tipoGanado',
        field: "tipoGanado",
        type: "string"
    },
    {
        header: 'OPERADORES.animales.indicadorUbm',
        field: "indicadorUbm",
        type: "function",
        fn: (animal: Animal) => {
            return animal.indicadorUbm == "si" ? 'general.si' : 'general.no';
        }
    }
]

export const OperadoresAnimalViewAccionesConfig: TablaAccion[] = [
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure dades"
    }
]

export const OperadoresAnimalEditAccionesConfig: TablaAccion[] = [
   {
        action: "edit",
        icon: "edit",
        toolTip: "Editar animal"
    },
    {
        action: "view",
        icon: "pageview",
        toolTip: "Veure dades"
    },
    {
        action: "delete",
        icon: "delete",
        toolTip: "Eliminar animal"
    }

]