import {BaseObj} from "../core/baseObj";

export class Etiqueta extends BaseObj{

   idEtiqueta!: number;
   nombre?: string;
   path?: string;
   fecha?: Date;
   contentType?: string;

}