import {HttpService} from "../core/http.service";
import {Observable, tap} from "rxjs";
import {Pageable} from "../../../model/core/pageable";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../components/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {Parcela} from "../../../model/parcela/parcela";
import {Producto} from "../../../model/autorizacion/producto";
import {Variedad} from "../../../model/parcela/variedad";
import {Cualificacion} from "../../../model/animal/cualificacion";
import {ResultadoImportDarp} from "../../../model/parcela/resultadoImportDarp";

@Injectable({
    providedIn: 'root'
})
export class ParcelaHttpService extends HttpService {


    private BASE = `parcelas`;

    private PARCELAS = (id: string) => `${this.BASE}/${id}`
    private PARCELA = (idOperador: string, idParcela: number) =>
        `${this.BASE}/${idOperador}/${idParcela}`;
    private PARCELA_CREATE = (idOperador: string) =>
        `${this.BASE}/${idOperador}/create`;

    private IMPORT_DARP = (idOperador: string) =>
        `${this.BASE}/${idOperador}/importDARP`;

    private CULTIVOS = `${this.BASE}/cultivos`
    private VARIEDADES = `${this.BASE}/variedades`
    private CUALIFICACIONES = `${this.BASE}/cualificaciones`

    constructor(http: HttpClient,
                toastSvc: ToastService,
                private translateSvc: TranslateService) {
        super(http, toastSvc);
    }

    public getParcelas(idOperador: string, page: number | null = null, filtros: object = {}): Observable<Pageable<Parcela>> {
        let httpParams = this.objectToHttpParams(filtros);
        if (page) {
            httpParams = httpParams.set("page", page - 1);
        }
        return this.get(this.PARCELAS(idOperador), httpParams)
            .pipe(tap(page => page.content = page.content.map(item => Object.assign(new Parcela(), item))));
    }

    public getParcela(idOperador: string, idParcela: number): Observable<Parcela> {
        return this.get(this.PARCELA(idOperador, idParcela));
    }

    public createParcela(idOperador: string, parcela: Parcela): Observable<Parcela> {
        return this.post(this.PARCELA_CREATE(idOperador), parcela)
            .pipe(
                tap(_ =>this.toastSvc.showSuccess(this.translateSvc.instant("successes.createParcelaOk")))
            );
    }

    public updateParcela(idOperador: string, numOrden: number, parcela: Parcela): Observable<Parcela> {
        return this.put(this.PARCELA(idOperador, numOrden), parcela)
            .pipe(
                tap(_ =>this.toastSvc.showSuccess(this.translateSvc.instant("successes.modificacionParcelaOk")))
            );
    }

    public eliminarParcela(idOperador: string, idParcela: number){
        return this.delete(this.PARCELA(idOperador, idParcela));
    }

    public getCultivos(): Observable<Producto[]> {
        return this.get(this.CULTIVOS);
    }

    public getVariedades(): Observable<Variedad[]> {
        return this.get(this.VARIEDADES);
    }

    public getCualificaciones(): Observable<Cualificacion[]> {
        return this.get(this.CUALIFICACIONES);
    }

    public importDARP(idOperador: string): Observable<ResultadoImportDarp>  {
        return this.get(this.IMPORT_DARP(idOperador))
            .pipe(
                tap(response => {
                    this.toastSvc.showSuccess(this.translateSvc.instant("successes.importDARPOk"));
                })
            );
    }

}