import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Page} from "../../../../core/model/core/pages/page";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AccionesTable} from "./operadores-alta.config";
import {ModalService} from "../../../../core/services/components/modal.service";
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {Operador} from "../../../../core/model/operadores/operador";

export enum OperadorFormularioView {
  VIEW_DADES_GENERALS,
  VIEW_DADES_MAGATZEMS,
  VIEW_DADES_PRODUCCIO,
  VIEW_DOCUMENTACIO,
  VIEW_PAGAMENT
}

@Component({
  selector: 'app-operadores-alta',
  templateUrl: './operadores-alta.component.html',
  styleUrls: ['./operadores-alta.component.scss']
})
export class OperadoresAltaComponent extends Page implements OnInit, OnDestroy{
  operadoresSvc: OperadoresHttpService;

  protected formGroup: FormGroup;
  protected formGeneral: FormGroup;
  protected formProduccion: FormGroup;
  protected formDocumentacion: FormGroup;

  public actualView = OperadorFormularioView.VIEW_DADES_GENERALS;
  public viewEnumForm = OperadorFormularioView;

  protected tableAcciones = AccionesTable;
  protected families: Map<string, any>;
  protected operador: Operador;

  constructor(protected modalSvc: ModalService,
              injector: Injector) {
    super();
    this.operadoresSvc = injector.get(OperadoresHttpService);

    this.formGroup = new FormGroup({
      "pagament": new FormGroup({
        "tipusPagament": new FormControl(null, Validators.required)
      }),
    })

    this.families = new Map<string, boolean>([
        ["prodPrimaria", false],
        ["preparacio", false],
        ["importacioExportacio", false],
        ["distMajorista", false],
        ["distMinorista", false],
        ["prodAlguesFito", false],
        ["estandard", false],
        ["comercialitzadors", false],
        ["importadors", false]
    ]);
  }

  private beforeUnloadHandler(event: BeforeUnloadEvent){
    // Cancel the event
    event.preventDefault();
    // Chrome requires returnValue to be set
    event.returnValue = '';

    // Display the confirmation dialog box
    return 'Are you sure you want to leave this page? Any unsaved data will be lost.';
  }

  ngOnInit() {
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  }


  changeViewSiguiente() {
    document.getElementById("top")?.scrollIntoView();

    switch (this.actualView) {
      case OperadorFormularioView.VIEW_DADES_GENERALS:
        this.actualView = OperadorFormularioView.VIEW_DADES_MAGATZEMS;
        break;
      case OperadorFormularioView.VIEW_DADES_MAGATZEMS:
        this.actualView = OperadorFormularioView.VIEW_DADES_PRODUCCIO;
        break;
      case OperadorFormularioView.VIEW_DADES_PRODUCCIO:
        this.actualView = OperadorFormularioView.VIEW_DOCUMENTACIO;
        break;
      case OperadorFormularioView.VIEW_DOCUMENTACIO:
        this.actualView = OperadorFormularioView.VIEW_PAGAMENT;
        break;
    }
  }

  changeViewAtras(){
    document.getElementById("top")?.scrollIntoView();

    switch (this.actualView) {
      case OperadorFormularioView.VIEW_DADES_MAGATZEMS:
        this.actualView = OperadorFormularioView.VIEW_DADES_GENERALS;
        break;
      case OperadorFormularioView.VIEW_DADES_PRODUCCIO:
        this.actualView = OperadorFormularioView.VIEW_DADES_MAGATZEMS;
        break;
      case OperadorFormularioView.VIEW_DOCUMENTACIO:
        this.actualView = OperadorFormularioView.VIEW_DADES_PRODUCCIO;
        break;
      case OperadorFormularioView.VIEW_PAGAMENT:
        this.actualView = OperadorFormularioView.VIEW_DOCUMENTACIO;
        break;
    }
  }

  validateFormPagament() {
    return this.formGroup.controls["pagament"].valid;
  }

  onPageConfirmed($event) {
    switch ($event.page) {
      case OperadorFormularioView.VIEW_DADES_GENERALS:
        this.formGeneral = $event.formGroup;
        this.operador = $event.operador;
        this.handleFamilies();
        break;
      case OperadorFormularioView.VIEW_DADES_PRODUCCIO:
        this.formProduccion = $event.formGroup;
        this.operador.almacenes = $event.almacenes;
        break;
      case OperadorFormularioView.VIEW_DOCUMENTACIO:
        this.formDocumentacion = $event.formGroup
        break;
    }
    this.changeViewSiguiente();
  }

  private handleFamilies() {
    for (let key of this.families.keys()) {
      this.families.set(key, this.formGeneral.get("dadesProduccio." + key)?.value);
    }
  }

  onConfirmarFinal() {

  }

}
