import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {ExternalGuard} from "./external.guard";
import {AuthService, JWTDataExternal} from "../services/security/auth.service";
import {EtiquetasService} from "../../modules/etiquetas/services/etiquetas.service";

@Injectable({
	providedIn: 'root'
})
export abstract class ExternalEtiquetasGuard extends ExternalGuard implements CanActivate {

	constructor(authSvc: AuthService,
				public etiquetasSvc: EtiquetasService) {
		super(authSvc);
	}

	override preIsAllowed(route: ActivatedRouteSnapshot) {
		super.preIsAllowed(route);

		let token = this.getToken();
		if (token != null) {
			const dataToken = AuthService.extractData(token) as JWTDataExternal;
			this.etiquetasSvc.initTramite(dataToken);
		}
	}

}
