import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-disclaimer-alta-confirm',
  templateUrl: './disclaimer-alta-confirm.component.html',
  styleUrls: ['./disclaimer-alta-confirm.component.scss']
})
export class DisclaimerAltaConfirmComponent {

  protected checkboxAceptado: boolean = false;

  constructor(protected dialogRef: MatDialogRef<DisclaimerAltaConfirmComponent>) { }

}
